/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, MutableRefObject, PropsWithChildren, FunctionComponent, useRef } from "react";
import { Step } from "react-joyride";
import { formWizardConfirmClassName, Tour, TourForwardRef } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { dataDrivenButtonClassName } from "./edit-style-item";

export const tourLayerAttributesClassName = "tour-layer-attirbutes";
export const tourLayerNameClassName = "tour-layer-name";
export const tourLayerStylesClassName = "tour-layer-styles";

type StepWithId = Step & { id: string };

interface LayerTourProps {
    running: boolean;
    onFinish: () => void;
}

export interface LayerTourContextProps {
    ref: MutableRefObject<TourForwardRef>;
}

export const LayerTourContext = createContext<LayerTourContextProps>(null);

export const LayerTour: FunctionComponent<PropsWithChildren<LayerTourProps>> = ({ running, onFinish, children }) => {
    const translate = useIventisTranslate();

    const ref = useRef<TourForwardRef>();

    const steps: StepWithId[] = [
        {
            id: "step-1",
            title: translate(Content.map4.layer_tour.tip_1_welcome.title),
            content: translate(Content.map4.layer_tour.tip_1_welcome.content),
            placement: "center",
            target: "body",
        },
        {
            id: "step-2",
            title: translate(Content.map4.layer_tour.tip_2_name.title),
            content: translate(Content.map4.layer_tour.tip_2_name.content),
            disableBeacon: true,
            placement: "bottom",
            target: `.${tourLayerNameClassName}`,
        },
        {
            id: "step-3",
            title: translate(Content.map4.layer_tour.tip_3_attributes.title),
            content: translate(Content.map4.layer_tour.tip_3_attributes.content),
            disableBeacon: true,
            placement: "bottom",
            target: `.${tourLayerAttributesClassName}`,
        },
        {
            id: "step-4",
            title: translate(Content.map4.layer_tour.tip_4_styles.title),
            content: translate(Content.map4.layer_tour.tip_4_styles.content),
            disableBeacon: true,
            placement: "bottom",
            target: `.${tourLayerStylesClassName}`,
        },
        {
            id: "step-5",
            title: translate(Content.map4.layer_tour.tip_5_attribute_styles.title),
            content: translate(Content.map4.layer_tour.tip_5_attribute_styles.content),
            disableBeacon: true,
            placement: "bottom",
            target: `.${dataDrivenButtonClassName}`,
        },
        {
            id: "step-6",
            title: translate(Content.map4.layer_tour.tip_6_save.title),
            content: translate(Content.map4.layer_tour.tip_6_save.content),
            disableBeacon: true,
            placement: "top",
            target: `.${formWizardConfirmClassName}`,
        },
    ];

    return (
        <>
            <Tour running={running} steps={steps} onSkip={() => onFinish()} onFinish={() => onFinish()} ref={ref} />
            <LayerTourContext.Provider value={{ ref }}>{children}</LayerTourContext.Provider>
        </>
    );
};
