/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { styled, muiInputFormsCSS, narrowPadding } from "@iventis/styles";

import { ICellEditor } from "@ag-grid-community/core";
import { AutocompleteWithLoading } from "@iventis/components";
import { ListItemCellEditorProps, ListItem, IventisTableData } from "../types/data-table.types";
import { useLazyLoadOptions } from "../lib/hooks";

const noSelectionOption = { id: undefined, name: undefined };

/**
 * Used as cellEditor for data table column of type "list-item"
 */
export const ListItemCellEditorComponent = forwardRef(_ListItemCellEditorComponent);

// eslint-disable-next-line no-underscore-dangle
function _ListItemCellEditorComponent<TData extends IventisTableData = IventisTableData>(
    { value: inputValue, componentParams: propOptions, filterOptions, column, node, colDef }: ListItemCellEditorProps<TData>,
    ref: React.ForwardedRef<ICellEditor>
) {
    const { listItems } = propOptions;
    const [loading, options] = useLazyLoadOptions<ListItem>(filterOptions ? filterOptions(listItems as ListItem[], node, column.getColId()) : listItems);
    options.push(noSelectionOption);
    if (!options.some((item) => item.id === inputValue?.id) && inputValue !== undefined) {
        // Attach the current value to the options if it is not in the options array. This makes the value visible even if we don't have all options yet
        options.push(inputValue);
    }
    const [value, setValue] = useState<{ id: string; name: string }>(inputValue);

    const initialValue = useMemo(() => options.find((item) => item.id === inputValue?.id), [options]);

    // The initial value may change if the options are lazy-loaded
    useEffect(() => setValue(initialValue), [initialValue]);

    const isInvalid = false;

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => ({
        // the final value to send to the grid, on completion of editing
        getValue() {
            const result = value ?? initialValue;
            return result;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
            return !node.data.canEdit;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
            return isInvalid || inputValue?.id === value?.id;
        },
    }));

    // Open with options visible by default
    return (
        <StyledAutocomplete
            loadingOptions={loading}
            disableClearable
            style={{ width: column.getActualWidth() }}
            value={value || noSelectionOption}
            autoFocus
            variant="standard"
            onChange={(value: { id: string; name: string } | string) => {
                const listItemId = typeof value === "string" ? value : value.id;
                setValue(options.find((i) => i?.id === listItemId || i?.name === listItemId));
            }}
            id={`${node.id}_${colDef.field}_editor`}
            options={options}
            filterOptions={(options, { inputValue }) => options.filter((option: ListItem) => option !== noSelectionOption && option.name.includes(inputValue))}
            getOptionLabel={(v) => (v as { name: string })?.name || ""}
            error={isInvalid}
        />
    );
}

export const StyledAutocomplete = styled(AutocompleteWithLoading)`
    ${muiInputFormsCSS};
    background-color: ${({ theme }) => theme.dataTableTheme.background};

    /* Force the input to fit to the height of the cell */
    height: 100%;
    .MuiFormControl-root,
    .MuiInputBase-root,
    .MuiInputBase-input {
        height: 100%;
        box-sizing: border-box;
    }
    .MuiInputBase-root {
        padding-left: ${narrowPadding};
    }
`;
