import React, { FunctionComponent } from "react";
import { createInitialState, NodeServices, TreeBrowserComponent, useTreeBrowserInitialise } from "@iventis/tree-browser";
import { LinearProgress } from "@mui/material";
import { AdminAssetType, CategoryRootNode, CategoryTreeContext, parseRecommendedAssetType, RecommendedAssetType } from "./category-types";
import { categoryNodeServices, getCategories } from "./category-services";
import { RecommendedCategory } from "./recommended-category-node";

interface CategoryRepositoryBasicProps {
    assetType: RecommendedAssetType;
    onCategoryChecked: (categoryId: string) => void;
}

/** Uses normal category node services accept different getTree action */
const basicCategoryNodeServices: NodeServices<CategoryRootNode> = {
    ...categoryNodeServices,
    getTree: async (assetType: AdminAssetType) => {
        switch (assetType) {
            case RecommendedAssetType.RecommendedLayerTemplate: {
                const categories = await getCategories(parseRecommendedAssetType(assetType));
                return {
                    id: assetType,
                    sourceId: assetType,
                    name: assetType,
                    type: "CategoryRoot",
                    assetType: parseRecommendedAssetType(assetType),
                    iconAssetId: null,
                    recommended: true,
                    childNodes: categories.map((category) => ({
                        ...category,
                        sourceId: category.id,
                        parentId: assetType,
                        type: "RecommendedCategory",
                        checked: false,
                        childNodes: [],
                        projectId: null,
                        instanceName: null,
                    })),
                };
            }
            default:
                throw new Error("Unknown asset type");
        }
    },
};

export const CategoryRepositoryBasicComponent: FunctionComponent<CategoryRepositoryBasicProps> = ({ onCategoryChecked, assetType }) => {
    const [{ tree, expandedNodeIds }, treeBrowserContext] = useTreeBrowserInitialise(
        createInitialState<CategoryRootNode>({
            mainNodeId: assetType,
            treeId: assetType,
        }),
        {
            ...basicCategoryNodeServices,
            updateNode: async (node) => {
                onCategoryChecked(node.name);
                return node;
            },
        },
        null
    );

    if (tree == null) {
        return <LinearProgress />;
    }

    return (
        <CategoryTreeContext.Provider value={treeBrowserContext}>
            <TreeBrowserComponent components={{ RecommendedCategory }} node={tree} openNodes={expandedNodeIds} displayRootNode={false} />
        </CategoryTreeContext.Provider>
    );
};
