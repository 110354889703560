import React, { FunctionComponent } from "react";
import { Content } from "@iventis/translations/content/typed-content";
import { fontSizes } from "@iventis/styles/src/atomic-rules";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { convertPascalToSentence } from "@iventis/utilities";

export const StatusIcon: FunctionComponent<{ height: number; width: number; label: string; fill: string }> = ({ height, width, label, fill }) => (
    <svg id="svgelem" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" rx="11" ry="11" width={width} height={height} style={{ fill }} />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" style={{ fontSize: `${fontSizes.xSmall}` }} fontWeight="lighter" fill="white">
            {label}
        </text>
    </svg>
);

const publishedStatusDimensions = { height: 23, width: 120 };

export const DraftIcon = () => {
    const translate = useIventisTranslate();
    return (
        <StatusIcon
            label={translate(Content.schedule.create_schedule_form.status_field_options.draft_label).toUpperCase()}
            fill="#E89800"
            height={publishedStatusDimensions.height}
            width={publishedStatusDimensions.width}
        />
    );
};

export const PublishedIcon = () => {
    const translate = useIventisTranslate();
    return (
        <StatusIcon
            label={translate(Content.schedule.create_schedule_form.status_field_options.published_label).toUpperCase()}
            fill="#009DE8"
            height={publishedStatusDimensions.height}
            width={publishedStatusDimensions.width}
        />
    );
};

export const ProjectStatusIcon = ({ projectStatus }: { projectStatus: ProjectStatus }) => {
    const theme = useTheme<Theme>();
    const fillColour = () => {
        switch (projectStatus) {
            case ProjectStatus.Active:
                return theme.secondaryColors.accept;
            case ProjectStatus.Trial:
                return theme.toastColours.info;
            case ProjectStatus.InArrears:
            case ProjectStatus.Cancelled:
            default:
                return theme.secondaryColors.error;
        }
    };
    return <StatusIcon width={60} height={23} label={convertPascalToSentence(projectStatus)} fill={fillColour()} />;
};
