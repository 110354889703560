import { CustomDialog, FormWizardTemplate } from "@iventis/components";
import { Body1, unauthenticatedOverlayStyle } from "@iventis/styles";
import React from "react";

export const UnauthenticatedOverlay = () => (
    <CustomDialog open slotProps={{ backdrop: { style: unauthenticatedOverlayStyle } }}>
        <FormWizardTemplate
            title="Please sign in again"
            currentStage={0}
            stages={[{ isValid: true, primaryButtonText: "Reload", primaryButtonCallback: () => window.location.reload(), secondaryButtons: [] }]}
        >
            <Body1>
                You were signed out of your account. Please press ‘Reload’ once you are signed into the Iventis dashboard again. You can sign in via{" "}
                <span>
                    <a href="https://office.com/" target="_blank" rel="noopener noreferrer">
                        office.com
                    </a>
                </span>
            </Body1>
        </FormWizardTemplate>
    </CustomDialog>
);
