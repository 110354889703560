import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminSpatialLibraryNode } from "./tree-browser-types";
import { BasicSpatialNode } from "./basic-spatial-node";
import { ImportMapModal } from "./import-map";

export interface SpatialLibraryNodeProps {
    node: AdminSpatialLibraryNode;
    setMapInList: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}

export const SpatialLibraryNodeComponent = ({ node, setMapInList }: SpatialLibraryNodeProps) => {
    const [showImportDialog, setShowImportDialog] = useState(false);

    return (
        <>
            <BasicSpatialNode
                node={node}
                icon={<FontAwesomeIcon icon={{ prefix: "far", iconName: "folders" }} className="icon" size="lg" />}
                overflowOptions={[{ label: "Import map", key: "import", selected: () => setShowImportDialog(true) }]}
            />
            {showImportDialog && (
                <ImportMapModal
                    setMapInList={setMapInList}
                    nodeId={node.id}
                    instanceName={node.instanceName}
                    projectId={node.parentId}
                    onClose={() => setShowImportDialog(false)}
                />
            )}
        </>
    );
};
