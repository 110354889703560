/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { RangeCalendarState } from "@react-stately/calendar";
import { useCalendarCell, useLocale, useFocusRing, mergeProps, DateValue } from "react-aria";
import { isSameDay, getDayOfWeek, CalendarDate } from "@internationalized/date";
import { styled } from "@iventis/styles";
import { css } from "@emotion/react";
import { RangeValue } from "@react-types/shared";

export function CalendarCell({ state, date, updateStartEndDates }: { state: RangeCalendarState; date: CalendarDate; updateStartEndDates: (value: RangeValue<DateValue>) => void }) {
    const ref = useRef(null);
    const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate, isInvalid } = useCalendarCell({ date }, state, ref);

    updateStartEndDates(state.highlightedRange);

    // The start and end date of the selected range will have
    // an emphasized appearance.
    const isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
    const isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;

    // We add rounded corners on the left for the first day of the month,
    // the first day of each week, and the start date of the selection.
    // We add rounded corners on the right for the last day of the month,
    // the last day of each week, and the end date of the selection.
    const { locale } = useLocale();
    const dayOfWeek = getDayOfWeek(date, locale);
    const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
    const isRoundedRight = isSelected && (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

    const { focusProps, isFocusVisible } = useFocusRing();

    return (
        <td {...cellProps}>
            <StyledCell
                {...mergeProps(buttonProps, focusProps)}
                ref={ref}
                $isSelected={isSelected}
                $isRoundedLeft={isRoundedLeft}
                $isRoundedRight={isRoundedRight}
                $isInvalid={isInvalid}
                hidden={isOutsideVisibleRange}
            >
                <StyledDay
                    $isSelected={isSelected}
                    $isDisabled={isDisabled}
                    $isFocusVisible={isFocusVisible}
                    $isSelectionStart={isSelectionStart}
                    $isSelectionEnd={isSelectionEnd}
                    $isInvalid={isInvalid}
                >
                    {formattedDate}
                </StyledDay>
            </StyledCell>
        </td>
    );
}

const StyledCell = styled.div<{ $isSelected: boolean; $isRoundedLeft: boolean; $isRoundedRight: boolean; $isInvalid: boolean }>`
    text-align: center;
    outline: none;
    background-color: ${(props) => (props.$isSelected ? (props.$isInvalid ? "bg-red-300" : props.theme.secondaryColors.dark) : "none")};
    ${(props) => props.$isRoundedLeft && "border-top-left-radius: 50%; border-bottom-left-radius: 50%;"}
    ${(props) => props.$isRoundedRight && "border-top-right-radius: 50%; border-bottom-right-radius: 50%;"}
    margin-bottom: 4px;
    :hover {
        cursor: pointer;
    }
`;

const StyledDay = styled.div<{ $isSelected: boolean; $isDisabled: boolean; $isFocusVisible: boolean; $isSelectionStart: boolean; $isSelectionEnd: boolean; $isInvalid: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 12px 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => css`
        ${// Focus ring, visible while the cell has keyboard focus.
        props.$isFocusVisible ? "ring-2 group-focus:z-2 ring-violet-600 ring-offset-2" : ""} ${// Darker selection background for the start and end.
        props.$isSelectionStart || props.$isSelectionEnd
            ? props.$isInvalid
                ? "bg-red-600 text-white hover:bg-red-700"
                : `background-color: ${props.theme.primaryColors.focus};
                   color: ${props.theme.typographyColors.blank};`
            : ""} ${// Hover state for cells in the middle of the range.
        props.$isSelected && !props.$isDisabled && !(props.$isSelectionStart || props.$isSelectionEnd)
            ? props.$isInvalid
                ? "hover:bg-red-400"
                : `background-color: ${props.theme.secondaryColors.dark};`
            : ""} ${// Hover state for non-selected cells.
        !props.$isSelected && !props.$isDisabled ? "hover:bg-violet-100" : ""}
    `};
`;
