/** Converts time input output to a date object */
export function timeInputStringToDate(time: string) {
    // On click away the string is empty
    if (time !== "") {
        // Spilt the string
        const [hours, minutes] = time.split(":");
        const newTime = new Date();

        const intHours = parseInt(hours, 10);
        const intMins = parseInt(minutes, 10);

        // Ensure the hours and minutes are valid
        if (Number.isNaN(intHours) || Number.isNaN(intMins)) {
            return null;
        }

        // Set time to the new date object
        newTime.setHours(intHours);
        newTime.setMinutes(intMins);
        return newTime;
    }
    return null;
}

/** Converts date object to a string which can be used with a time input */
export function dateToTimeInputString(date: Date) {
    const hours = date.getHours();
    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursString}:${minutesString}`;
}
