/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { useDateRangePickerState } from "react-stately";
import { useDateRangePicker } from "react-aria";
import { AriaDateRangePickerProps } from "@react-types/datepicker";
import { InteractiveElement, styled, StyledFieldLabel } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@mui/material";
import { DateField } from "./Datefield";
import { RangeCalendar } from "./RangeCalendar";
import { DateValue } from "./date-types";

export function DateRangePicker(props: AriaDateRangePickerProps<DateValue> & { testId: string }) {
    const state = useDateRangePickerState(props);
    const ref = useRef();
    const { groupProps, labelProps, startFieldProps, endFieldProps, buttonProps, calendarProps } = useDateRangePicker(props, state, ref);
    const { label, testId } = props;
    // Need to disable this lint because we need to remove onPress from buttonProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onPress, ...rest } = buttonProps;

    return (
        <StyledWrapper>
            <StyledFieldLabel {...labelProps}>{label}</StyledFieldLabel>
            <StyledDateRangeInput {...groupProps} ref={ref} className="flex group">
                <StyledInputWrapper>
                    <DateField label="Start date" visuallyHideLabel {...startFieldProps} testId={testId} />
                    <StyledHyphen aria-hidden="true">–</StyledHyphen>
                    <DateField label="End date" visuallyHideLabel {...endFieldProps} />
                </StyledInputWrapper>
                <InteractiveElement {...rest} onClick={() => state.setOpen(!state.isOpen)}>
                    <StyledFontAwesomeIcon icon={["far", "calendar"]} />
                </InteractiveElement>
            </StyledDateRangeInput>

            <Popover
                open={state.isOpen}
                onClose={() => state.setOpen(false)}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <RangeCalendar {...calendarProps} startFieldProps={startFieldProps} endFieldProps={endFieldProps} />
            </Popover>
        </StyledWrapper>
    );
}

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const StyledInputWrapper = styled.div`
    display: flex;
    position: relative;
    padding: 4px;
`;

const StyledHyphen = styled.span`
    padding: 0 4px;
`;

const StyledDateRangeInput = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.shades.darkBorder};
    padding: 8px;
    border-radius: 4px;
    position: relative;
    height: 44px;
    box-sizing: border-box;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.shades.two};
`;
