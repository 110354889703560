import { useEffect, useRef, useState } from "react";

/**
 * Behaves like a use state except uses a useEffect to keep the state insync depending on the value of the loaded state
 * @param _isLoading is the synced state still loading
 * @param syncedState the state to sync too
 * @returns [state: T, setState: (value: T) => void, stateIsReady: boolean]
 */
export const useSyncState = <T>(_isLoading: boolean, syncState: T) => {
    const [state, setState] = useState<T>(syncState);
    const stateHasBeenSet = useRef(false);
    const [stateIsReady, setStateIsReady] = useState(!_isLoading);
    useEffect(() => {
        setState(syncState);

        if (!stateHasBeenSet.current) {
            setStateIsReady(true);
            stateHasBeenSet.current = true;
        }
    }, [_isLoading, syncState]);

    return [state, stateIsReady ? setState : () => null, stateIsReady] as const;
};
