import React, { FunctionComponent } from "react";

export const InsertRowAboveComponent: FunctionComponent<{ fill: string; height?: string; width?: string }> = ({ fill, height = "14", width = "16" }) => (
    <svg width={width} height={height} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.385 6.364c.34 0 .615.275.615.615v6.406c0 .34-.276.615-.615.615H.615A.615.615 0 0 1 0 13.385V6.979c0-.34.276-.615.615-.615h14.77zm-.616 4.454H1.231v1.91h13.538v-1.91zm0-3.182H1.231v1.91h13.538v-1.91zM15.433 0c.313 0 .567.254.567.567V3.25a.567.567 0 0 1-.567.567H4.567A.567.567 0 0 1 4 3.251V.567C4 .254 4.254 0 4.567 0h10.866zM0 0l2.857 1.91L0 3.817V0z"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
);
