/* eslint-disable no-param-reassign */
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapImportLayer } from "./map-json-types";

export type MissingAsset = {
    originalId: string;
    newId?: string;
    modelId?: string;
    name: string;
    type: AssetType;
};

/** Replaces all the asset ids with the layer's assets and styles based on missingAssets */
export function replaceAssetsInMap(layers: MapImportLayer[], missingAssets: MissingAsset[]): MapImportLayer[] {
    const oldAssetIdToNewAssetIdMap: { [oldAssetId: string]: string } = Object.fromEntries(
        missingAssets.map((missingAsset) => [missingAsset.originalId, missingAsset.modelId ?? missingAsset.newId])
    );

    const newLayers: MapImportLayer[] = layers.map((layer) => {
        switch (layer.styleType) {
            case StyleType.Icon: {
                const updatedIcons = layer.icons.map((icon) => ({ ...icon, id: oldAssetIdToNewAssetIdMap[icon.id] }));
                const newStyle = { ...layer.style, iconImage: replaceStaticAndMappedIdIfInDictionary(layer.style.iconImage, oldAssetIdToNewAssetIdMap) };
                return { ...layer, style: newStyle, icons: updatedIcons };
            }
            case StyleType.Model: {
                const updatedModels = layer.models.map((icon) => ({ ...icon, id: oldAssetIdToNewAssetIdMap[icon.id] }));
                const newStyle = { ...layer.style, model: replaceStaticAndMappedIdIfInDictionary(layer.style.model, oldAssetIdToNewAssetIdMap) };
                return { ...layer, style: newStyle, models: updatedModels };
            }
            case StyleType.LineModel: {
                const updatedModels = layer.models.map((icon) => ({ ...icon, id: oldAssetIdToNewAssetIdMap[icon.id] }));
                const newStyle = { ...layer.style, model: replaceStaticAndMappedIdIfInDictionary(layer.style.model, oldAssetIdToNewAssetIdMap) };
                return { ...layer, style: newStyle, models: updatedModels };
            }
            default:
                return layer;
        }
    });

    return newLayers;
}

function replaceStaticAndMappedIdIfInDictionary(value: StyleValue<string>, map: { [oldValue: string]: string }): StyleValue<string> {
    const newValue = value;
    if (newValue.extractionMethod === StyleValueExtractionMethod.Mapped) {
        // If mapped, go through the static values and replace if in the map
        const oldValues = newValue.mappedValues;
        Object.keys(oldValues).forEach((dataFieldId) => {
            const mappedValue = oldValues[dataFieldId].staticValue;
            newValue.mappedValues[dataFieldId].staticValue = map?.[mappedValue] ?? mappedValue;
        });
    }
    newValue.staticValue.staticValue = map?.[newValue.staticValue.staticValue] ?? newValue.staticValue.staticValue;
    return newValue;
}
