/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { styled } from "@iventis/styles";

export const What3WordsLogo = () => <What3WordsLogoContainer>///</What3WordsLogoContainer>;

const What3WordsLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2px;
`;
