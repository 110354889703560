export const INDEX_COL_ID = "index";
export const COUNT_COL_ID = "count";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const KEY_BACKSPACE = "Backspace";
export const KEY_F2 = "F2";
export const KEY_ENTER = "Enter";
export const KEY_TAB = "Tab";
export const KEY_ARROW_LEFT = "ArrowLeft";
export const KEY_ARROW_RIGHT = "ArrowRight";

export const TIME_RANGE_FROM = "time-range-from";
export const TIME_RANGE_TO = "time-range-to";
