import { useEffect, useState } from "react";
import { SortableDragAndDropProps } from "./drag-and-drop-types";

/** A hook which given a list of items, returns an onDrag event and a sorted version of the list of items which subscribes to that onDrag event */
export const useDragAndDropReordering = <TItem extends { id: string }>(items: TItem[], getOrderProperty?: (item: TItem) => number) => {
    const constructOrderObject = (items: TItem[]) =>
        items.reduce((acc, item, index) => {
            let order = index;
            if (typeof getOrderProperty === "function") {
                order = getOrderProperty(item);
            }
            return { ...acc, [item.id]: order };
        }, {});
    const [order, setOrder] = useState<Record<string, number>>(constructOrderObject(items));

    useEffect(() => {
        // If items have been removed or added, re-calculate the order
        setOrder(constructOrderObject(items));
    }, [items.length]);

    const getSortedItems = (order: Record<string, number>) => items.sort((a, b) => order[a.id] - order[b.id]);

    const onDragEvent: SortableDragAndDropProps<TItem>["onDragOver"] | SortableDragAndDropProps<TItem>["onDragEnd"] = (itemsBeingDragged, over) => {
        if (over) {
            setOrder((currentOrder) => {
                const sorted = getSortedItems(currentOrder);
                const itemOverIndex = sorted.findIndex((item) => item.id === over.id);
                const itemIndex = sorted.findIndex((item) => itemsBeingDragged[0].id === item.id);

                // Splice the array to move the new item to the correct position
                const newItems = [...sorted];
                newItems.splice(itemOverIndex, 0, newItems.splice(itemIndex, 1)[0]);

                return newItems.reduce((acc, item, index) => ({ ...acc, [item.id]: index }), {});
            });
        }
    };

    const sortedItems = getSortedItems(order);

    return { sortedItems, order, onDragEvent } as const;
};
