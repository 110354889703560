import React, { FunctionComponent, ReactNode, useMemo, useRef, useState } from "react";
import { LibraryOverflowPopover, OverflowOption, StyledExpansionContainer, findAllParentIds, findNode, useTreeBrowser } from "@iventis/tree-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body2, InteractiveElement } from "@iventis/styles";
import { StyledBaseNode } from "./project-node";
import { AdminSpatialLibraryNode, AdminFolderNode, AdminMapNode } from "./tree-browser-types";
import { TreeBrowserProjectsContext } from "./project.helpers";

export interface SpatialLibraryNodeProps {
    node: AdminSpatialLibraryNode | AdminFolderNode | AdminMapNode;
    icon: ReactNode;
    allowExpand?: boolean;
    overflowOptions?: OverflowOption<AdminSpatialLibraryNode | AdminFolderNode | AdminMapNode, string>[];
}

export const BasicSpatialNode: FunctionComponent<SpatialLibraryNodeProps> = ({ node, icon, allowExpand = true, overflowOptions = [] }: SpatialLibraryNodeProps) => {
    const [{ tree, mainNodeId, expandedNodeIds, loadingNodeIds }, treeBrowserContext] = useTreeBrowser(
        TreeBrowserProjectsContext,
        node.id,
        "tree",
        "mainNodeId",
        "expandedNodeIds",
        "loadingNodeIds"
    );
    const loading = loadingNodeIds.includes(node.id);
    const indentLevel = useMemo(() => findAllParentIds(findNode([tree], mainNodeId), node.id).length - 2, [tree, mainNodeId]);
    const expanded = expandedNodeIds.includes(node.id);

    const overflowButtonRef = useRef(null);
    const [overFlowOpen, setOverFlowOpen] = useState(false);

    return (
        <StyledBaseNode node={node} treeContext={TreeBrowserProjectsContext}>
            <StyledExpansionContainer indentLevel={indentLevel}>
                {allowExpand && (
                    <InteractiveElement
                        onClick={(e) => {
                            e.stopPropagation();
                            treeBrowserContext.toggleExpandNode(node);
                        }}
                    >
                        <FontAwesomeIcon icon={loading ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="lg" spin={loading} />
                    </InteractiveElement>
                )}
            </StyledExpansionContainer>
            {icon}
            <Body2 className="name">{node.name}</Body2>
            <div />
            {overflowOptions?.length > 0 && (
                <>
                    <InteractiveElement ref={overflowButtonRef} className="ellipsis" onClick={() => setOverFlowOpen((isOpen) => !isOpen)}>
                        <span className="hidden">Click to open options for {node.name}</span>
                        <FontAwesomeIcon icon={["fas", "ellipsis"]} />
                    </InteractiveElement>
                    <LibraryOverflowPopover
                        overflowButtonRef={overflowButtonRef}
                        overflowOpen={overFlowOpen}
                        setOverflowOpen={setOverFlowOpen}
                        overflowOptions={overflowOptions}
                        node={node}
                    />
                </>
            )}
        </StyledBaseNode>
    );
};
