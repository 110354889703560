import { styled, checkboxWithLabelCss, media, sectionalMargin } from "@iventis/styles";

import React, { FunctionComponent } from "react";
import { FileUploadPreviewComponentProps } from "./upload-file";
import { CustomDialog } from "./custom-dialog";

export const StyledUploadDialog = styled(CustomDialog)<{ $minModalDimensions?: { width: string; height: string } }>`
    ${(props) =>
        props.$minModalDimensions &&
        `${media.small} {
        .MuiPaper-root {
            min-width: ${props.$minModalDimensions.width};
            min-height: ${props.$minModalDimensions.height};
        }
    }`}
`;

export const StyledImagePreview = styled.div<{ url: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    .thumbnail {
        background: center / contain no-repeat url(${(props) => props.url});
        border: solid 1px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        background-clip: content-box;
        background-origin: content-box;
        width: 120px;
        height: 120px;
        padding: 25px;
    }
`;

export const StyledUploadContainer = styled.div`
    .file-upload {
        width: 100%;
        .file-upload-input {
            flex-grow: 1;
        }
        margin-bottom: ${sectionalMargin};
    }
`;

export const StyledThumbnailUploadContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    .file-upload {
        width: 100%;
        .file-upload-input {
            flex-grow: 1;
        }
        margin-bottom: ${sectionalMargin};
    }
    .checkbox-container {
        ${checkboxWithLabelCss}
        align-items: start;
        margin-bottom: ${sectionalMargin};
    }
    .thumbnail-preview {
        grid-row: 2 / 3;
    }
`;

export const ThumbnailUploadPreview: FunctionComponent<FileUploadPreviewComponentProps> = ({ fileThumbnailUrl }) => (
    <StyledImagePreview className="thumbnail-preview" url={fileThumbnailUrl}>
        <div className="thumbnail" />
    </StyledImagePreview>
);
