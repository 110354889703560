import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { FilterFormat } from "@iventis/types";
import { useAssetSignatureCache } from "@iventis/utilities";
import { MutableRefObject, useMemo } from "react";
import { useInfiniteQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { Asset } from "@iventis/domain-model/model/asset";
import { getProjectDataFieldsQueryKey } from "@iventis/datafield-editor";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { IEditLayerServices } from "./edit-layer-services";
import { replaceLayerDataFieldsWithProjectDataFields } from "./edit-layer-helpers";
import { CategoryNode } from "./layer-template-categories";

export const TEMPLATE_3D_TAG = "3D layers";

const flattenCategories = (categories: CategoryNode[]): CategoryNode[] => categories.flatMap((category) => [category, ...flattenCategories(category?.childNodes ?? [])]);

export const getTemplateFilters = (selectedCategories: CategoryNode[], searchString: string, templateContainerRef?: MutableRefObject<HTMLDivElement>): FilterFormat[] => {
    const filters: FilterFormat[] = [];
    const searchFilterActive = searchString !== "" && searchString !== undefined;
    // While the user is searching, we don't want to filter by category
    if (selectedCategories && !searchFilterActive && selectedCategories.length > 0) {
        const flatSelectedCategories = flattenCategories(selectedCategories);
        filters.push({ fieldName: "tags", operator: IventisFilterOperator.In, value: flatSelectedCategories.map((category) => category.name) });
    }
    if (searchFilterActive) {
        filters.push({ fieldName: "search", operator: IventisFilterOperator.Eq, value: searchString });
    }
    if (templateContainerRef) {
        // scroll to top on template category change
        templateContainerRef.current?.scrollTo(0, 0);
    }

    return filters;
};

export const useLayerTemplates = (
    filter: FilterFormat[],
    { assetService, styleService: editStyleService }: IEditLayerServices,
    refetchCachedData,
    readyToRequestTemplates = true
) => {
    const assetSignatureCache = useAssetSignatureCache();

    const queryClient = useQueryClient();
    const noExistingData = queryClient.getQueryData<PagedResult<Asset[]>>(["assets", AssetType.LayerTemplate, filter]) == null;

    const { data, isLoading: assetLoading, isFetching: assetFetching, error: assetError } = useInfiniteQuery(
        ["assets", AssetType.LayerTemplate, filter],
        async ({ pageParam }) => {
            const response = await assetService.getAssetsByType(pageParam?.pageNumber ?? 1, AssetType.LayerTemplate, filter);
            return response;
        },
        {
            keepPreviousData: true,
            // If we don't want to refetch cached data, then enable a refetch only when no data exists
            enabled: (refetchCachedData || noExistingData) && readyToRequestTemplates,
            getNextPageParam: (prevPage, allPages) => (prevPage.lastPage ? undefined : { pageNumber: (allPages?.length ?? 0) + 1 }),
        }
    );

    const urls: { layerUrl: string; thumbnailUrl: string; iconUrl: string; assetId: string; name: string; tags: string[] }[] = useMemo(() => {
        const assets = data?.pages?.flatMap((page) => page.results);
        return (assets || []).map((asset) => ({
            assetId: asset.id,
            layerUrl: assetSignatureCache(asset.assetUrl, asset.authoritySignature),
            thumbnailUrl: asset.thumbnailUrl,
            iconUrl: asset.iconUrl,
            name: asset.name,
            tags: asset.tags,
        }));
    }, [data]);

    const getLayer = async (layerUrl: string, assetName: string) => {
        const layer = await assetService.getCdnAsset<MapLayer>(layerUrl);
        const projectDataFields = await editStyleService.getProjectDataFields();
        queryClient.setQueryData([getProjectDataFieldsQueryKey], projectDataFields);
        const dataFields = replaceLayerDataFieldsWithProjectDataFields(layer.dataFields, projectDataFields);
        return { ...layer, dataFields, name: assetName };
    };

    const templateLayerQueries = useQueries({
        queries: (urls || []).map(({ assetId, thumbnailUrl, name, iconUrl, layerUrl, tags }) => ({
            queryKey: ["template-layer-json", assetId],
            queryFn: async () => ({
                assetId,
                thumbnailUrl,
                iconUrl,
                name,
                layerUrl,
                tags,
            }),
        })),
    });

    return {
        assetError,
        assetLoading,
        assetFetching,
        templateLayerQueries,
        getLayer,
    };
};
