import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { LineEnd } from "@iventis/domain-model/model/lineEnd";
import { AnySupportedGeometry } from "@iventis/map-engine/src/types/internal";
import { MAPBOX_MAX_ZOOM, previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import {
    DerivedStyleValue,
    LayerStorageScope,
    LocalGeoJson,
    LocalGeoJsonObject,
    MapModuleLayer,
    Source,
    StoredPosition,
} from "@iventis/map-engine/src/types/store-schema";
import { GeoJsonTypes } from "geojson";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { IconPlacement } from "@iventis/domain-model/model/iconPlacement";
import { createStaticStyleValue, getStaticStyleValue } from "@iventis/map-engine/src/utilities/static-styles";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";
import { createAreaPreviewSourceData } from "./preview-area-helpers";
import { createLinePreviewSourceData } from "./preview-line-helpers";
import { createLineModelPreviewSourceData, createPointPreviewSourceData, getIconAttributedBasedStyleValue } from "./preview-point-helpers";

const textPreviewDatafieldId = "48262463-3583-4900-9239-3af38fce99cd";

const previewMapPositionDefault: StoredPosition = {
    lng: 0,
    lat: 0,
    zoom: 14.5,
    bearing: 0,
    pitch: 0,
    source: Source.HOST,
};

const previewMapPosition3DModels: StoredPosition = {
    ...previewMapPositionDefault,
    zoom: 20,
    bearing: 170,
    pitch: 70,
};

export const createDefaultLocalGeoJsonObject = (
    id: string,
    name: string,
    layerId: string,
    level: number,
    geometry: AnySupportedGeometry,
    additonalProperties: object = {}
): LocalGeoJsonObject => ({
    feature: {
        type: "Feature",
        properties: {
            id,
            layerid: layerId,
            rotation: { x: 0, y: 0, z: 0 },
            level,
            ...additonalProperties,
            [textPreviewDatafieldId]: "Text", // Text that will appear in preview
        },
        geometry,
    },
    objectId: id,
    waypoints: []
});

const previewObjectIdPrefix = "PreviewObject";
const objectNamePrefix = "Object";

export function createPreviewObjectId(index: number): string {
    return `${previewObjectIdPrefix}${index}`;
}

export function createPreviewObjectName(index: number): string {
    return `${objectNamePrefix} ${index}`;
}

/**
 * The function creates a source which a preview layer can use to display a preview object
 * @param { StyleType } styleType - Style type of the layer
 * @returns { LocalGeoJson } - Source of the preview layer
 */
export function createPreviewSourceData(layerStyle: LayerStyle, snapshotGeometry = false): LocalGeoJson {
    const geoJsonType: GeoJsonTypes = iventisLayerStyleToGeoJsonType(layerStyle.styleType);

    switch (layerStyle.styleType) {
        case StyleType.Area:
            return createAreaPreviewSourceData(layerStyle as AreaStyle, snapshotGeometry);
        case StyleType.Line:
            return createLinePreviewSourceData(layerStyle as LineStyle, snapshotGeometry);
        case StyleType.Icon:
        case StyleType.Point:
            return createPointPreviewSourceData(layerStyle, snapshotGeometry);
        case StyleType.LineModel:
            return { [previewLayerId]: [createLineModelPreviewSourceData()], };
        default:
            return {
                [previewLayerId]: [
                    createDefaultLocalGeoJsonObject(
                        "previewObject",
                        "Object 1",
                        previewLayerId,
                        0,
                        snapshotGeometry ? createPreviewSnapshotGeometry(geoJsonType) : createPreviewGeometry(geoJsonType)
                    ),
                ],
            };
    }
}

/** Works out the map camera position based on the style of the layer in preview */
export function calculateMapPosition(style: LayerStyle): StoredPosition {
    if (style.styleType === StyleType.Model || style.styleType === StyleType.LineModel) {
        return previewMapPosition3DModels;
    }

    if (style.styleType === StyleType.Point) {
        // Reduce it by 0.1 so the point is not hidden by Mapbox max zoom level
        return { ...previewMapPositionDefault, zoom: MAPBOX_MAX_ZOOM - 0.1 };
    }

    return previewMapPositionDefault;
}

/**
 * The function creates a preview geometry
 * For a LineString it will create a line from one side of the preview to the other
 * For a Polygon it will create a square rotated so one of it's conrers is at the bottom of the preview
 * @param { GeoJsonTypes } type - Type of GeoJSON that needs to be returned
 * @returns { AnySupportedGeometry } - The geometry needed for that feature
 */
function createPreviewGeometry(type: GeoJsonTypes): AnySupportedGeometry {
    switch (type) {
        case "LineString":
            return {
                type,
                coordinates: [
                    [0.001, 0.001],
                    [-0.001, 0.001],
                    [0.0003, 0],
                    [-0.001, -0.001],
                    [0.001, -0.001],
                ],
            };
        case "Polygon":
            return {
                type,
                coordinates: [
                    [
                        [-0.0012, 0],
                        [0, 0.0012],
                        [0.0012, 0],
                        [0, -0.0012],
                        [-0.0012, 0],
                    ],
                ],
            };
        case "Point":
            return {
                type,
                coordinates: [0, 0],
            };
        default:
            throw new Error("GeoJsonType was not recognised");
    }
}

/**
 * The function creates a preview snapshot geometry
 * For a LineString it will create a line from one side of the preview to the other
 * For a Polygon it will create a square rotated so one of it's conrers is at the bottom of the preview
 * @param { GeoJsonTypes } type - Type of GeoJSON that needs to be returned
 * @returns { AnySupportedGeometry } - The geometry needed for that feature
 */
function createPreviewSnapshotGeometry(type: GeoJsonTypes): AnySupportedGeometry {
    switch (type) {
        case "LineString":
            return {
                type,
                coordinates: [
                    [-0.003, 0],
                    [0.003, 0],
                ],
            };
        case "Polygon":
            return {
                type,
                coordinates: [
                    [
                        [-0.0013, 0],
                        [0, 0.0013],
                        [0.0013, 0],
                        [0, -0.0013],
                        [-0.0013, 0],
                    ],
                ],
            };
        case "Point":
            return {
                type,
                coordinates: [0, 0],
            };
        default:
            throw new Error("GeoJsonType was not recognised");
    }
}

/**
 * The function takes an Iventis StyleType enum and converts it to a GeoJSON type
 * @param { StyleType } styleType - styleType of the layer
 * @returns { GeoJsonTypes } - the equivalent GeoJsonType
 */
function iventisLayerStyleToGeoJsonType(styleType: StyleType): GeoJsonTypes {
    switch (styleType) {
        case StyleType.Area:
            return "Polygon";
        case StyleType.Line:
        case StyleType.LineModel:
            return "LineString";
        case StyleType.Point:
        case StyleType.Icon:
        case StyleType.Model:
            return "Point";
        default:
            throw new Error("StyleType was not recognised");
    }
}

/**
 * The functions createsd a preview layer assigning the style to the correct property
 * @param {AnyLayerStyle} layerStyle - layerStyle of the preview layer
 * @returns { MapModuleLayer } - a layer which can be added to the preview
 */
export function createPreviewLayer(layerStyle: LayerStyle): MapModuleLayer {
    let previewLayerStyle: LayerStyle;
    switch (layerStyle.styleType) {
        case StyleType.Area:
            previewLayerStyle = areaPreviewStyle(layerStyle as AreaStyle);
            break;
        case StyleType.Line:
            previewLayerStyle = linePreviewStyle(layerStyle as LineStyle);
            break;
        case StyleType.Point:
            previewLayerStyle = pointPreviewStyle(layerStyle as PointStyle);
            break;
        case StyleType.Icon:
            previewLayerStyle = iconPreviewStyle(layerStyle as IconStyle);
            break;
        case StyleType.Model:
            previewLayerStyle = modelPreviewStyle(layerStyle as ModelStyle);
            break;
        case StyleType.LineModel:
            previewLayerStyle = lineModelPreviewStyle(layerStyle as LineModelStyle);
            break;
        default:
            previewLayerStyle = layerStyle;
    }

    // Datafield for display preview text
    const df: DataField = {
        name: "Preview text",
        customerIdentifierName: "",
        allowedMetadataProperties: [],
        type: DataFieldType.Text,
        listValues: [],
        projectId: "",
        hierarchyMetadataPropertyName: "",
        showInApi: false,
        apiNameSingular: "",
        apiNamePlural: "",
        id: textPreviewDatafieldId,
        listItemProperties: [],
        listItemRelationships: [],
        order: 0,
        defaultValue: {
            valueText: undefined,
            dataFieldId: undefined,
            listItemId: undefined,
            valueDate: undefined,
            valueNumber: undefined,
            valueTickbox: undefined,
            valueRepeatedTimeRanges: undefined,
        },
    };

    return {
        id: previewLayerId,
        name: "Local Layer",
        visible: true,
        storageScope: LayerStorageScope.LocalOnly,
        styleType: previewLayerStyle.styleType,
        areaStyle: (previewLayerStyle.styleType === StyleType.Area ? previewLayerStyle : null) as AreaStyle,
        lineStyle: (previewLayerStyle.styleType === StyleType.Line ? previewLayerStyle : null) as LineStyle,
        pointStyle: (previewLayerStyle.styleType === StyleType.Point ? previewLayerStyle : null) as PointStyle,
        iconStyle: (previewLayerStyle.styleType === StyleType.Icon ? previewLayerStyle : null) as IconStyle,
        modelStyle: (previewLayerStyle.styleType === StyleType.Model ? previewLayerStyle : null) as ModelStyle,
        lineModelStyle: (previewLayerStyle.styleType === StyleType.LineModel ? previewLayerStyle : null) as LineModelStyle,
        dataFields: [df],
        source: `${previewLayerId}`,
        stamp: "",
        selected: false,
        drawingControls: null,
    };
}

/**
 * The functions creates a preview layer assigning the style to the correct property. Some style properties will be defaulted for snapshot layers, such as opacity which is always 100%.
 * @param {AnyLayerStyle} layerStyle - layerStyle of the preview layer
 * @returns { MapModuleLayer } - a layer which can be added to the preview
 */
export function createPreviewSnapshotLayer(layerStyle: LayerStyle): MapModuleLayer {
    let snapshotLayerStyle: LayerStyle;
    switch (layerStyle.styleType) {
        case StyleType.Area:
            snapshotLayerStyle = areaSnapshotStyle(layerStyle as AreaStyle);
            break;
        case StyleType.Line:
            snapshotLayerStyle = lineSnapshotStyle(layerStyle as LineStyle);
            break;
        case StyleType.Point:
            snapshotLayerStyle = pointSnapshotStyle(layerStyle as PointStyle);
            break;
        case StyleType.Icon:
            snapshotLayerStyle = iconSnapshotStyle(layerStyle as IconStyle);
            break;
        case StyleType.Model:
            snapshotLayerStyle = modelPreviewStyle(layerStyle as ModelStyle);
            break;
        case StyleType.LineModel:
            snapshotLayerStyle = lineModelPreviewStyle(layerStyle as LineModelStyle);
            break;
        default:
            snapshotLayerStyle = layerStyle;
    }
    return {
        id: previewLayerId,
        name: "Local Layer",
        visible: true,
        storageScope: LayerStorageScope.LocalOnly,
        styleType: layerStyle.styleType,
        areaStyle: (layerStyle.styleType === StyleType.Area ? snapshotLayerStyle : null) as AreaStyle,
        lineStyle: (layerStyle.styleType === StyleType.Line ? snapshotLayerStyle : null) as LineStyle,
        pointStyle: (layerStyle.styleType === StyleType.Point ? snapshotLayerStyle : null) as PointStyle,
        iconStyle: (layerStyle.styleType === StyleType.Icon ? snapshotLayerStyle : null) as IconStyle,
        modelStyle: (layerStyle.styleType === StyleType.Model ? snapshotLayerStyle : null) as ModelStyle,
        lineModelStyle: (layerStyle.styleType === StyleType.LineModel ? snapshotLayerStyle : null) as LineModelStyle,
        dataFields: null,
        source: `${previewLayerId}`,
        stamp: "",
        selected: false,
        drawingControls: null,
    };
}

export function getSnapshotZoomLevel(layerStyle: LayerStyle): number {
    switch (layerStyle.styleType) {
        case StyleType.Line:
            return 13;
        case StyleType.LineModel:
            return 19;
        default:
            return 14.5;
    }
}

export function getSnapshotPitch(layerStyle: LayerStyle): number {
    switch (layerStyle.styleType) {
        case StyleType.LineModel:
            return 70;
        default:
            return 0;
    }
}

export function getSnapshotBearing(layerStyle: LayerStyle): number {
    switch (layerStyle.styleType) {
        case StyleType.LineModel:
            return 170;
        default:
            return 0;
    }
}

/**
 * Create a area style with some snapshot defaults that make the snapshot easy to see. For example, snapshot images should have opacity at 100% so they can be seen easily.
 */
function areaSnapshotStyle(areaStyle: AreaStyle): AreaStyle {
    return {
        ...areaStyle,
        ...globalSnapshotStyles,
    };
}
/**
 * Create a line style with some snapshot defaults that make the snapshot easy to see. For example, snapshot images should have opacity at 100% so they can be seen easily.
 */
function lineSnapshotStyle(lineStyle: LineStyle): LineStyle {
    const isColourDataDriven = lineStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped;
    return {
        ...lineStyle,
        ...globalSnapshotStyles,
        width: createStaticStyleValue(15),
        blur: createStaticStyleValue(getStaticStyleValue(lineStyle.blur) > 0 ? 2 : 0),
        offset: createStaticStyleValue(0),
        arrowSize: isColourDataDriven ? createStaticStyleValue(0) : createStaticStyleValue(3),
        outlineWidth: isColourDataDriven ? createStaticStyleValue(0) : lineStyle.outlineWidth,
        end: isColourDataDriven ? createStaticStyleValue<LineEnd>(LineEnd.Square) : lineStyle.end,
        iconPlacement: createStaticStyleValue(IconPlacement.LineCenter),
    };
}
/**
 * Create a icon style with some snapshot defaults that make the snapshot easy to see. For example, snapshot images should have opacity at 100% so they can be seen easily.
 */
function iconSnapshotStyle(iconStyle: IconStyle): IconStyle {
    const isAttributeBasedStyle = getIconAttributedBasedStyleValue(iconStyle);
    return {
        ...iconStyle,
        ...globalSnapshotStyles,
        size: isAttributeBasedStyle ? createStaticStyleValue(0.9) : createStaticStyleValue(1.5),
        allowOverlap: createStaticStyleValue(true),
    };
}

/**
 * Create a point style with some snapshot defaults that make the snapshot easy to see. For example, snapshot images should have opacity at 100% so they can be seen easily.
 */
function pointSnapshotStyle(pointStyle: PointStyle): PointStyle {
    const isColourDataDriven = pointStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped;
    return {
        ...pointStyle,
        ...globalSnapshotStyles,
        radius: isColourDataDriven ? createStaticStyleValue(15) : createStaticStyleValue(43),
        outlineWidth: isColourDataDriven
            ? createStaticStyleValue((pointStyle.outlineWidth.staticValue.staticValue / pointStyle.radius.staticValue.staticValue) * 15)
            : createStaticStyleValue(pointStyle.outlineWidth.staticValue.staticValue !== 0 ? 5 : 0),
    };
}

function linePreviewStyle(lineStyle: LineStyle): LineStyle {
    const isColourDataDriven = lineStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped;
    return {
        ...lineStyle,
        ...globalPreviewStyles,
        text: isColourDataDriven ? createStaticStyleValue(false) : lineStyle.text,
        width: isColourDataDriven ? createStaticStyleValue(5) : lineStyle.width,
        outlineWidth: isColourDataDriven
            ? createStaticStyleValue((lineStyle.outlineWidth.staticValue.staticValue / lineStyle.width.staticValue.staticValue) * 5)
            : createStaticStyleValue(lineStyle.outlineWidth.staticValue.staticValue !== 0 ? 5 : 0),
    };
}

function areaPreviewStyle(areaStyle: AreaStyle): AreaStyle {
    const isColourDataDriven = areaStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped;
    return {
        ...areaStyle,
        ...globalPreviewStyles,
        text: isColourDataDriven ? createStaticStyleValue(false) : areaStyle.text,
    };
}

function pointPreviewStyle(pointStyle: PointStyle): PointStyle {
    const isColourDataDriven = pointStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped;
    return {
        ...pointStyle,
        ...globalPreviewStyles,
        text: isColourDataDriven ? createStaticStyleValue(false) : pointStyle.text,
        radius: isColourDataDriven ? createStaticStyleValue(15) : pointStyle.radius,
        outlineWidth: isColourDataDriven ? createStaticStyleValue((pointStyle.outlineWidth.staticValue.staticValue / pointStyle.radius.staticValue.staticValue) * 15) : pointStyle.outlineWidth,
    };
}

function iconPreviewStyle(iconStyle: IconStyle): IconStyle {
    const isAttributeBasedStyle = getIconAttributedBasedStyleValue(iconStyle);
    let { size } = iconStyle;
    // If the size is set by zoomable value, get the max size and set the size of the icon to that value
    if (size.extractionMethod === StyleValueExtractionMethod.Static && size.staticValue.extractionMethod === ZoomableValueExtractionMethod.Continuous) {
        size = createStaticStyleValue(size.staticValue.mappedZoomValues[getMaximumZoomLevelFromMappedValues(size.staticValue.mappedZoomValues)]?.value);
    }
    return {
        ...iconStyle,
        ...globalPreviewStyles,
        text: isAttributeBasedStyle ? createStaticStyleValue(false) : iconStyle.text,
        size: isAttributeBasedStyle ? createStaticStyleValue(0.65) : size,
        allowOverlap: createStaticStyleValue(true),
    };
}

/** Gets the maximum zoom level that exists and is visible within the given mapped values */
export function getMaximumZoomLevelFromMappedValues(mappedValues: ZoomableValue<unknown>["mappedZoomValues"]) {
    return Object.entries(mappedValues).reduce((max, [zoomLevel, value]) => Number.parseFloat(zoomLevel) > max && !value.hidden ? Number.parseFloat(zoomLevel) : max, 0);
}

function modelPreviewStyle(modelStyle: ModelStyle): ModelStyle {
    // Want to just show one size of model for the preview
    return {
        ...modelStyle,
        model: createStaticStyleValue(modelStyle.model.staticValue.staticValue),
        height: createStaticStyleValue(modelStyle.height.staticValue.staticValue),
        width: createStaticStyleValue(modelStyle.width.staticValue.staticValue),
        length: createStaticStyleValue(modelStyle.length.staticValue.staticValue),
    };
}

function lineModelPreviewStyle(lineModelStyle: LineModelStyle): LineModelStyle {
    // Want to just show one size of model for the preview
    return {
        ...lineModelStyle,
        model: createStaticStyleValue(lineModelStyle.model.staticValue.staticValue),
        height: createStaticStyleValue(lineModelStyle.height.staticValue.staticValue),
        width: createStaticStyleValue(lineModelStyle.width.staticValue.staticValue),
        length: createStaticStyleValue(lineModelStyle.length.staticValue.staticValue),
        spacing: createStaticStyleValue(lineModelStyle.spacing.staticValue.staticValue),
    };
}

/** Get the intersection of all keys from the given style types. This will produce all keys that have the same name across all objects. */
type GlobalKeys = keyof IconStyle & keyof LineStyle & keyof AreaStyle & keyof PointStyle;

/** An object that contains all intersecting properies from GlobalKeys where each prop has the same name & type */
// eslint-disable-next-line prettier/prettier
type IntersectingProps = { [K in GlobalKeys as DerivedStyleValue<K> extends never ? never : K]?: DerivedStyleValue<K> };

/** Global styles for all previews and snapshots */
const globalStyles: IntersectingProps = {
    textOverlap: createStaticStyleValue(true),
    textContent: { // Datafield to display preview text
        extractionMethod: StyleValueExtractionMethod.Literal,
        dataFieldId: textPreviewDatafieldId,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            mappedZoomValues: null,
            unitType: Units.None,
            staticValue: "Static Value",
        },
        mappedValues: null,
    } as StyleValue<string>,
    opacity: createStaticStyleValue(1),
};

/** Global styles for all previews for areas, lines, points, icons */
const globalPreviewStyles: IntersectingProps = {
    ...globalStyles,
};

/** Global styles for all snapshots for areas, lines, points, icons */
const globalSnapshotStyles: IntersectingProps = {
    ...globalStyles,
    text: createStaticStyleValue(false),
};
