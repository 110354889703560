import { UncheckedCheckBox, CheckedCheckBox, Body2, styled } from "@iventis/styles";
import React from "react";
import { Theme } from "@emotion/react";
import { Checkbox as CheckboxMUI, useTheme } from "@mui/material";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";

import { ModelSizingComponent } from "./model-sizing-component";
import { UploadModelData } from "./upload-model";

export interface ModelVariableSize {
    variableSizeModel: boolean;
    size?: { height: number; width: number; length: number };
}

export const UploadModelVariableSizeComponent = ({
    onChange,
    values,
}: {
    onChange: <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => void;
    values: ModelVariableSize;
}) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const handleVariableSizeModelToggleChange = () => {
        const updatedValue = !values.variableSizeModel;
        onChange("variableSizeModel", updatedValue);
        if (!updatedValue) {
            onChange("size", { height: 0, width: 0, length: 0 });
        }
    };

    return (
        <UploadModelVariableSizeContainer>
            <div className="checkbox-container">
                <Checkbox
                    icon={<UncheckedCheckBox size={15} borderStyle="1px solid rgba(0,0,0,0.23)" backgroundColour={theme.secondaryColors.blank} />}
                    checkedIcon={<CheckedCheckBox size={15} tickColour="white" borderStyle="1px solid transparent" backgroundColour={theme.primaryColors.focus} />}
                    checked={values.variableSizeModel}
                    onChange={() => handleVariableSizeModelToggleChange()}
                    size="small"
                />
                <Body2>{translate(Content.map5.styles.customDimensions)}</Body2>
            </div>
            <ModelSizingComponent
                onValueChanged={(property, value) => onChange("size", { ...values.size, [property]: value })}
                values={values.size}
                disabled={!values.variableSizeModel}
            />
        </UploadModelVariableSizeContainer>
    );
};

const UploadModelVariableSizeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    .checkbox-container {
        display: flex;
        align-items: center;
    }
`;

const Checkbox = styled(CheckboxMUI)`
    padding: 0px 10px 0px 0px;
`;
