import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { borderRadius, fontSizes, formPadding, popoverPadding, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Body1, Header5 } from "@iventis/styles/src/components/texts";
import { ClickAwayListener, IconButton, Tooltip, TooltipProps, ClickAwayListenerProps } from "@mui/material";
import React, { ReactNode } from "react";

/**
 * A tooltip that appears next to the wrapped element on hover.
 *
 * !!! Immediate child must accept a ref i.e. interactive components or a div, or this will throw an error.
 *
 * @param {string} text - Text to display in the tooltip
 * @param {string} placement - where to place the tooltip, see {@link https://mui.com/material-ui/react-tooltip/#positioned-tooltips}
 */
export const SimpleTooltip: React.FC<{ text: string | ReactNode; children: React.ReactElement; placement: TooltipProps["placement"]; disabled?: boolean; className?: string }> = ({
    text,
    children,
    placement,
    disabled,
    className,
}) => {
    if (!text || disabled) {
        return <>{children}</>;
    }
    return (
        <StyledTooltip title={<StyledContainer>{text}</StyledContainer>} placement={placement} disableInteractive className={className}>
            {children}
        </StyledTooltip>
    );
};

/**
 * A tooltip that follows the bottom of the cursor over the wrapped element on hover.
 *
 * !!! Immediate child must accept a ref i.e. interactive components or a div, or this will throw an error.
 *
 * @param {string} text - Text to display in the tooltip
 * @param {string} title [o] - Adds a title to the tooltip
 */
export const CursorTooltip: React.FC<{ title?: string; text: string | ReactNode; children: React.ReactElement; disabled?: boolean; enterDelay?: number }> = ({
    title,
    text,
    children,
    disabled,
    enterDelay,
}) => {
    if (!text || disabled) {
        return <>{children}</>;
    }
    if (!title) {
        return (
            <StyledTooltip title={<StyledContainer>{text}</StyledContainer>} placement="bottom-start" followCursor disableInteractive enterDelay={enterDelay}>
                {children}
            </StyledTooltip>
        );
    }
    return (
        <StyledTooltip
            title={
                <StyledContainer>
                    <Header5>{title}</Header5>
                    <Body1>{text}</Body1>
                </StyledContainer>
            }
            placement="bottom-start"
            followCursor
            disableInteractive
        >
            {children}
        </StyledTooltip>
    );
};

/**
 * An interactive tooltip with an info icon. Toggled open/close.
 *
 * !!! Immediate child must accept a ref i.e. interactive components or a div, or this will throw an error.
 *
 * @param {string} title - Title to display in the tooltip
 * @param {string | React.ReactNode} text - Text or elements to display in the tooltip
 */
export const InfoTooltip: React.FC<{
    /** Title to display in the tooltip */
    title: string;
    /** Contents of tooltip to display */
    text: React.ReactNode;
    /** Boolean whether tooltip is open or not */
    open: boolean;
    /** Action on close */
    onClose: () => void;
    className?: string;
    children: React.ReactElement;
    placement?: "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
    testId?: string;
}> = ({ title, text, open, onClose, children, className, placement = "top", testId }) => (
    <StyledTooltip
        className={className}
        open={open}
        title={
            <StyledInfoContainer onMouseDown={(e) => e.stopPropagation()} onMouseUp={(e) => e.stopPropagation()} data-testid={testId}>
                <FontAwesomeIcon size="lg" style={{ gridColumn: "1" }} icon={["fas", "circle-info"]} />
                <Header5 style={{ gridColumn: "2", textAlign: "left" }}>{title}</Header5>
                <div style={{ gridRow: "2", gridColumn: "1 / 4" }}>{text}</div>
                <StyledIconButton style={{ gridColumn: "3" }} onClick={onClose} data-testid="popper-close">
                    <FontAwesomeIcon size="lg" icon={["far", "xmark"]} />
                </StyledIconButton>
            </StyledInfoContainer>
        }
        placement={placement}
        arrow
        disableHoverListener
    >
        {children}
    </StyledTooltip>
);

/**
 * Click away listener which will work for our tool tips
 * @Note When using ensure that the button the tool tip is being activated by also wrapped in this
 */
export const ClickAwayListenerToolTip = ({ children, onClickAway }: ClickAwayListenerProps) => (
    <ClickAwayListener onClickAway={onClickAway} mouseEvent="onMouseDown">
        <div>{children}</div>
    </ClickAwayListener>
);

const StyledContainer = styled.div`
    padding: ${popoverPadding.xsmall} ${popoverPadding.small};
`;

const StyledInfoContainer = styled.div`
    padding: ${formPadding};
    display: grid;
    grid-template-columns: 5% auto 10%;
    align-items: center;
    row-gap: ${popoverPadding.standard};
    gap: ${popoverPadding.standard};
`;

export const StyledIconButton = styled(IconButton)`
    color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
    font-size: ${fontSizes.medium};
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledTooltip = styled((props: TooltipProps) => <Tooltip classes={{ popper: props.className }} {...props} />)<TooltipProps>`
    & .MuiTooltip-tooltip {
        padding: 0;
        background-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued70};
        color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
        font-size: ${fontSizes.medium};
        max-width: 400px;
        ${(props) => (props.followCursor ? "margin-top: 25px !important;" : "")} // I'm not sure how to override MUI's specificity for this rule
        border-radius: ${borderRadius.input};
    }
    & .MuiTooltip-arrow {
        color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued70};
    }
`;

export const ToolTipWrapper = styled.div`
    position: relative;
    height: 100%;
    display: inline-block;
`;
