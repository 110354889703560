import { CalendarDate } from "@internationalized/date";
import { MapObjectRepeatedTimeRangeValue, TimeRange } from "@iventis/types";
import { dateToTimeInputString, parseTimeNumberToDate, parseEpochSecondsToDate, parseDateToEpochSeconds, parseTimeStringToNumber } from "@iventis/utilities";
import { DateValue } from "react-aria";
import { v4 as uuid } from "uuid";
import { DateRangeAttributeValue } from "./repeated-time-range-types";

/**
 * Takes a datafield value and converts it to a DateRangeAttributeValue
 */
export function parseDataFieldValueToDateRange(dataFieldValue: MapObjectRepeatedTimeRangeValue[]): DateRangeAttributeValue[] {
    return dataFieldValue.map((value) => ({
        id: value.id,
        name: value.name,
        dateRange: {
            start: parseNumberDateToDateValue(value.startDate),
            end: parseNumberDateToDateValue(value.endDate),
        },
        timeRange: parseNumberTimeRangeToTimeRange(value.startTime, value.endTime),
    }));
}

/**
 * Takes a start and end time as HHMM and converts it to a time range HH:MM:SS-HH:MM:SS
 */
export function parseNumberTimeRangeToTimeRange(startTime: number, endTime: number): TimeRange | null {
    const startTimeDate = parseTimeNumberToDate(startTime);
    const endTimeDate = parseTimeNumberToDate(endTime);

    if (startTimeDate == null || endTimeDate == null) {
        return null;
    }

    const startTimeString = dateToTimeInputString(startTimeDate);
    const endTimeString = dateToTimeInputString(endTimeDate);
    return `${startTimeString}:00-${endTimeString}:00` as TimeRange;
}

/**
 * Takes an epoch (seconds) date and converts it to a DateValue
 */
export function parseNumberDateToDateValue(dateInSeconds: number): DateValue | null {
    const parsedDate = parseEpochSecondsToDate(dateInSeconds);

    if (parsedDate == null) {
        return null;
    }
    // Month is 0 based so add 1 to it
    const month = parsedDate.getMonth() + 1;
    return new CalendarDate(parsedDate.getFullYear(), month, parsedDate.getDate());
}

/**
 * Takes a datafield value and convert it to a MapObjectRepeatedTimeRangeValue
 */
export function parseDataFieldValuesToRepeatedTimeRange(dataFieldValue: DateRangeAttributeValue[]): MapObjectRepeatedTimeRangeValue[] {
    return dataFieldValue.map((value) => {
        const startDateDate = value?.dateRange?.start?.toDate("UTC");
        const endDateDate = value?.dateRange?.end?.toDate("UTC");
        const spiltTime = value?.timeRange?.split("-");
        const startTime = spiltTime?.[0];
        const endTime = spiltTime?.[1];

        return {
            id: value.id,
            name: value.name,
            startDate: parseDateToEpochSeconds(startDateDate) ?? 0,
            endDate: parseDateToEpochSeconds(endDateDate) ?? 0,
            startTime: parseTimeStringToNumber(startTime) ?? 0,
            endTime: parseTimeStringToNumber(endTime) ?? 0,
        };
    });
}

/**
 * Creates a new DateRangeAttributeValue
 */
export function createNewDateRange(): DateRangeAttributeValue {
    return {
        dateRange: {
            end: null,
            start: null,
        },
        timeRange: null,
        name: "",
        id: uuid(),
    };
}

/** Checks if the repeated time values are valid */
export function isRepeatedTimeRangeValid(values: DateRangeAttributeValue[]) {
    if (values == null) {
        return false;
    }

    const isMoreThanOneValue = values.length > 0;
    const noValuesAreNull = values.every(
        (value) => value.dateRange?.start != null && value.dateRange?.end != null && value.timeRange != null && value.name != null && value.name !== ""
    );
    return isMoreThanOneValue && noValuesAreNull;
}
