/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { DateValue, useCalendarGrid, useLocale } from "react-aria";
import { RangeCalendarState } from "@react-stately/calendar";
import { getWeeksInMonth, CalendarDate } from "@internationalized/date";
import { styled } from "@iventis/styles";
import { RangeValue } from "@react-types/shared";
import { CalendarCell } from "./CalendarCell";
import { DateField, DateFieldProps } from "./Datefield";

interface AriaCalendarGridProps {
    startDate?: CalendarDate;
    endDate?: CalendarDate;
    weekdayStyle?: "narrow" | "short" | "long";
}

type CalendarGridProps = AriaCalendarGridProps & { state: RangeCalendarState } & { startFieldProps: DateFieldProps; endFieldProps: DateFieldProps };

export function CalendarGrid({ state, startFieldProps, endFieldProps, ...props }: CalendarGridProps) {
    const { locale } = useLocale();
    const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);
    const [valueFromChild, setValueFromChild] = useState<RangeValue<DateValue>>({ start: null, end: null });

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    const updateStartEndDates = (value: RangeValue<DateValue>) => {
        setValueFromChild(value);
    };

    return (
        <>
            <StyledTable {...gridProps} cellPadding="0" cellSpacing="0">
                <thead {...headerProps}>
                    <tr>
                        {weekDays.map((day, i) => (
                            // weekdays is a simple array of weekday first chars - M, T, W, T etc - so we need a unique index
                            // for repeating days - Tuesday, Thursday...
                            // eslint-disable-next-line react/no-array-index-key
                            <StyledTh key={`${day}-${i}`}>{day}</StyledTh>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                        <tr key={weekIndex}>
                            {state
                                .getDatesInWeek(weekIndex)
                                .map((date: CalendarDate) =>
                                    date ? <CalendarCell updateStartEndDates={updateStartEndDates} key={date.toString()} state={state} date={date} /> : <td key={date.toString()} />
                                )}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
            <StyledStartEndFields>
                <StyledDateField label="Start date" {...startFieldProps} value={valueFromChild?.start ?? null} />
                <StyledDateField label="End date" {...endFieldProps} value={valueFromChild?.end ?? null} />
            </StyledStartEndFields>
        </>
    );
}

const StyledTable = styled.table`
    margin: auto;
`;

const StyledTh = styled.th`
    padding: 12px 14px;
    color: ${(props) => props.theme.shades.two};
    font-weight: normal;
`;

const StyledStartEndFields = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.shades.lightBorder};
`;

const StyledDateField = styled(DateField)`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 12px;

    .date-segment__wrapper {
        border: 1px solid ${(props) => props.theme.shades.darkBorder};
        border-radius: 4px;
        padding: 12px 14px;
        color: ${(props) => props.theme.shades.two};
    }
`;
