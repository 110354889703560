import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { TreeBrowserNode, TreeBrowserStateInterface, UnionOfNodes } from "@iventis/tree-browser";
import React from "react";

export interface SitemapVersionLevelWithFiles extends SitemapVersionLevel {
    mbtiles?: File;
    style?: File;
}

export interface SitemapVersionWithFiles extends SitemapVersion {
    sitemapVersionLevels: SitemapVersionLevelWithFiles[];
}

export interface SitemapTreeBrowserNode extends TreeBrowserNode {
    type: SiteMapNodeTypes;
}

export interface SitemapRootNode extends SitemapTreeBrowserNode {
    type: SiteMapNodeTypes.Root;
    childNodes: SitemapNode[];
}

export interface SitemapNode extends SitemapTreeBrowserNode {
    type: SiteMapNodeTypes.Sitemap;
    sitemap: Sitemap;
    childNodes: SitemapVersionNode[];
}

export interface SitemapVersionNode extends SitemapTreeBrowserNode {
    type: SiteMapNodeTypes.Version;
    version: SitemapVersion;
    childNodes: SitemapVersionLevelNode[];
}

export interface SitemapVersionLevelNode extends SitemapTreeBrowserNode {
    type: SiteMapNodeTypes.Level;
    level: SitemapVersionLevel;
    childNodes: [];
}

export enum SiteMapNodeTypes {
    Sitemap = "Sitemap",
    Version = "Version",
    Level = "Level",
    Root = "Sitemap-Root",
}

export type AnySitemapTreeNode = UnionOfNodes<SitemapRootNode>;

export const SitemapTreeBrowserContext = React.createContext<TreeBrowserStateInterface<SitemapRootNode>>(null);
