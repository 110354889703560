import { createContext } from "react";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { TreeBrowserStateInterface } from "@iventis/tree-browser";

export enum RecommendedAssetType {
    RecommendedLayerTemplate = "RecommendedLayerTemplate",
}

export type AdminAssetType = AssetType | RecommendedAssetType;

export function isRecommendedCategoryType(value: string): value is RecommendedAssetType {
    return Object.values(RecommendedAssetType).includes(value as RecommendedAssetType);
}

export function parseRecommendedAssetType(recommendedAssetType: AdminAssetType): AssetType {
    if (isRecommendedCategoryType(recommendedAssetType)) {
        switch (recommendedAssetType) {
            case RecommendedAssetType.RecommendedLayerTemplate:
                return AssetType.LayerTemplate;
            default:
                throw new Error("Unknown recommended asset type");
        }
    }
    return recommendedAssetType;
}

export interface CategoryNode extends AssetCategory {
    type: "Category";
    childNodes: CategoryNode[];
    sourceId: string;
}

export interface RecommendedCategoryNode extends AssetCategory {
    type: "RecommendedCategory";
    childNodes: RecommendedCategoryNode[];
    sourceId: string;
    checked: boolean;
    projectId: string;
    instanceName: string;
}

export type CategoryRootNodeChildren = CategoryNode | RecommendedCategoryNode;

export interface CategoryRootNode extends AssetCategory {
    type: "CategoryRoot";
    id: AdminAssetType;
    sourceId: AdminAssetType;
    name: AdminAssetType;
    recommended: boolean;
    projectId?: string;
    instanceName?: string;
    childNodes: CategoryRootNodeChildren[];
}

export const CategoryTreeContext = createContext<TreeBrowserStateInterface<CategoryRootNode>>(undefined);
