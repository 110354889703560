import { Model } from "@iventis/domain-model/model/model";
import { Asset } from "@iventis/domain-model/model/asset";
import { UploadModelData } from "../upload-model";

/** Parse a Model to UploadModelData */
export const convertModelToUploadModelData = (model: Model & { thumbnail: Asset }, imageUrl: string): UploadModelData => {
    const { height, width, length } = model;
    return {
        thumbnailId: model.thumbnail.id,
        modelId: model.id,
        categories: model.categories ?? model.thumbnail.tags ?? [],
        model: { fileName: model.name, modelUrl: null },
        thumbnail: { fileName: model.thumbnail.name, imageUrl },
        name: model.name,
        variableSizeModel: height != null && width != null && length != null,
        size: { height, width, length },
    };
};
