/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useState } from "react";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { LoadingComponent } from "@iventis/components";
import { styled } from "@iventis/styles";
import { BillingUnit } from "@iventis/domain-model/model/billingUnit";
import { api } from "../api/api";

export const CustomPriceEditor: FunctionComponent<{
    onDone: () => void;
    onCancel: () => void;
    instanceName: string;
    projectId: string;
    subscriptionPlanId: string;
}> = ({ onDone, onCancel, instanceName, projectId, subscriptionPlanId }) => {
    const [price, setPrice] = useState<number>(0);
    const [currency, setCurrency] = useState<IsoCurrencyCode>(IsoCurrencyCode.GBP);
    const [frequency, setFreq] = useState<SubscriptionPlanPriceFrequency>(SubscriptionPlanPriceFrequency.Monthly);
    const [billingUnit, setBillingUnit] = useState<BillingUnit>(BillingUnit.Project);

    const [submitting, setSubmitting] = useState<boolean>(false);

    const onConfirm = async () => {
        const newPrice: SubscriptionPlanPrice = {
            currencyCode: currency,
            frequency,
            price,
            projectId,
            subscriptionPlanId,
            name: undefined,
            stripeId: undefined,
            id: undefined,
            billingUnit,
            commitmentDays: 0,
        };
        setSubmitting(true);
        await api.post<SubscriptionPlanPrice>(`subscription-plans/${subscriptionPlanId}/subscription-plan-prices`, newPrice, { params: { instanceName } });
        setSubmitting(false);
        onDone();
    };

    return (
        <div style={{ display: "flex" }}>
            <InputContainer>
                <Autocomplete
                    value={currency}
                    style={{ width: "50%" }}
                    renderInput={(params) => <TextField {...params} />}
                    multiple={false}
                    options={Object.keys(IsoCurrencyCode)}
                    onChange={(_, value) => {
                        setCurrency(IsoCurrencyCode[value]);
                    }}
                />
                <TextField value={price} style={{ width: "50%" }} type="number" onChange={(e) => setPrice(Number(e.target.value))} />
                <Autocomplete
                    value={frequency}
                    style={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} />}
                    multiple={false}
                    options={Object.keys(SubscriptionPlanPriceFrequency)}
                    onChange={(_, value) => {
                        setFreq(SubscriptionPlanPriceFrequency[value]);
                    }}
                />
                <Autocomplete
                    value={billingUnit}
                    style={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} />}
                    multiple={false}
                    options={Object.keys(BillingUnit)}
                    onChange={(_, value) => {
                        setBillingUnit(BillingUnit[value]);
                    }}
                />
            </InputContainer>
            <div style={{ marginLeft: "auto" }}>
                {submitting ? (
                    <LoadingComponent />
                ) : (
                    <>
                        <IconButton onClick={() => onConfirm()}>
                            <FontAwesomeIcon icon={["far", "check"]} />
                        </IconButton>
                        <IconButton onClick={() => onCancel()}>
                            <FontAwesomeIcon icon={["far", "xmark"]} />
                        </IconButton>
                    </>
                )}
            </div>
        </div>
    );
};

const InputContainer = styled.div`
    display: flex;
    gap: 5px;
    flex-basis: auto;
    width: 80%;
`;
