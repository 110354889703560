import { Project } from "@iventis/domain-model/model/project";
import { AdminTreeBrowserNode, AdminTreeNodeType } from "@iventis/people";
import { applicationsDefault } from "@iventis/components";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { Node } from "@iventis/domain-model/model/node";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { ProjectWithSubscription } from "@iventis/domain-model/model/projectWithSubscription";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import React from "react";
import { TreeBrowserXState } from "@iventis/tree-browser";
import { AdminFolderNode, AdminInstanceNode, AdminMapNode, AdminProjectNode, AdminProjectNodeTree, AdminSpatialLibraryNode } from "./tree-browser-types";
import { rootNodeId } from "./tree-browser-constants";
import { ProjectSubscriptionResponse } from "./project-types";
import { RecommendedAssetType } from "./category-types";

export const TreeBrowserProjectsContext = React.createContext<TreeBrowserXState<AdminProjectNodeTree>>(null);

interface RootNodeFromChild<TChild extends AdminTreeBrowserNode> extends AdminTreeBrowserNode {
    childNodes: TChild[];
    type: AdminTreeNodeType.Root;
}

// The root node will be the instance
export const createRootNode = <TChild extends AdminTreeBrowserNode>(id: string, projects: TChild[]): RootNodeFromChild<TChild> => ({
    id,
    childNodes: projects,
    name: id,
    type: AdminTreeNodeType.Root,
});

export const instanceResponseToNode = (instanceName: string, parentId: string = rootNodeId): AdminInstanceNode => ({
    id: instanceName,
    childNodes: [],
    name: instanceName,
    type: AdminTreeNodeType.Instance,
    parentId,
});

export const projectResponseToNode = (project: Project, parentId: string = rootNodeId, projectSubscription?: ProjectSubscription): AdminProjectNode => ({
    id: project.id,
    childNodes: [],
    name: project.name,
    type: AdminTreeNodeType.Project,
    parentId,
    project,
    applications: applicationsDefault,
    status: project.status,
    projectSubscription,
});

export const parseSearchProjectResponse = (instanceName, projects: ProjectWithSubscription[]): AdminProjectNodeTree => {
    const projectNodes = projects.map((projectWithSubscription) =>
        projectResponseToNode(projectWithSubscription, instanceName, parseProjectSubscription((projectWithSubscription.subscription as unknown) as ProjectSubscriptionResponse))
    );
    return createRootNode(instanceName, projectNodes);
};

export const createProjectNode = (
    instanceId: string,
    name: string,
    applications: Record<ApplicationName, boolean>,
    subscription: ProjectSubscription,
    recommendedCategories: { [key in RecommendedAssetType]: string[] }
) => {
    // Make project
    // All properties except name are defined on the backend
    const proj: Project = {
        id: undefined,
        name,
        shortName: undefined,
        createdByUserId: undefined,
        createdByUserName: undefined,
        createdAt: undefined,
        lastUpdatedByUserId: undefined,
        lastUpdatedByUserName: undefined,
        lastUpdatedAt: undefined,
        projectSubscriptionId: undefined,
        status: undefined,
    };

    // Make node
    const projNode: AdminProjectNode = {
        id: undefined,
        childNodes: [],
        parentId: instanceId,
        name,
        type: AdminTreeNodeType.Project,
        project: proj,
        applications,
        status: ProjectStatus.Active,
        projectSubscription: subscription,
        recommendedLayerTemplateCategories: recommendedCategories[RecommendedAssetType.RecommendedLayerTemplate],
    };

    return projNode;
};

export const appRecordToArray = (applicationRecord: Record<ApplicationName, boolean>): ApplicationName[] =>
    Object.keys(applicationRecord)
        .filter((x) => applicationRecord[x])
        .map((x) => ApplicationName[x]);

/** Parses commitedUntil value. Want to only convert date when it is not null as null date defaults to 1970-01-01  */
export const parseProjectSubscription = (subscription?: ProjectSubscriptionResponse): ProjectSubscription | undefined => {
    if (subscription == null) {
        return undefined;
    }

    const { committedUntil } = subscription;

    if (committedUntil != null) {
        return { ...subscription, committedUntil: new Date(committedUntil) };
    }

    return { ...subscription, committedUntil: undefined };
};

/** Parse response from getting spatial library node to something that can be used in the project tree */
export const parseSpatialLibraryNode = (node: Node, projectId: string, instanceName: string): AdminSpatialLibraryNode => ({
    id: node.id,
    name: node.name,
    type: AdminTreeNodeType.SharedSpatialLibrary,
    parentId: projectId,
    instanceName,
    childNodes: [],
});

/** Parse response from getting map and folder nodes to something that can be used in the project tree */
export const parseMapAndFolderLibraryNodes = (node: Node, projectId: string, instanceName: string, overrideParentId?: string): AdminMapNode | AdminFolderNode => {
    const parentId = overrideParentId ?? node.parentId;
    switch (node.type) {
        case NodeType.Map:
            return { id: node.id, name: node.name, type: AdminTreeNodeType.Map, parentId, projectId, instanceName, childNodes: [] };
        case NodeType.Folder:
            return { id: node.id, name: node.name, type: AdminTreeNodeType.Folder, parentId, projectId, instanceName, childNodes: [] };
        default:
            throw new Error(`Node of type ${node.type} was not handled`);
    }
};
