/* eslint-disable arrow-body-style */
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import React, { FunctionComponent } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { styled } from "@iventis/styles";

export const ApplicationSelector: FunctionComponent<{
    onChange: (application: { app: ApplicationName; enabled: boolean }) => void;
    applications: Record<ApplicationName, boolean>;
    disabled?: boolean;
}> = ({ onChange, applications, disabled }) => {
    return (
        <StyledFormGroup>
            {/* Disabled as will always be on project */}
            <FormControlLabel control={<Checkbox disabled checked />} label="Project Settings" />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={disabled}
                        value={applications[ApplicationName.People]}
                        onChange={(event) => onChange({ app: ApplicationName.People, enabled: event.target.checked })}
                    />
                }
                label="People"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={disabled}
                        value={applications[ApplicationName.Maps]}
                        onChange={(event) => onChange({ app: ApplicationName.Maps, enabled: event.target.checked })}
                    />
                }
                label="Maps"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={disabled}
                        value={applications[ApplicationName.Schedule]}
                        onChange={(event) => onChange({ app: ApplicationName.Schedule, enabled: event.target.checked })}
                    />
                }
                label="Schedule"
            />
            {/* Disabled as unsupported for now */}
            <FormControlLabel control={<Checkbox disabled />} label="Iventis Engine" />
        </StyledFormGroup>
    );
};

const StyledFormGroup = styled(FormGroup)`
    max-height: 250px;
    overflow: auto;
    border: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    border-style: solid;
    border-radius: 4px;
    padding: 8px 14px;
`;
