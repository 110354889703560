import { findNode, NodeServices } from "@iventis/tree-browser";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { AssetRecommendedCategoryDto } from "@iventis/domain-model/model/assetRecommendedCategoryDto";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { isValidUuid } from "@iventis/utilities";
import { UploadIconData } from "@iventis/components";
import { convertUploadIconDataToAssetPostData } from "@iventis/api-helpers";
import { v4 as uuid } from "uuid";
import { AdminAssetType, CategoryNode, CategoryRootNode, RecommendedAssetType, parseRecommendedAssetType } from "./category-types";
import { api } from "../api/api";
import { createAsset } from "./assets-api-helpers";

export const CREATE_NEW_CATEGORY_ID = "create-new-category";

export const uploadCategoryIcon = async (category: AssetCategory, uploadIconData: UploadIconData) => {
    const asset = convertUploadIconDataToAssetPostData(uploadIconData, AssetType.TagIcon);
    const response = await createAsset(asset);
    if (category.id !== CREATE_NEW_CATEGORY_ID) {
        await updateCategory({ ...category, iconAssetId: response.id });
    }
    return response;
};

/** Gets the first level for categories for an asset type */
export const getCategories = async (assetType: AssetType) => {
    const response = await api.get<AssetCategory[]>(`categories?categoryType=${assetType}`);
    return response.data;
};

export const getRecommendedCategories = async (recommendedAssetType: AdminAssetType, instanceName: string, projectId: string) => {
    const assetType = parseRecommendedAssetType(recommendedAssetType);
    const response = await api.get<AssetCategory[]>(`instances/${instanceName}/projects/${projectId}/recommended-asset-tags?assetType=${assetType}`);
    return response.data;
};

export const createRecommendedCategory = async (recommendedCategory: AssetRecommendedCategoryDto, instanceName: string, projectId: string) => {
    await api.post<AssetCategory[]>(`instances/${instanceName}/projects/${projectId}/recommended-asset-tags`, recommendedCategory);
};

/** Gets a flat list of all the categories and children for an asset type */
export const getFlatCategories = async (assetType: AssetType) => {
    const { data: tags } = await api.get<AssetCategory[]>(`categories?categoryType=${assetType}`);

    const getByParent = async (id) => {
        const { data: children } = await api.get<AssetCategory[]>(`categories/${id}/children`);
        return children;
    };

    // Categories are only level 1 for now
    const children: AssetCategory[][] = await Promise.all(tags.map((tag) => getByParent(tag.id)));

    // Flatten tags + children into a single level array
    return [...tags, ...children.flat()];
};

const createCategory = async (category: AssetCategory): Promise<AssetCategory> => {
    const response = await api.post<AssetCategory>(`categories`, category);
    return response.data;
};

export const updateCategory = async (category: AssetCategory) => {
    const response = await api.put<AssetCategory>(`categories`, { ...category, parentId: isValidUuid(category.parentId) ? category.parentId : null });
    return response.data;
};

const deleteCategory = async (category: AssetCategory) => {
    const response = await api.delete<AssetCategory>(`categories/${category.id}?`);
    return response.data;
};

export const categoryNodeServices: NodeServices<CategoryRootNode> = {
    addNode: async (node) => {
        switch (node.type) {
            case "Category": {
                const newCategory = await createCategory({ ...node, id: uuid(), parentId: isValidUuid(node.parentId) ? node.parentId : null });
                const newNode: CategoryNode = { ...node, id: newCategory.id, sourceId: newCategory.id, type: "Category" };
                return newNode;
            }
            default:
                throw new Error(`${node.type} not supported`);
        }
    },
    deleteNodes: async (categories) => {
        await Promise.all(categories.map(deleteCategory));
        return categories.map((category) => category.id);
    },
    getTree: async (assetType: AdminAssetType) => {
        switch (assetType) {
            case RecommendedAssetType.RecommendedLayerTemplate: {
                return {
                    id: assetType,
                    sourceId: assetType,
                    name: assetType,
                    type: "CategoryRoot",
                    assetType: parseRecommendedAssetType(assetType),
                    iconAssetId: null,
                    recommended: true,
                    childNodes: [],
                };
            }
            default: {
                const categories = await getCategories(assetType);
                return {
                    id: assetType,
                    sourceId: assetType,
                    name: assetType,
                    type: "CategoryRoot",
                    assetType,
                    iconAssetId: null,
                    recommended: false,
                    childNodes: categories.map((category) => ({ ...category, sourceId: category.id, parentId: assetType, type: "Category", childNodes: [] })),
                };
            }
        }
    },
    getNode: async (id, context) => {
        const { data: children } = await api.get<AssetCategory[]>(`categories/${id}/children`);
        const node = findNode(context.tree.childNodes, id);
        switch (node.type) {
            case "Category": {
                return {
                    ...node,
                    childNodes: children.map((category) => ({ ...category, sourceId: category.id, type: "Category", childNodes: [] })),
                };
            }
            case "RecommendedCategory": {
                const recommendedCategories =
                    context.tree.projectId == null ? [] : await getRecommendedCategories(node.assetType, context.tree.instanceName, context.tree.projectId);
                return {
                    ...node,
                    childNodes: children.map((category) => ({
                        ...category,
                        sourceId: category.id,
                        type: "RecommendedCategory",
                        childNodes: [],
                        checked: node.checked || recommendedCategories.some((recommendedCategory) => recommendedCategory.id === category.id),
                        projectId: context.tree.projectId,
                        instanceName: context.tree.instanceName,
                    })),
                };
            }
            default:
                throw new Error("Node type not supported");
        }
    },
    updateNode: async (node) => {
        switch (node.type) {
            case "Category": {
                await updateCategory(node);
                return node;
            }
            case "RecommendedCategory": {
                await createRecommendedCategory({ assetType: node.assetType, categoryName: node.name, projectId: node.projectId }, node.instanceName, node.projectId);
                return node;
            }
            default:
                return node;
        }
    },
    moveNodes: async (nodes) => {
        if (nodes.length > 1) {
            throw new Error("Moving multiple nodes is not supported");
        }
        const node = nodes[0];
        switch (node.type) {
            case "Category":
                await updateCategory(node);
                break;
            default:
                throw new Error(`Node type ${node.type} being moved is not supported`);
        }
    },
    getNodeThumbnails: {},
};
