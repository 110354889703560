import { AdminTreeNodeType } from "@iventis/people";
import { PersonNode } from "./person-node";
import { ProjectNode } from "./project-node";
import { SpatialLibraryNodeComponent } from "./spatial-library-node";
import { MapNodeComponent } from "./map-node";
import { FolderNodeComponent } from "./folder-node";

export const nodeComponents = {
    [AdminTreeNodeType.Root]: undefined,
    [AdminTreeNodeType.Project]: ProjectNode,
    [AdminTreeNodeType.User]: PersonNode,
    [AdminTreeNodeType.SharedSpatialLibrary]: SpatialLibraryNodeComponent,
    [AdminTreeNodeType.Map]: MapNodeComponent,
    [AdminTreeNodeType.Folder]: FolderNodeComponent,
};
