import { createMachine, assign } from "xstate";

export type RemoteUploadState = {
    id: string;
    url: string;
    file: File;
    progress: number | null;
};

export type RemoteUploadEvents =
    | { type: "UPDATE_PROGRESS"; percentage: number }
    | { type: "UPLOAD_SUCCESS"; response: File }
    | { type: "UPLOAD_FAILED"; request: XMLHttpRequest }
    | { type: "NETWORK_ERROR"; error: ProgressEvent<EventTarget> }
    | { type: "RETRY" };

export const remoteUploadMachine = (id: string) =>
    createMachine(
        {
            id,
            predictableActionArguments: true,
            schema: {
                context: {} as RemoteUploadState,
                events: {} as RemoteUploadEvents,
                services: {} as { successCallback: { data: unknown } },
            },
            // eslint-disable-next-line no-undef
            tsTypes: {} as import("./remote-upload-machine.typegen").Typegen0,
            context: {
                id,
                url: undefined,
                file: undefined,
                progress: undefined,
            },
            initial: "uploadingFile",
            states: {
                uploadingFile: {
                    invoke: {
                        id: `uploading-file|${id}`,
                        src: "uploadFile",
                    },
                    on: {
                        UPDATE_PROGRESS: { actions: "updateProgress" },
                        UPLOAD_SUCCESS: { target: "success" },
                        UPLOAD_FAILED: { target: "failed" },
                        NETWORK_ERROR: { target: "failed" },
                    },
                },
                success: {
                    invoke: {
                        id: `success-callback|${id}`,
                        src: "successCallback",
                        onDone: "complete",
                    },
                },
                complete: { type: "final" },
                failed: {
                    on: {
                        RETRY: "uploadingFile",
                    },
                },
            },
        },
        {
            actions: {
                updateProgress: assign({ progress: (_, event) => event.percentage }),
            },
            services: {
                uploadFile: (context) => (send) => {
                    const xhr = new XMLHttpRequest();

                    xhr.upload.addEventListener("progress", (event) => {
                        if (event.lengthComputable) {
                            const percentage = Math.round((event.loaded * 100) / event.total);
                            send({ type: "UPDATE_PROGRESS", percentage });
                        }
                    });

                    xhr.open("PUT", context.url, true);
                    xhr.setRequestHeader("Content-Type", "application/zip");
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            send({ type: "UPLOAD_SUCCESS", ...xhr });
                        } else {
                            send({ type: "UPLOAD_FAILED", request: xhr });
                        }
                    };

                    xhr.onerror = (error) => {
                        send({ type: "NETWORK_ERROR", error });
                    };

                    xhr.send(context.file);
                },
            },
        }
    );
