/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { OptionsObject, VariantType } from "notistack";
import { Toasts } from "./toast-interface";
import { Toast } from "./toast.types";

export const toastsDoNothing: Toasts = {
    error(toast: Toast, options?: OptionsObject): void {},

    info(toast: Toast, options?: OptionsObject): void {},

    success(toast: Toast, options?: OptionsObject): void {},

    remove(key: string): void {},

    warning(toast: Toast, options?: OptionsObject): void {},

    _create(toast: Toast, variant: VariantType, options: OptionsObject): void {},
};
