import { Body2, Header3, sectionalMargin, styled } from "@iventis/styles";
import { useQuery } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import React, { FunctionComponent, useState } from "react";

import { api } from "../api/api";

export const DigitalTwinComponent: FunctionComponent = () => {
    const { data: status } = useQuery(["ENGINE-STATUS"], async () => api.get<string>(`digital-twin`).then((r) => r.data));
    const gettingStatusText = "Getting status...";

    const [buttonClicked, setButtonClicked] = useState(false);

    const onStatus = "running";
    const offStatus = "stopped";

    const turnOnText = "Turn on";
    const turnOffText = "Turn off";

    const turnOff = () => api.delete(`digital-twin`);
    const turnOn = () => api.post(`digital-twin`);

    const handleChange = async () => {
        setButtonClicked(true);
        if (status === onStatus) {
            await turnOff();
        }
        if (status === offStatus) {
            await turnOn();
        }
    };

    return (
        <DigitalTwinContainer>
            <Header3>Digital Twin</Header3>
            <Body2>Status: {status == null ? gettingStatusText : status}</Body2>
            <Button onClick={handleChange} disabled={buttonClicked || (status !== onStatus && status !== offStatus)}>
                {status === onStatus ? turnOffText : turnOnText}
            </Button>
        </DigitalTwinContainer>
    );
};

const DigitalTwinContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${sectionalMargin};
`;
