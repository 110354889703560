import { StyleType } from "@iventis/domain-model/model/styleType";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { MapImportLayer } from "./map-json-types";

/** Ensures that all necessary style values are populated */
export function ensureLayerStylesAreValid(layers: MapImportLayer[]): MapImportLayer[] {
    return layers.map((layer) => {
        switch (layer.styleType) {
            case StyleType.Model:
                return {
                    ...layer,
                    style: {
                        ...layer.style,
                        height: layer.style.height ?? defaultHeightStyleValue,
                        width: layer.style.width ?? defaultWidthStyleValue,
                        length: layer.style.length ?? defaultLengthStyleValue,
                        scale: layer.style.scale ?? defaultScaleStyleValue,
                    },
                };
            case StyleType.LineModel:
                return {
                    ...layer,
                    style: {
                        ...layer.style,
                        height: layer.style.height ?? defaultHeightStyleValue,
                        width: layer.style.width ?? defaultWidthStyleValue,
                        length: layer.style.length ?? defaultLengthStyleValue,
                        scale: layer.style.scale ?? defaultScaleStyleValue,
                    },
                };
            default:
                return layer;
        }
    });
}

const defaultHeightStyleValue: ModelStyle["height"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
};

const defaultWidthStyleValue: ModelStyle["width"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
};

const defaultLengthStyleValue: ModelStyle["length"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
};

const defaultScaleStyleValue: ModelStyle["scale"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
};
