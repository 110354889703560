/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react";
import { TextField, TextFieldProps, InputBaseProps } from "@mui/material";
import { timeInputWidth } from "@iventis/styles/src/atomic-rules";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Caption } from "@iventis/styles/src/components/texts";
import { ZERO_TIME_SPAN } from "@iventis/utilities";

export type TimeSelectorProps = {
    className?: string;
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    name?: string;
    value?: string;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    helperText?: string;
    error?: boolean;
    variant?: TextFieldProps["variant"];
    width?: string;
    dataTestId?: string;
    additionalInputProps?: InputBaseProps["inputProps"];
};

export const TimeSelector = forwardRef<HTMLDivElement, TimeSelectorProps>(
    ({ className, name, value, onChange, error, helperText, onBlur, disabled, variant = "outlined", width = timeInputWidth, dataTestId, additionalInputProps }, ref) => (
        <div ref={ref} className={`${className}-container`}>
            <StyledTextField
                className={className}
                disabled={disabled}
                id="time"
                type="time"
                name={name}
                variant={variant}
                value={value ?? ZERO_TIME_SPAN}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const time = e.target.value;
                    // If the time is of pattern 00:00 (without the seconds)
                    if (time != null && time.match(/^[0-9][0-9]:[0-9][0-9]$/)) {
                        // missing :ss on some old browsers, sometimes
                        e.target.value += ":00"; // add it ourselves
                    }
                    onChange(e);
                }}
                error={error}
                onBlur={onBlur}
                inputProps={{
                    step: 1, // 1 second time interval
                    ...additionalInputProps,
                }}
                data-testid={dataTestId}
                sx={{ width }}
            />
            <CustomHelperText show={helperText?.length > 0}>{helperText}</CustomHelperText>
        </div>
    )
);
const CustomHelperText = styled(Caption)<{ show: boolean }>`
    position: absolute;
    top: 100%;
    width: 100%;
    height: ${(props) => (props.show ? "15px" : "0px")};
    color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.error};
    transition: height ease-in-out 100;
`;

const StyledTextField = styled(TextField)`
    &.time-range-selector {
        width: 120px;
    }
    &.time-range-selector fieldset {
        border: none;
    }
    &.time-range-selector input {
        height: 44px;
        box-sizing: border-box;
    }
`;
