import { TooltipContentWrapper } from "@iventis/components";
import { Header6, Body2 } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent } from "react";
import { PointStyles } from "@iventis/map-engine/src/utilities/style-helpers";

export const PitchAlignmentInfo: FunctionComponent<{ styleType: PointStyles["styleType"] }> = ({ styleType }) => {
    const translate = useIventisTranslate();
    const styleTypeTranslated = translate(Content.map2.styles2.plural[styleType]);
    return (
        <TooltipContentWrapper>
            <Header6>{translate(Content.map2.styles.pitch_alignment_map)}</Header6>
            <Body2>{translate(Content.map2.styles.pitch_alignment_map_desc, { styleType: styleTypeTranslated })}</Body2>
            <Header6>{translate(Content.map2.styles.pitch_alignment_viewport)}</Header6>
            <Body2>{translate(Content.map2.styles.pitch_alignment_viewport_desc, { styleType: styleTypeTranslated })}</Body2>
        </TooltipContentWrapper>
    );
};
