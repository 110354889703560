import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { createContext } from "react";
import { SitemapVersionLevelWithFiles } from "./sitemap-types";

export interface ISitemapContext {
    createSitemap: (sitemap: Sitemap) => Promise<Sitemap>;
    updateSitemap: (sitemap: Sitemap) => Promise<Sitemap>;
    createSitemapVersion: (sitemapVersion: SitemapVersion) => Promise<SitemapVersion>;
    updateSitemapVersion: (sitemapVersion: SitemapVersion) => Promise<SitemapVersion>;
    deleteSitemapVersion: (id: string, sitemapId: string) => Promise<void>;
    createSitemapVersionLevel: (sitemapVersionLevel: SitemapVersionLevelWithFiles, sitemapId: string) => Promise<SitemapVersionLevelCreateResponse>;
    updateSitemapVersionLevel: (sitemapVersionLevel: SitemapVersionLevelWithFiles, sitemapId: string) => Promise<string>;
    deleteSitemapVersionLevel: (id: string, sitemapVersionId: string, sitemapId: string) => Promise<void>;
}

export const SitemapContext = createContext<ISitemapContext>(null);
