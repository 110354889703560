/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import { DatePickerSolid } from "@iventis/styles/src/components/date-picker-solid";
import { format } from "date-fns";
import { TextField } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

interface DateSelectorProps {
    value: Date;
    disabled?: boolean;
    testId?: string;
    valueChanged: (value: string) => void;
}

export const DateSelectorComponent = ({ value, disabled, testId, valueChanged }: DateSelectorProps) => {
    const parsedDate = value != null ? utcToZonedTime(value, "UTC") : null;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGb}>
            <DatePickerSolid
                className="Mui-Datepicker"
                // Value is an ISO8601 https://metomi.github.io/rose/2019.01.2/html/tutorial/cylc/scheduling/datetime-cycling.html
                value={parsedDate}
                disabled={disabled}
                onChange={(value: Date) => {
                    try {
                        // Only set the date if the year has been fully entered, we can assume they won't enter a date before the year 1000
                        if (value.getFullYear() > 1000) {
                            const formattedDate = format(value, "yyyy-MM-dd");
                            // Append UTC timezone to the date to ensure the date is stored in UTC
                            valueChanged(`${formattedDate}T00:00:00Z`);
                        }
                    } catch {
                        /* Catch required to stop error from bubbling up. We dont need to handle the error while user is entering a date. */
                    }
                }}
                renderInput={(params) => <TextField {...params} data-testid={testId} />}
                OpenPickerButtonProps={{
                    onMouseDown: (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    },
                }}
            />
        </LocalizationProvider>
    );
};
