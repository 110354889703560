import { Asset } from "@iventis/domain-model/model/asset";

enum IconActionStyleActionName {
    SET_ICON_SDF = "SET_ICON_SDF",
}

export const IconStyleActionTypes = {
    setIconSdfEnabled: IconActionStyleActionName.SET_ICON_SDF,
};

export interface IconStyleState {
    iconSDFEnabled: boolean;
    onIconSDFDisable: () => void;
}

interface SetIconSDFEnabledEvent {
    type: typeof IconStyleActionTypes.setIconSdfEnabled;
    payload: Asset[];
}

export type IconStyleActions = SetIconSDFEnabledEvent;
