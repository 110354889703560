import { xstateDevTools } from "@iventis/utilities";
import React from "react";
import { createRoot } from "react-dom/client";
import { inspect } from "@xstate/inspect";
import { App } from "./app";

if (xstateDevTools) {
    inspect({ iframe: false });
}

declare const VERSION: string;

if (VERSION != null) {
    // eslint-disable-next-line no-console
    console.log(`Admin Dashboard Version: ${VERSION}`);
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
