import { AppBarSubmenu, AppBarSubmenuItem, AppBarSubmenuTheme } from "@iventis/components";
import React, { FunctionComponent, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

export enum AssetsSubmenuItemType {
    ICONS = "icons",
    MODELS = "models",
    MAP_BACKGROUNDS = "map-backgrounds",
    LAYER_TEMPLATES = "layer-templates",
    ATTRIBUTES = "attributes",
    CATEGORIES = "categories",
    PROJECT_IMAGES = "project-images",
    SITEMAPS = "sitemaps",
}

export const subMenuItems: AppBarSubmenuItem<AssetsSubmenuItemType>[] = [
    { id: AssetsSubmenuItemType.ICONS, name: "Icons", pageIcon: "hexagon-image", sidePanelIcon: "hexagon-image" },
    { id: AssetsSubmenuItemType.MODELS, name: "3D models", pageIcon: "pencil", sidePanelIcon: "pencil" },
    { id: AssetsSubmenuItemType.LAYER_TEMPLATES, name: "Layer templates", pageIcon: "layer-group", sidePanelIcon: "layer-group" },
    { id: AssetsSubmenuItemType.ATTRIBUTES, name: "Predefined attributes", pageIcon: "tag", sidePanelIcon: "tag" },
    { id: AssetsSubmenuItemType.CATEGORIES, name: "Categories", pageIcon: "list", sidePanelIcon: "list" },
    { id: AssetsSubmenuItemType.MAP_BACKGROUNDS, name: "Map Backgrounds", pageIcon: "map", sidePanelIcon: "map" },
    { id: AssetsSubmenuItemType.SITEMAPS, name: "Sitemaps", pageIcon: "map", sidePanelIcon: "map" },
    { id: AssetsSubmenuItemType.PROJECT_IMAGES, name: "Project images", pageIcon: "image", sidePanelIcon: "image" },
];

export const AssetsAppComponent: FunctionComponent = () => {
    const nav = useNavigate();
    const location = useLocation();
    const selectedApp = location?.pathname?.replace("/resources", "").replace("/", "");
    useEffect(() => {
        if (selectedApp === "") {
            nav(subMenuItems[0].id);
        }
    }, [selectedApp]);
    return (
        <>
            <AppBarSubmenu
                items={subMenuItems}
                selectedItem={selectedApp}
                onItemClick={(item) => nav({ pathname: `/resources/${item.id}` })}
                fullScreenTitle={selectedApp}
                mobileOpen={false}
                theme={AppBarSubmenuTheme.Administrative}
            />
            <Outlet />
        </>
    );
};
