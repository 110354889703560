/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { Autocomplete, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { FunctionComponent, useState } from "react";
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { StyledIconButton } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from "uuid";
import { BillingUnit } from "@iventis/domain-model/model/billingUnit";

const getDefaultPricePlan = (subscriptionPlanId: string): SubscriptionPlanPrice => ({
    name: "",
    currencyCode: IsoCurrencyCode.GBP,
    price: 0,
    subscriptionPlanId,
    frequency: SubscriptionPlanPriceFrequency.Monthly,
    id: uuid(),
    stripeId: "",
    billingUnit: BillingUnit.Project,
    commitmentDays: 0,
});

export const PriceEditor: FunctionComponent<{
    pricePlans: SubscriptionPlanPrice[];
    onChange: (prices: SubscriptionPlanPrice[]) => void;
    subscriptionPlanId: string;
}> = ({ pricePlans: prices, onChange, subscriptionPlanId }) => {
    const [localPrices, setLocalPrices] = useState<Array<string>>([]);

    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Currency</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Frequency</TableCell>
                            <TableCell>Commitment days</TableCell>
                            <TableCell>Billing unit</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prices.map((price, index) => (
                            <TableRow style={{ position: "relative" }} key={price.id ?? index}>
                                <TableCell component="th" scope="row">
                                    <Autocomplete
                                        disabled={!localPrices.includes(price.id)}
                                        multiple={false}
                                        options={Object.keys(IsoCurrencyCode)}
                                        value={price.currencyCode}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(_, value) => {
                                            const newPrice: SubscriptionPlanPrice = {
                                                ...price,
                                                currencyCode: IsoCurrencyCode[value],
                                            };
                                            onChange(prices.map((p) => (p.id === newPrice.id ? newPrice : p)));
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        disabled={!localPrices.includes(price.id)}
                                        type="number"
                                        value={price.price ?? ""}
                                        onChange={(e) => {
                                            const newPrice: SubscriptionPlanPrice = {
                                                ...price,
                                                price: e.target.value?.length === 0 ? null : Number(e.target.value),
                                            };
                                            onChange(prices.map((p) => (p.id === newPrice.id ? newPrice : p)));
                                        }}
                                        onBlur={(e) => e.target.value?.length === 0 && onChange(prices.map((p) => (p.id === price.id ? { ...price, price: 0 } : p)))}
                                        style={{ width: "100%" }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disabled={!localPrices.includes(price.id)}
                                        multiple={false}
                                        options={Object.keys(SubscriptionPlanPriceFrequency)}
                                        value={price.frequency}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(e, value) => {
                                            const newPrice: SubscriptionPlanPrice = {
                                                ...price,
                                                frequency: SubscriptionPlanPriceFrequency[value],
                                            };
                                            onChange(prices.map((p) => (p.id === newPrice.id ? newPrice : p)));
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        disabled={!localPrices.includes(price.id)}
                                        type="number"
                                        value={price.commitmentDays ?? ""}
                                        onChange={(e) => {
                                            const newPrice: SubscriptionPlanPrice = {
                                                ...price,
                                                commitmentDays: e.target.value?.length === 0 ? null : Number(e.target.value),
                                            };
                                            onChange(prices.map((p) => (p.id === newPrice.id ? newPrice : p)));
                                        }}
                                        onBlur={(e) => e.target.value?.length === 0 && onChange(prices.map((p) => (p.id === price.id ? { ...price, commitmentDays: 0 } : p)))}
                                        style={{ width: "100%", maxWidth: "50px" }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disabled={!localPrices.includes(price.id)}
                                        multiple={false}
                                        options={Object.keys(BillingUnit)}
                                        value={price.billingUnit}
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(e, value) => {
                                            const newPrice: SubscriptionPlanPrice = {
                                                ...price,
                                                billingUnit: BillingUnit[value],
                                            };
                                            onChange(prices.map((p) => (p.id === newPrice.id ? newPrice : p)));
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </TableCell>
                                <TableCell>
                                    {price.id == null ? (
                                        <StyledIconButton
                                            disabled={!localPrices.includes(price.id)}
                                            onClick={() => {
                                                const newPrices = prices.filter((p) => p.id !== price.id);
                                                onChange(newPrices);
                                            }}
                                            size="small"
                                        >
                                            <FontAwesomeIcon icon={["far", "xmark"]} />
                                        </StyledIconButton>
                                    ) : (
                                        <div />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledIconButton
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                    const newPrice = getDefaultPricePlan(subscriptionPlanId);
                    setLocalPrices([...localPrices, newPrice.id]);
                    onChange([...prices, newPrice]);
                }}
                size="small"
            >
                <FontAwesomeIcon icon={["far", "plus"]} />
            </StyledIconButton>
        </>
    );
};
