import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { createContext, useContext } from "react";

export interface DataFieldListItemTableService {
    updateDefaultValue?: (dataField: OptionalExceptFor<DataField, "id" | "defaultValue">) => Promise<unknown>;
    getListItems: (dataFieldId: string) => Promise<DataFieldListItem[]>;
    getListItem: (dataFieldId: string, listItemId: string) => Promise<DataFieldListItem>;
    getDataFieldsForResource: (resourceId: string) => Promise<DataField[]>;
    deleteListItem: (dataFieldId: string, listItemId: string) => Promise<void>;
    deleteListItems: (dataFieldId: string) => Promise<void>;
    updateListItem: (dataFieldId: string, listItem: DataFieldListItem) => Promise<DataFieldListItem>;
    updateListItemOrder?: (dataFieldId: string, listItem: Pick<DataFieldListItem, "id" | "order"> & { listItemIdAfter: string }) => Promise<unknown>;
    addListItem: (dataFieldId: string, listItem: DataFieldListItem) => Promise<DataFieldListItem>;
    importListItems?: (datafieldId: string, csvJson: string, deleteExisting?: boolean) => Promise<void>;
    exportListItems?: (datafieldId: string, dataFieldName?: string) => void;
    isListItemBeingUsed?: (dataFieldId: string, listItemId: string) => Promise<boolean>;
}

export const DataFieldListItemTableServiceContext = createContext<DataFieldListItemTableService>(null);
export const useDataFieldListItemTableService = () => useContext(DataFieldListItemTableServiceContext);
