/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable arrow-body-style */
import React, { BaseSyntheticEvent, forwardRef, useImperativeHandle, useState } from "react";
import { IDoesFilterPassParams } from "@ag-grid-community/core";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { useEffectIgnoreInitialRender } from "../lib/hooks";
import { StyledInputField } from "../styles/data-table.styles";
import { ColumnDataType, IventisFilterRef, NumberFilterComponentProps, OperatorsTranslatedTextMapping } from "../types/data-table.types";
import { OperatorSelectorComponent } from "./operator-selector";
import { doesFilterPass, getFilterModel } from "../lib/grid.helpers";
import { FilterTemplateComponent } from "./filter-template";

export type NumberOperators = Exclude<IventisFilterOperator, IventisFilterOperator.In | IventisFilterOperator.Ct | IventisFilterOperator.Not | IventisFilterOperator.NotIn>;

/**
 * Used as FilterFramework for data table column of type "number"
 */
export const NumberFilterComponent = forwardRef<IventisFilterRef, NumberFilterComponentProps>(({ ...props }, ref) => {
    const { context } = props;
    const [operator, setOperator] = useState<NumberOperators>(IventisFilterOperator.Eq);
    const [value, setValue] = useState<number | "">("");

    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return value !== "";
            },

            getModel() {
                return getFilterModel(props.column, ColumnDataType.NUMBER, operator, value, props.apiFilterType);
            },
            doesFilterPass(params: IDoesFilterPassParams) {
                return doesFilterPass(value, params.data[props.colDef.field], operator);
            },
        };
    });

    useEffectIgnoreInitialRender(() => {
        props.filterChangedCallback();
    }, [operator, value]);

    const numberOperators: OperatorsTranslatedTextMapping<NumberOperators> = {
        [IventisFilterOperator.Eq]: context?.translate(IventisFilterOperator.Eq) || "Equals",
        [IventisFilterOperator.Gt]: context?.translate(IventisFilterOperator.Gt) || "Greater than",
        [IventisFilterOperator.Gte]: context?.translate(IventisFilterOperator.Gte) || "Greater than or equal to",
        [IventisFilterOperator.Lt]: context?.translate(IventisFilterOperator.Lt) || "Less than",
        [IventisFilterOperator.Lte]: context?.translate(IventisFilterOperator.Lte) || "Less than or equal to",
    };

    return (
        <FilterTemplateComponent
            title={props.colDef.headerName}
            onClose={(e) => {
                e.stopPropagation();
                props.api.hidePopupMenu();
            }}
        >
            <StyledInputField
                id="outlined-number"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={value}
                onChange={(e: BaseSyntheticEvent) => setValue(e.currentTarget.value)}
            />
            <OperatorSelectorComponent<NumberOperators> operator={operator} setOperator={setOperator} operators={numberOperators} />
        </FilterTemplateComponent>
    );
});
