import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Header5 } from "@iventis/styles/src/components/texts";
import { inlineTextIconMargin } from "@iventis/styles/src/atomic-rules";
import { InteractiveElement, styled } from "@iventis/styles";
import { dataTestIds } from "@iventis/testing";

interface StyleContainerProps {
    title: string | ReactNode;
    icon?: ReactNode;
    children?: React.ReactNode;
    defaultOpen?: boolean;
    testId?: string;
}

const StyleContainer: FunctionComponent<StyleContainerProps> = ({ children, title, icon, testId, defaultOpen = true }) => {
    const [show, setShow] = useState(defaultOpen);

    const handleChevronClick = () => {
        setShow(!show);
    };

    return (
        <StyledPropertyContainer className="style-container" data-testid={dataTestIds.editLayer.optionsContainer(testId)}>
            <InteractiveElement className="header-chevron-container" onClick={handleChevronClick}>
                {icon != null && icon}
                <Header5 className="title">{title}</Header5>
                <FontAwesomeIcon id="chevron" size="xs" icon={show ? "chevron-up" : "chevron-down"} />
            </InteractiveElement>
            {show && children}
        </StyledPropertyContainer>
    );
};

const StyledPropertyContainer = styled.div`
    .title {
        flex-basis: 95%;
        display: flex;
        gap: ${inlineTextIconMargin};
        align-items: center;
    }
    .header-chevron-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #chevron {
        flex-basis: 5%;
        cursor: pointer;
    }

    #header-icon {
        padding-right: ${inlineTextIconMargin};
    }
`;

export default StyleContainer;
