import React, { FunctionComponent, useEffect, useState } from "react";
import { LoadingComponent } from "@iventis/components";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { LayerThumbnailGenerator } from "@iventis/layer-styles/src/layer-thumbnail-generator";
import { DataFieldServicesContext } from "@iventis/datafield-editor";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { Model } from "@iventis/domain-model/model/model";
import { styled } from "@iventis/styles";
import { imageBlobResizer } from "@iventis/utilities";
import { api } from "../api/api";

/** Generates all the thumbnails for the given layers. Runs onDone when finished which contains a map of LayerId: Thumbnail Base64 image */
export const ImportThumbnailGenerator: FunctionComponent<{
    onDone: (thumbnailsGenerated: { [key: string]: string }) => void;
    layers: MapLayer[];
    models: Model[];
    importAssetService;
}> = ({ onDone, layers, models, importAssetService }) => {
    const [layersWithThumbnailToGenerate, setLayersWithThumbnailToGenerate] = useState<MapLayer[]>(layers);
    // Dictionary of layerId to thumbnail base64
    const [thumbnailsGenerated, setThumbnailsGenerated] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (layersWithThumbnailToGenerate.length === 0) {
            onDone(thumbnailsGenerated);
        }
    }, [layersWithThumbnailToGenerate.length]);

    return (
        <ThumbnailGeneratorContainer>
            <DataFieldServicesContext.Provider
                value={{
                    dataFieldListItemsService: {
                        getDataFieldListItem: () => Promise.resolve<DataFieldListItem>(null),
                        getDataFieldListItems: () => Promise.resolve<DataFieldListItem[]>(null),
                        postDataFieldListItem: () => Promise.resolve(),
                        putDataFieldListItem: () => Promise.resolve(),
                    },
                }}
            >
                {/** Generates all the thumbnails one by one. It must do it like this or else it will hang */}
                {layersWithThumbnailToGenerate[0] && (
                    <LayerThumbnailGenerator
                        key={layersWithThumbnailToGenerate[0].id}
                        layer={layersWithThumbnailToGenerate[0]}
                        services={{
                            isAssetSdf: async () => new Promise((resolve) => resolve(false)),
                            imageCompressor: async (id, pixelRes) => {
                                const asset = await importAssetService.getAsset(id);
                                const response = await api.get(`${asset.assetUrl}&${asset.authoritySignature}`, {
                                    responseType: "blob",
                                    // If withCredentials is not set to false the icons don't load in Mapbox
                                    withCredentials: false,
                                });
                                return imageBlobResizer(response.data, pixelRes);
                            },
                            modelGetter: async (ids) => new Promise((resolve) => resolve(models.filter((m) => ids.includes(m.id)))),
                            assetGetter: async (ids) => {
                                const assets = await importAssetService.getAssetsById(ids);
                                return assets.map((a) => ({ id: a.id, url: `${a.assetUrl}&${a.authoritySignature}` }));
                            },
                            setLayerThumbnail: async (url) => setThumbnailsGenerated({ ...thumbnailsGenerated, [layersWithThumbnailToGenerate[0].id]: url }),
                            // Unused services
                            bustCacheIds: () => null,
                            getLayerStyle: () => null,
                            getModels: () => null,
                            getProjectDataFields: () => null,
                            patchProjectAttribute: () => null,
                            postProjectAttribute: () => null,
                            refreshAssetEvent: null,
                        }}
                        onDone={() => {
                            setLayersWithThumbnailToGenerate(layersWithThumbnailToGenerate.filter((l) => l.id !== layersWithThumbnailToGenerate[0].id));
                        }}
                    />
                )}
                <LoadingComponent />
                Generating thumbnails, please wait...
            </DataFieldServicesContext.Provider>
        </ThumbnailGeneratorContainer>
    );
};

const ThumbnailGeneratorContainer = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
