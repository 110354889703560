import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";

export function createLiteralStyleValue<T>(staticValue: T, dataFieldId: string): StyleValue<T> {
    return {
        extractionMethod: StyleValueExtractionMethod.Literal,
        dataFieldId,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        mappedValues: {},
    };
}
