import { previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import { AnySupportedGeometry } from "@iventis/map-engine/src/types/internal";
import { createDefaultLocalGeoJsonObject, createPreviewObjectId, createPreviewObjectName } from "./preview-helper-functions";
import { createPointDataDrivenSnapshotGeometry, createPointPreviewGeometry } from "./preview-point-helpers";
import { IconDataDrivenStyleProperty } from "./preview-types";

export function createIconDataDrivenLocalGeoJson(dataDrivenStyleValue: IconDataDrivenStyleProperty, snapshot: boolean, level: number) {
    const maxNumberOfFeaturesAllowed = snapshot ? 3 : 4;
    // Get the possible colour key values
    const iconImageKeyValues =
        dataDrivenStyleValue.mappedValues != null
            ? Object.entries(dataDrivenStyleValue.mappedValues)
                  .map((keyValuePair) => keyValuePair[0])
                  .slice(0, maxNumberOfFeaturesAllowed) // Slice to the max number of features allowed.
            : [];

    // If the total number of data driven colour key values is less than the max number of features allowed, add 1 to allow for the default colour when no data field is selected.
    const numberOfFeatures = iconImageKeyValues.length < maxNumberOfFeaturesAllowed ? iconImageKeyValues.length + 1 : iconImageKeyValues.length;

    // Create an array of data driven objects with the data driven property assigned
    const dataDrivenLocalObjects = iconImageKeyValues.map((imageIconKey, index) => {
        const geometry = snapshot ? createPointDataDrivenSnapshotGeometry(index + 1, numberOfFeatures) : createIconDataDrivenPreviewGeometry(index + 1, numberOfFeatures);
        return createDefaultLocalGeoJsonObject(createPreviewObjectId(index), createPreviewObjectName(index), previewLayerId, level, geometry, {
            [dataDrivenStyleValue.dataFieldId]: imageIconKey,
        });
    });

    // If the number of features is more than colour key values, then we include the default colour feature. This just has no data field properties assigned.
    if (numberOfFeatures > iconImageKeyValues.length) {
        const geometry = snapshot
            ? createPointDataDrivenSnapshotGeometry(numberOfFeatures, numberOfFeatures)
            : createIconDataDrivenPreviewGeometry(numberOfFeatures, numberOfFeatures);
        const localObject = createDefaultLocalGeoJsonObject(createPreviewObjectId(numberOfFeatures), createPreviewObjectName(numberOfFeatures), previewLayerId, level, geometry);
        dataDrivenLocalObjects.push(localObject);
    }

    return {
        [previewLayerId]: dataDrivenLocalObjects,
    };
}

function createIconDataDrivenPreviewGeometry(featureNo: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createPointPreviewGeometry();
        case 2:
            return TwoIconDataDrivenPreviewGeometries[featureNo - 1];
        case 3:
            return ThreeIconDataDrivenPreviewGeometries[featureNo - 1];
        case 4:
            return FourIconDataDrivenPreviewGeometries[featureNo - 1];
        default:
            throw new Error("Total Features in preview must be 1-6");
    }
}

const TwoIconDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [-0.0007, 0],
    },
    {
        type: "Point",
        coordinates: [0.0007, 0],
    },
];

const ThreeIconDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [0, 0.0006],
    },
    {
        type: "Point",
        coordinates: [-0.0006, -0.0006],
    },
    {
        type: "Point",
        coordinates: [0.0006, -0.0006],
    },
];

const FourIconDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [-0.0006, 0.0006],
    },
    {
        type: "Point",
        coordinates: [0.0006, 0.0006],
    },
    {
        type: "Point",
        coordinates: [-0.0006, -0.0006],
    },
    {
        type: "Point",
        coordinates: [0.0006, -0.0006],
    },
];
