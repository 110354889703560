import React, { FunctionComponent, Ref, forwardRef, useImperativeHandle, useState } from "react";
import { styled, fontSizes } from "@iventis/styles";
import { FormControl, FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material";

interface CustomRadioSelectorProps extends RadioGroupProps {
    options: { id: string; name: string }[];
    onValueChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    defaultValueId?: string;
    testId?: string;
    ref?: Ref<CustomRadioSelectorRef>;
}

export type CustomRadioSelectorRef = {
    setValueExternally: (value: string) => void;
};

export const CustomRadioSelector: FunctionComponent<CustomRadioSelectorProps> = forwardRef<CustomRadioSelectorRef, CustomRadioSelectorProps>(
    ({ className, options, onValueChange, row, defaultValueId = options[0].id, testId }, ref) => {
        const [currentValue, setCurrentValue] = useState(defaultValueId);

        const handleValueChange = (event, value) => {
            setCurrentValue(value);
            onValueChange(event, value);
        };

        useImperativeHandle(
            ref,
            () => ({
                setValueExternally: (value: string) => {
                    setCurrentValue(value);
                },
            }),
            []
        );

        return (
            <FormControl className={className} data-testid={testId}>
                <StyledRadioGroup className={className} value={currentValue} onChange={handleValueChange} row={row}>
                    {options.map((option) => (
                        <StyledFormControlLabel
                            className={className}
                            value={option.id}
                            control={<Radio />}
                            label={option.name}
                            key={option.id}
                            data-cy={`radio-btn-${option.name}`}
                        />
                    ))}
                </StyledRadioGroup>
            </FormControl>
        );
    }
);

const StyledFormControlLabel = styled(FormControlLabel)`
    span {
        font-size: ${fontSizes.small};
    }
`;

const StyledRadioGroup = styled(RadioGroup)`
    justify-content: space-between;
`;
