import { convertSitemapsToSitemapNodes, ISitemapContext, SitemapNode, SiteMapNodeTypes, SitemapRootNode } from "@iventis/sitemaps";
import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { NodeServices } from "@iventis/tree-browser";
import { convertStringDatesToDates, readFileAsJson } from "@iventis/utilities";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { api } from "../api/api";

/** The root id has to be made up of both the project id and the instance name because we need both in our requests to the admin dashboard */
export const getProjectIdAndInstanceNameFromRootId = (id: string) => id.split("|");
export const createRootIdFromProjectIdAndInstanceName = (projectId: string, instanceName) => `${projectId}|${instanceName}`;

export const sitemapServices: NodeServices<SitemapRootNode> = {
    getTree: async (id) => {
        const [projectId, instanceName] = getProjectIdAndInstanceNameFromRootId(id);
        const { data } = await api.get<Sitemap[]>(`/instances/${instanceName}/projects/${projectId}/sitemaps`);

        // Convert the string dates we recieve from the backend into Date's
        convertStringDatesToDates(
            data.flatMap((sitemap) => sitemap.versions),
            "date"
        );

        // Convert sitemaps into a tree browser node implementation of sitemaps
        const nodeData = convertSitemapsToSitemapNodes(data, id);

        // Wrap all the sitemaps around a blank root node
        return {
            id,
            sourceId: id,
            name: undefined,
            type: SiteMapNodeTypes.Root,
            childNodes: nodeData,
            childCount: nodeData.length,
        } as SitemapRootNode;
    },
    addNode: async () => {
        throw new Error(`Sitemap tree browser only adds nodes locally. Please update on the server first, then call "addNodesLocally()"`);
    },
    updateNode: async () => {
        throw new Error(`Sitemap tree browser only updates nodes locally. Please update on the server first, then call "updateNodeLocally()"`);
    },
    deleteNodes: async (nodes, tree) => {
        const [projectId, instanceName] = getProjectIdAndInstanceNameFromRootId(tree.id);
        const { Sitemap: sitemaps } = nodes.reduce(
            (acc, node) => (node.type === SiteMapNodeTypes.Sitemap ? { [node.type]: [...(acc[node.type] ?? []), node] } : acc),
            {} as { [SiteMapNodeTypes.Sitemap]: SitemapNode[] }
        );
        const requests: Promise<unknown>[] = [];
        const ids: string[] = [];
        if (sitemaps?.length > 0) {
            const sitemapIds = sitemaps.map(({ id }) => id);
            const sitemapRequests = sitemapIds.map((id) => api.delete(`/instances/${instanceName}/projects/${projectId}/sitemaps/${id}`));
            requests.push(...sitemapRequests);
            ids.push(...sitemapIds);
        }
        await Promise.all(requests);
        return ids;
    },
    getNode: async () => null,
    moveNodes: async () => null,
};

export const createSitemapContext = (instanceName: string, projectId: string): ISitemapContext => ({
    async createSitemap(sitemap): Promise<Sitemap> {
        const res = await api.post<Sitemap>(`/instances/${instanceName}/projects/${projectId}/sitemaps`, { ...sitemap, projectId });
        return res.data;
    },
    async updateSitemap(sitemap): Promise<Sitemap> {
        const res = await api.patch<Sitemap>(`/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemap.id}`, { ...sitemap, projectId });
        return res.data;
    },
    async createSitemapVersion(sitemapVersion): Promise<SitemapVersion> {
        const { data } = await api.post<SitemapVersion>(`/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapVersion.sitemapId}/versions`, {
            ...sitemapVersion,
            date: new Date(Date.now()),
        });
        // Convert the string dates we recieve from the backend into Date's
        convertStringDatesToDates([data], "date");
        return data;
    },
    async updateSitemapVersion(sitemapVersion): Promise<SitemapVersion> {
        const { data } = await api.patch<SitemapVersion>(
            `/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapVersion.sitemapId}/versions/${sitemapVersion.id}`,
            sitemapVersion
        );
        // Convert the string dates we recieve from the backend into Date's
        convertStringDatesToDates([data], "date");
        return data;
    },
    async deleteSitemapVersion(id, sitemapId): Promise<void> {
        await api.delete<SitemapVersion>(`/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapId}/versions/${id}`);
    },
    async createSitemapVersionLevel(level, sitemapId): Promise<SitemapVersionLevelCreateResponse> {
        // Read the uploaded file as json and append to the request
        const style = await readFileAsJson(level.style);
        const res = await api.post<SitemapVersionLevelCreateResponse>(
            `/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapId}/versions/${level.sitemapVersionId}/levels`,
            { ...level, style }
        );
        return res?.data;
    },
    async updateSitemapVersionLevel(level, sitemapId): Promise<string> {
        // If a style has been uploaded, read the uploaded file as json and append to the request
        let style: unknown;
        if (level.style != null) {
            style = await readFileAsJson(level.style);
        }
        const res = await api.patch<string>(`/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapId}/versions/${level.sitemapVersionId}/levels/${level.id}`, {
            ...level,
            style,
        });
        return res?.data;
    },
    async deleteSitemapVersionLevel(id, sitemapVersionId, sitemapId): Promise<void> {
        await api.delete(`/instances/${instanceName}/projects/${projectId}/sitemaps/${sitemapId}/versions/${sitemapVersionId}/levels/${id}`);
    },
});
