import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useWindowSize } from "./use-window-size";

/**
 * Gets the maximum number of elements we can fit inside a given container ref. Can be useful if you can't place your element yet
 * Will re-calculate the max element count if the screen size, container ref, or element width/height changes. Can add extra dependencies
 * Each re-calculation will trigger a re-render of the component that uses this hook. Avoid pairing with a useMemo
 * If you have a simple element that you want to fill a container, see [useContainerFill](./use-container-fill.tsx).
 * @param elementWidth The width of the element we want to fit inside the container
 * @param elementHeight The height of the element we want to fit inside the container
 * @param overrides Allows for us to force a set amount of rows and/or columns to fit inside the container
 * @param extraDependencies Any extra dependencies that should trigger a re-calculation of the max element count
 * @returns [a ref for the container we want our elements to fit in, the total number of elements that can fit]
 * @example
 * // Gets the maximum amount of elements we can fit on one line
 * const [containerRef, maxElementCount] = useMaxElementCount<HTMLDivElement>(215, 260, {rows: 1});
 *
 * // Create an array of our elements
 * const elements = () => {
 *     const elements: React.ReactNode: [] = [];
 *     for (let index = 0; index < maxElementCount; index += 1) {
 *         elements.push(<Element />);
 *     }
 *     return elements;
 * });
 *
 * <div ref={containerRef}>
 *     {elements()}
 * </div>
 */
export const useMaxElementCount = <TContainer extends HTMLElement>(
    elementWidth: number,
    elementHeight: number,
    overrides?: { columns?: number; rows?: number; roundUp?: boolean },
    extraDependencies: unknown[] = []
): [MutableRefObject<TContainer>, number] => {
    const { screenWidth } = useWindowSize();
    const containerRef = useRef<TContainer>();

    const [nodeCount, setNodeCount] = useState<number>(0);

    useEffect(() => {
        if (containerRef.current) {
            const round = overrides?.roundUp ? Math.ceil : Math.floor;
            const x = overrides?.columns ?? round(containerRef.current.scrollWidth / elementWidth);
            const y = overrides?.rows ?? round(containerRef.current.scrollHeight / elementHeight);
            setNodeCount(x * y);
        } else {
            setNodeCount(0);
        }
    }, [containerRef?.current, screenWidth, elementWidth, elementHeight, ...extraDependencies]);

    return [containerRef, nodeCount];
};
