import { DoneInvokeEvent, assign, createMachine } from "xstate";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataTableCallbackRefs } from "@iventis/data-table";
import { MutableRefObject } from "react";
import { DataFieldListItemTableService } from "./data-field-list-item-table-services";
import { parseDataFieldListItemsToRowData } from "./data-field-list-item-table.helpers";

export type AddDatafieldListItemContext = {
    resourceId: string;
    selectedDataField: DataField;
    relatedDataFields: DataField[];
    relatedDataFieldsListItems: { [dataFieldId: string]: DataFieldListItem[] };
    tableApiRef: MutableRefObject<DataTableCallbackRefs>;
    hasChangedRef: MutableRefObject<boolean>;
    listItems: DataFieldListItem[];
    services: DataFieldListItemTableService;
    overrideExistingItems: boolean;
};

type UploadCSVEvent = {
    type: "UPLOAD";
    payload: File;
};

type DeleteDataFieldListItemsEvent = {
    type: "DELETE_ITEMS";
    payload: string;
};

type DeleteDataFieldListItemEvent = {
    type: "DELETE_ITEM";
    payload: string;
};

type DownloadEvent = {
    type: "DOWNLOAD";
};

type CloseEvent = {
    type: "CLOSE";
};

type UpdateDatafieldEvent = {
    type: "UPDATE_DATAFIELD";
    payload: DataField;
};

type UpdateListItems = {
    type: "UPDATE_LIST_ITEMS";
    payload: DataFieldListItem[];
};

type UpdateOptionToOverride = {
    type: "UPDATE_OPTION_TO_OVERRIDE";
    payload: boolean;
};

export type AddDatafieldListItemEvents =
    | CloseEvent
    | UploadCSVEvent
    | UpdateDatafieldEvent
    | DownloadEvent
    | DeleteDataFieldListItemsEvent
    | UpdateListItems
    | UpdateOptionToOverride
    | DeleteDataFieldListItemEvent;

export const AddDatafieldListItemMachine = createMachine<AddDatafieldListItemContext, AddDatafieldListItemEvents>(
    {
        id: "addDatafieldItems",
        initial: "idle",
        context: {
            resourceId: null,
            selectedDataField: null,
            relatedDataFields: null,
            relatedDataFieldsListItems: null,
            listItems: null,
            tableApiRef: null,
            hasChangedRef: null,
            services: null,
            overrideExistingItems: false,
        },
        predictableActionArguments: true,
        on: {
            UPDATE_LIST_ITEMS: {
                target: "idle",
                actions: assign((context, event: UpdateListItems) => ({ ...context, listItems: event.payload })),
            },
        },
        states: {
            idle: {
                on: {
                    UPLOAD: {
                        target: "uploading",
                    },
                    CLOSE: "finished",
                    UPDATE_DATAFIELD: {
                        target: "getSelectedDataFieldTableContext",
                        actions: assign((context, event: UpdateDatafieldEvent) => ({ ...context, selectedDataField: event.payload })),
                    },
                    DOWNLOAD: {
                        actions: ["downloadDataFieldItems"],
                    },
                    DELETE_ITEMS: {
                        target: "deleting",
                    },
                    DELETE_ITEM: {
                        actions: assign({ listItems: (context, event) => context.listItems.filter((l) => l.id !== event.payload) }),
                    },
                    UPDATE_OPTION_TO_OVERRIDE: {
                        actions: assign({ overrideExistingItems: (_, event) => event.payload }),
                    },
                },
            },
            getSelectedDataFieldTableContext: {
                invoke: {
                    src: "getSelectedDataFieldTableContext",
                    onDone: {
                        target: "idle",
                        actions: assign({
                            listItems: (
                                context,
                                event: DoneInvokeEvent<{
                                    listItems: DataFieldListItem[];
                                    relatedDataFields: DataField[];
                                    relatedDataFieldsListItems: { [dataFieldId: string]: DataFieldListItem[] };
                                }>
                            ) => event.data.listItems,
                            relatedDataFields: (context, event) => event.data.relatedDataFields,
                            relatedDataFieldsListItems: (context, event) => event.data.relatedDataFieldsListItems,
                        }),
                    },
                    onError: "idle",
                },
            },
            uploadSuccess: {
                invoke: {
                    src: "getDataFieldListItems",
                    onDone: {
                        target: "idle",
                        actions: assign({
                            listItems: (context, event) => {
                                const { newRows, updatedRows } = event.data.reduce(
                                    (acc, listItem) =>
                                        context.listItems.some((li) => li.id === listItem.id)
                                            ? { ...acc, updatedRows: [...acc.updatedRows, listItem] }
                                            : { ...acc, newRows: [...acc.newRows, listItem] },
                                    { newRows: [], updatedRows: [] } as {
                                        newRows: DataFieldListItem[];
                                        updatedRows: DataFieldListItem[];
                                    }
                                );
                                const deletedRows = context.listItems.filter((listItem) => !event.data.some((l) => l.id === listItem.id)) || [];
                                context.tableApiRef.current.getApi().applyTransaction({
                                    add: parseDataFieldListItemsToRowData(newRows, context.selectedDataField),
                                    update: parseDataFieldListItemsToRowData(updatedRows, context.selectedDataField),
                                    remove: parseDataFieldListItemsToRowData(deletedRows, context.selectedDataField),
                                });
                                context.hasChangedRef.current = true;
                                return event.data;
                            },
                        }),
                    },
                    onError: "idle",
                },
            },
            uploading: {
                invoke: {
                    src: "uploadDatafieldItems",
                    onDone: {
                        target: "uploadSuccess",
                    },
                    onError: "idle",
                },
            },
            deleting: {
                invoke: {
                    src: "deleteDataFieldListItems",
                    onDone: {
                        target: "idle",
                    },
                    onError: "idle",
                },
            },
            finished: { type: "final" },
        },
    },
    {
        actions: {
            downloadDataFieldItems: (context) => {
                context.services.exportListItems(context.selectedDataField.id, context.selectedDataField?.name);
            },
        },
        services: {
            uploadDatafieldItems: async (context, event: UploadCSVEvent) =>
                new Promise((resolve) => {
                    const file = event.payload;
                    const reader = new FileReader();

                    reader.onload = async () => {
                        // Here the content has been read successfuly
                        const content = reader.result;
                        const csvJson = JSON.stringify(content);
                        const data = await context.services.importListItems(context.selectedDataField.id, csvJson, context.overrideExistingItems);
                        resolve(data);
                    };

                    reader.readAsText(file);
                }),

            deleteDataFieldListItems: async (context) => {
                await context.services.deleteListItems(context.selectedDataField.id);
            },
            getDataFieldListItems: async (context) => {
                const updatedListItems = await context.services.getListItems(context.selectedDataField.id);
                return updatedListItems;
            },
            getSelectedDataFieldTableContext: async (context) => {
                const listItems = await context.services.getListItems(context.selectedDataField.id);
                let relatedDataFields: DataField[] = [];
                if (context.selectedDataField?.listItemRelationships?.length > 0) {
                    const resourceDataFields = await context.services.getDataFieldsForResource(context.resourceId);
                    const relationshipDataFieldIds = context.selectedDataField.listItemRelationships.map((rel) => rel.relatedToDataFieldId);
                    relatedDataFields = resourceDataFields.filter((resourceDf) => relationshipDataFieldIds.includes(resourceDf.id));
                }
                let relatedDataFieldsListItems: { [dataFieldId: string]: DataFieldListItem[] } = {};
                for (let i = 0; i < relatedDataFields.length; i += 1) {
                    const items = await context.services.getListItems(relatedDataFields[i].id);
                    relatedDataFieldsListItems = { ...relatedDataFieldsListItems, [relatedDataFields[i].id]: items };
                }
                return { listItems, relatedDataFields, relatedDataFieldsListItems };
            },
        },
    }
);
