import { alpha, InputBase, TextareaAutosize } from "@mui/material";
import { Theme } from "@emotion/react";
import { borderRadius, fontSizes } from "../atomic-rules";
import styled from "../transient-styled";

export const TextFieldSolid = styled(InputBase)`
    .MuiInputBase-input {
        border-radius: ${borderRadius.input};
        position: relative;
        background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
        border: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.three};
        font-size: ${fontSizes.medium};
        width: 100%;
        padding: 0 12px;
        font-family: ${[
            "SourceSansPro",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(",")};
    }
    .MuiInputBase-input:focus {
        box-shadow: ${({ theme }: { theme: Theme }) => alpha(theme.primaryColors.focus, 0.25)} 0 0 0 0.2rem;
        border-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    }
`;

export const TextAreaSolid = styled(TextareaAutosize)`
    font-size: ${fontSizes.small};
    background-color: ${({ theme }) => theme.secondaryColors.blank};
    box-sizing: border-box;
    border-radius: ${borderRadius.input};
    outline: none;
    width: 100%;
    padding: 12px;
    resize: vertical;
    border-width: 1px;
    :focus {
        padding: 11px;
        border-width: 2px;
        border-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    }
`;
