import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";

export const StyledHorizontalRule = styled.hr`
    border: none;
    width: 100%;
    background-color: ${({ theme }: { theme: Theme }) => theme.shades.three};
    height: 0.5px;
    align-self: flex-end;
`;
