import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { getModelLayerStyle } from "@iventis/map-engine/src/utilities/layer.helpers";
import { getStaticAndMappedValues } from "@iventis/map-engine/src/utilities/style-helpers";
import { LayerThumbnailGeneratorServices } from "./layer-thumbnail-generator";
import { PreviewContainerCallbackRefs } from "./preview-container";

interface ModelThumbnailGeneratorProps {
    layer: MapLayer;
    services: LayerThumbnailGeneratorServices;
    onCanvasReady: () => void;
}

export const MODEL_SNAPSHOT_HEIGHT_AND_WIDTH = 150;

export const ModelThumbnailGenerator = forwardRef<PreviewContainerCallbackRefs, ModelThumbnailGeneratorProps>(({ layer, services, onCanvasReady }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>();
    const screenshotBeingTaken = useRef(false);

    useImperativeHandle(
        ref,
        () => ({
            takeSnapshot() {
                return new Promise<string>((resolve) => {
                    const data = canvasRef.current.toDataURL();
                    resolve(data);
                });
            },
        }),
        [canvasRef]
    );

    useEffect(() => {
        const addCanvasImages = async () => {
            screenshotBeingTaken.current = true;
            const canvasContext = canvasRef.current.getContext("2d");
            // Get 3 or less model Ids
            const style = getModelLayerStyle(layer);
            const modelIds = getStaticAndMappedValues(style.model).slice(0, 3);
            // Get model and then thumbnail
            const models = await services.modelGetter(modelIds);
            const thumbnailIds = models.map((m) => m.thumbnailAssetId);
            const modelThumbnails = await services.assetGetter(thumbnailIds);
            const amountOfModels = modelThumbnails.length;
            // Load each of the model thumbnails onto the canvas
            const loadModel = async (assetUrl: string, index: number) =>
                new Promise<void>((res) => {
                    const image = new Image();
                    // Allows for image being added to canvas
                    image.crossOrigin = "*";
                    image.onload = () => {
                        // Get coordinates and dimensions of the image
                        const { x, y, imageWidth, imageHeight } = getImagePlacementForModelSnapshot(amountOfModels, index + 1, MODEL_SNAPSHOT_HEIGHT_AND_WIDTH);
                        // Add image to canvas
                        canvasContext.drawImage(image, x, y, imageWidth, imageHeight);
                        // If all the images have been added then emit canvas is ready
                        res();
                    };
                    image.src = assetUrl;
                });
            const loadModelRequest = modelThumbnails.map((thumbnail, index) => loadModel(thumbnail.url, index));
            // Wait for all of the models thumbnails to be loaded
            await Promise.all(loadModelRequest);
            onCanvasReady();
        };
        if (!screenshotBeingTaken.current) {
            addCanvasImages();
        }
    }, [canvasRef]);

    return <canvas ref={canvasRef} height={MODEL_SNAPSHOT_HEIGHT_AND_WIDTH} width={MODEL_SNAPSHOT_HEIGHT_AND_WIDTH} />;
});

/** Gets the image height, width and location on the canvas */
const getImagePlacementForModelSnapshot = (
    amountOfAssets: number,
    assetNumber: number,
    canvasWidthAndHeight: number
): { x: number; y: number; imageWidth: number; imageHeight: number } => {
    const halfSize = canvasWidthAndHeight / 2;
    const quarterSize = canvasWidthAndHeight / 4;
    switch (true) {
        case amountOfAssets === 1 && assetNumber === 1:
            return { x: 0, y: 0, imageWidth: canvasWidthAndHeight, imageHeight: canvasWidthAndHeight };
        // Left image
        case amountOfAssets === 2 && assetNumber === 1:
            return { x: 0, y: quarterSize, imageWidth: halfSize, imageHeight: halfSize };
        // Right image
        case amountOfAssets === 2 && assetNumber === 2:
            return { x: halfSize, y: quarterSize, imageWidth: halfSize, imageHeight: halfSize };
        // Top center image
        case amountOfAssets === 3 && assetNumber === 1:
            return { x: quarterSize, y: 0, imageWidth: halfSize, imageHeight: halfSize };
        // Bottom left image
        case amountOfAssets === 3 && assetNumber === 2:
            return { x: 0, y: halfSize, imageWidth: halfSize, imageHeight: halfSize };
        // Bottom right image
        case amountOfAssets === 3 && assetNumber === 3:
            return { x: halfSize, y: halfSize, imageWidth: halfSize, imageHeight: halfSize };
        default:
            throw new Error(`Amount of assets ${amountOfAssets} or asset number ${assetNumber} is not supported`);
    }
};
