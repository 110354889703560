import { useEffect, useRef, useState } from "react";

/** Works just like react's useState, except setState is unusable until the initially loading state has been set.
 * Useful for times when you're using useState but the initial state isn't ready on first render.
 * If isLoading turns back to true at some point, it will reset the state after loading is complete with the newly loaded state
 */
export const useDelayedState = <T>(_isLoading: boolean, loadedState: T) => {
    const [state, setState] = useState<T>(loadedState);
    const stateHasBeenSet = useRef(!_isLoading);
    const [isLoading, setIsLoading] = useState(_isLoading);
    useEffect(() => {
        if (_isLoading && stateHasBeenSet.current) {
            stateHasBeenSet.current = false;
            setIsLoading(true);
        }
        if (!_isLoading && !stateHasBeenSet.current) {
            setState(loadedState);
            setIsLoading(false);
            stateHasBeenSet.current = true;
        }
    }, [_isLoading]);

    return [state, isLoading ? () => null : setState, isLoading] as const;
};
