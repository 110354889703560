import { v4 as uuid } from "uuid";
import { MapImportLayer } from "./map-json-types";

export function replaceLayerMapObjectIds(layer: MapImportLayer, updatedDataFieldIds: { [id: string]: string }, updatedListItemIds: { [id: string]: string }) {
    return layer.mapObjects?.map((mapObject) => ({
        ...mapObject,
        dataFieldValues: mapObject.dataFieldValues.map((dfv) => ({
            ...dfv,
            valueRepeatedTimeRanges: dfv?.valueRepeatedTimeRanges?.map((vtr) => ({ ...vtr, id: uuid() })),
            dataFieldId: updatedDataFieldIds[dfv.dataFieldId],
            listItemId: updatedListItemIds[dfv.listItemId],
        })),
        id: uuid(),
        layerId: layer.id,
    }));
}
