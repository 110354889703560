import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { DataFieldValueTypes } from "@iventis/types";
import { isDataFieldValueRepeatedTimeRange, isValidTimeSpan } from "@iventis/utilities";

/** Creates an attribute default value */
export function createDataFieldDefaultValue(attribute: DataField, value: DataFieldValueTypes) {
    const defaultValue: DataField["defaultValue"] = { dataFieldId: attribute.id, valueText: null, valueRepeatedTimeRanges: null };

    if (attribute.type === DataFieldType.Number) {
        if (typeof value === "number") {
            defaultValue.valueNumber = value;
        }
        if (value == null) {
            defaultValue.valueNumber = undefined;
        }
    }
    if (attribute.type === DataFieldType.Text && typeof value === "string") {
        defaultValue.valueText = value;
    }
    if (attribute.type === DataFieldType.Tickbox && typeof value === "boolean") {
        defaultValue.valueTickbox = value;
    }
    if (attribute.type === DataFieldType.Date && typeof value === "string") {
        // Dates are formatted using formatISO inside the Object Datafield component
        defaultValue.valueDate = new Date(value);
    }

    if (attribute.type === DataFieldType.Time && typeof value === "string" && isValidTimeSpan(value)) {
        defaultValue.valueTime = value;
    }

    if (attribute.type === DataFieldType.TimeRange && typeof value === "string") {
        // Split the time range
        const [from, to] = value.split("-");
        if (isValidTimeSpan(from)) {
            defaultValue.valueTimeRangeFrom = from;
        }
        if (isValidTimeSpan(to)) {
            defaultValue.valueTimeRangeTo = to;
        }
    }

    if (attribute.type === DataFieldType.Image && typeof value === "string") {
        defaultValue.valueId = value;
    }

    if (attribute.type === DataFieldType.Hyperlink && typeof value === "string") {
        defaultValue.valueText = value;
    }

    if (attribute.type === DataFieldType.RepeatedTimeRanges && isDataFieldValueRepeatedTimeRange(value)) {
        defaultValue.valueRepeatedTimeRanges = value;
    }

    return defaultValue;
}

/** Gets a string, number, date or boolean value from a {@link DataField} */
export function getAttributeDefaultValue(attribute: DataField) {
    switch (attribute.type) {
        case DataFieldType.Text:
            return attribute.defaultValue.valueText;
        case DataFieldType.Number:
            return attribute.defaultValue.valueNumber;
        case DataFieldType.Date:
            return attribute.defaultValue.valueDate;
        case DataFieldType.Tickbox:
            return attribute.defaultValue.valueTickbox;
        case DataFieldType.Time:
            return attribute.defaultValue.valueTime;
        case DataFieldType.TimeRange:
            if (attribute.defaultValue.valueTimeRangeFrom == null || attribute.defaultValue.valueTimeRangeTo == null) {
                return undefined;
            }
            return { valueTimeRangeFrom: attribute.defaultValue.valueTimeRangeFrom, valueTimeRangeTo: attribute.defaultValue.valueTimeRangeTo };
        // What3Words does not have a default value
        case DataFieldType.What3Words:
            return "";
        case DataFieldType.Image:
            return attribute.defaultValue.valueId;
        case DataFieldType.Hyperlink:
            return attribute.defaultValue.valueText;
        case DataFieldType.RepeatedTimeRanges:
            return attribute.defaultValue.valueRepeatedTimeRanges;
        default:
            throw new Error(`Attribute type ${attribute.type} is not supported`);
    }
}
