export interface UpdateMappedAttributesState {
    allProperties: string[];
    mappedAttributesToProperty: { [attributeId: string]: string };
    saving: boolean;
    overwriteExistingObjects: boolean;
}

interface UpdatedMappedAttribute {
    type: "update-mapped-attribute";
    attributeId: string;
    property: string;
}

interface UpdatedProperties {
    type: "update-properties";
    properties: string[];
}

interface UpdateSaving {
    type: "saving";
    value: boolean;
}

interface UpdateOverwriteExistingObjects {
    type: "overwrite-objects";
    value: boolean;
}

type UpdatedMappedAttributesAction = UpdatedMappedAttribute | UpdatedProperties | UpdateSaving | UpdateOverwriteExistingObjects;

/** Handles an update to the mapped attribute to imported geojson properties and when properties are updated */
export const updateMappedAttributes = (state: UpdateMappedAttributesState, action: UpdatedMappedAttributesAction): UpdateMappedAttributesState => {
    switch (action.type) {
        case "update-mapped-attribute": {
            // If property value is cleared
            if (action.property == null) {
                const updatedMappedAttributes = {};
                // Recreate the updatedMappedAttributes object without the attribute which value is null
                Object.entries(state.mappedAttributesToProperty).forEach(([key, value]) => {
                    if (key !== action.attributeId) {
                        updatedMappedAttributes[key] = value;
                    }
                });
                return { ...state, mappedAttributesToProperty: updatedMappedAttributes };
            }

            return {
                ...state,
                // Add new mapped value
                mappedAttributesToProperty: { ...state.mappedAttributesToProperty, [action.attributeId]: action.property },
            };
        }
        case "update-properties":
            // Reset all mapped attributes and replace properties with new ones
            return {
                ...state,
                allProperties: action.properties,
                mappedAttributesToProperty: {},
            };
        case "saving":
            return {
                ...state,
                saving: action.value,
            };
        case "overwrite-objects":
            return {
                ...state,
                overwriteExistingObjects: action.value,
            };
        default:
            throw new Error("Action type not handled");
    }
};
