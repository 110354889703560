import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { BaseSyntheticEvent } from "react";
import { OperatorsTranslatedTextMapping } from "../types/data-table.types";

// Using old function syntax so we can pass JSX generics
export function OperatorSelectorComponent<T extends string = string>({
    operator,
    setOperator,
    operators,
}: React.PropsWithChildren<{
    operator: T;
    setOperator: (operator: T) => void;
    operators: OperatorsTranslatedTextMapping<T>;
}>) {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Operator</FormLabel>
            <RadioGroup aria-label="Operator" name="operator" value={operator} onChange={(e: BaseSyntheticEvent) => setOperator(e.target.value)}>
                {Object.keys(operators).map((op) => (
                    <FormControlLabel key={op.toString()} value={op} control={<Radio color="primary" size="small" />} label={operators[op]} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
