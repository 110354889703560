import { ProjectUser } from "@iventis/domain-model/model/projectUser";
import { User } from "@iventis/domain-model/model/user";
import { UserGroupWithUsers } from "@iventis/domain-model/model/userGroupWithUsers";
import { UserGroup } from "@iventis/domain-model/model/userGroup";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { TreeBrowserNode } from "@iventis/tree-browser";
import { Permission } from "@iventis/domain-model/model/permission";
import { PostUserCreateRequest } from "./people-view.types";

export enum AdminTreeNodeType {
    Root = "Root",
    Instance = "Instance",
    Project = "Project",
    User = "User",
    Group = "Group",
    SharedSpatialLibrary = "SharedSpatialLibrary",
    Map = "Map",
    Folder = "Folder",
}

export interface TreeBrowserNodeWithPerson<TType extends string = string, TChild extends TreeBrowserNode = never> extends TreeBrowserNode {
    type: TType;
    person: ProjectUser;
    childNodes: TChild[];
}

/** Base people app node */
export interface AdminTreeBrowserNode extends TreeBrowserNode {
    type: AdminTreeNodeType;
}

/** Base people app ROOT node */
export interface AdminRootNode<TChild extends AdminTreeBrowserNode = null> extends AdminTreeBrowserNode {
    type: AdminTreeNodeType.Root;
    childNodes: TChild extends null ? [] : TChild[];
}

/** Base people app PERSON node */
export interface AdminPersonNode<TChild extends AdminTreeBrowserNode = never> extends AdminTreeBrowserNode, TreeBrowserNodeWithPerson<AdminTreeNodeType.User, TChild> {
    person: ProjectUser;
    permission?: Permission;
    team?: UserGroup;
    childNodes: TChild[];
    type: AdminTreeNodeType.User;
}

/** Base people app TEAM node */
export interface AdminTeamsNode<TChild extends AdminTreeBrowserNode = null> extends AdminTreeBrowserNode {
    team: UserGroupWithUsers;
    type: AdminTreeNodeType.Group;
    childNodes: TChild extends null ? [] : TChild[];
}

export type PostNewUserAndGroups = (callback: (serAndGroupsRequest: User[]) => void) => (userAndGroupsRequest: PostUserCreateRequest[]) => Promise<User[]>;

export type ResetPasswordsApi = (userIds: string[]) => Promise<{ userIds: string[] }>;

export type EnableSSoApi = (userIds: string[], projectId: string) => Promise<{ userIds: string[] }>;

export type RemovePeopleApi = (callback: (userIds: string[]) => void) => (userIds: string[], projectId: string) => Promise<{ userIds: string[] }>;

export type GetTeams = () => Promise<UserGroupWithUsers[]>;

export type RemovePersonFromTeamApi = (teamId: string, personId: string) => Promise<void>;

export type PostTeam = (name: string) => Promise<UserGroup>;

export type DeleteTeam = (id: string) => Promise<string>;

export type PutTeamLeader = (teamId: string, userId: string, authorisation: Authorisation) => Promise<unknown>;

export type AddPeopleToTeamApi = (teamId: string, personIds: string[]) => Promise<void> | Promise<unknown>;

export type GetPeople = (projectId: string) => Promise<User[]>;

export type PeopleApiCallbacks = {
    postNewUserAndGroups: ReturnType<PostNewUserAndGroups>;
    getTeams: GetTeams;
    getPeople: GetPeople;
};

export type TeamApiCallbacks = {
    postNewUserAndGroups: ReturnType<PostNewUserAndGroups>;
    resetPasswords: ResetPasswordsApi;
    getTeams: GetTeams;
    removePersonFromTeam: RemovePersonFromTeamApi;
    createTeam: PostTeam;
    deleteTeam: DeleteTeam;
    updateTeamLeader: PutTeamLeader;
    addPeopleToTeam: AddPeopleToTeamApi;
    getPeople: GetPeople;
};
