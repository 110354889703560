import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { LocalGeoJson } from "@iventis/map-engine";
import { previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import { AnySupportedGeometry } from "@iventis/map-engine/src/types/internal";
import { getStaticStyleValue } from "@iventis/map-engine/src/utilities/static-styles";
import { createDefaultLocalGeoJsonObject, createPreviewObjectId, createPreviewObjectName } from "./preview-helper-functions";
import { createIconDataDrivenLocalGeoJson } from "./preview-icon-helpers";

export function createPointPreviewSourceData(layerStyle: LayerStyle, snapshotGeometry: boolean): LocalGeoJson {
    switch (layerStyle.styleType) {
        case StyleType.Point: {
            const pointStyle = layerStyle as PointStyle;
            const isDataDriven = pointStyle.colour?.extractionMethod === StyleValueExtractionMethod.Mapped;
            if (isDataDriven) {
                return createPointDataDrivenLocalGeoJson(pointStyle, snapshotGeometry);
            }
            return createPointLocalGeoJson(snapshotGeometry);
        }
        case StyleType.Icon: {
            const iconStyle = layerStyle as IconStyle;
            const dataDrivenStyleValue = getIconAttributedBasedStyleValue(iconStyle);
            if (dataDrivenStyleValue) {
                return createIconDataDrivenLocalGeoJson(dataDrivenStyleValue, snapshotGeometry, 0);
            }
            return createPointLocalGeoJson(snapshotGeometry);
        }
        default:
            throw new Error("Unsupported point style passed");
    }
}

function createPointDataDrivenLocalGeoJson(pointStyle: PointStyle, snapshot: boolean) {
    const maxNumberOfFeaturesAllowed = snapshot ? 3 : 4;

    // Get the possible colour key values
    const colourKeyValues =
        pointStyle.colour.mappedValues != null
            ? Object.entries(pointStyle.colour.mappedValues)
                  .map((keyValuePair) => keyValuePair[0])
                  .slice(0, maxNumberOfFeaturesAllowed) // Slice to the max number of features allowed.
            : [];

    // If the total number of data driven colour key values is less than the max number of features allowed, add 1 to allow for the default colour when no data field is selected.
    const numberOfFeatures = colourKeyValues.length < maxNumberOfFeaturesAllowed ? colourKeyValues.length + 1 : colourKeyValues.length;

    // Create an array of data driven objects with the data driven property assigned
    const dataDrivenLocalObjects = colourKeyValues.map((colourKey, index) => {
        const geometry = snapshot ? createPointDataDrivenSnapshotGeometry(index + 1, numberOfFeatures) : createPointDataDrivenPreviewGeometry(index + 1, numberOfFeatures);
        return createDefaultLocalGeoJsonObject(createPreviewObjectId(index), createPreviewObjectName(index), previewLayerId, 0, geometry, {
            [pointStyle.colour.dataFieldId]: colourKey,
        });
    });

    // If the number of features is more than colour key values, then we include the default colour feature. This just has no data field properties assigned.
    if (numberOfFeatures > colourKeyValues.length) {
        const geometry = snapshot
            ? createPointDataDrivenSnapshotGeometry(numberOfFeatures, numberOfFeatures)
            : createPointDataDrivenPreviewGeometry(numberOfFeatures, numberOfFeatures);
        const localObject = createDefaultLocalGeoJsonObject(createPreviewObjectId(numberOfFeatures), createPreviewObjectName(numberOfFeatures), previewLayerId, 0, geometry);
        dataDrivenLocalObjects.push(localObject);
    }

    return {
        [previewLayerId]: dataDrivenLocalObjects,
    };
}

/** Returns an attribute based style value or undefined if there are none  */
export function getIconAttributedBasedStyleValue(iconStyle: IconStyle) {
    if (iconStyle.iconImage.extractionMethod === StyleValueExtractionMethod.Mapped) {
        return iconStyle.iconImage;
    }
    if (getStaticStyleValue(iconStyle.customColour) && iconStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped) {
        return iconStyle.colour;
    }
    return undefined;
}

export function createPointLocalGeoJson(snapshot: boolean) {
    const geom = snapshot ? createPointPreviewSnapshotGeometry() : createPointPreviewGeometry();
    const localObject = createDefaultLocalGeoJsonObject("PreviewObject", "Object 1", previewLayerId, 0, geom);
    return {
        [previewLayerId]: [localObject],
    };
}

export function createPointPreviewSnapshotGeometry(): AnySupportedGeometry {
    return {
        type: "Point",
        coordinates: [0, 0],
    };
}

export function createPointPreviewGeometry(): AnySupportedGeometry {
    return {
        type: "Point",
        coordinates: [0, 0],
    };
}

export function createPointDataDrivenSnapshotGeometry(featureNo: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createPointPreviewSnapshotGeometry();
        case 2:
            return TwoPointDataDrivenPreviewSnapshotGeometries[featureNo - 1];
        case 3:
            return ThreePointDataDrivenPreviewSnapshotGeometries[featureNo - 1];
        default:
            throw new Error("Total Features in snapshot must be 1-4");
    }
}

export function createPointDataDrivenPreviewGeometry(featureNo: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createPointPreviewGeometry();
        case 2:
            return TwoPointDataDrivenPreviewGeometries[featureNo - 1];
        case 3:
            return ThreePointDataDrivenPreviewGeometries[featureNo - 1];
        case 4:
            return FourPointDataDrivenPreviewGeometries[featureNo - 1];
        default:
            throw new Error("Total Features in preview must be 1-6");
    }
}

export function createLineModelPreviewSourceData() {
    return createDefaultLocalGeoJsonObject("previewObject", "Object 1", previewLayerId, 0, {
        type: "LineString",
        coordinates: [
            [0.0003, -0.000005],
            [-0.0003, -0.000005],
        ],
    } as AnySupportedGeometry);
}

export const TwoPointDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [-0.0006, 0],
    },
    {
        type: "Point",
        coordinates: [0.0006, 0],
    },
];

export const ThreePointDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [0, 0.0006],
    },
    {
        type: "Point",
        coordinates: [-0.0007, -0.0007],
    },
    {
        type: "Point",
        coordinates: [0.0007, -0.0007],
    },
];

const TWO_POINT_COORDINATE = 0.000001;

const TwoPointDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [-TWO_POINT_COORDINATE, 0],
    },
    {
        type: "Point",
        coordinates: [TWO_POINT_COORDINATE, 0],
    },
];

const THREE_POINT_COORDINATE = 0.0000009;

const ThreePointDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [0, THREE_POINT_COORDINATE],
    },
    {
        type: "Point",
        coordinates: [-THREE_POINT_COORDINATE, -THREE_POINT_COORDINATE],
    },
    {
        type: "Point",
        coordinates: [THREE_POINT_COORDINATE, -THREE_POINT_COORDINATE],
    },
];

const FourPointDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Point",
        coordinates: [-THREE_POINT_COORDINATE, THREE_POINT_COORDINATE],
    },
    {
        type: "Point",
        coordinates: [THREE_POINT_COORDINATE, THREE_POINT_COORDINATE],
    },
    {
        type: "Point",
        coordinates: [-THREE_POINT_COORDINATE, -THREE_POINT_COORDINATE],
    },
    {
        type: "Point",
        coordinates: [THREE_POINT_COORDINATE, -THREE_POINT_COORDINATE],
    },
];
