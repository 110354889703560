import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { css } from "@emotion/react";
import { styled } from "@iventis/styles";
import { Header3 } from "@iventis/styles/src/components/texts";

export const ErrorLarge: React.FC<{ className?: string; text: ReactNode | string; subdued?: boolean }> = ({ className, text, subdued }) => (
    <>
        <StyledContainer className={className} subdued={subdued}>
            <FontAwesomeIcon icon={["fal", "triangle-exclamation"]} size="8x" />
            {typeof text === "string" ? <Header3>{text}</Header3> : text}
        </StyledContainer>
    </>
);

const StyledContainer = styled.section<{ subdued: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${({ subdued, theme }) =>
        subdued &&
        css`
            color: ${theme.typographyColors.moreSubdued};
        `}

    h3 {
        margin-top: 20px;
    }
`;
