import React from "react";
import { Header5 } from "@iventis/styles/src/components/texts";
import { InteractiveElement, formPadding, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import Highlighter from "react-highlight-words";
import { inlineTextIconMargin, fontSizes, formGap, iconWidth, verticalPopoverGap } from "@iventis/styles/src/atomic-rules";
import { ResultGroup, ResultType } from "../types/types";

interface SearchResultsProps<TResult extends ResultType> {
    resultGroups: ResultGroup<TResult>[];
    noResultsMessage: string;
    searching: boolean;
    searchString: string;
}

export const SearchResults = <TResult extends ResultType>({ resultGroups, noResultsMessage, searching, searchString }: SearchResultsProps<TResult>) => (
    <StyledResults>
        {resultGroups.length === 0 && !searching ? (
            <span className="no-results">{noResultsMessage}</span>
        ) : (
            resultGroups.map((group) => (
                <div className="result-group" key={group.type}>
                    <div className="header">
                        {group.icon}
                        <Header5>{group.name}</Header5>
                    </div>
                    <div className="result">
                        {group.results.map(({ result, displayText }) => (
                            <ResultComponent
                                key={result.id}
                                result={result}
                                groupType={group.type}
                                displayText={displayText}
                                searchString={searchString}
                                onResultClicked={group.onResultClick}
                            />
                        ))}
                    </div>
                </div>
            ))
        )}
    </StyledResults>
);

interface ResultProps<TResult extends ResultType> {
    result: TResult;
    groupType: string;
    displayText: string;
    searchString: string;
    onResultClicked: (result: TResult) => void;
}

export const ResultComponent = <TResult extends ResultType>({ result, displayText, groupType, searchString, onResultClicked }: ResultProps<TResult>) => {
    const { data: thumbnailUrl } = useQuery(["search-result-preview", result.id], { queryFn: async () => (await result.thumbnailUrl) ?? "", enabled: result.thumbnailUrl != null });
    return (
        <InteractiveElement onClick={() => onResultClicked(result)} key={result.id} data-testid={`${groupType}-${displayText.toLowerCase()}`}>
            {result.thumbnailUrl && <img src={thumbnailUrl} alt="layer-thumbnail" />}
            <Highlighter
                highlightStyle={{ fontWeight: "800", backgroundColor: "transparent" }}
                highlightClassName="highlight"
                searchWords={searchString.split(" ")}
                textToHighlight={displayText}
            />
        </InteractiveElement>
    );
};

interface SimpleSearchResultsProps<TResult extends ResultType> {
    results: TResult[];
    onClick: (id: TResult) => void;
    noResultsMessage: string;
    searching: boolean;
}

export const SimpleSearchResults = <TResult extends ResultType>({ results, noResultsMessage, searching, onClick }: SimpleSearchResultsProps<TResult>) => (
    <StyledResults>
        {results.length === 0 && !searching ? (
            <span className="no-results">{noResultsMessage}</span>
        ) : (
            results.map((result) => (
                <InteractiveElement onClick={() => onClick(result)} key={result.id}>
                    {result.name}
                </InteractiveElement>
            ))
        )}
    </StyledResults>
);

const StyledResults = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${formGap};

    .result-group {
        display: flex;
        flex-direction: column;
        gap: ${verticalPopoverGap};
    }

    .header {
        display: flex;
        gap: ${inlineTextIconMargin};
        align-items: center;
        font-size: ${fontSizes.small};
    }

    .result {
        display: flex;
        flex-direction: column;
        font-size: ${fontSizes.small};
        justify-content: center;
        align-items: flex-start;

        /* When there is no icon this is needed to make sure the text is far left */
        span {
            text-align: start;
        }

        button {
            display: flex;
            align-items: center;
            padding: ${verticalPopoverGap} ${formPadding};
            width: 100%;
            gap: ${inlineTextIconMargin};
            :hover {
                background-color: ${({ theme }: { theme: Theme }) => theme.otherColors.hover};
            }
        }
    }

    .no-results {
        font-size: ${fontSizes.small};
        font-style: italic;
    }

    img {
        width: ${iconWidth.small};
    }
`;
