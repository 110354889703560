import { AutocompleteWithLoading } from "@iventis/components";
import React, { FunctionComponent } from "react";
import { useQuery } from "@tanstack/react-query";
import { getInstances } from "./project-api-functions";

interface InstanceSelectorProps {
    instance: string;
    setInstance: (instance: string) => void;
}

/** Dropdown which allows you to pick an instance */
export const InstanceSelectorComponent: FunctionComponent<InstanceSelectorProps> = ({ instance, setInstance }) => {
    // Instance and project selection
    const { isLoading: isInstancesLoading, data: instances } = useQuery(["instances"], async () => {
        const instances = await getInstances();
        setInstance(instances[0].name);
        return instances;
    });

    return (
        <AutocompleteWithLoading
            disableClearable
            options={instances == null ? [] : instances.map((instance) => instance.name)}
            value={instance}
            onChange={(value) => setInstance(value)}
            loadingOptions={isInstancesLoading}
            getOptionLabel={(option) => option}
        />
    );
};
