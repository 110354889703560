import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { ListItemCellEditorComponent, ListItemCellEditorProps, IventisCellEditor } from "@iventis/data-table";
import { DataFieldListItemRowData } from "./data-field-list-item-table.helpers";

export const RelationshipListItemCellEditor = forwardRef<IventisCellEditor, ListItemCellEditorProps<DataFieldListItemRowData>>((props, ref) => {
    const listItemCellEditorRef = useRef<IventisCellEditor>(null);

    useImperativeHandle(ref, () => ({
        getValue() {
            const value = listItemCellEditorRef.current?.getValue();
            return value?.id;
        },
        isCancelBeforeStart() {
            return listItemCellEditorRef.current?.isCancelBeforeStart?.() ?? false;
        },
        isCancelAfterEnd() {
            return listItemCellEditorRef.current?.isCancelAfterEnd?.() ?? false;
        },
    }));

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ListItemCellEditorComponent ref={listItemCellEditorRef} {...props} />;
});
