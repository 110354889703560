import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, TouchSensor, MouseSensor, pointerWithin, useSensor } from "@dnd-kit/core";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { DragAndDropContext } from "./drag-and-drop-context";
import { DragAndDropContextType, DragAndDropProps } from "./drag-and-drop-types";
import { anchorToMouseModifier, removeIdSuffixes } from "./drag-and-drop-helpers";

export const DragAndDrop = <TItem extends { id: string }>({
    calculateNewParent,
    getItems,
    onDragEnd,
    onDragStart,
    onDragOver,
    createOverlayComponent,
    findBottomItem,
    calculateDropZoneHeight,
    idSuffixes = [],
    children,
    collisionDetection = pointerWithin,
    ghostModifiers = [anchorToMouseModifier],
    modifiers = [],
}: PropsWithChildren<DragAndDropProps<TItem>>) => {
    const [activeItems, setActiveItems] = useState<TItem[]>();
    const [overElement, setOverElement] = useState<HTMLElement>(undefined);
    const [anchoredToTop, setAnchoredToTop] = useState<boolean>(true);

    const setDropZoneOverElement = (newElement: HTMLElement) => {
        setOverElement(() => newElement);
        setContext((newContext) => ({ ...newContext, overElement: newElement }));
    };

    const [context, setContext] = useState<DragAndDropContextType>({
        newParentId: undefined,
        bottomItemId: undefined,
        idSuffixes,
        setOverElement: setDropZoneOverElement,
        setAnchoredToTop,
        dropZoneHeight: undefined,
        overElement: undefined,
    });

    // Dragging an element activates when user has mouseDown/touchDown over 10px
    const pointerSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            distance: 10,
        },
    });

    const handleDragStart = (event: DragStartEvent) => {
        const items = getItems(removeIdSuffixes(event.active.id as string, idSuffixes));
        setActiveItems(items);
        onDragStart(items);
    };

    const handleDragOver = (event: DragOverEvent) => {
        if (event.over != null && typeof event.over.id === "string") {
            onDragOver?.(activeItems, event.over);
            const id = removeIdSuffixes(event.over?.id, idSuffixes);
            const newFolder = calculateNewParent(id);
            const bottomItemId = findBottomItem(newFolder);
            let height: number;
            // If we are over the breadcrumb, we dont want to set a height
            if (!idSuffixes.some((suffix) => (event.over.id as string).endsWith(suffix))) {
                height = calculateDropZoneHeight(newFolder);
            }
            setContext({ ...context, newParentId: newFolder.id, bottomItemId, dropZoneHeight: height });
        } else {
            setDropZoneOverElement(undefined);
            setContext({ ...context, newParentId: undefined, bottomItemId: undefined });
        }
    };

    const handleDragEnd = (event: DragEndEvent) => {
        onDragEnd(activeItems, event.over == null ? null : { ...event.over, id: removeIdSuffixes(event.over.id as string, idSuffixes) });
        setDropZoneOverElement(undefined);
        setContext({ ...context, newParentId: undefined, bottomItemId: undefined });
    };

    // Only generate drag overlay if the active items change
    const dragGhost = createOverlayComponent && <DragOverlay modifiers={ghostModifiers}>{activeItems != null && createOverlayComponent(activeItems)}</DragOverlay>;

    // Only generate the drop zone if the drop zone changes
    const dropZonePortal = useMemo(() => (overElement ? createPortal(<DropZone height={context.dropZoneHeight} anchoredToTop={anchoredToTop} />, overElement) : <></>), [
        overElement,
    ]);

    return (
        <DragAndDropContext.Provider value={context}>
            <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                sensors={[pointerSensor, touchSensor]}
                collisionDetection={collisionDetection}
                modifiers={modifiers}
            >
                <>
                    {children}
                    {dragGhost}
                    {overElement && dropZonePortal}
                </>
            </DndContext>
        </DragAndDropContext.Provider>
    );
};

const DropZone = styled.div<{ height: number; anchoredToTop: boolean }>`
    ${({ anchoredToTop }) => (anchoredToTop ? "top: 0;" : "bottom: 0;")}
    left: 0;
    right: 0;
    /* If we have a height set use it, otherwise bottom: 0 will wrap the element just fine */
    ${({ height }) => (height != null ? `height: ${height}px;` : "bottom: 0;")}
    border: 2px dashed ${({ theme }: { theme: Theme }) => theme.tertiaryColors.primary};
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 200;
`;
