import React, { useMemo } from "react";
import { DatePickerSolid } from "@iventis/styles/src/components/date-picker-solid";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormLabel } from "@iventis/styles";
import { FormItemContainer } from "./project-form";

type ProjectFormCommittedUntilProps = {
    date: Date;
    onDateChange: (date: Date) => void;
};

export const ProjectFormCommittedUntilInput = ({ date, onDateChange }: ProjectFormCommittedUntilProps) => {
    /** Stops the user picking a past date or current day */
    const minDate = useMemo(() => {
        const today = new Date();
        return today.setDate(today.getDate() + 1);
    }, []);
    return (
        <FormItemContainer>
            <FormLabel className="input-label space-within-content">Contract expiry</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerSolid
                    className="Mui-Datepicker"
                    inputFormat="dd/MM/yyyy"
                    value={date ?? null}
                    onChange={onDateChange}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(params) => <TextField {...params} />}
                    minDate={minDate}
                />
            </LocalizationProvider>
        </FormItemContainer>
    );
};
