import React, { ReactNode } from "react";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { inputHeight } from "@iventis/styles/src/atomic-rules";
import { undefinedValueCheck } from "@iventis/utilities";
import { dataTestIds } from "@iventis/testing";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";

interface DefinedValueSelectorProps<T extends unknown = string> extends ValueSelector<T> {
    optionalValues: {
        label?: string;
        value: T;
        icon?: ReactNode;
    }[];
}

/**
 * Component where the user can select one of the defined values passed into the component
 * @param { string [] } optionalValues - the values that the user can select
 * @param { string } selectedValue - the value which is selected
 * @param { (string) => VoidFunction } changeStyle - a function to handle when a value is selected
 */
export function DefinedValueSelectorComponent<ValueType extends unknown = string>({
    optionalValues,
    value: selectedValue,
    changeValue: changeStyle,
    disabled = false,
}: React.PropsWithChildren<DefinedValueSelectorProps<ValueType>>) {
    undefinedValueCheck(selectedValue, "Value selector can not accept undefined value.");

    // If the selected value is not one of the options then throw an error
    if (!optionalValues.find(({ value }) => value === selectedValue)) {
        throw new Error(`${selectedValue} does not exist inside the optional values`);
    }

    const handleButtonClick = (value: ValueType) => {
        changeStyle(value);
    };

    return (
        <StyledButtonContainer aria-label="value selector">
            {optionalValues.map(({ value, label, icon }, index) => (
                <StyledButton
                    type="button"
                    className={index === 0 ? "first-button" : index === optionalValues.length - 1 ? "last-button" : ""}
                    key={value?.toString()}
                    valueAmount={optionalValues.length}
                    selected={value === selectedValue}
                    onClick={() => handleButtonClick(value)}
                    disabled={disabled}
                    data-testid={dataTestIds.editLayer.definedValue(label)}
                >
                    {/* Todo: Replace text with icons, once icons have been created by NoMensa */}
                    {/* Checks if we are looking at the join button or end button since they share the Round trait. Tries to load the graphic, if not loads the text */}
                    {(icon != null && icon) || label || (value as string)}
                </StyledButton>
            ))}
        </StyledButtonContainer>
    );
}

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${inputHeight};
    .first-button {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .last-button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

const StyledButton = styled.button<{ valueAmount: number; theme?: Theme; selected: boolean }>`
    background-color: ${({ theme, selected }: { theme: Theme; selected: boolean }) => (selected ? theme.tertiaryColors.primaryBackground : theme.secondaryColors.blank)};
    width: ${({ valueAmount }: { valueAmount: number }) => `calc(${100 / valueAmount}% - 5px)`};
    margin-right: 4px;
    .last-button {
        margin-right: 0px;
    }
    height: ${inputHeight};
    border: ${({ theme, selected }: { theme: Theme; selected: boolean }) =>
        `${selected ? `2px` : `1px`} solid ${selected ? theme.tertiaryColors.primary : theme.tertiaryColors.headerBackground}`};
    font-weight: ${({ selected }: { selected: boolean }) => `${selected ? `500` : `normal`}`};
    cursor: pointer;
    border-radius: 0px;
`;

type DefinedValueSelectorComponentCreator<ValueType extends unknown = string> = ValueSelectorComponentCreator<DefinedValueSelectorProps<ValueType>>;

export const definedValueSelectorComponentCreator = <ValueType extends unknown = string>(
    // We target [0] here because we know it will only ever be one parameter
    additionalProps: Parameters<DefinedValueSelectorComponentCreator<ValueType>>[0]
): // eslint-disable-next-line react/jsx-props-no-spreading
ReturnType<DefinedValueSelectorComponentCreator<ValueType>> => (genericProps) => <DefinedValueSelectorComponent<ValueType> {...genericProps} {...additionalProps} />;
