/* eslint-disable no-console */
import React from "react";
import { DateRangePicker } from "@iventis/components";
import { TextField } from "@mui/material";
import { InteractiveElement, styled, StyledFieldLabel } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { AriaDateRangePickerProps, DateValue } from "react-aria";
import { AttributeTimeRangeEditor } from "../../datafield-editor/src/attribute-editor-components";
import { DateRangeAttributeValue } from "./repeated-time-range-types";

type DateRangeAttributeProps = {
    value: DateRangeAttributeValue;
    handleDelete: () => void;
    handleChange: (value: DateRangeAttributeValue) => void;
};

export function DateRangeAttribute({ value, handleDelete, handleChange }: DateRangeAttributeProps) {
    const translate = useIventisTranslate();

    const handleNameChange = (name: DateRangeAttributeValue["name"]): void => {
        handleChange({ ...value, name });
    };

    const handleDateChange = (date: DateRangeAttributeValue["dateRange"]): void => {
        handleChange({ ...value, dateRange: date });
    };

    const handleTimeChange = (time: DateRangeAttributeValue["timeRange"]): void => {
        handleChange({ ...value, timeRange: time });
    };

    return (
        <StyledDateRangeAttribute>
            <div>
                <StyledFieldLabel>{translate(Content.map8.dates.dateRangeAttribute.name)}</StyledFieldLabel>
                <StyledTextField
                    value={value.name}
                    onChange={(event) => handleNameChange(event.target.value)}
                    className="range-name"
                    data-testid={`repeated-time-range-name-input-${value.name}`}
                />
            </div>
            <DateRangePicker
                testId={value.name}
                onChange={handleDateChange}
                value={value.dateRange as AriaDateRangePickerProps<DateValue>["value"]}
                label={translate(Content.map8.dates.dateRangeAttribute.dates)}
            />
            <AttributeTimeRangeEditor
                testIdPrefix={value.name}
                className="time-inputs"
                label={translate(Content.map8.dates.dateRangeAttribute.time)}
                value={value.timeRange}
                setValue={handleTimeChange}
                unifiedInput
            />
            <StyledInteractiveElement onClick={handleDelete}>
                <FontAwesomeIcon icon={["far", "trash"]} size="xl" />
            </StyledInteractiveElement>
        </StyledDateRangeAttribute>
    );
}

const StyledDateRangeAttribute = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .time-inputs {
        gap: 0px;
    }
`;

const StyledInteractiveElement = styled(InteractiveElement)`
    align-self: flex-end;
    height: 44px;
    svg {
        color: ${(props) => props.theme.shades.darkBorder};
    }
`;

const StyledTextField = styled(TextField)`
    &.range-name input {
        height: 44px;
        box-sizing: border-box;
    }
`;
