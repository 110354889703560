import React, { FunctionComponent, useEffect } from "react";
import { ApplicationNavigationComponent, NavigationBarComponent } from "@iventis/components";
import { useNavigate, Outlet, useLocation } from "react-router";
import { styled } from "@iventis/styles";
import { IVENTIS_LOGO, useObservableValue } from "@iventis/utilities";
import { Application, applications } from "./navigation.types";
import { UnauthenticatedOverlay } from "./unauthenticated-overlay";
import { isAuthenticated$ } from "../app";
import "../api/axios-interceptor";

const NavigationComponent: FunctionComponent = () => {
    const assumeAuthenticated = useObservableValue(isAuthenticated$, true);

    const navigate = useNavigate();
    const location = useLocation();

    const selectedApp = location.pathname.split("/")[1];

    const handleApplicationChange = (application: Application) => {
        const selectedApplicaiton = applications.find(({ appName }) => appName === application);
        navigate(selectedApplicaiton.url);
    };
    useEffect(() => {
        if (selectedApp === "") {
            navigate(applications[0].url);
        }
    }, [selectedApp]);

    return (
        <Container>
            {!assumeAuthenticated && <UnauthenticatedOverlay />}
            <NavigationBarComponent logoUrl={IVENTIS_LOGO} onLogoClicked={() => handleApplicationChange(Application.CUSTOMERS)} />
            <div className="content">
                <ApplicationNavigationComponent
                    applications={applications}
                    selectedApp={selectedApp}
                    handleApplicationSelected={(app) => handleApplicationChange(app as Application)}
                />
                <Outlet />
            </div>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        min-height: 0;
    }
`;

export default NavigationComponent;
