import { useWindowSize } from "@iventis/utilities/src/hooks/use-window-size";
import { DataField } from "@iventis/domain-model/model/dataField";
import { AdditionalDataFieldProperties } from "@iventis/types";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import React from "react";
import { CustomDialogAnchor, StyledCustomDialog } from "@iventis/components";
import AddAttributeForm from "./add-attribute-form";
import { AdditionalAttributeDataFormComponent, AttributeEditor } from "./attribute-editor";

export interface DataFieldModalProps {
    existingDataField?: AdditionalDataFieldProperties;
    onDataFieldAdded?: (dataField: AdditionalDataFieldProperties, predefined?: boolean) => void;
    createableProjectDataFields: DataField[];
    close: () => void;
    AdditionalAttributeDataForm?: AdditionalAttributeDataFormComponent;
    showListItems?: boolean;
    allowDeletion?: boolean;
    isProjectAttributesOnly?: boolean;
    navigateToProjectSettings?: () => void;
    allowEditing?: boolean;
}

export const DataFieldModal: React.FC<
    {
        open: boolean;
        onClose: () => void;
        filterDataFieldTypes?: DataFieldType[];
    } & DataFieldModalProps
> = ({
    onClose,
    open,
    existingDataField,
    onDataFieldAdded,
    close,
    createableProjectDataFields,
    allowDeletion,
    allowEditing = true,
    isProjectAttributesOnly,
    navigateToProjectSettings,
    AdditionalAttributeDataForm,
    filterDataFieldTypes,
    showListItems = true,
}) => {
    const { screenWidth } = useWindowSize();
    const isNewDataField = Object.keys(existingDataField || {}).length === 0;

    return (
        <StyledCustomDialog $fullHeight anchor={CustomDialogAnchor.TOP} open={open} $minWidth="800px" onClose={onClose} $screenWidth={screenWidth} $alternativePadding>
            {!isNewDataField || isProjectAttributesOnly ? (
                <AttributeEditor
                    existingAttribute={existingDataField}
                    allowDeletion={allowDeletion}
                    onClose={onClose}
                    allowTypeEditing={isNewDataField}
                    allowEditing={allowEditing}
                    isProjectAttributesOnly={isProjectAttributesOnly}
                    AdditionalAttributeDataForm={AdditionalAttributeDataForm}
                    showListItems={showListItems}
                />
            ) : (
                <AddAttributeForm
                    savedDataField={existingDataField}
                    projectAttributes={createableProjectDataFields}
                    onClose={close}
                    onSave={onDataFieldAdded}
                    navigateToProjectSettings={navigateToProjectSettings}
                    AdditionalAttributeDataForm={AdditionalAttributeDataForm}
                    filterDataFieldTypes={filterDataFieldTypes}
                />
            )}
        </StyledCustomDialog>
    );
};
