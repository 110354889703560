import { Body3, Header6, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import React, { useCallback, Fragment } from "react";

interface MapperTableProps<T> {
    items: T[];
    /** Component to render in the selector column */
    selector: (item: T) => React.ReactElement;
    itemColumnHeader: string;
    typeColumnHeader: string;
    selectorColumnHeader: string;
    getItemId: (item: T) => string;
    getItemName: (item: T) => string;
    getTypeName: (item: T) => string;
    /** Omits the bottom border in a modal */
    scrollable?: boolean;
}

/**
 * Provides a table with a Name and type column, and a column to add an input e.g. a selector or autocomplete
 * Useful for mapping unknown values from imports to assets/attributes within the app
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MapperTable<T extends any>({
    scrollable,
    items,
    selector,
    itemColumnHeader,
    typeColumnHeader,
    selectorColumnHeader,
    getItemId,
    getItemName,
    getTypeName,
}: MapperTableProps<T>) {
    /** For modals with overflow we don't want to show border at the bottom for the last attribute */
    const hideBorderBottom = useCallback(
        (index: number) => {
            if (!scrollable) {
                return false;
            }
            return index === items.length - 1;
        },
        [scrollable, items.length]
    );

    return (
        <MapperContainer>
            <MapperHeader>{itemColumnHeader}</MapperHeader>
            <MapperHeader>{typeColumnHeader}</MapperHeader>
            <MapperHeader>{selectorColumnHeader}</MapperHeader>
            {items.map((item, index) => (
                <Fragment key={getItemId(item)}>
                    <MapperProperty hideBorderBottom={hideBorderBottom(index)}>
                        <Body3>{getItemName(item)}</Body3>
                    </MapperProperty>
                    <MapperProperty hideBorderBottom={hideBorderBottom(index)}>
                        <Body3>{getTypeName(item)}</Body3>
                    </MapperProperty>
                    <MapperProperty hideBorderBottom={hideBorderBottom(index)}>{selector(item)}</MapperProperty>
                </Fragment>
            ))}
        </MapperContainer>
    );
}

const MapperContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
`;

const MapperHeader = styled(Header6)`
    padding: 10px 20px;
    border-top: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
`;

const MapperProperty = styled.div<{ hideBorderBottom: boolean }>`
    width: 100%;
    height: 65px;
    border-bottom: ${(props) => (props.hideBorderBottom ? "0px" : "1px")} solid ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    display: flex;
    justify-items: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;
`;
