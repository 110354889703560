import { IRowNode } from "@ag-grid-community/core";
import { RowNetworkStatus } from "../types/data-table.types";

export const setRowNetworkStatus = (node: IRowNode, status: RowNetworkStatus) => {
    const newData = { ...node.data, networkStatus: status };
    node.setData(newData);
};

export const isValueValid = (node: IRowNode, value: unknown, required: boolean) => {
    if (!required) {
        return true;
    }

    if (node.data.networkStatus === RowNetworkStatus.ExistsOnServer || node.data.networkStatus === undefined) {
        return true;
    }

    if (value == null) {
        return false;
    }

    // REMOVE NASTINESS WHEN startAt/ endAt refactored
    // eslint-disable-next-line no-prototype-builtins
    if (typeof value === "object" && value.hasOwnProperty("value") && (value as { value: unknown }).value === null) {
        return false;
    }

    // Add in the check for node network status!
    return value !== undefined && value !== null && value !== "";
};
