/* eslint-disable class-methods-use-this */
import { BehaviorSubject } from "rxjs";
import { filterTree } from "../tree-operations";
import { NodeServices, TreeBrowserNode, TreeBrowserState, UnionOfNodes } from "../types/data-types";
import { TreeBrowserSelection } from "./tree-browser-selection";
import { TreeBrowserStateInterface } from "./tree-browser-state";

export class TreeBrowserReadOnly<TNode extends TreeBrowserNode> extends TreeBrowserStateInterface<TNode> {
    public state: BehaviorSubject<TreeBrowserState<TNode>>;

    protected nodeServices: NodeServices<TNode>;

    protected readonly treeBrowserSelection: TreeBrowserSelection<TNode>;

    constructor(initialState: TreeBrowserState<TNode>, nodeServices: NodeServices<TNode>, treeBrowserSelection?: Partial<TreeBrowserSelection<TNode>>) {
        super({ ...initialState, isLoadingTree: true }, nodeServices, treeBrowserSelection);
    }

    public get currentState() {
        return this.state.value;
    }

    public setState(state: TreeBrowserState<TNode>) {
        this.state.next(state);
    }

    toggleExpandNode(node: UnionOfNodes<TNode>): void {
        const collapse = this.currentState.expandedNodeIds.includes(node.id);
        this.setState({
            ...this.currentState,
            expandedNodeIds: collapse ? this.currentState.expandedNodeIds.filter((id) => id !== node.id) : [...this.currentState.expandedNodeIds, node.id],
        });
    }

    expandNode(node: UnionOfNodes<TNode>): void {
        this.setState({ ...this.currentState, expandedNodeIds: [...this.currentState.expandedNodeIds, node.id] });
    }

    toggleSelectNodes(nodeIds: string[], from?: string): void {
        const newlySelected = this.treeBrowserSelection.fromSelectNodes({
            nodes: nodeIds,
            selectedNodeIds: this.currentState.selectedNodeIds,
            mainNodeId: this.currentState.mainNodeId,
            tree: this.currentState.tree,
            from,
            ignoreExistingSelection: false,
            ctrlHeld: false,
        });
        this.setState({ ...this.currentState, selectedNodeIds: newlySelected });
    }

    toggleSelectAll(): void {
        const selectedNodeIds = this.treeBrowserSelection.fromSelectAll({ tree: this.currentState.tree, selectedNodeIds: this.currentState.selectedNodeIds });
        this.setState({ ...this.currentState, selectedNodeIds });
    }

    search(searchString: string): void {
        const filteredTree = searchString?.length ? filterTree(this.currentState.tree, (n) => n.name.toLowerCase().includes(searchString.toLowerCase())) : this.currentState.tree;
        this.setState({ ...this.currentState, filteredTree });
    }

    initialise(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    getNode(): TNode {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    addNode(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    addNodesLocally(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    deleteNodes(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    removeNodesLocally(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    collapseNode(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    setMainNode(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    setTree(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    getThumbnail(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    setSort(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    updateNode(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    updateNodeLocally(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    updateNodesLocally(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    moveNodes(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    toggleFavourite(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    setWithExistingTree(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }

    destroy(): void {
        throw new Error("Method not allowed, this is a read only tree browser");
    }
}
