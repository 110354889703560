import React from "react";
import { styled } from "@iventis/styles";
import { Chip, useTheme } from "@mui/material";
import { Theme, css } from "@emotion/react";
import { DisabledOverlay } from "./disabled-overlay";

export enum ChipType {
    PrimaryButton,
    HighlightedExisting,
    BasicExisting,
}

export const StyledChip: React.FC<{
    chipType: ChipType;
    onClick: () => void;
    label: React.ReactNode;
    className?: string;
    chipContainerClassName?: string;
    disabled?: boolean;
    dataTestId?: string;
}> = ({ chipType, onClick, label, className, disabled = false, chipContainerClassName, dataTestId }) => {
    const theme = useTheme<Theme>();

    const chipSX = {
        "&:hover": {
            backgroundColor: theme.otherColors.highlightChipHover,
        },
    };

    const chip = (() => {
        if (chipType === ChipType.PrimaryButton) {
            return <StyledPrimaryChip data-testid={dataTestId} label={label} variant="outlined" onClick={onClick} className={className} />;
        }

        if (chipType === ChipType.HighlightedExisting) {
            return <StyledHighlightedChip data-testid={dataTestId} sx={chipSX} label={label} onClick={onClick} className={className} />;
        }

        if (chipType === ChipType.BasicExisting) {
            return <StyledNormalChip data-testid={dataTestId} label={label} onClick={onClick} className={className} />;
        }
        throw new Error("Chip type not recognised");
    })();

    return (
        <div className={chipContainerClassName} style={{ position: "relative" }}>
            {chip}
            {disabled && <DisabledOverlay />}
        </div>
    );
};

const StyledPrimaryChip = styled(Chip)`
    ${({ theme }: { theme: Theme }) => css`
        border-color: ${theme.tertiaryColors.primary};
        color: ${theme.tertiaryColors.primary};
    `};
    font-weight: 500;
`;

const StyledHighlightedChip = styled(Chip)`
    ${({ theme }: { theme: Theme }) => css`
        background-color: ${theme.tertiaryColors.highlightBackground};
        color: ${theme.primaryColors.subdued50};
    `};
    font-weight: bold;
`;

const StyledNormalChip = styled(Chip)`
    ${({ theme }: { theme: Theme }) => css`
        background-color: ${theme.secondaryColors.lightGrey};
        color: ${theme.typographyColors.core};
    `};
    font-weight: bold;
`;

export const StyledPinkSelectableChip = styled(Chip)<{ selected: boolean }>`
    ${({ theme, selected }: { theme: Theme; selected: boolean }) => css`
        background-color: ${selected ? theme.primaryColors.focus : theme.secondaryColors.blank};
        color: ${selected ? theme.typographyColors.blank : theme.typographyColors.lessSubdued};
        border: 1px solid ${selected ? theme.primaryColors.focus : theme.typographyColors.subdued};
        :hover {
            background-color: ${selected ? theme.primaryColors.focus : theme.primaryColors.focusAccent};
        }
    `};
    box-sizing: border-box;
`;

export const InputWithChipsCss = styled.div`
    .MuiInputBase-adornedStart {
        height: 45.72px;
        // Mui have made it impossible to override the padding of this input when it is adorned for some reason.
        // So I have use !important here to achieve our own style
        padding: 0 0 0 5px !important;
        .MuiInputBase-input {
            margin-left: 5px;
        }
    }
`;
