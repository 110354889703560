import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { Body2, EmphasisText, inlineTextIconMargin, InteractiveElement, sectionalMargin, StyledFieldLabel, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useConstant } from "@iventis/utilities";
import { Divider, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { FunctionComponent, useContext, useMemo, useState } from "react";

import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { ChipType, StyledChip } from "@iventis/components/src/chips";
import { FormWizardTemplate } from "@iventis/components/src/form-wizard-template";
import { SitemapContext } from "./sitemap-context";
import { SitemapLevelAccordion, SitemapLevelForm } from "./sitemap-version-level-form";
import {
    createEmptySitemapVersion,
    createEmptySitemapVersionLevel,
    getLevelsWithDuplicateIndexes,
    getNextLevelIndex,
    hasSitemapVersionChanged,
    hasSitemapVersionsLevelsChanged,
    isNewSitemapVersionLevelValid,
    isSitemapVersionLevelValid,
    sortByLevelIndex,
    splitExistingAndNewLevels,
    upsertSitemapVersionAndLevels,
} from "./sitemap-helpers";
import { SitemapVersionLevelWithFiles, SitemapVersionWithFiles } from "./sitemap-types";

export const SitemapVersionWizard: FunctionComponent<{
    sitemapId: string;
    existingSitemapVersion: SitemapVersion | undefined;
    onSitemapVersionUpserted: (sitemapVersion: SitemapVersion) => void;
    onSitemapVersionLevelsUpdated: (sitemapVersion: SitemapVersion, uploadTileRequests?: (SitemapVersionLevelCreateResponse & SitemapVersionLevelWithFiles)[]) => void;
    cancel: () => void;
}> = ({ existingSitemapVersion, onSitemapVersionLevelsUpdated, onSitemapVersionUpserted, cancel, sitemapId }) => {
    const translate = useIventisTranslate();
    // Creating or editing
    const action = useConstant(() => (existingSitemapVersion ? "edit" : "create"));
    // Api services
    const sitemapServices = useContext(SitemapContext);
    // Updatable sitemap version, with an initial state of the existing entity if exists
    const [sitemapVersion, setSitemapVersion] = useState<SitemapVersionWithFiles>(existingSitemapVersion ?? createEmptySitemapVersion(sitemapId));

    // Using react query to update on the server
    const { isLoading, mutateAsync: upsertSitemapVersion } = useMutation({
        mutationFn: async (sitemapVersion: SitemapVersionWithFiles) => {
            if (action === "create") {
                const remoteSitemapVersion = await sitemapServices.createSitemapVersion(sitemapVersion);
                // Tell the parent we've successfully upserted, but keep the levels the same as they have not yet been successfully uploaded
                onSitemapVersionUpserted(remoteSitemapVersion);
                return;
            }
            // Update the sitemap version if the name changes, otherwise we can confidently assume the existing sitemap version is up to date
            let remoteSitemapVersion: SitemapVersion = sitemapVersion;
            if (hasSitemapVersionChanged(existingSitemapVersion, sitemapVersion)) {
                remoteSitemapVersion = await sitemapServices.updateSitemapVersion(sitemapVersion);
                // Tell the parent we've successfully upserted, but keep the levels the same as they have not yet been successfully uploaded
                onSitemapVersionUpserted(remoteSitemapVersion);
            }
            await upsertSitemapVersionAndLevels(existingSitemapVersion, sitemapVersion, sitemapServices, onSitemapVersionLevelsUpdated);
        },
    });

    /*
        VALIDATIONS
    */
    const hasSitemapVersionUpdated = useMemo(() => hasSitemapVersionChanged(existingSitemapVersion, sitemapVersion), [sitemapVersion]);
    const hasSitemapVersionsLevelsUpdated = useMemo(
        () => existingSitemapVersion == null || hasSitemapVersionsLevelsChanged(existingSitemapVersion?.sitemapVersionLevels, sitemapVersion.sitemapVersionLevels),
        [sitemapVersion.sitemapVersionLevels]
    );
    const isSitemapVersionValid = sitemapVersion.name?.length > 0;
    const areSitemapVersionLevelsValid = useMemo(() => {
        const [oldLevels, newLevels] = splitExistingAndNewLevels(existingSitemapVersion?.sitemapVersionLevels ?? [], sitemapVersion?.sitemapVersionLevels);
        return oldLevels.every((level) => isSitemapVersionLevelValid(level)) && newLevels.every((level) => isNewSitemapVersionLevelValid(level));
    }, [sitemapVersion.sitemapVersionLevels]);
    const allLevelIndexesUnique = getLevelsWithDuplicateIndexes(sitemapVersion.sitemapVersionLevels)?.length === 0;
    // Combine all validations
    const isValid = !isLoading && (hasSitemapVersionUpdated || hasSitemapVersionsLevelsUpdated) && isSitemapVersionValid && areSitemapVersionLevelsValid && allLevelIndexesUnique;

    return (
        <FormWizardTemplate
            currentStage={0}
            stages={[
                {
                    primaryButtonText: translate(Content.common.buttons.save),
                    primaryButtonCallback: () => upsertSitemapVersion(sitemapVersion),
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: cancel, buttonDisabled: isLoading }],
                    showLoadingSpinner: isLoading,
                    Component: (
                        <SitemapVersionForm
                            sitemapVersion={sitemapVersion}
                            setSitemapVersion={setSitemapVersion}
                            existingLevels={existingSitemapVersion?.sitemapVersionLevels ?? []}
                            action={action}
                        />
                    ),
                    isValid,
                },
            ]}
            title={translate(Content.map6.sitemapVersion.title[action])}
        />
    );
};

export const SitemapVersionForm: FunctionComponent<{
    sitemapVersion: SitemapVersionWithFiles;
    setSitemapVersion: (sitemapVersion: SitemapVersionWithFiles | ((sitemapVersion: SitemapVersionWithFiles) => void)) => void;
    existingLevels: SitemapVersionLevel[];
    action: "edit" | "create";
}> = ({ sitemapVersion, setSitemapVersion, existingLevels, action }) => {
    const translate = useIventisTranslate();
    const duplicateLevelIndexes = useMemo(() => getLevelsWithDuplicateIndexes(sitemapVersion.sitemapVersionLevels), [sitemapVersion.sitemapVersionLevels]);

    return (
        <StyledForm>
            <div>
                <StyledFieldLabel>{translate(Content.map6.sitemapVersion.name)}</StyledFieldLabel>
                <TextField
                    sx={{ minWidth: "70%" }}
                    variant="outlined"
                    value={sitemapVersion.name}
                    onChange={(event) => setSitemapVersion((s) => ({ ...s, name: event.target.value }))}
                />
            </div>
            {action === "edit" && (
                <>
                    <div className="levels-header">
                        <InteractiveElement className="levels-sort" onClick={() => setSitemapVersion((s) => ({ ...s, sitemapVersionLevels: sortByLevelIndex(s) }))}>
                            <Body2>{translate(Content.map6.sitemapVersion.levels)}</Body2>
                            <FontAwesomeIcon icon="sort" size="xs" />
                        </InteractiveElement>
                        <StyledChip
                            chipContainerClassName="add-level"
                            label={
                                <>
                                    <FontAwesomeIcon icon={["far", "plus"]} /> {translate(Content.map6.sitemapVersion.add_level)}
                                </>
                            }
                            chipType={ChipType.PrimaryButton}
                            onClick={() => {
                                setSitemapVersion((s) => ({
                                    ...s,
                                    sitemapVersionLevels: [createEmptySitemapVersionLevel(s.id, getNextLevelIndex(s.sitemapVersionLevels)), ...sortByLevelIndex(s)],
                                }));
                            }}
                        />
                    </div>
                    <Divider />
                    {!(sitemapVersion.sitemapVersionLevels?.length > 0) && <EmphasisText>{translate(Content.map6.sitemapVersion.there_are_no_levels)}</EmphasisText>}
                    {sitemapVersion.sitemapVersionLevels.map((level) => {
                        const isNew = !existingLevels.some((l) => l.id === level.id);
                        const isLevelIndexUnique = !duplicateLevelIndexes.some((l) => l.id === level.id);
                        return (
                            <SitemapLevelAccordion
                                key={level.id}
                                sitemapVersionLevel={level}
                                onDelete={(id) => setSitemapVersion((s) => ({ ...s, sitemapVersionLevels: s.sitemapVersionLevels.filter((l) => l.id !== id) }))}
                                isNew={isNew}
                                isLevelIndexUnique={isLevelIndexUnique}
                            >
                                <SitemapLevelForm
                                    sitemapVersionLevel={level}
                                    isLevelIndexUnique={isLevelIndexUnique}
                                    setSitemapVersionLevel={(sitemapVersionLevel) =>
                                        setSitemapVersion((s) => {
                                            const newLevels = [...s.sitemapVersionLevels];
                                            const index = newLevels.findIndex((l) => l.id === sitemapVersionLevel.id);
                                            newLevels[index] = sitemapVersionLevel;
                                            return { ...s, sitemapVersionLevels: newLevels };
                                        })
                                    }
                                />
                            </SitemapLevelAccordion>
                        );
                    })}
                </>
            )}
        </StyledForm>
    );
};

/*
    STYLES
*/

const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${sectionalMargin};

    .levels-header {
        display: flex;
        justify-content: space-between;
        align-items: end;

        .levels-sort {
            display: flex;
            column-gap: ${inlineTextIconMargin};
            align-items: center;
        }
    }
`;
