import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { replaceArrayElementByProperty } from "../array/array";

/**
 * Gets data and allows for mutations, stores and updates the data in local state
 * Useful when you need RESTful operations on data
 */
export const useLocalDataWithMutations = <TData extends { id: string }>(
    queryKey: string,
    apiOperations: {
        getFn: () => Promise<TData[]>;
        postFn?: (data: TData) => Promise<TData>;
        patchFn?: (data: Partial<TData>) => Promise<TData>;
        putFn?: (data: TData) => Promise<TData>;
        deleteFn?: (id: string) => Promise<void>;
    }
) => {
    const queryClient = useQueryClient();

    const [data, setData] = useState<TData[]>(null);

    const { getFn, postFn, patchFn, putFn, deleteFn } = apiOperations;

    const { isLoading } = useQuery([queryKey], getFn, {
        onSuccess: (getData) => {
            if (data == null) {
                setData(getData ?? []);
            }
        },
    });

    const { mutateAsync: postMutate } = useMutation(postFn, {
        onMutate: (postItem) => {
            setData([...data, postItem]);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    const { mutateAsync: patchMutate } = useMutation(patchFn, {
        onMutate: (patchItem) => {
            const originalItem = data.find(({ id }) => id === patchItem.id);
            const updatedItem = { ...originalItem, ...patchItem };
            const updatedData = replaceArrayElementByProperty(data, "id", updatedItem);
            setData(updatedData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    const { mutateAsync: putMutate } = useMutation(putFn, {
        onMutate: (putItem) => {
            const updatedData = replaceArrayElementByProperty(data, "id", putItem);
            setData(updatedData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    const { mutateAsync: deleteMutate } = useMutation(deleteFn, {
        onMutate: (deletedItemId) => {
            const updatedData = data.filter((data) => data.id !== deletedItemId);
            setData(updatedData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    return { data, isLoading, post: postMutate, patch: patchMutate, put: putMutate, delete: deleteMutate };
};
