import { UnionOfNodes } from "@iventis/tree-browser";
import { EMPTY_GUID } from "@iventis/utilities";
import { getAssetSignature } from "@iventis/utilities/src/performance/asset-signature-cache";
import { getAsset } from "./assets-api-helpers";
import { CategoryRootNode, CategoryNode } from "./category-types";

/** Gets all the categories whose order changes as a result of the new order on the updated category.
 * @returns Affected categories with their new order
 */
export const getAffectedCategories = (allCategories: UnionOfNodes<CategoryRootNode>[], updatedCategory: UnionOfNodes<CategoryRootNode>, _oldOrder: number | undefined) => {
    let oldOrder = _oldOrder;
    const allCategoriesWithOrder = [];
    allCategories.forEach((category, index) => {
        if (category.id === updatedCategory.id && oldOrder == null) {
            oldOrder = index + 1;
        }
        allCategoriesWithOrder.push({ ...category, order: category.order ?? index + 1 });
    });
    const allAffectedCategories = [updatedCategory];
    const affectedSiblingsAbove = allCategoriesWithOrder.filter(({ order }) => order >= updatedCategory.order && order < oldOrder);
    const affectedSiblingsBelow = allCategoriesWithOrder.filter(({ order }) => order <= updatedCategory.order && order > oldOrder);
    affectedSiblingsAbove.forEach((sibling) => allAffectedCategories.push({ ...sibling, order: sibling.order + 1 }));
    affectedSiblingsBelow.forEach((sibling) => allAffectedCategories.push({ ...sibling, order: sibling.order - 1 }));
    return allAffectedCategories;
};

export async function getCategoryThumbnail(node: CategoryNode): Promise<string | null> {
    if (!node.iconAssetId || node.iconAssetId === EMPTY_GUID) {
        return null;
    }
    const asset = await getAsset(node.iconAssetId);
    if (asset == null) {
        return null;
    }
    return getAssetSignature(asset.assetUrl, asset.authoritySignature);
}
