import React, { useReducer } from "react";
import { styled, Body3, StyledFieldLabel, gapWithRoomForErrorMessage } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";

import { ModelVariableSize } from "./upload-model-variable-size";
import { validationReducer, createValidationReducerInitialValues } from "./model-sizing-component-state";
import { IncrementalValueComponent } from "./selectors/incremental-value-input";

export const MAX_SIZE_MODEL = 1000;
export const MIN_SIZE_MODEL = 0.1;

export const ModelSizingComponent = ({
    values,
    disabled,
    onValueChanged,
}: {
    onValueChanged: (property: keyof ModelVariableSize["size"], value: number) => void;
    values: ModelVariableSize["size"];
    disabled?: boolean;
}) => {
    const translate = useIventisTranslate();

    const [validation, dispatch] = useReducer(validationReducer, createValidationReducerInitialValues(values));

    const handleValueChange = (value: number, property: "height" | "width" | "length") => {
        dispatch({ type: property, value });
        onValueChanged(property, value);
    };

    return (
        <ModelSizingContainer>
            <div className="selector-container">
                <div>
                    <StyledFieldLabel> {`${translate(Content.map5.fixed_shape.length)} (${translate(Content.map3.units.meters)})`}</StyledFieldLabel>
                    <IncrementalValueComponent
                        changeValue={(value) => handleValueChange(value, "length")}
                        minValue={MIN_SIZE_MODEL}
                        maxValue={MAX_SIZE_MODEL}
                        increment={1}
                        value={values.length}
                        decimals={2}
                        disabled={disabled}
                        hideError
                        alwaysEmitValue
                    />
                </div>
                <div>
                    <StyledFieldLabel> {`${translate(Content.map5.fixed_shape.width)} (${translate(Content.map3.units.meters)})`} </StyledFieldLabel>
                    <IncrementalValueComponent
                        changeValue={(value) => handleValueChange(value, "width")}
                        minValue={MIN_SIZE_MODEL}
                        maxValue={MAX_SIZE_MODEL}
                        increment={1}
                        value={values.width}
                        decimals={2}
                        disabled={disabled}
                        hideError
                        alwaysEmitValue
                    />
                </div>
                <div>
                    <StyledFieldLabel>{`${translate(Content.map2.styles.height)} (${translate(Content.map3.units.meters)})`}</StyledFieldLabel>
                    <IncrementalValueComponent
                        changeValue={(value) => handleValueChange(value, "height")}
                        minValue={MIN_SIZE_MODEL}
                        maxValue={MAX_SIZE_MODEL}
                        decimals={2}
                        increment={1}
                        value={values.height}
                        disabled={disabled}
                        hideError
                        alwaysEmitValue
                    />
                </div>
            </div>
            <Body3 className="dimensions-invalid">
                {validation.showError && <span>{translate(Content.map5.styles.modelVariableSizeError, { minValue: MIN_SIZE_MODEL, maxValue: MAX_SIZE_MODEL })}</span>}
            </Body3>
        </ModelSizingContainer>
    );
};

const ModelSizingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .selector-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .dimensions-invalid {
        position: absolute;
        color: ${(props) => props.theme.secondaryColors.error};
        height: ${gapWithRoomForErrorMessage};
        bottom: -${gapWithRoomForErrorMessage};
    }
`;
