import { useEffect, useRef } from "react";

/**
 * Throttles the amount of times the callback is called
 * Value passed until the callback is the most upto date value
 */
export const useThrottledCallback = <ValueType>(callback: (value: ValueType) => void, throttleMs: number) => {
    const valueRef = useRef<ValueType>(null);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    useEffect(
        () => () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        },
        []
    );

    return (newValue: ValueType) => {
        valueRef.current = newValue;
        if (timeoutRef.current == null) {
            timeoutRef.current = setTimeout(() => {
                callback(valueRef.current);
                timeoutRef.current = null;
            }, throttleMs);
        }
    };
};
