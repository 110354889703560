import { styled, sectionalMargin } from "@iventis/styles";

export const TreeContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .title {
        padding: ${sectionalMargin};
        box-sizing: border-box;
    }
    .header {
        display: flex;
        align-items: center;
        padding: ${sectionalMargin};
        justify-content: space-between;
        box-sizing: border-box;
    }

    .browser {
        overflow-y: auto;
        flex-grow: 1;
        position: relative;
    }
`;
