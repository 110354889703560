import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { Hierarchy } from "@iventis/domain-model/model/hierarchy";
import { createMapFromArray } from "@iventis/utilities";
import { CategoryNode, convertHierarchyToTreeBrowserNode } from "./layer-template-categories";

/** Gets the recommended categories from a category hierarchy */
export const getRecommendedCategories = (categories: Hierarchy<AssetCategory>[], recommended: AssetCategory[]): CategoryNode[] => {
    if (categories == null || recommended == null) {
        return [];
    }

    // Create a recommended category map to reduce going through the recommended array multiple times
    const recommendedCategoryMap = createMapFromArray(recommended, "id");
    return categories.reduce((recommendedNodeCategories, category) => {
        // Convert to a category node
        const nodeCategory = convertHierarchyToTreeBrowserNode(category);
        // Check if the category is recommended
        const found = recommendedCategoryMap.get(nodeCategory.id);
        if (found) {
            recommendedNodeCategories.push(nodeCategory);
        }
        // Check if children of category node is recommended
        nodeCategory.childNodes.forEach((child) => {
            const found = recommendedCategoryMap.get(child.id);
            if (found) {
                recommendedNodeCategories.push(child);
            }
        });
        return recommendedNodeCategories;
    }, []);
};
