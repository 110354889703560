import React, { FunctionComponent } from "react";
import { Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, inlineTextIconMargin, inputHeight } from "@iventis/styles";

interface UploadFileInputProps {
    onInputClick: () => void;
    onButtonClick: () => void;
    buttonText: string;
    fileName?: string;
    loading?: boolean;
    disabled?: boolean;
    dataTestId?: string;
    containerDataTestId?: string;
    classNames?: {
        input?: string;
        button?: string;
        container?: string;
    };
}

export const UploadFileInput: FunctionComponent<UploadFileInputProps> = ({
    loading = false,
    disabled = false,
    classNames = {},
    fileName = "",
    buttonText,
    dataTestId = "select-file-button",
    containerDataTestId,
    onButtonClick,
    onInputClick,
}) => (
    <StyledFileInput className={classNames.container} data-testid={containerDataTestId}>
        <TextField
            style={{ height: inputHeight }}
            variant="outlined"
            className={classNames.input}
            disabled={disabled}
            value={fileName}
            onClick={(e) => {
                onInputClick();
                (e.target as HTMLInputElement).blur();
            }}
        />

        <Button className={classNames.button} disabled={loading || disabled} color="primary" variant="contained" onClick={() => onButtonClick()} data-testid={dataTestId}>
            {loading ? (
                <FontAwesomeIcon icon={["fas", "circle-notch"]} spin style={{ marginRight: inlineTextIconMargin }} />
            ) : (
                <FontAwesomeIcon icon={["far", "file-upload"]} style={{ marginRight: inlineTextIconMargin }} />
            )}
            {buttonText}
        </Button>
    </StyledFileInput>
);

const StyledFileInput = styled.div`
    display: flex;

    input {
        color: ${(props) => props.theme.tertiaryColors.primary};
        text-decoration: underline;
        font-weight: 600;
    }

    .MuiInputBase-root {
        flex: 1 1;
        height: ${inputHeight};
        border-radius: 4px 0 0 4px;
    }

    button {
        border-radius: 0 4px 4px 0;
        box-shadow: none;
    }
`;
