/* eslint-disable react/jsx-props-no-spreading */
import { sectionalMargin as sectionalMarginHeight, styled } from "@iventis/styles";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";

interface Props<TValue> {
    values?: TValue[];
    selectedValues: TValue[];
    placeholder?: string;
    onChange: (value: TValue[]) => void;
    getLabel?: (value: TValue) => string;
    isOptionEqualToValue?: (value: TValue, option: TValue) => boolean;
    title?: string;
    className?: string;
    sectionalMargin?: boolean;
    definedValues?: boolean;
    dataTestId?: string;
    disabled?: boolean;
}

export const MultipleValueTextInput = <TValue extends unknown>({
    values = [],
    title,
    onChange,
    selectedValues,
    placeholder = "",
    getLabel,
    isOptionEqualToValue,
    className,
    sectionalMargin = true,
    definedValues = true,
    dataTestId = "multi-value-text-input",
    disabled = false,
}: Props<TValue>) => (
    <>
        {title && <StyledFieldLabel id="name">{title}</StyledFieldLabel>}
        <StyledDiv className={className}>
            <Autocomplete
                multiple
                freeSolo={!definedValues}
                options={values}
                onChange={(_, value: TValue[]) => onChange(value)}
                value={selectedValues}
                getOptionLabel={(value: TValue) => (getLabel ? getLabel(value) : (value as string))}
                renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
                data-testid={dataTestId}
                disabled={disabled}
                isOptionEqualToValue={isOptionEqualToValue || ((option, value) => option === value)}
            />
        </StyledDiv>
        {sectionalMargin && <div role="presentation" style={{ height: sectionalMarginHeight }} />}
    </>
);

const StyledDiv = styled.div`
    .MuiInputBase-adornedStart {
        height: 45.72px;
        // Mui have made it impossible to override the padding of this input when it is adorned for some reason.
        // So I have use !important here to achieve our own style
        padding: 0 0 0 5px !important;
        .MuiInputBase-input {
            margin-left: 5px;
        }
    }
`;
