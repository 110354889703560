/* eslint-disable react/jsx-props-no-spreading */
import { InteractiveElement, fontSizes, styled } from "@iventis/styles";
import React, { useEffect, useMemo, useRef } from "react";
import { useDateFormatter } from "react-aria";
import { RangeCalendarState } from "react-stately";

export function YearSelector({ state, setYearSelectorOpen }: { state: RangeCalendarState; setYearSelectorOpen: (open: boolean) => void }) {
    const formatter = useDateFormatter({
        year: "numeric",
        timeZone: state.timeZone,
    });
    const selectedYearElement = useRef(null);

    // Format 22 years on each side of the current year according
    // to the current locale and calendar system.
    const years = useMemo(() => {
        const years = [];
        for (let i = -22; i <= 22; i += 1) {
            const date = state.focusedDate.add({ years: i });
            years.push({
                value: date,
                formatted: formatter.format(date.toDate(state.timeZone)),
            });
        }
        return years;
    }, [state.focusedDate]);

    const updateYear = (index: number) => {
        const date = years[index].value;
        state.setFocusedDate(date);
        setYearSelectorOpen(false);
    };

    useEffect(() => {
        if (selectedYearElement.current) {
            selectedYearElement.current.scrollIntoView({ block: "center" });
        }
    }, []);

    return (
        <StyledYearSelector aria-label="Year">
            {years.map((year, i) => (
                // use the index as the value so we can retrieve the full
                // date object from the list in updateYear. We cannot only
                // store the year number, because in some calendars, such
                // as the Japanese, the era may also change.
                <StyledYearButton
                    $focussedDate={state.focusedDate.year === Number(year.formatted)}
                    onClick={() => updateYear(Number(i))}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    {...(state.focusedDate.year === Number(year.formatted) ? { ref: selectedYearElement } : {})}
                >
                    {year.formatted}
                </StyledYearButton>
            ))}
        </StyledYearSelector>
    );
}

const StyledYearButton = styled(InteractiveElement)<{ $focussedDate: boolean }>`
    border-radius: 18px;
    margin: 10px;
    padding: 8px 10px;
    font-size: ${fontSizes.medium};
    ${(props) => (props.$focussedDate ? `background-color: ${props.theme.primaryColors.focus}; &:not([disabled]) { color: ${props.theme.secondaryColors.blank}; }` : "")};
    ${(props) => (!props.$focussedDate ? `&:hover { background-color: ${props.theme.shades.libraryHover}; }` : "")};
`;

const StyledYearSelector = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-y: scroll;
    max-height: 300px;
    text-align: center;
`;
