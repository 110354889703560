import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { Divider } from "@mui/material";
import React, { FunctionComponent, useMemo, useState } from "react";
import { styled, formPadding, Header4, inlineTextIconMargin, muiInputFormsCSS } from "@iventis/styles";

import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { v4 as uuid } from "uuid";
import { AdditionalDataFieldProperties } from "@iventis/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { isValidUuid, useConstant, useDebouncer } from "@iventis/utilities";
import { DataField } from "@iventis/domain-model/model/dataField";
import { FormWizardTemplate, Stages, FormWizardTitle, ConfirmationDialogComponent } from "@iventis/components";
import { WHAT3WORDS_ATTRIBUTE_NAME } from "@iventis/what3words";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";
import { AttributeListItemEditor, AttributeNameEditor, AttributeTypeSelector } from "./attribute-editor-components";
import { EditListItemPropertyForm } from "./edit-list-item-property-form";
import { AttributeDefaultValueComponent } from "./attribute-default-value";

export type AdditionalAttributeDataFormComponent<TAttribute extends DataField = DataField> = FunctionComponent<{
    attribute: TAttribute;
    updateAttribute: (updator: (df: TAttribute) => TAttribute) => void;
}>;

interface AttributeEditorProps {
    existingAttribute: AdditionalDataFieldProperties;
    allowDeletion?: boolean;
    allowEditing?: boolean;
    allowTypeEditing?: boolean;
    isProjectAttributesOnly?: boolean;
    onClose: () => void;
    AdditionalAttributeDataForm: AdditionalAttributeDataFormComponent;
    showListItems?: boolean;
}

enum FormStage {
    EditAttribute = 0,
    AddProperty = 1,
}

/**
 * A component that renders both an [AttributeTypeSelector](./create-attribute.tsx) and an [AttributeTypeEditor](./create-attribute.tsx) component together inside of a form wizard template.
 * Keeps the state of the modified attribute and will send it to onChange once save is clicked.
 */
export const AttributeEditor: React.FunctionComponent<AttributeEditorProps> = ({
    existingAttribute,
    allowDeletion = true,
    allowEditing = true,
    onClose,
    allowTypeEditing = true,
    isProjectAttributesOnly = false,
    AdditionalAttributeDataForm,
    showListItems = true,
}) => {
    /*
        Globals
    */
    const translate = useIventisTranslate();
    const { dataFieldsService } = useDataFieldServices();
    const { resourceId, allowSetDefaultValue } = useDataFieldConfig();
    /*
        Local state
    */
    const dataFieldId = useConstant<string>(() => (isValidUuid(existingAttribute.id) ? existingAttribute.id : uuid()));
    const [attributeWithModifications, setAttributeWithModifications] = useState<AdditionalDataFieldProperties>({ ...existingAttribute });
    const [areListItemsSaving, setAreListItemsSaving] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [stage, setStage] = useState<FormStage>(FormStage.EditAttribute);

    /*
        Derived state
    */
    // Are we creating or updating
    const isNewDataField = !isValidUuid(attributeWithModifications.id);
    // Is the form valid
    const isDefined = attributeWithModifications.type !== undefined && attributeWithModifications.name !== undefined && attributeWithModifications.name !== "";
    const isDifferent = useMemo(() => {
        // For list items default values are saved when they are changed in the data table
        if (attributeWithModifications.type === DataFieldType.List) {
            return JSON.stringify({ ...attributeWithModifications, defaultValue: null }) !== JSON.stringify({ ...existingAttribute, defaultValue: null });
        }

        if (isDefined) {
            return JSON.stringify(attributeWithModifications) !== JSON.stringify(existingAttribute);
        }

        if (attributeWithModifications.type === DataFieldType.What3Words) {
            return true;
        }

        return false;
    }, [existingAttribute, attributeWithModifications, isDefined]);
    const isValid = isDefined && isDifferent;
    // Title text to show in the form wizard header
    const titleText = useMemo(() => {
        if (isNewDataField) {
            return isProjectAttributesOnly ? translate(Content.map4.attributes.create_predefined_title) : translate(Content.map4.attributes.create_attribute_title);
        }
        return translate(Content.map4.attributes.edit_attribute_title);
    }, [translate, isNewDataField, isProjectAttributesOnly]);
    // Text to show in the form wizard header beneath the title text
    const footerText = useMemo(() => {
        if (isNewDataField) {
            return translate(Content.map4.attributes.create_new_attribute);
        }
        return allowTypeEditing ? translate(Content.map4.attributes.edit_attribute) : translate(Content.map4.attributes.edit_attribute_no_type);
    }, [translate, isNewDataField, isProjectAttributesOnly, allowTypeEditing]);

    /*
        Mutations
    */
    const { isLoading: isSavingAttribute, mutateAsync: saveAttribute } = useMutation(async (attribute: AdditionalDataFieldProperties) => {
        if (isValid) {
            if (isValidUuid(attribute.id)) {
                await dataFieldsService.putDataField(attribute, resourceId);
            } else {
                await dataFieldsService.postDataField({ ...attribute, id: dataFieldId }, resourceId);
                setAttributeWithModifications((a) => ({ ...a, id: dataFieldId }));
            }
        }
    });
    const { isLoading: isDeletingAttribute, mutateAsync: deleteAttribute } = useMutation(async (attributeId: string) => {
        await dataFieldsService.deleteDataField(attributeId, resourceId);
    });

    /*
        Debouncer
    */
    const saveDebounceRef = useDebouncer(saveAttribute, 1500);

    /*
        Functions
    */
    const updateAttribute = async (df: AdditionalDataFieldProperties | ((df: DataField) => DataField), forceSave = false) => {
        setAttributeWithModifications((d) => {
            let attribute: DataField;
            if (typeof df === "function") {
                attribute = df(d);
            } else {
                attribute = df;
            }
            if (isNewDataField) {
                return attribute;
            }
            saveDebounceRef.current.cancel();
            if (forceSave) {
                saveAttribute(attribute);
            } else {
                saveDebounceRef.current(attribute);
            }
            return attribute;
        });
    };
    const handleTypeChange = (value: DataFieldType) => {
        switch (value) {
            case DataFieldType.List:
                updateAttribute((a) => ({ ...a, type: value, listValues: [] }));
                break;
            case DataFieldType.What3Words:
                updateAttribute((a) => ({ ...a, type: value, listValues: undefined, name: WHAT3WORDS_ATTRIBUTE_NAME }));
                break;
            default:
                updateAttribute((a) => ({ ...a, type: value, listValues: undefined }));
        }
    };
    const handleAddProperty = (attribute) => {
        setAttributeWithModifications(attribute);
        setStage(FormStage.EditAttribute);
    };

    const status = areListItemsSaving || isSavingAttribute ? "saving" : isDifferent || !isDefined ? "unsaved" : "saved";
    const stages: Stages = [
        {
            primaryButtonText: isNewDataField ? translate(Content.map.data_fields.add) : translate(Content.common.buttons.save),
            submittingText: translate(Content.common.saving),
            submittedText: translate(Content.common.saved),
            isSubmitted: status === "saved",
            showLoadingSpinner: status === "saving",
            primaryButtonCallback: () => saveAttribute(attributeWithModifications),
            submitButtonDataCy: "attribute-editor-submit",
            secondaryButtons: [
                {
                    buttonText: translate(Content.common.buttons.close),
                    onButtonPressed: onClose,
                    dataCy: "close-attribute-editor",
                },
            ],
            isValid,
            ...(allowDeletion
                ? {
                      tertiaryButtonText: translate(Content.common.buttons.delete),
                      tertiaryButtonCallback: () => setShowConfirmationDialog(true),
                      tertiaryButtonDisabled: isNewDataField || isDeletingAttribute,
                      tertiaryButtonSpinning: { is: isDeletingAttribute, text: translate(Content.common.buttons.deleting) },
                      tertiaryTestId: "delete-attribute-button",
                  }
                : {}),
        },
        {
            replaceTemplateWithComponent: true,
            Component: <EditListItemPropertyForm attribute={attributeWithModifications} onConfirm={handleAddProperty} onClose={() => setStage(FormStage.EditAttribute)} />,
        },
    ];

    return (
        <FormWizardTemplate
            currentStage={stage}
            stages={stages}
            title={
                <FormWizardTitle
                    footer={footerText}
                    title={
                        <TextIconContainer>
                            <FontAwesomeIcon icon={["fas", "tag"]} />
                            <Header4>{titleText}</Header4>
                        </TextIconContainer>
                    }
                    customTitle
                />
            }
        >
            <FormContainer data-testid="attribute-editor">
                {allowTypeEditing && isNewDataField && (
                    <>
                        <AttributeTypeSelector value={attributeWithModifications.type} onChange={handleTypeChange} />
                        <Divider />
                    </>
                )}
                {/* Name input */}
                <AttributeNameEditor disabled={!allowEditing} attribute={attributeWithModifications} updateDataField={updateAttribute} />
                {attributeWithModifications.type !== DataFieldType.List && allowSetDefaultValue && attributeWithModifications.type !== DataFieldType.Image && (
                    <div>
                        <AttributeDefaultValueComponent
                            attribute={attributeWithModifications}
                            setDefaultValue={(defaultValue) => updateAttribute({ ...attributeWithModifications, defaultValue })}
                        />
                    </div>
                )}
                {/* List items editor */}
                {!isNewDataField && showListItems && attributeWithModifications.type === DataFieldType.List && (
                    <AttributeListItemEditor
                        disabled={!allowEditing}
                        onInsertColumn={() => setStage(FormStage.AddProperty)}
                        attribute={attributeWithModifications}
                        areListItemsSaving={setAreListItemsSaving}
                    />
                )}
                {/* Other inputs provided by the parent */}
                {AdditionalAttributeDataForm != null && <AdditionalAttributeDataForm attribute={attributeWithModifications} updateAttribute={updateAttribute} />}
                <ConfirmationDialogComponent
                    dataCy="delete-attribute-prompt"
                    show={showConfirmationDialog}
                    title={translate(Content.map8.deleteAttributePrompt.title)}
                    message={translate(Content.map8.deleteAttributePrompt.message)}
                    confirmText={translate(Content.common.buttons.confirm)}
                    cancelText={translate(Content.common.buttons.cancel)}
                    handleCancel={() => {
                        setShowConfirmationDialog(false);
                    }}
                    handleConfirm={async () => {
                        await deleteAttribute(attributeWithModifications.id);
                        setShowConfirmationDialog(false);
                        onClose();
                    }}
                />
            </FormContainer>
        </FormWizardTemplate>
    );
};

const TextIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${inlineTextIconMargin};
`;

const FormContainer = styled.div`
    ${muiInputFormsCSS}

    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 100%;
    padding: ${formPadding} 0;
    box-sizing: border-box;
    flex-grow: 1;

    .full-width {
        width: 100%;
    }

    .ag-grid-iventis {
        height: auto;
        display: flex;
        // max width of 100% - width of the add column button
        max-width: calc(100% - 45px);
    }
`;
