import React, { FunctionComponent, SyntheticEvent } from "react";
import { CheckedCheckBox as CheckedCheckBoxTemplate, UncheckedCheckBox as UncheckedCheckBoxTemplate } from "@iventis/styles/src/components";
import { Checkbox, useTheme } from "@mui/material";
import { Theme } from "@emotion/react";

export const CheckedCheckBox = () => {
    const theme = useTheme<Theme>();
    return (
        <CheckedCheckBoxTemplate
            size={15}
            tickColour={theme.typographyColors.blank}
            tickWeight="fas"
            borderStyle={`1.5px solid ${theme.primaryColors.subdued70}`}
            backgroundColour={theme.primaryColors.subdued70}
        />
    );
};

export const UncheckedCheckBox = () => {
    const theme = useTheme<Theme>();
    return <UncheckedCheckBoxTemplate borderStyle={`1.5px solid ${theme.primaryColors.subdued70}`} backgroundColour={theme.secondaryColors.blank} size={15} />;
};

export const CheckBoxStyled: FunctionComponent<{
    checked: boolean;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    icon?: React.ReactNode;
    checkedIcon?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styleAdditions?: any;
    disabled?: boolean;
    testId?: string;
}> = ({ checked, onChange, icon, checkedIcon, disabled = false, styleAdditions = {}, testId }) => (
    <Checkbox
        disabled={disabled}
        style={styleAdditions}
        checked={checked}
        icon={icon || <UncheckedCheckBox />}
        checkedIcon={checkedIcon || <CheckedCheckBox />}
        color="default"
        onChange={onChange}
        data-testid={testId}
    />
);
