import React, { FunctionComponent } from "react";
import { styled, formFieldVerticalGap, gapWithRoomForErrorMessage } from "@iventis/styles";

import { resizeImageDimensionGivenHeight, resizeImageDimensionGivenWidth } from "@iventis/utilities";
import { IncrementalValueComponent } from "../index";

export const ImageUploadPreviewResizer: FunctionComponent<{
    fileThumbnailUrl: string;
    maxHeight: number;
    maxWidth: number;
    updateDimensions: (value: { height: number; width: number }) => void;
    height: number;
    width: number;
    disabled: boolean;
    displayHeight?: number;
    displayWidth?: number;
}> = ({ fileThumbnailUrl, maxHeight, maxWidth, updateDimensions, height, width, disabled, displayHeight, displayWidth }) => {
    const updateHeight = (newHeight: number) => {
        const newDimensions = resizeImageDimensionGivenHeight(width, height, newHeight);
        if (newDimensions.height < 1 || newDimensions.width < 1) {
            return; // Do not change dimensions if one side is below 1 pixel
        }
        if (newDimensions.height <= maxHeight && newDimensions.width <= maxWidth) {
            updateDimensions(newDimensions);
        }
    };

    const updateWidth = (newWidth: number) => {
        const newDimensions = resizeImageDimensionGivenWidth(width, height, newWidth);
        if (newDimensions.height < 1 || newDimensions.width < 1) {
            return; // Do not change dimensions if one side is below 1 pixel
        }
        if (newDimensions.height <= maxHeight && newDimensions.width <= maxWidth) {
            updateDimensions(newDimensions);
        }
    };

    return (
        <StyledImagePreviewResier
            url={fileThumbnailUrl}
            height={calculateImageSize(height, maxHeight, displayHeight)}
            width={calculateImageSize(width, maxWidth, displayWidth)}
            maxHeight={displayHeight ?? maxHeight}
            maxWidth={displayWidth ?? maxWidth}
        >
            <div className="image-container">
                <div className="image" />
            </div>
            <div className="controls">
                <div>
                    <IncrementalValueComponent
                        minValue={1}
                        maxValue={maxHeight}
                        increment={Math.ceil(maxHeight / 20 / 5) * 5} // Scale increment by the max size, round to nearest multiple of 5
                        units={[{ id: "h", name: "Height (px)" }]}
                        selectedUnitId="h"
                        value={height}
                        disabled={disabled}
                        changeValue={(h: number) => updateHeight(h)}
                    />
                </div>
                <div>
                    <IncrementalValueComponent
                        disabled={disabled}
                        minValue={1}
                        maxValue={maxWidth}
                        increment={Math.ceil(maxWidth / 20 / 5) * 5} // Scale increment by the max size, round to nearest multiple of 5
                        units={[{ id: "w", name: "Width (px)" }]}
                        selectedUnitId="w"
                        value={width}
                        changeValue={(w: number) => updateWidth(w)}
                    />
                </div>
            </div>
        </StyledImagePreviewResier>
    );
};

export const StyledImagePreviewResier = styled.div<{ url: string; height: number; width: number; maxHeight: number; maxWidth: number }>`
    width: 100%;
    display: flex;
    gap: ${formFieldVerticalGap};
    flex-wrap: wrap;
    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${(props) => props.maxHeight}px;
        width: ${(props) => props.maxWidth}px;
        min-width: ${(props) => props.maxWidth}px;
        min-height: ${(props) => props.maxHeight}px;
        border: solid 1px rgba(0, 0, 0, 0.1);
    }
    .image {
        background: center / contain no-repeat url(${(props) => props.url});
        box-sizing: border-box;
        background-clip: content-box;
        background-origin: content-box;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
    }
    .controls {
        display: flex;
        gap: ${gapWithRoomForErrorMessage};
        flex-direction: column;
        justify-content: space-between;
    }
`;

// Calculates what size we should display the image depending on the actual, max and display size of the image
const calculateImageSize = (actual: number, max: number, display?: number) => {
    if (display) {
        // If we have a display size and the actual size is larger than the max, just use the display size
        if (actual > max) return display;

        // Otherwise set the size as a portion of the display size
        return (actual / max) * display;
    }
    // Validation on the selectors will ensure its less than max so we dont need to worry
    return actual;
};
