import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { DataFieldListItemPropertyValue } from "@iventis/domain-model/model/dataFieldListItemPropertyValue";
import { IventisRowNode, IventisTableData } from "@iventis/data-table";
import { DataFieldListItemRelationshipValue } from "@iventis/domain-model/model/dataFieldListItemRelationshipValue";

export interface DataFieldListItemRowData extends IventisTableData {
    name: string;
}

export type DataFieldListItemRowNode = IventisRowNode<DataFieldListItemRowData>;
export type DataFieldListItemRowDataArray = DataFieldListItemRowNode["data"][];

export const parseDataFieldListItemsToRowData = (listItems: DataFieldListItem[], dataField: DataField): DataFieldListItemRowDataArray =>
    (listItems ?? []).map((x) => {
        const properties = [];
        x.propertyValues?.forEach((p) => {
            const name = dataField.listItemProperties?.find((listItemProperty) => listItemProperty.id === p.propertyId)?.name;
            const type = dataField.listItemProperties?.find((listItemProperty) => listItemProperty.id === p.propertyId)?.type;
            if (name) properties[name] = type === DataFieldListItemPropertyType.Text ? p.text : p.number;
        });
        const relationships = [];
        x.relationshipValues?.forEach((r) => {
            relationships[r.relationshipId] = r.relatedToDataFieldListItemId;
        });
        return {
            id: x.id,
            canEdit: true,
            name: x.name,
            default: x.id === dataField?.defaultValue?.listItemId,
            ...x.metaData,
            ...properties,
            ...relationships,
        };
    });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMetadataFromRow = (row: DataFieldListItemRowData, datafield: DataField, dataKeys: string[]): any => {
    const data = {};

    dataKeys.forEach((x) => {
        if (datafield.allowedMetadataProperties?.includes(x)) data[x] = row[x];
    });

    return data;
};

export const getPropertyValuesFromRow = (row: DataFieldListItemRowData, datafield: DataField): DataFieldListItemPropertyValue[] => {
    const data: DataFieldListItemPropertyValue[] = [];

    datafield.listItemProperties?.forEach((property) => {
        const propertyValueFromRow = row[property.name];
        if (propertyValueFromRow != null) {
            data.push({
                dataFieldListItemId: row.id,
                propertyId: property.id,
                text: property.type === DataFieldListItemPropertyType.Text ? propertyValueFromRow ?? "" : undefined,
                number: property.type === DataFieldListItemPropertyType.Number ? Number(propertyValueFromRow ?? 0) : undefined,
            });
        }
    });
    return data;
};

export const getListItemFromRow = (row: DataFieldListItemRowData, datafield: DataField, listItems: DataFieldListItem[], dataKeys: string[]): DataFieldListItem => {
    const metadata = getMetadataFromRow(row, datafield, dataKeys);
    const { id, name } = row;

    const customerIdName = datafield.customerIdentifierName;

    const customerIdentifier = metadata[customerIdName];

    const existingItem = listItems.find((x) => x.id === id);
    const order = existingItem?.order ? existingItem?.order : 0;

    const propertyValues = getPropertyValuesFromRow(row, datafield);

    const relationshipValues: DataFieldListItemRelationshipValue[] = [];

    datafield.listItemRelationships?.forEach((relationship) => {
        const relatedToListItemIdFromRow = row[relationship.id];
        if (relatedToListItemIdFromRow != null) {
            const relationshipValue: DataFieldListItemRelationshipValue = {
                relationshipId: relationship.id,
                relatedToDataFieldListItemId: relatedToListItemIdFromRow,
                dataFieldListItemId: existingItem.id,
            };
            relationshipValues.push(relationshipValue);
        }
    });

    const listItem: DataFieldListItem = {
        id,
        name,
        metaData: metadata,
        customerIdentifier,
        order,
        propertyValues,
        uniqueId: undefined,
        dataFieldId: datafield.id,
        relationshipValues,
    };

    return listItem;
};

export const getRowFromListItem = (item: DataFieldListItem): DataFieldListItemRowData => ({
    id: item.id,
    canEdit: true,
    name: item.name,
    ...item.metaData,
    ...item.propertyValues,
});
