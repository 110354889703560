export const cacheAssetSignature = () => {
    const cache = {};
    return (assetUrl: string, assetSignature: string) => {
        if (assetUrl in cache) {
            return cache[assetUrl];
        }
        const result = getAssetSignature(assetUrl, assetSignature);
        cache[assetUrl] = result;
        return result;
    };
};

export const getAssetSignature = (assetUrl: string, assetSignature: string) => (assetSignature ? `${assetUrl}?${assetSignature}` : assetUrl);
