/* eslint-disable @typescript-eslint/no-explicit-any */
import { NodeType } from "@iventis/domain-model/model/nodeType";
import React, { PropsWithChildren, useMemo } from "react";
import { GetInteractivity, NodeComponent } from "../types/component-types";
import { TreeBrowserNode, UnionOfNodes } from "../types/data-types";

export interface TreeBrowserProps<TNode extends TreeBrowserNode, TExtraProps extends any = undefined> {
    node: TNode;
    /** array of ids of the nodes that have been expanded */
    openNodes: string[];
    /** object containing node components where keys match the node type */
    components: Partial<{ [key in UnionOfNodes<TNode>["type"]]: NodeComponent<UnionOfNodes<TNode>, TExtraProps> }>;
    /** HtmlElement which the container for this component */
    containerElement?: HTMLElement;
    /** is the top level node to be displayed? */
    displayRootNode?: boolean;
    /** Node type of the parent node */
    parentNodeType?: NodeType;
    extraProps?: TExtraProps;
    className?: string;
    /** Callback function on a node to check if it's selectable */
    getInteractivity?: GetInteractivity<TNode>;
    setMapInList?: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}

/**
 * Component for browsing given tree of nodes.
 */
export function TreeBrowserComponent<TNode extends TreeBrowserNode, TExtraProps extends any = undefined>({
    node,
    openNodes,
    components,
    displayRootNode,
    containerElement,
    extraProps,
    parentNodeType,
    getInteractivity,
    setMapInList,
}: PropsWithChildren<TreeBrowserProps<TNode, TExtraProps>>) {
    const NodeComponent = useMemo(() => (node == null ? undefined : components[node.type]), [components]);

    const childNodes = useMemo(() => {
        if (node == null) return undefined;
        if (!displayRootNode || openNodes.includes(node.id)) {
            return node.childNodes.map((childNode) => (
                <TreeBrowserChild
                    key={childNode.id}
                    node={childNode}
                    openNodes={openNodes}
                    components={components}
                    extraProps={extraProps}
                    setMapInList={setMapInList}
                    getInteractivity={getInteractivity}
                    parentNodeType={node.type as NodeType}
                />
            ));
        }
        return <></>;
    }, [node, node.type, node.childNodes, openNodes, extraProps]);

    if (node == null) {
        // To prevent breaking, if tree is null return an empty fragment
        return <></>;
    }

    return (
        <>
            {displayRootNode && (
                <NodeComponent
                    setMapInList={setMapInList}
                    node={node}
                    expanded={openNodes.includes(node.id)}
                    extraProps={extraProps}
                    containerElement={containerElement}
                    getInteractivity={getInteractivity}
                    parentNodeType={parentNodeType}
                />
            )}
            {childNodes}
        </>
    );
}

function TreeBrowserChild<TNode extends TreeBrowserNode, TExtraProps extends any = undefined>({
    node: childNode,
    openNodes,
    components,
    extraProps,
    parentNodeType,
    getInteractivity,
    setMapInList,
}: PropsWithChildren<TreeBrowserProps<TNode, TExtraProps>>) {
    return (
        <TreeBrowserComponent
            node={childNode}
            openNodes={openNodes}
            components={components}
            extraProps={extraProps}
            setMapInList={setMapInList}
            getInteractivity={getInteractivity}
            parentNodeType={parentNodeType}
            displayRootNode
        />
    );
}
