/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step, TooltipRenderProps } from "react-joyride";
import { Body1, Header2, popoverPadding, zIndexes, formPadding, borderRadius, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Box, Button, useTheme } from "@mui/material";

import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { IventisErrorBoundary } from "@iventis/error-boundaries";

export interface TourForwardRef {
    /** Progress the tour to the next step */
    nextStep: () => void;
    /** Make the tour go back a step, if at the first step will do nothing */
    prevStep: () => void;
    /** Immediately finish the tour and call the onFinish event */
    finishTour: () => void;
    /** Immeditately skip the tour and call the onSkip event */
    skipTour: () => void;
    /** Temporarily close the tour to be opned again. Doesn't change the current step. NOTE: also doesn't show the beacon! */
    minimiseTour: () => void;
    /** Reopen the tour on the current step */
    maximiseTour: () => void;
    /** Gets the current step of the tour */
    getStep: () => number;
    /** Returns true if the tour is currently running - regardless if its minimised or not */
    isRunning: () => boolean;
}

interface TourProps {
    steps: Step[];
    running: boolean;
    /** Is called when the tour is finished */
    onFinish: () => void;
    /** Is called when the tour is skipped */
    onSkip?: () => void;
    finishText?: string;
    /** A custom callback function that is called at the end of our own internal joyride callback */
    callback?: (data: CallBackProps) => void;
}

const TourTooltip = ({
    continuous,
    index,
    step,
    isLastStep,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps,
    finishText,
}: TooltipRenderProps & { finishText?: string }) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    return (
        <StyledBox {...tooltipProps}>
            <Header2 data-testid="tour-title">{step.title}</Header2>
            <Body1 data-testid="tour-content">{step.content}</Body1>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <StyledButton style={{ color: theme.secondaryColors.blank }} {...skipProps} data-testid="tour-skip-button">
                    {translate(Content.common.buttons.skip)}
                </StyledButton>
                <div>
                    {index > 0 && <Button {...backProps}>{translate(Content.common.buttons.back)}</Button>}
                    {continuous && (
                        <StyledButton variant="contained" data-testid="tour-confirm-button" {...primaryProps}>
                            {isLastStep ? (
                                finishText ? (
                                    <span>{finishText}</span>
                                ) : (
                                    <span>{translate(Content.common.buttons.finish)}</span>
                                )
                            ) : (
                                <span>{translate(Content.common.buttons.next)}</span>
                            )}
                        </StyledButton>
                    )}
                    {!continuous && (
                        <StyledButton data-testid="tour-close-button" {...closeProps}>
                            {translate(Content.common.buttons.close)}
                        </StyledButton>
                    )}
                </div>
            </div>
        </StyledBox>
    );
};

/**
 * A product tour with tooltips at each step.
 *
 *
 * @param {Step[]} steps - Steps to display in the tour. "title" and "content" are should be strings
 * @param {boolean} running - Whether or not the tour is running.
 * @param {function} onFinish - Runs when the tour is finished.
 */
export const Tour = forwardRef<TourForwardRef, TourProps>(({ steps, running, onFinish, onSkip, finishText, callback }, ref) => {
    const theme = useTheme<Theme>();

    const [currentStep, setCurrentStep] = useState(0);

    // Used to control "minimising" the tour
    const [localRunning, setCurrentRunning] = useState(true);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, step, action } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
        const scrollableEvents: string[] = [EVENTS.STEP_BEFORE, EVENTS.TOUR_START];

        // click on background to close the tour
        if (action === ACTIONS.CLOSE) {
            setCurrentRunning(false);
        }

        if (finishedStatuses.includes(status)) {
            if (typeof onSkip === "function" && status === STATUS.SKIPPED) {
                onSkip();
            } else {
                onFinish();
            }
        }
        if (scrollableEvents.includes(type)) {
            // scroll to the location
            const element = typeof step.target !== "string" ? step.target : document.querySelector(step.target);
            // Some tour steps target the body element, this is usually when a tour begins and introduces the tour.
            // Although Element and HTML Element has a function scrollIntoView, HTMLBodyElement does not. You cannot
            // scroll the body into view. Therefore we check to see if we are targetting a HTMLBodyElement. If we are
            // then do not try to scroll it into view.
            if (element instanceof HTMLBodyElement === false) {
                element?.scrollIntoView();
            }
        }

        // Control if we should progress to the next step or the previous step
        if (type === EVENTS.STEP_AFTER) {
            if (action === ACTIONS.PREV) {
                onPrevStep();
            } else if (action === ACTIONS.NEXT) {
                onNextStep();
            }
        }

        // Call our custom callback passed into the tour if one exists
        if (callback != null) callback(data);
    };

    const TourComponent = useMemo(() => {
        if (typeof finishText === "string") {
            return (args: TooltipRenderProps) => <TourTooltip {...args} finishText={finishText} />;
        }
        return TourTooltip;
    }, [finishText]);

    const onNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const onPrevStep = () => {
        if (currentStep === 0) return;
        setCurrentStep(currentStep - 1);
    };

    const onMinimiseTour = () => {
        setCurrentRunning(false);
    };

    const onMaximiseTour = () => {
        setCurrentRunning(true);
    };

    // Assign events to ref functions
    useImperativeHandle(ref, () => ({
        nextStep: () => onNextStep(),
        prevStep: () => onPrevStep(),
        finishTour: () => onFinish(),
        skipTour: () => onSkip(),
        minimiseTour: () => onMinimiseTour(),
        maximiseTour: () => onMaximiseTour(),
        getStep: () => currentStep,
        isRunning: () => running,
    }));

    return (
        <IventisErrorBoundary action="none" onError={() => null} size="hidden">
            <Joyride
                callback={handleJoyrideCallback}
                hideCloseButton
                continuous
                run={running && localRunning}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: +zIndexes.tour,
                        arrowColor: theme.primaryColors.subdued70,
                    },
                }}
                tooltipComponent={TourComponent}
                disableScrolling
                stepIndex={currentStep}
                spotlightClicks
            />
        </IventisErrorBoundary>
    );
});

const StyledBox = styled(Box)`
    display: flex;
    text-align: center;
    max-width: 420px;
    min-width: 290px;
    min-height: 150px;
    flex-direction: column;
    justify-content: space-between;
    gap: ${popoverPadding.standard};
    padding: ${formPadding};
    border-radius: ${borderRadius.input};
    color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
    background-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued70};
    width: 200px;
`;

const StyledButton = styled(Button)`
    height: 40px;
`;
