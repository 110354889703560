import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const TitleWithIcon: FunctionComponent<{ icon: IconProp; title: string }> = ({ icon, title }) => (
    <>
        <FontAwesomeIcon icon={icon} />
        {title}
    </>
);
