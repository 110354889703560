/* eslint-disable @typescript-eslint/no-empty-function */
import { IventisTableData } from "@iventis/data-table";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { FilterFormat } from "@iventis/types";
import React, { useContext } from "react";

export interface IDataFieldService {
    postDataField: (dataField: DataField, resourceId: string) => Promise<unknown>;
    putDataField: (dataField: DataField, resourceId: string) => Promise<unknown>;
    deleteDataField: (dataFieldId: string, resourceId: string) => Promise<unknown>;
    getDataFieldsForResource: (resourceId: string) => Promise<DataField[]>;
    isDataFieldBeingUsed: (dataFieldId: string, resourceId: string) => Promise<boolean>;
}

export interface IDataFieldListItemService {
    postDataFieldListItem: (dataFieldListItem: DataFieldListItem, dataFieldId: string, resourceId: string) => Promise<void>;
    putDataFieldListItem: (dataFieldListItem: DataFieldListItem, dataFieldId: string, resourceId: string) => Promise<void>;
    patchDataFieldListItemOrder?: (dataFieldId: string, listItem: Pick<DataFieldListItem, "id" | "order"> & { listItemIdAfter: string }) => Promise<unknown>;
    deleteDataFieldListItem?: (dataFieldListItemId: string, dataFieldId: string, resourceId: string) => Promise<void>;
    getDataFieldListItems: (dataFieldId: string, filter?: FilterFormat[], fromComponentHook?: boolean) => Promise<DataFieldListItem[]>;
    getDataFieldListItem: (dataFieldId: string, listItemId: string) => Promise<DataFieldListItem>;
    importDataFieldListItems?: (datafieldId: string, csvJson: string, resourceId: string, deleteExisting?: boolean) => Promise<void>;
    exportDataFieldListItems?: (datafieldId: string, dataFieldName?: string) => void;
    isListItemBeingUsed?: (dataFieldId: string, listItemId: string) => Promise<boolean>;
}

export interface IDataFieldServices {
    dataFieldsService?: IDataFieldService;
    dataFieldListItemsService?: IDataFieldListItemService;
}

export const DataFieldServicesContext = React.createContext<IDataFieldServices>(null);
export const useDataFieldServices = () => useContext(DataFieldServicesContext);

interface DataFieldConfig {
    /** Resource the datafield is being added to, could be a project, layer etc. */
    resourceId: string;
    /** If the datafields can have a default value set */
    allowSetDefaultValue: boolean;
    /** Checks whether the list item can be saved */
    validateListItem: (dataField: DataField, rowData: IventisTableData) => boolean;
}

export const DataFieldConfigContext = React.createContext<DataFieldConfig>({
    resourceId: undefined,
    allowSetDefaultValue: undefined,
    validateListItem: () => true,
});

export const useDataFieldConfig = () => useContext(DataFieldConfigContext);

export const dataFieldServicesDoNothing: IDataFieldServices = {
    dataFieldsService: {
        postDataField: async () => {},
        putDataField: async () => {},
        deleteDataField: async () => null,
        getDataFieldsForResource: async () => [],
        isDataFieldBeingUsed: async () => null,
    },
    dataFieldListItemsService: {
        getDataFieldListItems: async () => [],
        getDataFieldListItem: async () => null,
        postDataFieldListItem: async () => {},
        putDataFieldListItem: async () => {},
        patchDataFieldListItemOrder: async () => {},
        deleteDataFieldListItem: () => null,
        importDataFieldListItems: () => null,
        exportDataFieldListItems: () => null,
        isListItemBeingUsed: () => null,
    },
};
