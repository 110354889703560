import { ProjectUser } from "@iventis/domain-model/model/projectUser";
import { useMachine } from "@xstate/react";
import { createMachine, assign, DoneInvokeEvent } from "xstate";
import { v4 as uuid } from "uuid";
import { Toasts } from "@iventis/toasts/src/toast-interface";
import { UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { addNewPeopleMachine, addNewPeopleMachineId } from "./add-new-people.machine";
import { PeopleViewMachineContext } from "./people-view.types";
import { GetPeople, GetTeams, PostNewUserAndGroups } from "./types";

export const isAllUsersSelected = (users: ProjectUser[] = [], selectedUserIds: string[] = []) => users.length === selectedUserIds.length && users.length !== 0;

export const AddPeopleMachine = "AddPeopleMachine";

export enum PeopleViewMachineEventType {
    UPDATE_SELECTION = "UPDATE_SELECTION",
    CLEAR_SELECTION = "CLEAR_SELECTION",
    ADDING_NEW_PEOPLE = "ADDING_NEW_PEOPLE",
    SEND_INVITES = "SEND_INVITES",
    CANCEL_ADDING_NEW_PEOPLE = "CANCEL_ADDING_NEW_PEOPLE",
    TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL",
    REMOVE_PEOPLE = "REMOVE_PEOPLE",
    ADD_PEOPLE = "ADD_PEOPLE",
    UPDATE_PEOPLES_SSO = "UPDATE_PEOPLES_SSO",
}

export type UpdatePeopleSsoEvent = {
    type: PeopleViewMachineEventType.UPDATE_PEOPLES_SSO;
    payload: { userIds: string[]; enabled: boolean };
};

export type UpdatePersonSelectionEvent = {
    type: PeopleViewMachineEventType.UPDATE_SELECTION;
    payload: { ids: string[]; selected: boolean };
};

export type ClearPersonSelectionEvent = {
    type: PeopleViewMachineEventType.CLEAR_SELECTION;
    payload: undefined;
};

export type AddingNewPeopleEvent = {
    type: PeopleViewMachineEventType.ADDING_NEW_PEOPLE;
};

export type ToggleSelectAll = {
    type: PeopleViewMachineEventType.TOGGLE_SELECT_ALL;
};

export type RemovePeople = {
    type: PeopleViewMachineEventType.REMOVE_PEOPLE;
    payload: string[];
};

export type AddPeopleEvent = {
    type: PeopleViewMachineEventType.ADD_PEOPLE;
    payload: ProjectUser[];
};

export type PeopleViewMachineEvents =
    | ClearPersonSelectionEvent
    | UpdatePersonSelectionEvent
    | AddingNewPeopleEvent
    | ToggleSelectAll
    | RemovePeople
    | AddPeopleEvent
    | UpdatePeopleSsoEvent;

export type PeopleViewMachineParams = {
    postNewUserAndGroups: ReturnType<PostNewUserAndGroups>;
    getTeams: GetTeams;
    getPeople: GetPeople;
    toast: Toasts;
    translate: UseIventisTranslate;
};

export const peopleViewMachine = ({ postNewUserAndGroups, getTeams, getPeople, toast, translate }: PeopleViewMachineParams) =>
    createMachine<PeopleViewMachineContext, PeopleViewMachineEvents>({
        id: "people-view-machine",
        predictableActionArguments: true,
        context: {
            people: undefined,
            selectedPeople: [],
            projectId: undefined,
        },
        initial: "peopleLoading",
        states: {
            peopleLoading: {
                id: "peopleLoading",
                invoke: {
                    src: (context) => getPeople(context.projectId),
                    onDone: {
                        actions: assign((context, event: DoneInvokeEvent<ProjectUser[]>) => ({
                            ...context,
                            people: event.data,
                        })),
                        target: "idle",
                    },
                    onError: "peopleLoadingError",
                },
            },
            peopleLoadingError: {
                id: "peopleLoadingError",
                type: "final",
            },
            addingNewPeople: {
                id: "addingNewPeople",
                invoke: {
                    id: addNewPeopleMachineId,
                    src: addNewPeopleMachine({ postNewUserAndGroups, getTeams, toast, translate, isChild: true }),
                    data: {
                        newUserGroup: [{ id: uuid(), emails: [], valid: false, teams: [] }],
                        teams: [],
                    },
                    onDone: "#idle",
                },
                on: {
                    ADD_PEOPLE: {
                        actions: assign((context, event) => {
                            const users = event.payload;
                            return {
                                ...context,
                                people: [...context.people, ...users],
                            };
                        }),
                    },
                },
            },
            idle: {
                id: "idle",
                on: {
                    UPDATE_SELECTION: {
                        actions: assign((context, { payload }) => {
                            const { selectedPeople } = context;
                            const updatedSelectedPeople = payload.ids.reduce((updatedSelectedPeople, selectedPerson) => {
                                if (payload.selected && !updatedSelectedPeople.includes(selectedPerson)) {
                                    return [...updatedSelectedPeople, selectedPerson];
                                }
                                if (updatedSelectedPeople.includes(selectedPerson)) {
                                    return updatedSelectedPeople.filter((p) => p !== selectedPerson);
                                }
                                return updatedSelectedPeople;
                            }, selectedPeople);

                            return {
                                ...context,
                                selectedPeople: updatedSelectedPeople,
                            };
                        }),
                    },
                    UPDATE_PEOPLES_SSO: {
                        actions: assign((context, { payload }) => {
                            const { people } = context;
                            const updatedPeople = people.map((person) => {
                                if (payload.userIds.includes(person.id)) {
                                    return {
                                        ...person,
                                        ssoEnabled: payload.enabled,
                                    };
                                }
                                return person;
                            });
                            return {
                                ...context,
                                people: updatedPeople,
                            };
                        }),
                    },
                    CLEAR_SELECTION: {
                        actions: assign((context) => ({
                            ...context,
                            selectedPeople: [],
                        })),
                    },
                    ADDING_NEW_PEOPLE: {
                        target: "addingNewPeople",
                    },
                    REMOVE_PEOPLE: {
                        actions: assign((context, event) => ({
                            selectedPeople: [],
                            people: context.people.filter(({ id }) => !event.payload.includes(id)),
                        })),
                    },
                    TOGGLE_SELECT_ALL: [
                        {
                            cond: (context) => isAllUsersSelected(context.people, context.selectedPeople),
                            actions: assign((context) => ({
                                ...context,
                                selectedPeople: [],
                            })),
                        },
                        {
                            actions: assign((context) => ({
                                ...context,
                                selectedPeople: context.people.map(({ id }) => id),
                            })),
                        },
                    ],
                },
            },
        },
    });

const usePeopleViewMachine = () => useMachine(peopleViewMachine({} as PeopleViewMachineParams));

export type UsePeopleViewMachineType = ReturnType<typeof usePeopleViewMachine>;
