import { Theme, css } from "@emotion/react";
import { fontSizes } from "@iventis/styles/src/atomic-rules";
import { styled, iconButtonSize } from "@iventis/styles";

export interface StyledLibraryNodeProps {
    fontWeight: number;
    borderColour: string;
    selected: boolean;
    emptyNode: boolean;
    selectionDisabled?: boolean;
    isFavourite?: boolean;
    theme?: Theme;
    draggingNode?: boolean;
    singleTopRowButton: boolean;
}

export const getNodeBackgroundColor = (hover: boolean, selected: boolean, emptyNode: boolean, theme: Theme, selectionDisabled?: boolean, isdraggingNode?: boolean) => {
    if (isdraggingNode) {
        return theme.typographyColors.blank;
    }

    if (emptyNode) {
        return theme.typographyColors.blank;
    }
    if (selected) {
        return theme.secondaryColors.selection;
    }
    if (selectionDisabled) {
        return theme.typographyColors.blank;
    }
    if (hover) {
        return theme.shades.libraryHover;
    }

    return theme.typographyColors.blank;
};

export const MARGIN_WIDTH_PX = 44;
export const NODE_HEIGHT_PX = 44;
export const EMPTY_NODE_HEIGHT_PX = 44;
export const BOTTOM_PADDING_PX = 44;

export const NewStyledCheckBoxContainer = styled.div`
    flex: 0 0 ${MARGIN_WIDTH_PX}px;
    align-self: center;
    height: 100%;
    position: relative;
    width: ${iconButtonSize};

    .MuiButtonBase-root {
        padding: 0;
        display: flex;
        width: 100%;
        height: 100%;
    }

    svg {
        /* MUI Checkbox by default is raised slightly */
        margin-top: 2px;
    }
`;

export const sharedNodeCss = (props: StyledLibraryNodeProps) => ({ theme }: { theme: Theme }) => css`
    position: relative;
    cursor: ${props.emptyNode ? "default" : "pointer"};
    color: black;

    background-color: ${getNodeBackgroundColor(false, props.selected, props.emptyNode, props.theme, props.selectionDisabled, props.draggingNode)};

    .colour-on-focus:not(.selection-disabled) {
        color: ${props.selected ? theme.typographyColors.core : theme.typographyColors.subdued};
    }

    .colour-on-focus-light:not(.selection-disabled) {
        color: ${props.selected ? theme.primaryColors.subdued70 : theme.typographyColors.moreSubdued};
    }

    .favourite {
        visibility: ${props.selected || props.isFavourite ? "visible" : "hidden"};
    }

    :hover {
        .colour-on-focus {
            color: ${props.theme.typographyColors.core};
        }
        .colour-on-focus-light {
            color: ${props.theme.primaryColors.subdued70};
        }
        .favourite {
            visibility: visible;
        }
    }

    span {
        flex-grow: 1;
        flex-shrink: 1;
        display: inline-block;
        align-self: center;
        font-weight: ${props.fontWeight};
        font-size: ${fontSizes.small};
    }
`;
