import { Body2 } from "@iventis/styles";
import React, { FunctionComponent, useState } from "react";
import { TextField, LinearProgress } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import { fileToBase64, useConstant } from "@iventis/utilities";
import { useQuery } from "@tanstack/react-query";
import { Asset } from "@iventis/domain-model/model/asset";
import { FileUploadComponent, FormWizardTemplate, DisabledOverlay } from "../index";
import { StyledThumbnailUploadContainer, StyledUploadContainer, ThumbnailUploadPreview } from "./upload-shared-styles";
import { FromGapComponent } from "./form-gap";

export type UploadBackgroundData = {
    name: string;
    thumbnail: { fileName: string; imageUrl: string };
    json: { fileName: string; dataUrl: string };
};

export const UploadMapBackgroundForm: FunctionComponent<{
    close: () => void;
    uploadRemotely: (data: UploadBackgroundData) => Promise<Asset>;
    translate?: (text: string) => string;
    getExistingData?: (id: string) => Promise<UploadBackgroundData>;
    existingId?: string;
    setSelectedId?: (id: string) => void;
}> = ({ close, uploadRemotely, translate = (text) => text, getExistingData, existingId, setSelectedId }) => {
    const [state, setState] = useState<"idle" | "loading" | "error">("idle");

    const defaultData = useConstant(() => ({
        name: null,
        thumbnail: { fileName: null, imageUrl: null },
        categories: [],
        json: { fileName: null, dataUrl: null },
    }));

    // Form data
    const [data, _setData] = useState<UploadBackgroundData>(defaultData);

    const { isLoading, isFetching } = useQuery(["get-existing-asset", existingId], () => (existingId ? getExistingData(existingId) : Promise.resolve(defaultData)), {
        onSuccess(data) {
            _setData(data);
            if (existingId) {
                _setData(data);
            }
        },
    });

    // Update form data
    const setData = <TField extends keyof UploadBackgroundData, TValue extends UploadBackgroundData[TField]>(field: TField, value: TValue) =>
        _setData((d) => ({ ...d, [field]: value }));

    // Upload image to local state
    const uploadThumbnailLocally = async (file: File) => {
        const { withHeader } = await fileToBase64(file);
        setData("thumbnail", { fileName: file.name, imageUrl: withHeader });
        setState("idle");
    };

    // Upload json to local state
    const uploadJSONLocally = async (file: File) => {
        const { withHeader } = await fileToBase64(file);
        setData("json", { fileName: file.name, dataUrl: withHeader });
        setState("idle");
    };

    // Upload background to remote storage
    const uploadBackgroundRemotely = async () => {
        setState("loading");
        let err = false;
        try {
            const res = await uploadRemotely(data);
            if (setSelectedId) setSelectedId(res?.id);
        } catch {
            err = true;
            setState("error");
        }
        if (!err) {
            close();
        }
    };

    return (
        <FormWizardTemplate
            stages={[
                {
                    isValid: true,
                    primaryButtonText: translate("Confirm"),
                    primaryButtonCallback: uploadBackgroundRemotely,
                    secondaryButtons: [{ buttonText: translate("Cancel"), onButtonPressed: close }],
                },
            ]}
            isSubmitting={state === "loading"}
            currentStage={0}
            title={existingId == null ? translate("Upload a background") : translate("Update background")}
        >
            {(isLoading || isFetching) && <LinearProgress style={{ position: "absolute", width: "100%", top: "0px", left: "0px" }} />}
            {(isLoading || isFetching) && <DisabledOverlay />}
            <StyledFieldLabel id="name">{translate("Name of map background")}</StyledFieldLabel>
            <TextField type="text" aria-labelledby="name" name="name" variant="outlined" value={data.name || ""} onChange={(e) => setData("name", e.target.value)} />

            <FromGapComponent />

            <StyledFieldLabel id="select-json">{translate("Select JSON")}</StyledFieldLabel>
            <StyledUploadContainer>
                <FileUploadComponent
                    PreviewComponent={() => null}
                    removeRequested={() => null}
                    loading={false}
                    fileName={data.json.fileName}
                    fileThumbnailUrl={null}
                    uploadFile={uploadJSONLocally}
                    uploadButtonText={translate("Select file")}
                    removeImageText={null}
                    className="file-upload"
                    persistFileSelectInput
                    ariaLabelledBy="select-model"
                    fileSizeRestriction={3}
                    translate={translate}
                />
            </StyledUploadContainer>

            <StyledFieldLabel id="select-thumbnail">{translate("Select thumbnail")}</StyledFieldLabel>
            <StyledThumbnailUploadContainer>
                <FileUploadComponent
                    PreviewComponent={ThumbnailUploadPreview}
                    removeRequested={() => null}
                    loading={false}
                    fileName={data.thumbnail.fileName}
                    fileThumbnailUrl={data.thumbnail.imageUrl}
                    uploadFile={uploadThumbnailLocally}
                    uploadButtonText={translate("Select file")}
                    removeImageText={null}
                    inputAccept="image/*"
                    className="file-upload"
                    persistFileSelectInput
                    ariaLabelledBy="select-thumbnail"
                    fileSizeRestriction={1}
                    translate={translate}
                />
            </StyledThumbnailUploadContainer>
            {state === "error" && (
                <>
                    <FromGapComponent />
                    <Body2 style={{ color: "red" }}>{translate("Something went wrong")}</Body2>
                </>
            )}
        </FormWizardTemplate>
    );
};
