/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react";
import { useRangeCalendarState } from "react-stately";
import { useRangeCalendar, useLocale } from "react-aria";
import { createCalendar } from "@internationalized/date";
import { borderRadius, fontSizes, Header2, InteractiveElement, styled } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AriaRangeCalendarProps } from "@react-types/calendar";
import { CalendarGrid } from "./CalendarGrid";
import { DateValue } from "./date-types";
import { YearSelector } from "./YearSelector";
import { DateFieldProps } from "./Datefield";

export function RangeCalendar(props: AriaRangeCalendarProps<DateValue> & { startFieldProps: DateFieldProps; endFieldProps: DateFieldProps }) {
    const { locale } = useLocale();
    const state = useRangeCalendarState({
        ...props,
        locale,
        createCalendar,
    });
    const [yearSelectorOpen, setYearSelectorOpen] = useState(false);

    const ref = useRef(null);
    const { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(props, state, ref);
    // remove unused Button props
    const { onPress, onFocusChange, ...restPrevButtonProps } = prevButtonProps;
    const { onPress: onPressNext, onFocusChange: onNextFocusChange, ...restNextButtonProps } = nextButtonProps;

    const { startFieldProps, endFieldProps } = props;

    return (
        <StyledRangeCalendar {...calendarProps} ref={ref}>
            <StyledHeader>
                <StyledDiv>
                    <StyledHeader2>{title}</StyledHeader2>
                    <StyledInteractiveElement onClick={() => setYearSelectorOpen(!yearSelectorOpen)}>
                        <FontAwesomeIcon icon={["fas", yearSelectorOpen ? "caret-up" : "caret-down"]} />
                    </StyledInteractiveElement>
                </StyledDiv>

                <div>
                    <StyledInteractiveElement {...restPrevButtonProps} onClick={() => state.focusPreviousPage()}>
                        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                    </StyledInteractiveElement>
                    <StyledInteractiveElement {...restNextButtonProps} onClick={() => state.focusNextPage()}>
                        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                    </StyledInteractiveElement>
                </div>
            </StyledHeader>
            {yearSelectorOpen ? (
                <YearSelector setYearSelectorOpen={setYearSelectorOpen} state={state} />
            ) : (
                <CalendarGrid state={state} startFieldProps={startFieldProps} endFieldProps={endFieldProps} />
            )}
        </StyledRangeCalendar>
    );
}

const StyledRangeCalendar = styled.div`
    width: 330px;
    border-radius: ${borderRadius.standard};
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
`;

const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    border-bottom: 1px solid ${(props) => props.theme.shades.lightBorder};
`;

const StyledHeader2 = styled(Header2)`
    font-size: ${fontSizes.medium};
    font-weight: 500;
`;

const StyledInteractiveElement = styled(InteractiveElement)`
    padding: 12px;
`;

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
`;
