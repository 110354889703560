import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { FormLabel, formGap } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { listItemTypes } from "./data-fields-types.constants";

interface ListItemPropertyTypeSelectorProps {
    onChange: (value: DataFieldListItemPropertyType) => void;
    value: DataFieldListItemPropertyType;
}

/**
 * A selector component for choosing a DataFieldListItemPropertyType for a list item. Does not keep its own state, value must be stored externally.
 */
export const ListItemPropertyTypeSelector: React.FunctionComponent<ListItemPropertyTypeSelectorProps> = ({ value, onChange }) => {
    const translate = useIventisTranslate();
    return (
        <>
            <FormLabel className="input-label space-within-content" style={{ marginTop: formGap }}>
                {translate(Content.map6.add_list_item_attribute.type)}
            </FormLabel>
            <TextField
                value={value ?? null}
                onChange={(value) => onChange(value.target.value as DataFieldListItemPropertyType)}
                select // tell TextField to render select
                data-testid="list-item-property-type"
            >
                {listItemTypes
                    .filter(({ type }) => type != null)
                    .map(({ name, type }) => (
                        <MenuItem data-testid={`list-item-property-type-${type}`} key={type} value={name}>
                            {translate(name)}
                        </MenuItem>
                    ))}
            </TextField>
        </>
    );
};
