/* eslint-disable import/no-extraneous-dependencies */
import { Slider } from "@mui/material";
import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { undefinedValueCheck } from "@iventis/utilities";
import { ValueSelector } from "./component.types";

export interface SliderSelectorProps extends ValueSelector<number> {
    minValue?: number;
    maxValue?: number;
    increment?: number;
    colour: string;
    throttleMs?: number;
    testId?: string;
}

/**
 * Component containing a gradient opacity slider
 * @param { number } startSliderValue - value that the slider will start off
 * @param { number } minValue - the minimum value the slider can be if a value is chosen which is below this the minimum value is used instead
 * @param { number } maxValue - the maximum value the slider can be if a value is chosen which is above this the maximum value is used instead
 * @param { number } increment - the increment amount on "step" is
 * @param { string } colour - colour value used for the slider is usually the colour of the layer
 * @param { (number) => void } changeStyle - a function to handle the value of the slider when it is changed
 */
export const SliderSelectorComponent: FunctionComponent<SliderSelectorProps> = ({ value, minValue = 0, maxValue = 100, increment = 1, colour, changeValue: changeStyle }) => {
    undefinedValueCheck(value, "Value selector can not accept undefined value.");

    if (value > maxValue || value < minValue) {
        throw new Error(`Slider Value is ${value} not in the range of ${minValue} to ${maxValue}`);
    }

    return (
        <StyledSliderContainer colour={colour} data-testid="slider-container">
            <StyledGradientSlider value={value} min={minValue} max={maxValue} onChange={(_, newValue: number) => changeStyle(newValue)} step={increment} data-testid="slider" />
        </StyledSliderContainer>
    );
};

export const StyledSliderContainer = styled.div<{ colour: string }>`
    height: 20px;
    flex-basis: 80%;
    border-radius: 10px;
    background-image: ${(props) => `linear-gradient(to right, rgba(0,0,0,0) 15%, ${props.colour});}`};
`;

export const StyledGradientSlider = styled(Slider)`
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 0px;
    color: transparent;
    height: 0px;
    .MuiSlider-thumb {
        height: 14px;
        width: 14px;
        background-color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
        border: 2px solid ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
    }
    .MuiSlider-thumb:hover {
        box-shadow: none;
    }
`;
