import { Asset } from "@iventis/domain-model/model/asset";
import { Model } from "@iventis/domain-model/model/model";
import { AxiosResponse } from "axios";
import { FilterFormat } from "@iventis/types/api.types";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { UploadModelData, UploadProjectImageData } from "@iventis/components";
import { isUrlBase64 } from "@iventis/utilities";
import {
    convertModelDataToLodFileAssetPostData,
    convertModelDataToLodFileAssetPutData,
    convertModelDataToThumbnailAssetPostData,
    convertModelDataToThumbnailAssetPutData,
    convertUploadModelDataToModelPostData,
    convertUploadModelDataToModelPutData,
    ModelAsset,
    AssetsPostRequest,
    AssetsPutRequest,
} from "@iventis/api-helpers";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import qs from "qs";
import { api } from "../api/api";

export const getModelsInProject = async ({ instanceName, projectId }: { instanceName: string; projectId: string }) => {
    const response = await api.get<Model[]>(`instances/${instanceName}/projects/${projectId}/models`);
    return response.data;
};

export const getAssetsInProject = async ({ filter, instanceName, projectId }: { filter: FilterFormat[]; instanceName: string; projectId: string }) => {
    const response = await api.get<Asset[]>(`instances/${instanceName}/projects/${projectId}/assets`, {
        params: { filters: JSON.stringify(filter) },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }) },
    });
    return response.data;
};

export const getAssets = async ({ filter }: { filter: FilterFormat[] }) => {
    const response = await api.post<Asset[], AxiosResponse<Asset[]>, FilterFormat[]>(`assets/filter`, filter);
    return response.data;
};

export const getAsset = async (assetId: string) => {
    const response = await api.get<Asset, AxiosResponse<Asset>, null>(`assets/${assetId}`);
    return response.data;
};

export const createAsset = async (asset: AssetsPostRequest) => api.post<Asset, AxiosResponse<Asset>, AssetsPostRequest>("assets", asset).then((res) => res.data);

export const updateAsset = async (asset: AssetsPutRequest) => api.put<Asset, AxiosResponse<Asset>, AssetsPutRequest>(`assets/${asset.id}`, asset).then((res) => res.data);

export const createModel = async (model: Model) => api.post<Model, AxiosResponse<Model>, Model>("models", model).then((res) => res.data);

export const updateModel = async (model: Model) => api.put<Model, AxiosResponse<Model>, Model>(`models/${model.id}`, model).then((res) => res.data);

export const getModels = async ({ filter }: { filter: FilterFormat[] }) => {
    const response = await api.post<Model[], AxiosResponse<Model[]>, FilterFormat[]>("models/filter", filter);
    return response.data;
};

export const getModel = async (modelId: string) => {
    const response = await api.get<Model, AxiosResponse<Model>, null>(`models/${modelId}`);
    return response.data;
};

export const getAssetsbyIds = async (ids: string[]) => {
    const filter: FilterFormat[] = [
        { fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.Model },
        { fieldName: "id", operator: IventisFilterOperator.In, value: ids },
    ];
    const response = await api.post<Asset[], AxiosResponse<Asset[]>, FilterFormat[]>("assets/filter", filter);
    return response.data;
};

// Calls the api and creates all required assets and the model.
export const createModelWithAssets = async (postModelData: UploadModelData): Promise<ModelAsset> => {
    // First upload the thumbnail to assets.
    const createthumbnailAsset = convertModelDataToThumbnailAssetPostData(postModelData);
    const thumbnailAsset = await createAsset(createthumbnailAsset);
    // Upload the files to assets (Currently we only have 1 file)
    const fileToCreate = convertModelDataToLodFileAssetPostData(postModelData);
    const fileAsset = await createAsset(fileToCreate);
    // Append the thumbnail and file asset ids to the model type.
    const modelToCreate = convertUploadModelDataToModelPostData(postModelData, thumbnailAsset.id, fileAsset.id);
    // Create the model
    const model = await createModel(modelToCreate);
    // Instead of making a request
    const modelAsset: ModelAsset = { ...model, thumbnail: thumbnailAsset };
    return modelAsset;
};

// Calls the api and updates all required assets and the model.
export const updateModelWithAssets = async (putModelData: UploadModelData, existingModel: ModelAsset): Promise<ModelAsset> => {
    const apiRequests: { model?: Promise<Model>; thumbnailAsset?: Promise<Asset>; modelAsset?: Promise<Asset> } = {};

    // Append the thumbnail and file asset ids to the model type.
    const modelToUpdate = convertUploadModelDataToModelPutData(putModelData, existingModel);
    // Update the model
    apiRequests.model = updateModel(modelToUpdate);
    // Update the thumbnail to assets.
    // Only update thumbnail if it has changed
    const createthumbnailAsset = convertModelDataToThumbnailAssetPutData(putModelData, existingModel);
    apiRequests.thumbnailAsset = updateAsset(createthumbnailAsset);
    // Update the files to assets (Currently we only have 1 file)
    if (isUrlBase64(putModelData.model.modelUrl)) {
        // Only update lod file if a modelUrl has changed
        const fileToUpdate = convertModelDataToLodFileAssetPutData(putModelData, existingModel.lods[0].files[0]);
        apiRequests.modelAsset = updateAsset(fileToUpdate);
    }
    const [model, newThumbnailAsset] = await Promise.all([apiRequests.model, apiRequests.thumbnailAsset, apiRequests.modelAsset]);
    // Instead of making a request
    const modelAsset: ModelAsset = { ...model, thumbnail: newThumbnailAsset ?? existingModel.thumbnail };
    return modelAsset;
};

export const updateImage = async (putImageData: UploadProjectImageData, existingImage: Asset) => {
    const params = { params: { height: putImageData.image.height, width: putImageData.image.width } };
    const res = await api.put<Asset, AxiosResponse<Asset>, AssetsPutRequest>(
        `assets/${existingImage.id}`,
        {
            ...existingImage,
            name: putImageData.image.fileName,
            dataUrl: putImageData.image.imageUrl,
            type: AssetType.Image,
            tags: [],
            metaData: {},
        },
        params
    );
    return res.data;
};

export const createImage = async (imageData: UploadProjectImageData) => {
    const params = { params: { height: imageData.image.height, width: imageData.image.width } };
    const res = await api.post<Asset, AxiosResponse<Asset>, AssetsPostRequest>(
        "assets",
        {
            id: imageData.id,
            name: imageData.image.fileName,
            dataUrl: imageData.image.imageUrl,
            type: AssetType.Image,
            tags: [],
            metaData: {},
        },
        params
    );
    return res.data;
};

// Gets the thumbnail URL for a given a model id
export const getModelThumbnailUrl = async (modelId: string, imageGetter: (assetUrl: string, assetSignature: string) => string) => {
    const model = await getModel(modelId);
    const thumbnailAsset = await getAsset(model.thumbnailAssetId);
    return imageGetter(thumbnailAsset.assetUrl, thumbnailAsset.authoritySignature);
};

export const deleteModel = async (modelId: string) => api.delete(`models/${modelId}`);

export const deleteAsset = async (assetId: string) => api.delete(`assets/${assetId}`);
