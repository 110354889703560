import { inlineTextIconMargin } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Chip, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
/**
 * A component to display the tag of a sitemap, if a background colour is not provided, it will fallback to the a theme colour
 */
export const TagComponent: FunctionComponent<{ tag: string; backgroundColour?: string; className?: string }> = ({ tag, backgroundColour, className }) => {
    const theme = useTheme<Theme>();
    return (
        <Chip
            className={className}
            label={tag}
            style={{
                // If no colour is set, fallback to one of our theme colours
                backgroundColor: backgroundColour ?? theme.tertiaryColors.primary,
                color: theme.typographyColors.blank,
                marginRight: inlineTextIconMargin,
                fontWeight: 500,
                letterSpacing: "0.2em",
                height: "24px",
            }}
        />
    );
};
