/* eslint-disable react/destructuring-assignment */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { isValueValid } from "../lib/internal-columns";
import { StyledTextCell } from "../styles/data-table.styles";
import { ListItemCellComponentProps } from "../types/data-table.types";

/**
 * Used as cellRenderer for data table column of type "list-item"
 */
export const ListItemCellComponent: FunctionComponent<ListItemCellComponentProps> = (props) => {
    let {
        componentParams: { listItems },
    } = props;
    const {
        value,
        required,
        node,
        componentParams: { validate = () => true },
        colDef,
    } = props;

    if (typeof listItems === "function") {
        listItems = [];
    }

    // Value could either be the ID of the list item or the list item itself or null
    let listItemId;

    switch (typeof value) {
        case "string":
            listItemId = value;
            break;
        default:
            if (value == null) listItemId = "";
            else listItemId = value.id;
    }

    const columnEditable = props.colDef.editable;
    const rowEditable = props.data?.canEdit;
    // Only an explicit false value should prevent edits
    const canEdit = !(columnEditable === false || rowEditable === false);

    const listItemName = listItems.find((item) => item.id === listItemId)?.name;

    return (
        <CellWithDropDown
            dataTestId={`data-table-cell-row-${node.data?.name ?? node.data?.groupDisplayValue ?? ""}-column-${colDef.headerName}`}
            className={isValueValid(node, value, required) && validate(node) ? "valid" : "invalid"}
            showCaret={canEdit}
        >
            {listItemName ? <span>{listItemName}</span> : value?.name ? <span>{value?.name}</span> : ""}
        </CellWithDropDown>
    );
};

export const CellWithDropDown: FunctionComponent<{ children: React.ReactNode; showCaret?: boolean; className?: string; dataTestId?: string }> = ({
    children,
    showCaret = true,
    className,
    dataTestId,
}) => (
    <StyledListItemCell data-testid={dataTestId} className={className}>
        <span>{children}</span>
        {showCaret && <FontAwesomeIcon icon={["fas", "caret-down"]} />}
    </StyledListItemCell>
);

const StyledListItemCell = styled(StyledTextCell)<{ theme?: Theme }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    svg {
        color: ${({ theme }: { theme: Theme }) => theme.dataTableTheme.typography.subdued};
    }
`;
