import { DefaultSubscriptionPlan } from "@iventis/domain-model/model/defaultSubscriptionPlan";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { api } from "../api/api";

export const SUBSCRIPTION_PLAN_QUERY_KEY = "subscription-plans";

export const getSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
    const response = await api.get<SubscriptionPlan[]>(`subscription-plans`);
    return response.data;
};

export const getSubscriptionPlansCounts = async (instanceName: string): Promise<{ [key: string]: number }> => {
    const response = await api.get<{ [key: string]: number }>(`subscription-plans/counts?instanceName=${instanceName}`);
    return response.data;
};

export const createSubscriptionPlan = async (plan: SubscriptionPlan) => {
    const res = await api.post<SubscriptionPlan>(`subscription-plans`, plan);
    return res.data;
};

// This will update the plan but not the pricing
const updateSubscriptionPlan = async (plan: SubscriptionPlan) => {
    await api.put<SubscriptionPlan>(`subscription-plans`, plan);
};

const updateSubscriptionPlanPrice = async (planId: string, planPrice: SubscriptionPlanPrice) => {
    const res = await api.post<SubscriptionPlanPrice>(`subscription-plans/${planId}/subscription-plan-prices`, planPrice);
    return res.data;
};

export const updateSubscriptionPlanAndPricing = async (plan: SubscriptionPlan, existingPrices: SubscriptionPlanPrice[]): Promise<SubscriptionPlan> => {
    await updateSubscriptionPlan(plan);

    const priceUpdates: Promise<SubscriptionPlanPrice>[] = plan.subscriptionPlanPrices.reduce(
        // Don't add any existing prices
        (cum, planPrice) => (existingPrices.some((p) => planPrice.id === p.id) ? cum : [...cum, updateSubscriptionPlanPrice(plan.id, planPrice)]),
        []
    );

    const newPrices = await Promise.all(priceUpdates);

    return { ...plan, subscriptionPlanPrices: [...existingPrices, ...newPrices] };
};

export const getPricesForSubscriptionPlan = async (planId: string, projectId?: string) => {
    const prices = await api.get<SubscriptionPlanPrice[]>(`/subscription-plans/${planId}/subscription-plan-prices`, projectId && { params: { projectId } });
    return prices.data;
};

export const getDefaultPlan = async () => {
    const response = await api.get<DefaultSubscriptionPlan>("default-subscription-plan");
    return response.data;
};

export const setDefaultPlan = async (defaultPlan: DefaultSubscriptionPlan) => {
    const res = await api.put<DefaultSubscriptionPlan>("default-subscription-plan", defaultPlan);
    return res.data;
};
