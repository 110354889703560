/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColDef, IRowNode } from "@ag-grid-community/core";
import { ObjectEntries } from "@iventis/types/useful.types";
import { createMachine, assign } from "xstate";
import { RowNetworkStatus, HasDataTableValueChanged, IventisRowNode, IventisTableData } from "../types/data-table.types";

/*
    CONTEXT
*/

export enum DataTableOperations {
    Insert = "insert",
    InsertColumn = "insertColumn",
    Edit = "edit",
    Delete = "delete",
}

export type QueueInsert<TData extends IventisTableData = IventisTableData> = { operation: DataTableOperations.Insert; node: IventisRowNode<TData> };
// eslint-disable-next-line no-unused-vars
export type QueueInsertColumn<TData extends IventisTableData = IventisTableData> = { operation: DataTableOperations.InsertColumn; column: ColDef<TData> };
export type QueueEdit<TData extends IventisTableData = IventisTableData> = {
    operation: DataTableOperations.Edit;
    node: IventisRowNode<TData>;
    update: ObjectEntries<TData>;
};
export type QueueDelete<TData extends IventisTableData = IventisTableData> = { operation: DataTableOperations.Delete; row: IventisRowNode<TData>["data"] };
export type QueueContext<TData extends IventisTableData = IventisTableData> = QueueInsert<TData> | QueueEdit<TData> | QueueDelete<TData> | QueueInsertColumn<TData>;

export type GridMachineContext<TData extends IventisTableData = IventisTableData> = {
    queue: QueueContext<TData>[];
    hasValueChanged: HasDataTableValueChanged<TData>;
};

/*
    EVENTS
*/

enum Events {
    EDIT = "EDIT",
    DELETE_REQUEST = "DELETE_REQUEST",
    INSERT = "INSERT",
    COPY = "COPY",
    DELETE_CONFIRMED = "DELETE_CONFIRMED",
    DELETE_CANCELLED = "DELETE_CANCELLED",
    INSERT_COLUMN = "INSERT_COLUMN",
}

type EventsWithNoPayload<T> = { type: T };

type EditEvent<TData extends IventisTableData> = {
    type: "EDIT";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: { node: IRowNode<TData>; update: ObjectEntries<TData>; oldValue: any }; // The new value assigned to the cell/cells
};

type InsertEvent<TData extends IventisTableData> = {
    type: "INSERT";
    payload: IRowNode<TData>;
};

// eslint-disable-next-line no-unused-vars
type InsertColumnEvent<TData extends IventisTableData> = {
    type: "INSERT_COLUMN";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: { column: any };
};

export type DeleteConfirmedEvent<TData extends IventisTableData> = {
    type: "DELETE_CONFIRMED";
    payload: TData;
};

type DeleteRequestedEvent<TData extends IventisTableData> = {
    type: "DELETE_REQUEST";
    payload: TData;
};

export type GridMachineEvents<TData extends IventisTableData> =
    | EditEvent<TData>
    | InsertEvent<TData>
    | InsertColumnEvent<TData>
    | DeleteConfirmedEvent<TData>
    | DeleteRequestedEvent<TData>
    | EventsWithNoPayload<"DELETE_CANCELLED">
    | EventsWithNoPayload<"DESELECT">
    | EventsWithNoPayload<"COPY">;

/*
    MACHINE
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dataTableMachine = <TData extends IventisTableData>() =>
    createMachine(
        {
            predictableActionArguments: true,
            id: "grid",
            initial: "idle",
            schema: {
                context: {} as GridMachineContext<TData>,
                events: {} as GridMachineEvents<TData>,
                services: {} as {
                    deleteRowAsync: { data: TData };
                },
            },
            // eslint-disable-next-line no-undef
            tsTypes: {} as import("./data-table-machine.typegen").Typegen0,
            context: {
                queue: [],
                hasValueChanged: () => true,
            },
            on: {
                // Events while fetching
                INSERT: { actions: ["enqueueInsert"] },
                EDIT: [{ cond: "isIdenticalRequest", actions: [] }, { cond: "isSortAppliedToColumn", actions: ["enqueueEdit", "restoreSort"] }, { actions: "enqueueEdit" }],
                COPY: { actions: ["copy"] },
                /*
                There are a number of commented out conditions.
                This is because a cell selection now implicitly selects a row too, so cell checks further to a row check are no longer run
            */
                DELETE_REQUEST: [
                    { cond: "isSelectionUnSaved", actions: ["deleteRow"] },
                    { cond: "isSelectionSaved", actions: ["enqueueDelete"] },
                    // Deleting cells edits the row (or rows), so here we enqueue an edit operation
                    // { cond: "isSelectionRange", actions: ["enqueueEdit", "deleteCells"] },
                    // { cond: "isSelectionSingleCell", actions: ["enqueueEdit", "deleteCells"] },
                ],
            },
            states: {
                idle: {
                    id: "idle",
                    on: {
                        // Events while idle
                        INSERT_COLUMN: { target: "insertingColumn", actions: ["enqueueInsertColumn"] },
                        INSERT: { target: "insertingRow", actions: ["enqueueInsert"] },
                        EDIT: [
                            { cond: "isIdenticalRequest", actions: [] },
                            { cond: "isSortAppliedToColumn", target: "editingRow", actions: ["enqueueEdit", "restoreSort"] },
                            { target: "editingRow", actions: ["enqueueEdit"] },
                        ],
                        DELETE_REQUEST: [
                            { cond: "isSelectionUnSaved", target: "deletingRow" },
                            { cond: "isSelectionSaved", target: "deletingRow", actions: ["enqueueDelete"] },
                        ],
                    },
                },
                editingRow: {
                    id: "editingRow",
                    exit: ["refreshIndex"], // Everytime we edit a row, we want to refresh the index to keep in sync with the order. This ensures that
                    invoke: {
                        src: "editRowAsync",
                        onDone: [
                            { cond: "isLastInQueue", target: "idle", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isDeleteNextInQueue", target: "deletingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isEditNextInQueue", target: "editingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isInsertNextInQueue", target: "insertingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                        ],
                        onError: {
                            target: "failure",
                            actions: ["onNetworkRequestError", "dequeue"],
                        },
                    },
                },
                deletingRow: {
                    id: "deletingRow",
                    initial: "confirmingDelete",
                    states: {
                        confirmingDelete: {
                            entry: ["onDeleteRequested"],
                            exit: ["onDeleteHandled"],
                            on: {
                                DELETE_CONFIRMED: [
                                    { cond: "isSelectionSaved", target: "deleting" },
                                    { cond: "isSelectionUnSaved", target: "#idle", actions: ["deleteRow"] },
                                ],
                                DELETE_CANCELLED: { target: "#idle", actions: ["dequeue"] },
                                // Block further requests
                                INSERT: {},
                                EDIT: {},
                                DELETE_REQUEST: {},
                            },
                        },
                        deleting: {
                            invoke: {
                                src: "deleteRowAsync",
                                onDone: [
                                    { cond: "isLastInQueue", target: "#idle", actions: ["deleteRow", "onNetworkRequestSuccess", "refreshGridData", "dequeue"] },
                                    { cond: "isDeleteNextInQueue", target: "#deletingRow", actions: ["deleteRow", "onNetworkRequestSuccess", "refreshGridData", "dequeue"] },
                                    { cond: "isEditNextInQueue", target: "#editingRow", actions: ["deleteRow", "onNetworkRequestSuccess", "refreshGridData", "dequeue"] },
                                    { cond: "isInsertNextInQueue", target: "#insertingRow", actions: ["deleteRow", "onNetworkRequestSuccess", "refreshGridData", "dequeue"] },
                                    { cond: "isInsertColumnNextInQueue", target: "#insertingColumn", actions: ["onNetworkRequestSuccess", "dequeue"] },
                                ],
                                onError: {
                                    target: "#idle",
                                    actions: ["onNetworkRequestError", "dequeue"],
                                },
                            },
                        },
                    },
                },
                insertingRow: {
                    id: "insertingRow",
                    invoke: {
                        src: "insertRowAsync",
                        onDone: [
                            { cond: "isLastInQueue", target: "idle", actions: ["onNetworkRequestSuccess", "dequeue", "refreshIndex"] },
                            { cond: "isDeleteNextInQueue", target: "deletingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isEditNextInQueue", target: "editingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isInsertNextInQueue", target: "insertingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isInsertColumnNextInQueue", target: "insertingColumn", actions: ["onNetworkRequestSuccess", "dequeue"] },
                        ],
                        onError: {
                            target: "failure",
                            actions: ["onNetworkRequestError", "dequeue"],
                        },
                    },
                },
                insertingColumn: {
                    id: "insertingColumn",
                    invoke: {
                        src: "insertColumnAsync",
                        onDone: [
                            { cond: "isLastInQueue", target: "idle", actions: ["onNetworkRequestSuccess", "dequeue", "refreshIndex"] },
                            { cond: "isDeleteNextInQueue", target: "deletingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isEditNextInQueue", target: "editingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isInsertNextInQueue", target: "insertingRow", actions: ["onNetworkRequestSuccess", "dequeue"] },
                            { cond: "isInsertColumnNextInQueue", target: "insertingColumn", actions: ["onNetworkRequestSuccess", "dequeue"] },
                        ],
                        onError: {
                            target: "failure",
                            actions: ["onNetworkRequestError", "dequeue"],
                        },
                    },
                },
                failure: {
                    id: "failure",
                    on: {
                        // Handle failed state
                    },
                },
            },
        },
        {
            actions: {
                // queueing
                enqueueInsert: assign({
                    queue: (context, event) => {
                        const node = event.payload;
                        return context.queue.concat([{ operation: DataTableOperations.Insert, node }]);
                    },
                }),
                enqueueEdit: assign({
                    queue: (context, event) => {
                        if (event.type === Events.EDIT) {
                            const { node, update } = event.payload;
                            return context.queue.concat([{ operation: DataTableOperations.Edit, node, update }]);
                        }
                        return context.queue;
                    },
                }),
                enqueueDelete: assign({
                    queue: (context, event) => context.queue.concat([{ operation: DataTableOperations.Delete, row: event.payload }]),
                }),
                enqueueInsertColumn: assign({
                    queue: (context, event) => context.queue.concat([{ operation: DataTableOperations.InsertColumn, column: event.payload.column }]),
                }),
                dequeue: assign({ queue: (context) => context.queue.slice(1) }),
            },
            guards: {
                isSelectionUnSaved: (context, event) => event.payload.networkStatus === RowNetworkStatus.PendingCreation,
                isSelectionSaved: (context, event) => event.payload.networkStatus !== RowNetworkStatus.PendingCreation,
                isLastInQueue: (context) => context.queue.length === 1,
                isInsertColumnNextInQueue: (context) => context.queue[1]?.operation === DataTableOperations.InsertColumn,
                isInsertNextInQueue: (context) => context.queue[1]?.operation === DataTableOperations.Insert,
                isEditNextInQueue: (context) => context.queue[1]?.operation === DataTableOperations.Edit,
                isDeleteNextInQueue: (context) => context.queue[1]?.operation === DataTableOperations.Delete,
                isIdenticalRequest: (context: GridMachineContext<TData>, event: EditEvent<TData>) => {
                    const { queue } = context;
                    const lastEdit = queue?.[queue?.length - 1] as QueueEdit<TData>;
                    const [lastEditColumn, lastEditValue] = lastEdit?.update ?? [];
                    const { update, node } = event.payload;
                    const [columnId, newValue] = update; // Only supporting one cell update ata time
                    // If it 's the first edit made on the tabel, a different column to the last or a different row to the last, then it is not identical
                    if (lastEdit == null || lastEditColumn !== columnId || node.data.id !== lastEdit.node.data.id) {
                        return false;
                    }

                    const res = !context.hasValueChanged(lastEditValue, newValue, columnId as keyof TData, node);
                    return res;
                },
            },
        }
    );

export { Events as machineEvents };
