/** Reads a {@link File} and returns a string */
export const fileToString = (file: File): Promise<string> =>
    new Promise((res) => {
        const reader = new FileReader();

        reader.onload = () => {
            // Get result from reader
            const { result } = reader;

            if (typeof result === "string") {
                res(result);
            } else {
                throw new Error("Failed to parse file");
            }
        };

        // Read file
        reader.readAsText(file);
    });
