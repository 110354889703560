/* eslint-disable no-underscore-dangle */
import { createContext, useContext } from "react";
import { interpret, Interpreter } from "xstate";
import { xstateDevTools } from "@iventis/utilities/src/environment-variables";
import { BehaviorSubject } from "rxjs";
import { useObservableValue } from "@iventis/utilities";
import { RemoteUploadEvents, remoteUploadMachine, RemoteUploadState } from "./remote-upload-machine";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoteUploadGroup = Map<string, Interpreter<RemoteUploadState, any, RemoteUploadEvents, any, any>>;

export class RemoteUpload {
    private _uploads$ = new BehaviorSubject<RemoteUploadGroup>(new Map());

    get uploads$() {
        return this._uploads$;
    }

    get uploads() {
        return this.uploads$.getValue();
    }

    public upload(id: string, url: string, file: File, successCallback: (file: File) => void) {
        const machine = interpret(
            remoteUploadMachine(id)
                .withContext({
                    id,
                    url,
                    file,
                    progress: null,
                })
                .withConfig({
                    services: {
                        successCallback: async (context, event) => {
                            await successCallback(event.response);
                        },
                    },
                }),
            { devTools: xstateDevTools }
        );
        machine.start();
        this.uploads.set(id, machine);
        this.uploads$.next(this.uploads);
    }

    public remove(id: string) {
        this.uploads.delete(id);
        this.uploads$.next(this.uploads);
    }
}

export const RemoteUploadContext = createContext<RemoteUpload>(null);

export const useRemoteUpload = () => {
    const context = useContext(RemoteUploadContext);
    const uploads = useObservableValue(context.uploads$, context.uploads$.value);
    return { uploads, context };
};
