import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { createContext } from "react";
import { PostImportedGeoJsonMapObject } from "./layer-geojson-types";

interface IImportGeoJsonContext {
    projectAttributes: DataField[];
    getListItem: (dataFieldId: string) => Promise<DataFieldListItem[]>;
    postMapObjects: (mapObjects: PostImportedGeoJsonMapObject[], overwriteExistingObjects: boolean) => Promise<void>;
}

const doNothingImportGeoJsonContext: IImportGeoJsonContext = {
    projectAttributes: [],
    getListItem: () => Promise.resolve([]),
    postMapObjects: () => Promise.resolve(),
};

export const ImportGeoJsonContext = createContext<IImportGeoJsonContext>(doNothingImportGeoJsonContext);
