import { Theme } from "@emotion/react";
import { DataTableTheme } from "./data-table-theme";

export const greyTheme = (theme: Theme, overrides?: Partial<DataTableTheme["dataTableTheme"]>): DataTableTheme => ({
    dataTableTheme: {
        typography: {
            core: theme.typographyColors.core,
            subdued: theme.typographyColors.subdued,
            moreSubdued: theme.typographyColors.evenMoreSubdued,
            lessSubdued: theme.typographyColors.lessSubdued,
        },
        background: theme.secondaryColors.blank,
        root: {
            border: "none",
        },
        header: {
            background: theme.tertiaryColors.headerBackground,
            bottomBorder: "none",
        },
        selected: {
            rowBackgroundColour: theme.tertiaryColors.selectionBackgroundColour,
            rowBorder: "0",
            cellBackgroundColour: theme.tertiaryColors.selectionBackgroundColour,
            cellBorderColour: theme.tertiaryColors.primary,
            cellRangeBackgroundColour: theme.tertiaryColors.selectionBackgroundColour,
        },
        unSelected: {
            borderColor: theme.typographyColors.blank,
        },
        alternateRows: {
            backgroundColour: {
                firstRow: theme.typographyColors.blank,
                secondRow: theme.secondaryColors.light20,
            },
        },
        buttons: {
            primary: {
                background: theme.primaryColors.focusAccent,
                text: theme.typographyColors.blank,
            },
            secondary: {
                background: theme.typographyColors.blank,
                text: theme.primaryColors.focusAccent,
            },
        },
        checkBox: {
            checked: {
                tick: { color: theme.typographyColors.blank, weight: "fas" },
                background: theme.primaryColors.subdued70,
                border: "none",
            },
            unchecked: {
                background: theme.typographyColors.blank,
                border: "none",
            },
            cell: {
                colour: theme.primaryColors.focus,
            },
        },
        popover: {
            boxShadow: `0 0 5px 1px ${theme.typographyColors.subdued}`,
            borderRadius: "4px",
        },
        validation: {
            invalidCellColour: theme.primaryColors.focus,
        },
        icons: {
            color: `${theme.primaryColors.subdued70}`,
        },
        chips: {
            background: theme.secondaryColors.lightGrey,
        },
        ...overrides,
    },
});
