/* eslint-disable react/jsx-props-no-spreading */
import { AutocompleteWithLoading, ChipType, CustomDialog, FormWizardTemplate, StyledChip, UploadIconForm } from "@iventis/components";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { NodeSortAction } from "@iventis/domain-model/model/nodeSortAction";
import { Project } from "@iventis/domain-model/model/project";
import { Body1, Header3, Subtitle, sectionalMargin, StyledIconButton, muiInputFormsCSS, styled, StyledFieldLabel } from "@iventis/styles";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { defaultSkeletonMixin, iconButtonSize, inputHeight } from "@iventis/styles/src/atomic-rules";
import { convertPascalToSentence, useMaxElementCount } from "@iventis/utilities";
import { TEMPLATE_3D_TAG } from "@iventis/layer-styles/src/layer-template-query";
import {
    createInitialState,
    findNode,
    getNodeNestedLevel,
    NodeComponentProps,
    TreeBrowserComponent,
    UnionOfNodes,
    useTreeBrowser,
    useTreeBrowserInitialise,
} from "@iventis/tree-browser";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { RepoContainer } from "./assets-repo-template";
import { CategoryNode, CategoryRootNode, CategoryTreeContext, RecommendedAssetType, isRecommendedCategoryType, parseRecommendedAssetType, AdminAssetType } from "./category-types";
import { categoryNodeServices, CREATE_NEW_CATEGORY_ID, getCategories, getRecommendedCategories, updateCategory, uploadCategoryIcon } from "./category-services";
import { RecommendedCategory } from "./recommended-category-node";
import { InstanceSelectorComponent } from "./instance-selector";
import { SimpleProjectSelectorComponent } from "./project-selector";
import { getAffectedCategories, getCategoryThumbnail } from "./category-helpers";
import { api } from "../api/api";

const CATEGORY_THUMBNAIL_QUERY_KEY = "category-thumbnail";

export const CategoryRepositoryComponent: FunctionComponent = () => {
    const [assetType, setAssetType] = useState<{ id: AdminAssetType; name: string }>({ id: AssetType.Model, name: "Model" });
    const [getProjectCategoriesLoading, setGetProjectCategoriesLoading] = useState<boolean>(false);
    const [error, setError] = useState(false);

    // Instance and project selection
    const [instance, setInstance] = useState<string>(null);

    const handleProjectSelected = async (project: Project) => {
        setGetProjectCategoriesLoading(true);
        // Get the recommended categories and the categories for the project
        const [recommendedCategories, categories] = await Promise.all([
            getRecommendedCategories(assetType.id, instance, project.id),
            getCategories(parseRecommendedAssetType(assetType.id)),
        ]);
        if (categories == null) {
            setError(true);
            setGetProjectCategoriesLoading(false);
        } else {
            // Update the tree with the project id, instance name and categories
            treeBrowserContext.updateNode({
                ...tree,
                instanceName: instance,
                projectId: project.id,
                childNodes: categories.map((category) => ({
                    ...category,
                    parentId: tree.id,
                    sourceId: category.id,
                    type: "RecommendedCategory",
                    childNodes: [],
                    checked: recommendedCategories.some((rc) => rc.id === category.id),
                    projectId: project.id,
                    instanceName: instance,
                })),
            });
        }
        setGetProjectCategoriesLoading(false);
    };

    // Get Skeleton Loading stuff
    const [containerRef, count] = useMaxElementCount<HTMLDivElement>(1, 44, { columns: 1 });

    const [{ tree, expandedNodeIds, isLoadingTree: isLoading }, treeBrowserContext] = useTreeBrowserInitialise(
        createInitialState<CategoryRootNode>({
            mainNodeId: assetType.id,
            treeId: assetType.id,
            sort: { field: "order", action: NodeSortAction.Asc, name: "Order", sort: (nodes) => nodes.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) },
        }),
        categoryNodeServices,
        null
    );

    const assetTypeOptions = useMemo(() => {
        const assetTypes = Object.keys(AssetType).map((key) => ({
            id: AssetType[key],
            name: convertPascalToSentence(AssetType[key]),
        }));
        const recommendedAssetTypes = Object.keys(RecommendedAssetType).map((key) => ({
            id: RecommendedAssetType[key],
            name: convertPascalToSentence(RecommendedAssetType[key]),
        }));
        return [...assetTypes, ...recommendedAssetTypes];
    }, []);

    const switchAssetType = (value: { id: AdminAssetType; name: string }) => {
        treeBrowserContext.setTree({
            id: value.id,
            sourceId: value.id,
            name: value.id,
            type: "CategoryRoot",
            assetType: parseRecommendedAssetType(value.id),
            recommended: isRecommendedCategoryType(value.id),
            iconAssetId: null,
            childNodes: [],
        });
        setAssetType(value);
    };

    return (
        <CategoryTreeContext.Provider value={treeBrowserContext}>
            <RepoContainer>
                <CategoriesHeader>
                    <Header3>Categories</Header3>
                </CategoriesHeader>
                <StyledOptionsRow>
                    <SelectorContainer>
                        <Subtitle>Asset category</Subtitle>
                        <AutocompleteWithLoading
                            disableClearable
                            value={assetType}
                            options={assetTypeOptions}
                            onChange={(value) => switchAssetType(value)}
                            getOptionLabel={(value) => value.name}
                            loadingOptions={false}
                        />
                    </SelectorContainer>
                    {!tree?.recommended && (
                        <StyledChip
                            label={
                                <>
                                    <FontAwesomeIcon icon={["far", "plus"]} /> Add category
                                </>
                            }
                            disabled={isLoading}
                            chipType={ChipType.PrimaryButton}
                            onClick={() =>
                                treeBrowserContext.addNodesLocally([
                                    { ...tree, id: CREATE_NEW_CATEGORY_ID, sourceId: CREATE_NEW_CATEGORY_ID, type: "Category", name: "", parentId: assetType.id, childNodes: [] },
                                ])
                            }
                        />
                    )}
                    {tree?.recommended && (
                        <SelectorContainer>
                            <Subtitle>Instance</Subtitle>
                            <InstanceSelectorComponent instance={instance} setInstance={setInstance} />
                        </SelectorContainer>
                    )}
                </StyledOptionsRow>
                <StyledOptionsRow>
                    {tree?.recommended && (
                        <SelectorContainer>
                            <Subtitle>Project</Subtitle>
                            <SimpleProjectSelectorComponent instance={instance} onProjectSelected={handleProjectSelected} />
                        </SelectorContainer>
                    )}
                </StyledOptionsRow>

                <Divider />
                <CategoriesContainer ref={containerRef}>
                    {isLoading ||
                        (getProjectCategoriesLoading &&
                            Array(count)
                                .fill(null)
                                // eslint-disable-next-line react/no-array-index-key
                                .map((_, index) => <SkeletonRow marginLeft={0} key={index} />))}
                    {error && <p>There was an error loading the categories</p>}
                    {!isLoading && !getProjectCategoriesLoading && (
                        <TreeBrowserComponent components={{ Category, RecommendedCategory }} node={tree} openNodes={expandedNodeIds} displayRootNode={false} />
                    )}
                    {tree?.childNodes?.length === 0 && !error && (tree.recommended ? <p>Please search for a project</p> : <p>No categories found</p>)}
                </CategoriesContainer>
            </RepoContainer>
        </CategoryTreeContext.Provider>
    );
};

const StyledOptionsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${sectionalMargin};
    width: 100%;
`;

const CategoriesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .MuiTextField-root {
        flex-grow: 1;
    }
`;

const Category: FunctionComponent<NodeComponentProps<CategoryNode>> = ({ node: category }) => {
    const queryClient = useQueryClient();

    const creatingNew = category.id === CREATE_NEW_CATEGORY_ID;
    const [editing, setEditing] = React.useState<boolean>(creatingNew);
    const [{ expandedNodeIds, loadingNodeIds, tree }, treeBrowserContext] = useTreeBrowser(CategoryTreeContext, category.id, "expandedNodeIds", "loadingNodeIds", "tree");

    const [modal, setModal] = useState<"icon-editor" | "order-editor" | "parent-editor" | null>(null);

    const loading = loadingNodeIds.includes(category.id);
    const expanded = expandedNodeIds.includes(category.id);

    const levelsDeep = getNodeNestedLevel(tree, category);
    const isTopLevel = levelsDeep === 0;

    const { data: categoryThumbnail } = useQuery([CATEGORY_THUMBNAIL_QUERY_KEY, category.id], () => getCategoryThumbnail(category), { enabled: category.iconAssetId != null });

    return (
        <StyledCategoryRowContainer marginLeft={levelsDeep}>
            <CustomDialog open={modal != null} onClose={() => setModal(null)}>
                {modal === "icon-editor" && (
                    <UploadIconForm
                        close={() => setModal(null)}
                        uploadRemotely={async (uploadIconData) => {
                            const response = await uploadCategoryIcon(category, uploadIconData);
                            const updatedCategory = { ...category, iconAssetId: response.id };
                            treeBrowserContext.updateNodeLocally(updatedCategory);
                            const thumbnail = await getCategoryThumbnail(updatedCategory);
                            queryClient.setQueryData([CATEGORY_THUMBNAIL_QUERY_KEY, category.id], thumbnail);
                            return response;
                        }}
                        acceptedFileInput="image/svg+xml"
                        setSelectedId={() => null}
                    />
                )}
                {modal === "order-editor" && (
                    <CategoryOrderForm
                        existingOrder={category.order ?? null}
                        submit={(order) => {
                            const updatedCategory = { ...category, order };
                            const siblings = treeBrowserContext.getNode(category.parentId).childNodes;
                            const affectedCategories = getAffectedCategories(siblings, updatedCategory, category.order);
                            treeBrowserContext.updateNodesLocally(affectedCategories);
                            Promise.all(affectedCategories.map(updateCategory));
                        }}
                        close={() => setModal(null)}
                    />
                )}
                {modal === "parent-editor" && (
                    <CategoryParentForm
                        category={category}
                        submit={(newParentId) => {
                            const parentNode = treeBrowserContext.getNode(newParentId);
                            treeBrowserContext.moveNodes([{ ...category, order: parentNode.childNodes.length + 1 }], newParentId);
                        }}
                        close={() => setModal(null)}
                    />
                )}
            </CustomDialog>
            <StyledCategoryRow>
                <div className="category-content">
                    <div className="icon-area">
                        {isTopLevel && (
                            <StyledIconButton onClick={() => treeBrowserContext.toggleExpandNode(category)} size="small">
                                <FontAwesomeIcon icon={loading ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="sm" spin={loading} />
                            </StyledIconButton>
                        )}
                    </div>
                    <StyledIconButton onClick={() => setModal("icon-editor")}>
                        {typeof categoryThumbnail === "string" ? (
                            <img className="category-icon" src={categoryThumbnail} alt="category icon" />
                        ) : (
                            <FontAwesomeIcon icon={["far", "circle-plus"]} />
                        )}
                    </StyledIconButton>
                    {editing ? (
                        <CategoryNameEditor
                            category={category}
                            onConfirm={(localCategory) => {
                                setEditing(false);
                                if (creatingNew) {
                                    treeBrowserContext.addNode({ ...localCategory });
                                    treeBrowserContext.removeNodesLocally([category.id]);
                                } else {
                                    treeBrowserContext.updateNode(localCategory);
                                }
                            }}
                            onCancel={() => {
                                setEditing(false);
                                if (creatingNew) {
                                    treeBrowserContext.removeNodesLocally([category.id]);
                                }
                            }}
                        />
                    ) : (
                        <Body1 style={{ fontWeight: 600 }}>{category?.name}</Body1>
                    )}
                </div>

                {category.name !== TEMPLATE_3D_TAG && (
                    <>
                        {!editing && (
                            <div className="category-actions">
                                {isTopLevel && (
                                    <StyledIconButton
                                        onClick={() =>
                                            treeBrowserContext.addNodesLocally([
                                                {
                                                    ...category,
                                                    id: CREATE_NEW_CATEGORY_ID,
                                                    sourceId: CREATE_NEW_CATEGORY_ID,
                                                    name: "",
                                                    parentId: category.id,
                                                    childNodes: [],
                                                    iconAssetId: null,
                                                },
                                            ])
                                        }
                                        size="small"
                                    >
                                        <FontAwesomeIcon icon={["far", "plus"]} />
                                    </StyledIconButton>
                                )}
                                <StyledIconButton onClick={() => setEditing(true)} size="small">
                                    <FontAwesomeIcon icon={["far", "edit"]} />
                                </StyledIconButton>
                                <StyledIconButton onClick={() => setModal("order-editor")} size="small">
                                    <FontAwesomeIcon icon={["fas", "sort"]} />
                                </StyledIconButton>
                                <StyledIconButton onClick={() => setModal("parent-editor")} size="small">
                                    <FontAwesomeIcon icon={["far", "up-down-left-right"]} />
                                </StyledIconButton>
                                <StyledIconButton
                                    onClick={() => (creatingNew ? treeBrowserContext.removeNodesLocally([category.id]) : treeBrowserContext.deleteNodes([category]))}
                                    size="small"
                                >
                                    <FontAwesomeIcon icon={["far", "trash"]} />
                                </StyledIconButton>
                            </div>
                        )}
                    </>
                )}
            </StyledCategoryRow>
            <Divider />
        </StyledCategoryRowContainer>
    );
};

const CategoryNameEditor = ({ category, onConfirm, onCancel }: { category: CategoryNode; onConfirm: (category: CategoryNode) => void; onCancel: () => void }) => {
    const [localCategory, setLocalCategory] = React.useState<CategoryNode>(category);

    // Sync state with the external category
    useEffect(() => {
        if (localCategory.iconAssetId !== category.iconAssetId) {
            setLocalCategory((c) => ({ ...c, iconAssetId: category.iconAssetId }));
        }
    }, [category.iconAssetId]);

    return (
        <>
            <TextField sx={{ flexGrow: 1 }} variant="standard" value={localCategory.name} onChange={(event) => setLocalCategory((c) => ({ ...c, name: event.target.value }))} />
            <div>
                <StyledIconButton
                    onClick={() => onConfirm(localCategory)}
                    size="small"
                    disabled={localCategory.name == null || localCategory.name === "" || localCategory.iconAssetId == null}
                >
                    <FontAwesomeIcon icon={["far", "check"]} />
                </StyledIconButton>
                <StyledIconButton onClick={onCancel} size="small">
                    <FontAwesomeIcon icon={["far", "xmark"]} />
                </StyledIconButton>
            </div>
        </>
    );
};

const CategoryOrderForm = ({ existingOrder, submit, close }: { existingOrder: number; submit: (order: number) => void; close: () => void }) => {
    const [order, setOrder] = useState(existingOrder ?? null);
    return (
        <FormWizardTemplate
            title="Order your category"
            currentStage={0}
            stages={[
                {
                    primaryButtonText: "Confirm",
                    primaryButtonCallback: () => {
                        submit(order);
                        close();
                    },
                    secondaryButtons: [{ buttonText: "Cancel", onButtonPressed: close }],
                },
            ]}
        >
            <StyledFieldLabel>Order:</StyledFieldLabel>
            <TextField sx={{ width: "60px" }} type="number" value={order} onChange={(event) => setOrder(event.target.value === "" ? null : Number(event.target.value))} />
        </FormWizardTemplate>
    );
};

const CategoryParentForm = ({ category, submit, close }: { category: CategoryNode; submit: (newParentId: string) => void; close: () => void }) => {
    const [{ tree }] = useTreeBrowser(CategoryTreeContext, category.id, "tree");

    const [selectedParent, setSelectedParent] = useState<UnionOfNodes<CategoryRootNode>>(findNode([tree], category.parentId));

    const { data: children, isLoading: isChildrenLoading } = useQuery(
        [category.id],
        async () => {
            const res = await api.get<AssetCategory[]>(`categories/${category.id}/children`);
            return res.data;
        },
        { retryOnMount: true, enabled: category.childNodes.length === 0 }
    );

    const isValidToMove = category.childNodes?.length === 0 && !isChildrenLoading && children?.length === 0;

    const options: UnionOfNodes<CategoryRootNode>[] = useMemo(() => [tree, ...tree.childNodes.filter((node) => node.id !== category.id)], []);

    const getOptionLabel = (node: UnionOfNodes<CategoryRootNode>) => {
        if (node.id === tree.id) {
            return "Root";
        }
        return node.name;
    };

    return (
        <FormWizardTemplate
            title="Change category parent"
            currentStage={0}
            stages={[
                {
                    primaryButtonText: "Move",
                    primaryButtonCallback: () => {
                        submit(selectedParent.id);
                        close();
                    },
                    secondaryButtons: [{ buttonText: "Cancel", onButtonPressed: close }],
                },
            ]}
        >
            <MoveCategoryContainer>
                <Body1>Parent: </Body1>
                <Autocomplete<UnionOfNodes<CategoryRootNode>, false>
                    options={options}
                    value={selectedParent}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(_, newValue: UnionOfNodes<CategoryRootNode>) => {
                        setSelectedParent(newValue);
                    }}
                    disabled={!isValidToMove}
                    multiple={false}
                />

                {!isValidToMove && (
                    <Body1>
                        <span className="category-name">{category.name}</span> can not be moved as it has child categories
                    </Body1>
                )}
            </MoveCategoryContainer>
        </FormWizardTemplate>
    );
};

export const StyledCategoryRowContainer = styled.div<{ marginLeft: number }>`
    margin-left: ${({ marginLeft }) => marginLeft * 20}px;
    height: ${inputHeight};
    box-sizing: border-box;
`;

const CategoriesHeader = styled.div`
    padding-bottom: ${sectionalMargin};
`;

const SelectorContainer = styled.div`
    width: 40%;
    position: relative;
    .project-search {
        width: 100%;
    }
`;

export const StyledCategoryRow = styled.div`
    padding-left: 0;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${muiInputFormsCSS}

    .category-icon {
        width: 20px;
        height: 20px;
    }

    .category-content {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-grow: 1;

        .icon-area {
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${iconButtonSize};
            height: ${iconButtonSize};
        }

        .MuiInputBase-input {
            padding: 0;
        }
    }

    .category-checkbox-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`;

const MoveCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${muiInputFormsCSS};

    .category-name {
        font-weight: 600;
    }
`;

const SkeletonRow = styled(StyledCategoryRowContainer)`
    width: 100%;
    ${defaultSkeletonMixin}
    height: ${inputHeight};
`;
