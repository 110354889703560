import React, { FunctionComponent } from "react";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import {
    Body2,
    formGap,
    muiInputFormsCSS,
    sectionalMargin,
    StyledFieldLabel,
    StyledIconButton,
    Subtitle,
    inputLeftPadding,
    EmphasisText,
    FillSpace,
    styled,
} from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";

import { Divider, TextField, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { ColorSelectorComponent } from "@iventis/components/src/selectors/colour-selector";
import { ChipType, StyledChip } from "@iventis/components/src/chips";

export const SitemapForm: FunctionComponent<{
    sitemap: Sitemap;
    setSitemap: (sitemap: Sitemap | ((sitemap: Sitemap) => Sitemap)) => void;
    allowVersions: boolean;
    addVersion: () => void;
    editVersion: (sitemapVersion: SitemapVersion) => void;
    deleteVersion: (sitemapVersion: SitemapVersion) => void;
}> = ({ sitemap, setSitemap, allowVersions, addVersion, editVersion, deleteVersion }) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();
    return (
        <StyledForm>
            {/* NAME INPUT */}
            <div className="sitemap-name sitemap-input-with-label">
                <StyledFieldLabel>{translate(Content.map6.sitemap.name)}</StyledFieldLabel>
                <TextField variant="outlined" value={sitemap.name} onChange={(event) => setSitemap((s) => ({ ...s, name: event.target.value }))} />
            </div>
            {/* KEY INPUT */}
            <div className="sitemap-key sitemap-input-with-label">
                <StyledFieldLabel>{translate(Content.map6.sitemap.key)}</StyledFieldLabel>
                <TextField
                    variant="outlined"
                    value={sitemap.tag}
                    onChange={({ target: { value } }) => setSitemap((s) => ({ ...s, tag: value?.length > 3 ? value.toLocaleUpperCase().slice(0, 3) : value.toLocaleUpperCase() }))}
                />
            </div>
            {/* COLOUR INPUT */}
            <div className="sitemap-colour sitemap-input-with-label">
                <StyledFieldLabel>{translate(Content.map6.sitemap.colour_of_label)}</StyledFieldLabel>
                <ColorSelectorComponent value={sitemap.colour ?? theme.tertiaryColors.primary} changeValue={(value) => setSitemap((s) => ({ ...s, colour: value }))} />
            </div>
            {/* VERSIONS */}
            {allowVersions && (
                <div className="versions">
                    <div className="versions-heading">
                        <Subtitle>{translate(Content.map6.sitemap.versions)}</Subtitle>
                        <StyledChip
                            chipContainerClassName="add-version"
                            label={
                                <>
                                    <FontAwesomeIcon icon={["far", "plus"]} /> {translate(Content.map6.sitemap.add_version)}
                                </>
                            }
                            chipType={ChipType.PrimaryButton}
                            onClick={addVersion}
                        />
                    </div>
                    {!(sitemap.versions?.length > 0) && <EmphasisText>{translate(Content.map6.sitemap.there_are_no_versions)}</EmphasisText>}
                    {sitemap.versions?.map((version) => (
                        <React.Fragment key={version.id}>
                            <Divider />
                            <StyledSitemapVersionRow>
                                <Body2>{version.name}</Body2>
                                <FillSpace />
                                <StyledIconButton className="delete-button" onClick={() => deleteVersion(version)}>
                                    <span className="hidden">
                                        {translate(Content.common.buttons.remove)} - {version.name}
                                    </span>
                                    <FontAwesomeIcon icon={["far", "trash"]} size="xs" />
                                </StyledIconButton>
                                <StyledIconButton onClick={() => editVersion(version)}>
                                    <span className="hidden">
                                        {translate(Content.map6.sitemapVersion.title.edit)} - {version.name}
                                    </span>
                                    <FontAwesomeIcon icon={["far", "pen-to-square"]} size="xs" />
                                </StyledIconButton>
                            </StyledSitemapVersionRow>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </StyledForm>
    );
};

const StyledSitemapVersionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${inputLeftPadding};
`;

const StyledForm = styled.div`
    ${muiInputFormsCSS}
    display: grid;
    grid-template-areas:
        "name key"
        "colour ."
        "versions versions";
    gap: ${sectionalMargin};
    .sitemap-name {
        grid-area: name;
    }
    .sitemap-key {
        grid-area: key;
    }
    .sitemap-colour {
        grid-area: colour;
    }
    .versions {
        display: flex;
        flex-direction: column;
        row-gap: ${formGap};
        grid-area: versions;

        .versions-heading {
            display: flex;
            justify-content: space-between;
            align-items: end;
        }
    }
    .sitemap-input-with-label {
        display: flex;
        flex-direction: column;
    }
`;
