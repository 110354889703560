/* eslint-disable react/jsx-props-no-spreading */
import { UserGroup } from "@iventis/domain-model/model/userGroup";
import { Theme } from "@emotion/react";
import { styled, Body1, Body3, CheckedCheckBox, labelMargin, media, muiInputFormsCSS, UncheckedCheckBox } from "@iventis/styles";
import { Content } from "@iventis/translations/content/typed-content";
import { Button, Checkbox, TextField, useTheme } from "@mui/material";
import { formButton, sectionalMargin } from "@iventis/styles/src/atomic-rules";
import React, { FunctionComponent, useMemo } from "react";
import { Sender } from "xstate";
import { MultipleValueTextInput, StyledHorizontalRule } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";

import { NewTeamUsers } from "./people-view.types";
import { AddNewPeopleEvents, AddNewPeopleMachineEvents } from "./add-new-people.machine";

interface AddPeopleToTeamSelectorProps {
    userGroups: NewTeamUsers[];
    teams: UserGroup[];
    send: Sender<AddNewPeopleEvents>;
    specifyProjectAdminStatus?: boolean;
    specifyUserSso?: boolean;
}

export const AddPeopleToTeamSelector: FunctionComponent<AddPeopleToTeamSelectorProps> = ({ userGroups, teams, send, specifyProjectAdminStatus = true, specifyUserSso = true }) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();

    // Create a name id pair
    const selectorOptions = useMemo(() => teams.filter((team) => team.systemUserGroup == null).map(({ id, name }) => ({ id, name })), [teams]);

    return (
        <StyledContainer>
            {userGroups.map((group) => (
                <StyledGroupContainer key={group.id}>
                    <StyledPeopleTeamContainer>
                        <div className="value-input value-input-email">
                            <>
                                <Body1>{translate(Content.people.add_people.email)}</Body1>
                                <StyledDescription>{translate(Content.people.add_people.separate_comma)}</StyledDescription>
                            </>
                            <TextField
                                data-testid="input-user-email"
                                variant="outlined"
                                onChange={(event) =>
                                    send({ type: AddNewPeopleMachineEvents.UPDATE_EMAILS_IN_USER_GROUP, payload: { newEmails: event.target.value, userGroupId: group.id } })
                                }
                                error={!group.valid && group.emails.length > 0}
                            />
                            <StyledErrorMessage data-testid="email-input-error">
                                {!group.valid && group.emails.length > 0 && <span>{translate(Content.people.invalid_email_address)}</span>}
                            </StyledErrorMessage>
                        </div>
                        <div className="value-input value-input-team">
                            <>
                                <Body1>{translate(Content.people.add_people.team)}</Body1>
                                <StyledDescription>{translate(Content.people.add_people.add_invitee_team)}</StyledDescription>
                            </>
                            <MultipleValueTextInput<{ id: string; name: string }>
                                values={selectorOptions}
                                dataTestId="teams-selector"
                                onChange={(values: { id: string; name: string }[]) => {
                                    send({
                                        type: AddNewPeopleMachineEvents.UPDATE_TEAM_IN_USER_GROUP,
                                        payload: { userGroupId: group.id, newTeamIds: values.map(({ id }) => id) as string[] },
                                    });
                                }}
                                getLabel={(value: { id: string; name: string }) => value.name}
                                className="team-selector"
                                selectedValues={group.teams.map(({ id, name }) => ({
                                    id,
                                    name,
                                }))}
                                isOptionEqualToValue={(value, option) => option.id === value.id}
                            />
                        </div>
                        {specifyProjectAdminStatus && (
                            <div className="checkbox-input value-input-admin">
                                <Checkbox
                                    checked={group.isProjectAdmin}
                                    icon={
                                        <UncheckedCheckBox size={15} borderStyle={`1px solid ${theme.typographyColors.subdued}`} backgroundColour={theme.secondaryColors.blank} />
                                    }
                                    checkedIcon={
                                        <CheckedCheckBox
                                            size={15}
                                            tickColour={theme.typographyColors.blank}
                                            borderStyle={`1px solid ${theme.primaryColors.focus}`}
                                            backgroundColour={theme.primaryColors.focus}
                                        />
                                    }
                                    color="default"
                                    onChange={(event) =>
                                        send({
                                            type: AddNewPeopleMachineEvents.UPDATE_PROJECT_ADMIN_STATUS,
                                            payload: { newUserGroupId: group.id, projectAdmin: event.currentTarget.checked },
                                        })
                                    }
                                />
                                <StyledDescription>{translate(Content.people.add_people.project_admin)}</StyledDescription>
                            </div>
                        )}
                        {specifyUserSso && (
                            <div className="checkbox-input value-input-sso">
                                <Checkbox
                                    checked={group.ssoEnabled}
                                    icon={
                                        <UncheckedCheckBox size={15} borderStyle={`1px solid ${theme.typographyColors.subdued}`} backgroundColour={theme.secondaryColors.blank} />
                                    }
                                    checkedIcon={
                                        <CheckedCheckBox
                                            size={15}
                                            tickColour={theme.typographyColors.blank}
                                            borderStyle={`1px solid ${theme.primaryColors.focus}`}
                                            backgroundColour={theme.primaryColors.focus}
                                        />
                                    }
                                    color="default"
                                    onChange={(event) =>
                                        send({
                                            type: AddNewPeopleMachineEvents.UPDATE_USER_SSO,
                                            payload: { newUserGroupId: group.id, ssoEnabled: event.currentTarget.checked },
                                        })
                                    }
                                />
                                <StyledDescription>{translate(Content.people.add_people.sso)}</StyledDescription>
                            </div>
                        )}
                    </StyledPeopleTeamContainer>
                    <StyledHorizontalRule />
                </StyledGroupContainer>
            ))}
            <StyledButton data-testid="add-more-button" onClick={() => send({ type: AddNewPeopleMachineEvents.ADD_USER_GROUP })}>
                {translate(Content.people.add_people.add_more)}
            </StyledButton>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    ${muiInputFormsCSS};
    min-height: 450px;
`;

const StyledButton = styled(Button)`
    width: ${formButton.width};
    height: ${formButton.height};
`;

const StyledGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${sectionalMargin};
    :last-child {
        margin-bottom: 0;
    }
`;

/** Space for any errors to display */
const ERROR_SPACE = "20px";

const StyledPeopleTeamContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    ${media.large} {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        column-gap: ${sectionalMargin};
    }
    .value-input {
        display: flex;
        flex-direction: column;
        gap: ${labelMargin};
    }

    .checkbox-input {
        display: flex;
        flex-direction: row;
        gap: ${labelMargin};
        align-items: center;
        padding-bottom: 5px;
    }

    // Email input spreads across the top
    .value-input-email {
        grid-row: 1;
        grid-column: 1 / 3;
        ${media.large} {
            grid-column: 1 / 2;
        }
    }
    // Team selector inline with project admin checkbox
    .value-input-team {
        grid-row: 2;
        grid-column: 1 / 3;
        ${media.large} {
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

    .value-input-sso {
        grid-row: 3;
        grid-column: 1;
    }

    .value-input-admin {
        grid-row: 3;
        grid-column: 2;
    }
`;

const StyledErrorMessage = styled(Body3)`
    height: ${ERROR_SPACE};
    width: 100%;
    color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.cancel};
`;

const StyledDescription = styled(Body1)`
    color: ${({ theme }: { theme: Theme }) => theme.shades.two};
`;
