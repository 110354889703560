import { AssetInputComponent, ErrorBox, IAssetService, MapperTable } from "@iventis/components";
import { Header4, Body1 } from "@iventis/styles";
import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertPascalToSentence, useAssetSignatureCache } from "@iventis/utilities";
import { MissingAsset } from "@iventis/map-export-import-mapper";

export const AssetMapper: FunctionComponent<{ missingAssets: MissingAsset[]; onSelectAsset: (assetId: string, replacing: MissingAsset) => void; assetService: IAssetService }> = ({
    missingAssets,
    onSelectAsset,
    assetService,
}) => {
    const imageGetter = useAssetSignatureCache();

    return (
        <>
            {!missingAssets.every((missingAsset) => missingAsset.newId != null) && (
                <ErrorBox>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FontAwesomeIcon icon={["fas", "exclamation-circle"]} />
                        <Header4>Assets missing</Header4>
                    </div>
                    <Body1>Some of the assets you are trying to import do not exist in this project. Please select a replacement for the missing asset.</Body1>
                </ErrorBox>
            )}
            <MapperTable
                items={missingAssets}
                selector={(item) => (
                    <AssetInputComponent
                        assetType={item.type}
                        selectorDescription="Choose asset"
                        imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
                        assetService={assetService}
                        value={item.newId}
                        changeValue={(asset) => onSelectAsset(asset, item)}
                    />
                )}
                itemColumnHeader="Missing asset"
                typeColumnHeader="Type"
                selectorColumnHeader="Replacement"
                getItemId={(item) => item.originalId}
                getItemName={(item) => item.name}
                getTypeName={(item) => convertPascalToSentence(item.type)}
            />
        </>
    );
};
