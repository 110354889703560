import { ApplicationName } from "@iventis/domain-model/model/applicationName";

// Default values for applications:
//   Settings should always be true
//   Unreal is unsupported here for now
export const applicationsDefault: Record<ApplicationName, boolean> = {
    [ApplicationName.Settings]: true,
    [ApplicationName.Dashboard]: false,
    [ApplicationName.Maps]: false,
    [ApplicationName.People]: false,
    [ApplicationName.Schedule]: false,
};
