import { MapImportTokenCreateResponseDto } from "@iventis/domain-model/model/mapImportTokenCreateResponseDto";
import { DataFieldListItemReponseDto as DataFieldListItemResponseDto } from "@iventis/domain-model/model/dataFieldListItemReponseDto";
import { MapImportCreateRequestDto } from "@iventis/domain-model/model/mapImportCreateRequestDto";
import { ImportIventisMap, MapImportLayer } from "@iventis/map-export-import-mapper/src/map-json-types";
import { MissingAsset } from "@iventis/map-export-import-mapper";
import { DataField } from "@iventis/domain-model/model/dataField";
import { CreateAssetRequest } from "@iventis/domain-model/model/createAssetRequest";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { IventisExportedModel } from "@iventis/domain-model/model/iventisExportedModel";
import { IventisExportedIcon } from "@iventis/domain-model/model/iventisExportedIcon";
import { Model } from "@iventis/domain-model/model/model";
import { aggregatePagedResponseLists } from "@iventis/api-helpers";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { api } from "../api/api";

/** Makes API calls to import a map */
export async function importMapRequests(mapImport: ImportIventisMap, parentId: string, instanceName: string, projectId: string) {
    // Get url to upload to
    const response = await api.post<MapImportTokenCreateResponseDto>("map-import-tokens");
    // Create request
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", response.data.uploadUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onerror = () => {
        throw new Error("Upload has errored");
    };

    xhr.send(JSON.stringify(mapImport));

    // Confirm to API upload is complete
    return new Promise<void>((resolve) => {
        xhr.onload = async () => {
            if (xhr.status !== 200) {
                throw new Error("Upload was unsuccessful");
            }
            await api.post<void, void, MapImportCreateRequestDto>(`instances/${instanceName}/projects/${projectId}/map-imports`, { id: response.data.mapImportId, parentId });
            resolve();
        };
    });
}

/** Gets all unique assets from a ImportIventisMap, returns them as MissingAsset array */
export async function getAllUniqueAssetsAsMissing(mapImport: ImportIventisMap, instanceName: string, projectId: string): Promise<MissingAsset[]> {
    const allModels = mapImport.layers.flatMap((layer) => getImportedLayerModels(layer));
    const allIcons = mapImport.layers.flatMap((layer) => (layer.styleType === StyleType.Icon ? layer.icons : []));

    const uniqueModels = allModels.reduce<IventisExportedModel[]>((models, model) => {
        if (!models.some(({ id }) => id === model.id)) {
            return [...models, model];
        }
        return models;
    }, []);

    const uniqueIcons = allIcons.reduce<IventisExportedIcon[]>((icons, icon) => {
        if (!icons.some(({ id }) => id === icon.id)) {
            return [...icons, icon];
        }
        return icons;
    }, []);

    const allAssets: MissingAsset[] = [];

    // Get model thumbnail id from mapping service on the given model ids since they link to the asset's service model id
    const { data: projectModels } = await api.get<Model[]>(`instances/${instanceName}/projects/${projectId}/models`);

    uniqueModels.forEach((model) => {
        allAssets.push({
            name: model.name,
            originalId: model.id,
            type: AssetType.Model,
            newId: projectModels.find((projectModel) => projectModel.id === model.id)?.thumbnailAssetId,
        });
    });

    uniqueIcons.forEach((icon) => {
        allAssets.push({ name: icon.name, originalId: icon.id, type: AssetType.MapIcon, newId: icon.id });
    });

    return allAssets;
}

export async function getProjectDataFields(instanceName: string, projectId: string) {
    const response = await api.get<DataField[]>(`instances/${instanceName}/projects/${projectId}/data-fields`);
    return response.data;
}

export async function getDataFieldListItem(instanceName: string, projectId: string, dataFieldId: string) {
    const result = await aggregatePagedResponseLists<DataFieldListItemResponseDto>(async (pageNumber) => {
        const response = await api.get<PagedResult<DataFieldListItemResponseDto[]>>(
            `instances/${instanceName}/projects/${projectId}/data-field/${dataFieldId}/list-items?pageNumber=${pageNumber}`
        );
        return response.data;
    });

    return result;
}

export async function postLayerThumbnail(layerId: string, dataUrl: string, instanceName: string, projectId: string) {
    await api.post<void, void, Pick<CreateAssetRequest, "id" | "dataUrl" | "type">>(`instances/${instanceName}/projects/${projectId}/assets`, {
        id: layerId,
        dataUrl,
        type: AssetType.LayerPreview,
    });
}

const getImportedLayerModels = (mapImport: MapImportLayer) => {
    switch (mapImport.styleType) {
        case StyleType.Model:
        case StyleType.LineModel:
            return mapImport.models;
        default:
            return [];
    }
};
