import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { Content } from "@iventis/translations/content/typed-content";
import { TitleWithIcon } from "@iventis/styles/src/components/title-with-icon";
import React, { FunctionComponent, useContext, useEffect, useReducer } from "react";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { IconOrientation } from "@iventis/domain-model/model/iconOrientation";
import { IconAlignment } from "@iventis/domain-model/model/iconAlignment";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useAssetSignatureCache } from "@iventis/utilities";
import {
    assetPickerComponentCreator,
    AssetInputPreviewComponent,
    definedValueSelectorComponentCreator,
    incrementalValueSelectorComponentCreator,
    sliderWithTextSelectorCreator,
    colourSelectorComponentCreator,
    ColourSelectorFormulaPreview,
} from "@iventis/components";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { createStaticStyleValue, getStaticStyleValue } from "@iventis/map-engine/src/utilities/static-styles";
import { defaultIconStyle } from "@iventis/map-engine/src/utilities/default-style-values";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { StyledStyleItemContainer } from "./line-edit-style";
import { EditStyleProps } from "./style.types";
import EditTextStyle from "./text-edit-style";
import { PitchAlignmentInfo } from "./pitch-alignment-info";
import { IconStyleActionTypes, IconStyleActions, IconStyleState } from "./icon-edit-style-types";
import { EditStyleContext } from "./edit-style-context";

function iconEditStyleReducer(state: IconStyleState, action: IconStyleActions): IconStyleState {
    switch (action.type) {
        case IconStyleActionTypes.setIconSdfEnabled: {
            const iconSDFEnabled = action.payload.every((asset) => asset?.metaData?.sdf);
            if (!iconSDFEnabled) {
                // Reset custom colour - we don't want it
                state.onIconSDFDisable();
            }
            return {
                ...state,
                iconSDFEnabled,
            };
        }
        default:
            throw Error(`No case exists for IconEditStyleActionType of ${action.type}`);
    }
}

/** Makes up the part of the edit layer form where you edit the style of the icon */
export const IconEditStyleComponent: FunctionComponent<EditStyleProps<IconStyle>> = ({ layerStyle, changeStyleValue, dataFields }) => {
    const translate = useIventisTranslate();

    const imageGetter = useAssetSignatureCache();

    const [iconEditStyleState, dispatchIconReducer] = useReducer(iconEditStyleReducer, {
        iconSDFEnabled: false,
        onIconSDFDisable: () => {
            const updatedCustomColour =
                layerStyle?.customColour != null
                    ? { ...layerStyle.customColour, staticValue: { ...layerStyle.customColour.staticValue, staticValue: false } }
                    : createStaticStyleValue(false);
            changeStyleValue("customColour", updatedCustomColour);
        },
    });

    const { assetService, onAssetRefresh } = useContext(EditStyleContext);

    useEffect(() => {
        const assetIds: string[] = [layerStyle.iconImage.staticValue.staticValue, ...Object.values(layerStyle.iconImage.mappedValues ?? {}).map((ddStyle) => ddStyle.staticValue)];
        assetService.getAssetsById(assetIds).then((response) => dispatchIconReducer({ type: IconStyleActionTypes.setIconSdfEnabled, payload: response }));
    }, [layerStyle.iconImage]);

    return (
        <>
            <StyleContainer title={<TitleWithIcon icon={["fas", "image"]} title={translate(Content.map2.styles.icon)} />}>
                <StyledStyleItemContainer>
                    {/* ICON PICKER */}
                    <EditStyleItemComponent
                        styleProperty="iconImage"
                        value={layerStyle.iconImage}
                        changeStyleValue={changeStyleValue}
                        component={assetPickerComponentCreator({
                            assetType: AssetType.MapIcon,
                            selectorDescription: translate(Content.map2.styles.choose_icon_image),
                            imageUrlGetter: (asset) => imageGetter(asset.assetUrl, asset.authoritySignature),
                            assetService,
                            onAssetRefresh,
                        })}
                        PreviewComponent={({ value }) => (
                            <AssetInputPreviewComponent
                                assetId={value}
                                imageGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
                                assetServices={assetService}
                            />
                        )}
                        showDataDriven
                        dataFields={dataFields}
                        title={translate(Content.map2.styles.icon)}
                    />

                    {/* SIZE INCREMENTER */}
                    <EditStyleItemComponent
                        styleProperty="size"
                        value={layerStyle.size}
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 0.1,
                            maxValue: 3.0,
                            increment: 0.1,
                            units: [{ id: "x", name: translate(Content.map3.units.scale) }],
                            selectedUnitId: "x",
                            decimals: 1,
                            testId: "icon-size",
                        })}
                        title={translate(Content.map2.styles.icon_size)}
                        showHiddenOptions
                        disabled={getStaticStyleValue(layerStyle.iconTextFit ?? defaultIconStyle.iconTextFit)}
                    />

                    {iconEditStyleState.iconSDFEnabled && (
                        <>
                            <EditStyleItemComponent
                                styleProperty="customColour"
                                value={layerStyle.customColour ?? getDefaultStyleProperty(layerStyle.styleType, "customColour")}
                                changeStyleValue={changeStyleValue}
                                component={definedValueSelectorComponentCreator<boolean>({
                                    optionalValues: [
                                        { value: true, label: translate(Content.common.yes) },
                                        { value: false, label: translate(Content.common.no) },
                                    ],
                                })}
                                title={translate(Content.map5.styles.customiseColour)}
                            />
                            <EditStyleItemComponent
                                styleProperty="colour"
                                component={colourSelectorComponentCreator()}
                                PreviewComponent={ColourSelectorFormulaPreview}
                                showDataDriven
                                title={translate(Content.map2.styles.colour)}
                                value={layerStyle.colour ?? getDefaultStyleProperty(layerStyle.styleType, "colour")}
                                changeStyleValue={changeStyleValue}
                                dataFields={dataFields}
                                disabled={!getStaticStyleValue(layerStyle.customColour)}
                            />
                        </>
                    )}
                    {/* OPACITY SLIDER */}
                    <EditStyleItemComponent
                        styleProperty="opacity"
                        value={layerStyle.opacity}
                        component={sliderWithTextSelectorCreator({
                            minValue: 0,
                            maxValue: 1,
                            increment: 0.05,
                            colour: layerStyle.customColour?.staticValue.staticValue
                                ? layerStyle.colour.staticValue.staticValue
                                : getStaticStyleValue(getDefaultStyleProperty(layerStyle.styleType, "colour")),
                        })}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.opacity)}
                        isZoomableValue={false}
                    />

                    {/* Allow overlap */}
                    <EditStyleItemComponent
                        styleProperty="allowOverlap"
                        component={definedValueSelectorComponentCreator<boolean>({
                            optionalValues: [
                                { value: true, label: translate(Content.map2.styles.show_all) },
                                { value: false, label: translate(Content.map2.styles.combine) },
                            ],
                        })}
                        value={layerStyle.allowOverlap}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.allow_overlap)}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <StyleContainer testId="rotation" title={<TitleWithIcon title={translate(Content.map2.styles2.rotation)} icon={["fas", "compass"]} />} defaultOpen={false}>
                <StyledStyleItemContainer>
                    {/* ROTATION INCREMENTOR */}
                    <EditStyleItemComponent
                        styleProperty="rotation"
                        value={layerStyle.rotation ?? getDefaultStyleProperty(layerStyle.styleType, "rotation")}
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 0,
                            maxValue: 359,
                            increment: 10,
                            units: [{ id: "degrees", name: translate(Content.map3.units.degrees) }],
                            selectedUnitId: "degrees",
                        })}
                        title={translate(Content.map2.styles.rotation)}
                        isZoomableValue={false}
                        showDataDriven
                        dataFields={dataFields}
                    />
                    {/* ICON ORIENTATION */}
                    <EditStyleItemComponent
                        styleProperty="orientation"
                        component={definedValueSelectorComponentCreator<IconOrientation>({
                            optionalValues: [
                                { value: IconOrientation.Flatten, label: translate(Content.map2.styles2.flat) },
                                { value: IconOrientation.Raise, label: translate(Content.map2.styles2.raised) },
                            ],
                        })}
                        value={layerStyle.orientation ?? getDefaultStyleProperty(layerStyle.styleType, "orientation")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles2.orientation_3d)}
                        infoTitle={translate(Content.map2.styles.pitch_alignment)}
                        infoContent={<PitchAlignmentInfo styleType={StyleType.Icon} />}
                    />
                    <EditStyleItemComponent
                        styleProperty="iconAlignment"
                        component={definedValueSelectorComponentCreator<IconAlignment>({
                            optionalValues: [
                                { value: IconAlignment.Screen, label: translate(Content.map8.iconStyle.screen) },
                                { value: IconAlignment.North, label: translate(Content.map8.iconStyle.north) },
                            ],
                        })}
                        value={layerStyle.iconAlignment ?? getDefaultStyleProperty(layerStyle.styleType, "iconAlignment")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map8.iconStyle.alignment)}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <EditTextStyle layerStyle={layerStyle} dataFields={dataFields} changeStyleValue={changeStyleValue} />
        </>
    );
};
