/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subscription, Observable } from "rxjs";
import { useEffect, useState } from "react";

export const useEffectIgnoreInitialRender = (callback: (params?: any) => void, dependencyArray: any[]) => {
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }
        callback();
    }, dependencyArray);
};

/**
 * Features lazy-loading via a provided callback function.
 * Consolidates literal options OR callback as a single parameter for cleanliness.
 * @param get callback function to get options
 * @returns the loading state of the options (if lazily loaded), as well as the options themselves (which will be an empty array if the options are loading)
 */
export const useLazyLoadOptions = <Option>(get: (() => Observable<any>) | Option[]): [boolean, Option[]] => {
    const isArray = Array.isArray(get);
    const [options, setOptions] = useState<Option[]>(isArray ? get : []);
    const [loading, setLoading] = useState<boolean>(!isArray);

    useEffect(() => {
        let subscription: Subscription;
        if (!isArray) {
            setLoading(true);
            subscription = get().subscribe((opts) => {
                setOptions(opts);
                setLoading(false);
            });
        } else {
            setOptions(get);
        }
        return () => subscription?.unsubscribe();
    }, []);

    return [loading, options];
};
