export const fileToBase64 = async (file: File): Promise<{ withHeader: string; withoutHeader: string }> =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const result = (reader.result as string).split(",")[1];
            resolve({ withHeader: reader.result as string, withoutHeader: result });
        };
        reader.readAsDataURL(file);
    });

/** Gets an image from an url and converts to base64 without rendering anything in the DOM  */
export const getBase64FromImageUrl = async (url: string): Promise<string> => {
    if (url == null) {
        return null;
    }

    const image = await fetch(url);
    const blob = await image.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data as string);
        };
    });
};
