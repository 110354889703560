import { alpha } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { fontSizes } from "../atomic-rules";
import styled from "../transient-styled";

export const DatePickerSolid = styled(DatePicker)`
    .MuiInputBase-root {
        border-radius: 4px;
        position: relative;
        background-color: ${(props) => props.theme.shades.five};
        border: 1px solid #ced4da;
        font-size: ${fontSizes.medium};
        width: auto;
        padding: 10px 12px;
        &:focus {
            box-shadow: ${() => alpha("#000000", 0.25)} 0 0 0 0.2rem;
            border-color: ${(props) => props.theme.primaryColors.focus};
        }
    }
`;
