/* eslint-disable react/jsx-props-no-spreading */
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import React, { FunctionComponent, useMemo, useState } from "react";
import { FormLabel, inlineTextIconMargin, styled } from "@iventis/styles";
import { FormWizardTitle } from "@iventis/components";
import { Divider, TextField, Checkbox, IconButton } from "@mui/material";

import { sectionalMargin } from "@iventis/styles/src/atomic-rules";
import { basicTranslator, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations/content/typed-content";
import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { Project } from "@iventis/domain-model/model/project";
import { ApplicationSelector } from "@iventis/components/src/selectors/application-selector";
import { FormWizardTemplate } from "@iventis/components/src/form-wizard-template";
import { applicationsDefault } from "@iventis/components/src/selectors/application-selector.helpers";
import { AutocompleteWithLoading } from "@iventis/components/src/autocomplete";
import { useQuery } from "@tanstack/react-query";
import { EMPTY_GUID, useDelayedConstant, useDelayedState, useSyncState } from "@iventis/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BillingUnit } from "@iventis/domain-model/model/billingUnit";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { SUBSCRIPTION_PLAN_QUERY_KEY, getPricesForSubscriptionPlan, getSubscriptionPlans } from "./subscription-plans-api-helpers";
import { getProjectSubscription } from "./project-api-functions";
import { SUBSCRIPTION_PRICE_QUERY_KEY } from "./constants";
import { ProjectFormCommittedUntilInput } from "./project-form-committed-until";
import { CustomPriceEditor } from "./custom-price";
import { RecommendedAssetType } from "./category-types";
import { CategoryRepositoryBasicComponent } from "./category-repository-basic";

const PROJECT_SUBSCRIPTION_KEY = "project_subscription";

export const TEMPORARY_PROJECT_ID = "default";

export const ProjectFormComponent: FunctionComponent<{
    onClose?: () => void;
    onConfirm?: (
        name: string,
        applications: Record<ApplicationName, boolean>,
        billingProvider: ProjectBillingProvider,
        plan: SubscriptionPlan,
        price: SubscriptionPlanPrice,
        maxUsers: number,
        unlimitedUsers: boolean,
        recommendedLayerTemplateCategories: string[],
        committedUntilDate: Date
    ) => void;
    subtitle: string;
    translate?: UseIventisTranslate;
    project?: Project;
    instanceName: string;
}> = ({ onClose, onConfirm, subtitle, translate = basicTranslator, project, instanceName }) => {
    const [name, setName] = useState(project?.name || "");
    const [applications, setApplications] = useState<Record<ApplicationName, boolean>>(applicationsDefault);

    const [makingCustomPrice, setMakingCustomPrice] = useState<boolean>(false);

    // If projectSubscriptionId is an empty guid, the project has no subscription, so we we won't fetch it
    const existingSubscription = project?.projectSubscriptionId !== EMPTY_GUID && !!project?.id;

    const { data: projectSubscription, isLoading: isLoadingProjectSub, isFetching } = useQuery(
        [PROJECT_SUBSCRIPTION_KEY, project?.id],
        async () => getProjectSubscription(instanceName, project.id, project.projectSubscriptionId),
        { enabled: existingSubscription }
    );
    const { data: plans, isLoading: isLoadingPlans } = useQuery([SUBSCRIPTION_PLAN_QUERY_KEY], async () => getSubscriptionPlans());
    const [plan, setPlan] = useSyncState<SubscriptionPlan | null>(
        isLoadingPlans || isLoadingProjectSub,
        plans?.find((p) => p.id === projectSubscription?.subscriptionPlanId)
    );
    const { data: prices, isLoading: isLoadingPrices, refetch: refetchPrices } = useQuery(
        [SUBSCRIPTION_PRICE_QUERY_KEY, plan?.id],
        () => getPricesForSubscriptionPlan(plan?.id, project?.id),
        {
            enabled: plan?.id != null,
        }
    );

    const loading = (isLoadingProjectSub && existingSubscription) || isFetching;

    const [price, setPrice] = useSyncState<SubscriptionPlanPrice | null>(
        isLoadingPlans || isLoadingPrices || isLoadingProjectSub,
        prices?.find((p) => p.id === projectSubscription?.subscriptionPlanPriceId)
    );

    const { value: originalCurrency } = useDelayedConstant(() => price?.currencyCode, price != null && existingSubscription);

    const [maxUsers, setMaxUsers, isLoadingMaxUsers] = useDelayedState<number>(loading, projectSubscription?.maximumUsers);
    const [unlimitedUsers, setUnlimitedUsers, isLoadingUnlimitedUsers] = useDelayedState<boolean>(loading, projectSubscription?.maximumUsers == null);

    const [billingProvider, setBillingProvider] = useDelayedState<ProjectBillingProvider>(isLoadingProjectSub, projectSubscription?.billingProvider ?? ProjectBillingProvider.None);

    const [recommendedLayerTemplateCategories, setRecommendedLayerTemplateCategories] = useState<string[]>([]);

    // Validation
    const textValid = useMemo(() => name.length >= 1, [name]);

    const maxUsersValid = useMemo(() => maxUsers > 0 || unlimitedUsers, [maxUsers, unlimitedUsers, isLoadingMaxUsers, isLoadingUnlimitedUsers]);

    const [committedUntilDate, setCommittedUntilDate] = useDelayedState<Date | undefined>(loading, projectSubscription?.committedUntil);

    const handleSetPrice = (price: SubscriptionPlanPrice) => {
        if (price.billingUnit === BillingUnit.User) {
            setUnlimitedUsers(false);
        }
        setPrice(price);
    };

    return (
        <FormWizardTemplate
            currentStage={0}
            title={
                <FormWizardTitle
                    header={subtitle}
                    title={
                        project ? <span>{translate(Content.projects.create_project_form.title_edit)}</span> : <span>{translate(Content.projects.create_project_form.title)}</span>
                    }
                />
            }
            stages={[
                {
                    primaryButtonText: translate(Content.common.buttons.save),
                    primaryButtonCallback: () =>
                        onConfirm(name, applications, billingProvider, plan, price, maxUsers, unlimitedUsers, recommendedLayerTemplateCategories, committedUntilDate),
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: onClose }],
                    isValid: !!plan && !!price && textValid && maxUsersValid && !makingCustomPrice,
                },
            ]}
        >
            <FormContainer>
                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.project_name)}</FormLabel>
                    <TextField
                        variant="outlined"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={!textValid}
                        helperText={textValid || !!project ? "" : translate(Content.projects.create_project_form.name_invalid)}
                        disabled={!!project}
                    />
                </FormItemContainer>
                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.project_apps)}</FormLabel>
                    <ApplicationSelector
                        onChange={({ app: ApplicationName, enabled: boolean }) => {
                            setApplications({
                                ...applications,
                                [ApplicationName]: boolean,
                            });
                        }}
                        applications={applications}
                        disabled={!!project}
                    />
                </FormItemContainer>

                <Divider />

                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.billing_provider)}</FormLabel>
                    <AutocompleteWithLoading
                        disableClearable
                        // Currently we can only create projects with "None" billing provider, but when editing we can have either Stripe or None
                        options={project?.id ? [ProjectBillingProvider.None, ProjectBillingProvider.Stripe] : [ProjectBillingProvider.None]}
                        value={billingProvider}
                        onChange={(value) => {
                            setBillingProvider(ProjectBillingProvider[value]);
                        }}
                        style={{ width: "100%" }}
                        disabled={!!project}
                        loadingOptions={isLoadingProjectSub && existingSubscription}
                        getOptionLabel={(option) => option}
                    />
                </FormItemContainer>

                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.subscription_plan)}</FormLabel>
                    <AutocompleteWithLoading
                        disableClearable
                        options={plans ?? []}
                        value={plan ?? null}
                        onChange={(value) => {
                            setPlan(value);
                        }}
                        error={!plan}
                        helperText={plan ? "" : translate(Content.projects.create_project_form.plan_invalid)}
                        loadingOptions={(isLoadingPlans || isLoadingProjectSub) && existingSubscription}
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option.name}
                    />
                </FormItemContainer>

                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.subscription_plan_price)}</FormLabel>
                    {makingCustomPrice ? (
                        <CustomPriceEditor
                            onDone={() => {
                                setMakingCustomPrice(false);
                                refetchPrices();
                            }}
                            onCancel={() => setMakingCustomPrice(false)}
                            instanceName={instanceName}
                            projectId={project.id}
                            subscriptionPlanId={plan.id}
                        />
                    ) : (
                        <div style={{ display: "flex" }}>
                            <AutocompleteWithLoading
                                disableClearable
                                disabled={plan == null}
                                options={
                                    prices?.sort((a, b) => {
                                        // Sort by if project.id exists
                                        if (a?.projectId && !b?.projectId) {
                                            return 1;
                                        }
                                        if (b?.projectId && !a?.projectId) {
                                            return -1;
                                        }
                                        return 0;
                                    }) ?? []
                                }
                                groupBy={(option) => (option?.projectId ? "Custom" : "Public")}
                                value={prices?.some((p) => p?.id === price?.id) ? price : null}
                                onChange={(value) => {
                                    handleSetPrice(value as SubscriptionPlanPrice);
                                }}
                                style={{ width: "90%" }}
                                getOptionLabel={(option: SubscriptionPlanPrice) =>
                                    `${option.currencyCode} - ${option.price} - ${option.frequency} - ${option.billingUnit.toString()}`
                                }
                                // If the user has an active subscription on stripe, we do not want to change the currency as this is not supported in stripe
                                getOptionDisabled={(option) =>
                                    existingSubscription &&
                                    billingProvider === ProjectBillingProvider.Stripe &&
                                    projectSubscription.status !== ProjectStatus.Trial &&
                                    (originalCurrency == null || option.currencyCode !== originalCurrency)
                                }
                                loadingOptions={(isLoadingPrices || isLoadingPlans || isLoadingProjectSub) && existingSubscription}
                            />
                            <IconButton disabled={project?.id == null} style={{ marginLeft: "auto" }} onClick={() => setMakingCustomPrice(true)}>
                                <FontAwesomeIcon icon={["far", "plus"]} />
                            </IconButton>
                        </div>
                    )}
                </FormItemContainer>

                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.projects.create_project_form.max_users)}</FormLabel>

                    <TextField
                        variant="outlined"
                        value={unlimitedUsers ? translate(Content.projects.create_project_form.unlimited) : maxUsers ?? 0}
                        onChange={(event) => {
                            if (!unlimitedUsers) setMaxUsers(Number.parseInt(event.target.value, 10));
                        }}
                        error={!maxUsersValid}
                        helperText={maxUsersValid ? undefined : translate(Content.projects.create_project_form.max_users_invalid)}
                        type={unlimitedUsers ? "text" : "number"}
                        disabled={unlimitedUsers}
                    />
                    <div style={{ display: "flex", alignItems: "center", gap: inlineTextIconMargin }}>
                        <Checkbox checked={unlimitedUsers} onChange={() => setUnlimitedUsers(!unlimitedUsers)} disabled={price?.billingUnit === BillingUnit.User} />
                        <FormLabel className="checkbox-label">{translate(Content.projects.create_project_form.unlimited_users)}</FormLabel>
                    </div>
                </FormItemContainer>
                <ProjectFormCommittedUntilInput date={committedUntilDate} onDateChange={setCommittedUntilDate} />
                {project == null && (
                    <>
                        <Divider />
                        <FormItemContainer>
                            <FormLabel className="input-label space-within-content">Recommended layer template categories</FormLabel>
                            <CategoryRepositoryBasicComponent
                                onCategoryChecked={(id) => setRecommendedLayerTemplateCategories((recommended) => [...recommended, id])}
                                assetType={RecommendedAssetType.RecommendedLayerTemplate}
                            />
                        </FormItemContainer>
                    </>
                )}
            </FormContainer>
        </FormWizardTemplate>
    );
};

const FormContainer = styled.div`
    display: flex;
    gap: ${sectionalMargin};
    flex-direction: column;
`;

export const FormItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
