import { AppDetails } from "@iventis/components";

export enum Application {
    CUSTOMERS = "Customers",
    RESOURCES = "Resources",
    PLANS = "Plans",
    PEOPLE = "People",
    DIGITAL_TWIN = "Digital Twin",
}

export const instanceApplication: AppDetails = { appName: Application.CUSTOMERS, icon: "users", url: "/customers" } as const;
export const assetsApplication: AppDetails = { appName: Application.RESOURCES, icon: "file-lines", url: "/resources" } as const;
export const plansApplication: AppDetails = { appName: Application.PLANS, icon: "clipboard-list-check", url: "/plans" } as const;
export const digitalTwinApplication: AppDetails = { appName: Application.DIGITAL_TWIN, icon: "aperture", url: "/digital-twin" } as const;
export const applications: AppDetails[] = [instanceApplication, assetsApplication, plansApplication, digitalTwinApplication];
