/* eslint-disable react/destructuring-assignment */
import { Theme } from "@emotion/react";
import React, { CSSProperties, FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { IventisCellRendererParams, RowNetworkStatus } from "../types/data-table.types";
import { isValueValid } from "../lib/internal-columns";
import { StyledTextCell } from "../styles/data-table.styles";

export const TextCellContents: FunctionComponent<{ children?: React.ReactNode; valid: boolean; className?: string; style?: CSSProperties; dataCy?: string; testId?: string }> = ({
    children,
    valid,
    className,
    style,
    dataCy,
    testId,
}) => (
    <StyledTextCell data-cy={dataCy} style={style} className={`${valid ? "valid" : "invalid"} ${className}`} data-testid={testId}>
        {children}
    </StyledTextCell>
);

export const TextCellRenderer: FunctionComponent<IventisCellRendererParams> = ({ value, required, node, valueFormatted, context, column, data, colDef }) => {
    const displayText = valueFormatted != null ? valueFormatted : value ?? "";
    const dataTestId = `data-table-cell-row-${node.data?.name ?? node.data?.groupDisplayValue ?? ""}-column-${colDef.headerName}`;
    return (
        <>
            {data?.networkStatus === RowNetworkStatus.Template && required ? (
                <StyledTemplateCell data-testid={dataTestId}>{context.translate("Type {{ name }}", { name: colDef.headerName?.toLocaleLowerCase() })}</StyledTemplateCell>
            ) : (
                <TextCellContents dataCy={`${column.getColId()}-${node.data?.networkStatus}`} valid={isValueValid(node, value, required)} testId={dataTestId}>
                    {displayText}
                </TextCellContents>
            )}
        </>
    );
};

const StyledTemplateCell = styled(StyledTextCell)(
    ({ theme }: { theme: Theme }) => `
    font-style: italic;
    color: ${theme.dataTableTheme.typography.subdued};
`
);
