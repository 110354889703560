import React, { PropsWithChildren, useMemo } from "react";
import { SortableContext } from "@dnd-kit/sortable";
import { pointerWithin } from "@dnd-kit/core";
import { SortableDragAndDropProps } from "./drag-and-drop-types";
import { getSortableListType } from "./drag-and-drop-helpers";
import { DragAndDrop } from "./drag-and-drop";

export const DragAndDropSortable = <TItem extends { id: string }>({
    items,
    listType,
    getItems,
    calculateNewParent,
    createOverlayComponent,
    findBottomItem,
    onDragStart,
    onDragEnd,
    onDragOver,
    collisionDetection = pointerWithin,
    children,
    idSuffixes,
    calculateDropZoneHeight = () => undefined,
    modifiers,
    ghostModifiers,
}: PropsWithChildren<SortableDragAndDropProps<TItem>>) => {
    const listSortingStatergy = useMemo(() => getSortableListType(listType), [listType]);

    return (
        <DragAndDrop
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            getItems={getItems}
            calculateNewParent={calculateNewParent}
            createOverlayComponent={createOverlayComponent}
            findBottomItem={findBottomItem}
            collisionDetection={collisionDetection}
            calculateDropZoneHeight={calculateDropZoneHeight}
            idSuffixes={idSuffixes}
            ghostModifiers={ghostModifiers}
            modifiers={modifiers}
        >
            <SortableContext items={items} strategy={listSortingStatergy}>
                {children}
            </SortableContext>
        </DragAndDrop>
    );
};
