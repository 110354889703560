import React, { FunctionComponent, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InteractiveElement } from "@iventis/styles/src/components";
import { styled, borderRadius, iconButtonSize } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Theme } from "@emotion/react";

import { SimpleTooltip } from "@iventis/components";
import { InsertColumnComponentProps } from "../types/data-table.types";

/**
 * Header component that will call the add column callback when clicked
 */
export const InsertColumnComponent: FunctionComponent<InsertColumnComponentProps> = ({ ...props }) => {
    const { insertColumn } = props;

    const translate = useIventisTranslate();

    const headerRef = useRef(null);

    return (
        <>
            <StyledHeaderCell ref={headerRef}>
                <SimpleTooltip text={translate(Content.table.addColumn)} placement="top">
                    <StyledInteractiveElement
                        name="filter"
                        data-testid="add-column"
                        onClick={(e) => {
                            insertColumn();
                            e.stopPropagation();
                        }}
                    >
                        <span className="hidden">{translate(Content.table.addColumn)}</span>
                        <FontAwesomeIcon style={{ color: "white" }} size="xl" icon={["far", "circle-plus"]} />
                    </StyledInteractiveElement>
                </SimpleTooltip>
            </StyledHeaderCell>
        </>
    );
};

const StyledHeaderCell = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    z-index: 10;
    margin-left: 2px;
`;

const StyledInteractiveElement = styled(InteractiveElement)`
    background-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued70};
    width: ${iconButtonSize};
    height: ${iconButtonSize};
    border-radius: ${borderRadius.standard};
`;
