import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { Permission } from "@iventis/domain-model/model/permission";
import { ProjectUser } from "@iventis/domain-model/model/projectUser";
import { UserGroup } from "@iventis/domain-model/model/userGroup";
import { UserGroupWithUsers } from "@iventis/domain-model/model/userGroupWithUsers";
import { UnionOfNodes } from "@iventis/tree-browser";
import { getRenderedNameText } from "./tree-nodes";
import { AdminPersonNode, AdminRootNode, AdminTreeBrowserNode, AdminTreeNodeType, AdminTeamsNode } from "./types";

/** People tree */
export type PeopleNodeTree = AdminRootNode<AdminPersonNode>;

/** Any people tree node */
export type AnyPeopleTreeNode = UnionOfNodes<PeopleNodeTree>;

/** Teams tree */
export type TeamsNodeTree = AdminRootNode<AdminTeamsNode<AdminPersonNode>>;

/** Any teams tree node */
export type AnyTeamTreeNode = UnionOfNodes<TeamsNodeTree>;

export interface AdminPersonNodeExtended<TUser extends ProjectUser, TChild extends AdminTreeBrowserNode = never> extends AdminPersonNode<TChild> {
    person: TUser;
}

/** HINT: pass generic type "never" if no expected children */
export const createPersonNode = <TUser extends ProjectUser, TChild extends AdminTreeBrowserNode = never>(
    person: TUser,
    parentNodeId: string,
    overrides?: { children?: TChild[]; permission?: Permission; team?: UserGroup }
): AdminPersonNodeExtended<TUser, never> => ({
    id: person.id,
    childNodes: [],
    parentId: parentNodeId,
    type: AdminTreeNodeType.User,
    name: getRenderedNameText(person),
    person,
    ...overrides,
});

export const createPeopleNodeTree = (people: ProjectUser[]): AdminRootNode<AdminPersonNode> => {
    const rootNodeId = "root";

    const childNodes = people
        .map((person) => ({ ...createPersonNode<ProjectUser, never>(person, rootNodeId), team: undefined, permission: undefined }))
        .sort((childA, childB) => childA.name.toLowerCase().localeCompare(childB.name.toLowerCase()));

    return {
        id: rootNodeId,
        childNodes,
        parentId: undefined,
        type: AdminTreeNodeType.Root,
        name: "People",
    };
};

export const createTeamsNodeTree = (teams: UserGroupWithUsers[]): TeamsNodeTree => {
    const rootNodeId = "root";

    const childNodes: AdminTeamsNode<AdminPersonNode>[] = teams
        .filter((team) => team.systemUserGroup == null)
        .sort((teamA, teamB) => teamA.name.toLowerCase().localeCompare(teamB.name.toLowerCase()))
        .map((team) => ({
            id: team.id,
            childNodes: team.userMembers
                .map((person) => ({
                    ...createPersonNode<ProjectUser, never>(person as ProjectUser, team.id),
                    team,
                    permission: team.permissions.find((x) => x.ownerId === person.id),
                }))
                .sort((childA, childB) => childA.name.toLowerCase().localeCompare(childB.name.toLowerCase())),
            parentId: rootNodeId,
            type: AdminTreeNodeType.Group,
            name: team.name,
            team,
        }));

    return {
        id: rootNodeId,
        childNodes,
        parentId: undefined,
        type: AdminTreeNodeType.Root,
        name: "Teams",
    };
};

export const isUserLeader = (userId: string, teamId: string, teams: UserGroupWithUsers[]): boolean => {
    const team = teams.find((x) => x.id === teamId);

    const permission = team.permissions.find((x) => x.resourceId === teamId && x.ownerId === userId);

    if (permission === undefined) {
        return false;
    }

    return permission.authorisation === Authorisation.Admin;
};
