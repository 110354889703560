import { createContext } from "react";
import { DragAndDropContextType } from "./drag-and-drop-types";

export const DragAndDropContext = createContext<DragAndDropContextType>({
    newParentId: undefined,
    bottomItemId: undefined,
    idSuffixes: [],
    setOverElement: undefined,
    dropZoneHeight: undefined,
    overElement: undefined,
    setAnchoredToTop: undefined,
});
