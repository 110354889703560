import React, { FunctionComponent, MutableRefObject, PropsWithChildren, createContext, useContext, useRef } from "react";
import { Tour, TourForwardRef } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Step } from "react-joyride";
import { Content } from "@iventis/translations";
import { LayerTourContext } from "./layer-tour";

export const classNameTourAddAttribute = "tour-add-attribute";

interface AttributeTourProps {
    running: boolean;
    onFinish: () => void;
}

export interface AttributeTourContextProps {
    ref: MutableRefObject<TourForwardRef>;
}

export const AttributeTourContext = createContext<AttributeTourContextProps>(null);

export const AttributeTour: FunctionComponent<PropsWithChildren<AttributeTourProps>> = ({ running, onFinish, children }) => {
    const translate = useIventisTranslate();

    const { ref: layerTourRef } = useContext(LayerTourContext);

    const ref = useRef<TourForwardRef>();

    const steps: Step[] = [
        {
            title: translate(Content.map4.attributes.dataDriven.tour.stage_one.title),
            content: translate(Content.map4.attributes.dataDriven.tour.stage_one.content),
            placement: "right",
            target: ".add-attribute-button",
            disableBeacon: true,
        },
    ];

    return (
        <>
            <Tour
                running={running}
                steps={steps}
                onFinish={() => {
                    onFinish?.();
                    if (layerTourRef?.current?.isRunning()) {
                        layerTourRef.current.maximiseTour();
                        layerTourRef.current.nextStep();
                    }
                }}
                ref={ref}
            />
            <AttributeTourContext.Provider value={{ ref }}>{children}</AttributeTourContext.Provider>
        </>
    );
};
