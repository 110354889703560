import { TreeBrowserSort, PendingTreeBrowserRequest, TreeBrowserState, TreeBrowserNode, UnionOfNodes } from "../../types/data-types";

export type TreeBrowserMachineContext<TNode extends TreeBrowserNode> = Omit<TreeBrowserState<TNode>, "isLoadingTree">;

export const TreeBrowserMachineEventType = {
    ADD_NODES: "ADD_NODES" as const,
    ADD_NODE_REQUESTED: "ADD_NODE_REQUESTED" as const,
    DELETE_NODES: "DELETE_NODES" as const,
    DELETE_NODES_REQUESTED: "DELETE_NODES_REQUESTED" as const,
    UPDATE_NODE: "UPDATE_NODE" as const,
    UPDATE_NODES: "UPDATE_NODES" as const,
    UPDATE_NODE_REQUESTED: "UPDATE_NODE_REQUESTED" as const,
    REPLACE_NODE: "REPLACE_NODE" as const,
    GET_NODE_REQUESTED: "GET_NODE_REQUESTED" as const,
    SORT_NODES: "SORT_NODES" as const,
    SORT_NODES_REQUESTED: "SORT_NODES_REQUESTED" as const,
    GET_THUMBNAILS: "GET_THUMBNAILS" as const,
    GET_THUMBNAILS_REQUESTED: "GET_THUMBNAILS_REQUESTED" as const,
    TOGGLE_EXPAND_NODE: "TOGGLE_EXPAND_NODE" as const,
    COLLAPSE_NODE: "COLLAPSE_NODE" as const,
    EXPAND_NODE: "EXPAND_NODE" as const,
    TOGGLE_SELECT_NODES: "TOGGLE_SELECT_NODES" as const,
    SELECT_SINGLE_NODE: "SELECT_SINGLE_NODE" as const,
    APPEND_LOADING_NODE: "APPEND_LOADING_NODE" as const,
    REMOVE_LOADING_NODE: "REMOVE_LOADING_NODE" as const,
    SET_MAIN_NODE: "SET_MAIN_NODE" as const,
    SET_TREE: "SET_TREE" as const,
    TOGGLE_SELECT_ALL: "TOGGLE_SELECT_ALL" as const,
    RESOURCE_OPENED: "RESOURCE_OPENED" as const,
    RESOURCE_CLOSED: "RESOURCE_CLOSED" as const,
    APPEND_REQUEST_ID: "APPEND_REQUEST_ID" as const,
    REMOVE_REQUEST_ID: "REMOVE_REQUEST_ID" as const,
    MOVE_NODES_REQUESTED: "MOVE_NODES_REQUESTED" as const,
    MOVE_NODES: "MOVE_NODES" as const,
    ADD_FAVOURITE: "ADD_FAVOURITE" as const,
    ADD_FAVOURITE_REQUESTED: "ADD_FAVOURITE_REQUESTED" as const,
    DELETE_FAVOURITE: "DELETE_FAVOURITE" as const,
    DELETE_FAVOURITE_REQUESTED: "DELETE_FAVOURITE_REQUESTED" as const,
    SET_WITH_EXISTING_TREE: "SET_WITH_EXISTING_TREE" as const,
    SEARCH: "SEARCH" as const,
    UPDATE_CONTEXT: "UPDATE_CONTEXT" as const,
};

export interface AddNodes<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.ADD_NODES;
    payload: TNode[];
}
export interface AddNodeRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.ADD_NODE_REQUESTED;
    payload: { node: TNode; context: TreeBrowserMachineContext<TNode> };
}

export interface DeleteNode<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.DELETE_NODES_REQUESTED;
    payload: { nodes: TNode[]; context: TreeBrowserMachineContext<TNode>; onDone?: (nodes: TNode[]) => void };
}

export interface DeleteNodesLocally {
    type: typeof TreeBrowserMachineEventType.DELETE_NODES;
    payload: string[];
}

export interface UpdateNode<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.UPDATE_NODE_REQUESTED;
    payload: { node: TNode; context: TreeBrowserMachineContext<TNode>; previousNodeParentId?: string; replaceChildren?: boolean };
}

export interface UpdateNodeLocally<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.UPDATE_NODE;
    payload: { node: TNode; previousNodeParentId?: string; skipExpandedCheck?: boolean; replaceChildren?: boolean };
}

export interface UpdateNodesLocally<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.UPDATE_NODES;
    payload: { nodes: TNode[] };
}

export interface ToggleExpandNode<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.TOGGLE_EXPAND_NODE | typeof TreeBrowserMachineEventType.EXPAND_NODE;
    payload: { node: TNode; skipGet?: boolean; forceGet?: boolean };
}

export interface CollapseNode {
    type: typeof TreeBrowserMachineEventType.COLLAPSE_NODE;
    payload: { id: string };
}

export interface ReplaceNode<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.REPLACE_NODE;
    data: TNode;
}

export interface GetNodeRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.GET_NODE_REQUESTED;
    payload: { nodeId: string; context: TreeBrowserMachineContext<TNode>; sort: TreeBrowserSort<TNode> };
}

export interface ToggleSelectNodes<TNode> {
    type: typeof TreeBrowserMachineEventType.TOGGLE_SELECT_NODES;
    payload: { nodes: string[] | TNode[]; from: string; ignoreExistingSelection?: boolean; ctrlHeld?: boolean };
}

export interface SetMainNode<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.SET_MAIN_NODE;
    payload: TNode;
}

export interface SetTree<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.SET_TREE;
    payload: TNode;
}

export interface SortNodesRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.SORT_NODES_REQUESTED;
    payload: { sort: TreeBrowserSort<TNode>; context: TreeBrowserMachineContext<TNode>; treeId: string; expandedNodeIds: string[]; mainNodeId: string };
}

export interface SortNodes<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.SORT_NODES;
    payload: { sort: TreeBrowserSort<UnionOfNodes<TNode>>; sortedTree: TNode };
}

export interface GetThumbnails {
    type: typeof TreeBrowserMachineEventType.GET_THUMBNAILS;
    payload: { [nodeId: string]: string };
}

export interface GetThumbnailsRequested<TNodeTree extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.GET_THUMBNAILS_REQUESTED;
    payload: { node: UnionOfNodes<TNodeTree>; tree: TNodeTree };
}

export interface AppendLoadingNode {
    type: typeof TreeBrowserMachineEventType.APPEND_LOADING_NODE;
    payload: string;
}

export interface RemoveLoadingNode {
    type: typeof TreeBrowserMachineEventType.REMOVE_LOADING_NODE;
    payload: string;
}

export interface SelectAllNodes {
    type: typeof TreeBrowserMachineEventType.TOGGLE_SELECT_ALL;
}

export interface ResourceOpened {
    type: typeof TreeBrowserMachineEventType.RESOURCE_OPENED;
    payload: { id: string; sourceId?: string };
}

export interface ResourceClosed {
    type: typeof TreeBrowserMachineEventType.RESOURCE_CLOSED;
    payload: { id: string; sourceId?: string };
}

export interface AppendRequestId {
    type: typeof TreeBrowserMachineEventType.APPEND_REQUEST_ID;
    payload: PendingTreeBrowserRequest[];
}

export interface RemoveRequestId {
    type: typeof TreeBrowserMachineEventType.REMOVE_REQUEST_ID;
    payload: PendingTreeBrowserRequest[];
}

export interface MoveNodesRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.MOVE_NODES_REQUESTED;
    payload: { nodes: TNode[]; context: TreeBrowserMachineContext<TNode>; newParentId: string; onDone?: (nodes: TNode[]) => void };
}

export interface MoveNodesLocally<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.MOVE_NODES;
    payload: { nodes: TNode[]; newParentId: string };
}

export interface AddFavourite<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.ADD_FAVOURITE;
    payload: { node: TNode };
}

export interface AddFavouriteRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.ADD_FAVOURITE_REQUESTED;
    payload: { node: TNode };
}

export interface DeleteFavourite<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.DELETE_FAVOURITE;
    payload: { node: TNode };
}

export interface DeleteFavouriteRequested<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.DELETE_FAVOURITE_REQUESTED;
    payload: { node: TNode };
}

export interface setWithExistingTree<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.SET_WITH_EXISTING_TREE;
    payload: { tree: TNode; mainNodeId: string };
}

export interface search {
    type: typeof TreeBrowserMachineEventType.SEARCH;
    payload: string;
}

export interface UpdateContext<TNode extends TreeBrowserNode> {
    type: typeof TreeBrowserMachineEventType.UPDATE_CONTEXT;
    payload: TreeBrowserMachineContext<TNode>;
}

export type TreeBrowserMachineEvent<TNode extends TreeBrowserNode> =
    | AddNodes<UnionOfNodes<TNode>>
    | AddNodeRequested<UnionOfNodes<TNode>>
    | DeleteNode<UnionOfNodes<TNode>>
    | DeleteNodesLocally
    | UpdateNode<UnionOfNodes<TNode>>
    | UpdateNodeLocally<UnionOfNodes<TNode>>
    | ToggleExpandNode<UnionOfNodes<TNode>>
    | CollapseNode
    | ReplaceNode<UnionOfNodes<TNode>>
    | GetNodeRequested<UnionOfNodes<TNode>>
    | ToggleSelectNodes<UnionOfNodes<TNode>>
    | SetMainNode<UnionOfNodes<TNode>>
    | SetTree<UnionOfNodes<TNode>>
    | SortNodesRequested<UnionOfNodes<TNode>>
    | SortNodes<TNode>
    | GetThumbnails
    | GetThumbnailsRequested<TNode>
    | AppendLoadingNode
    | RemoveLoadingNode
    | SelectAllNodes
    | ResourceOpened
    | ResourceClosed
    | AppendRequestId
    | RemoveRequestId
    | MoveNodesRequested<UnionOfNodes<TNode>>
    | MoveNodesLocally<UnionOfNodes<TNode>>
    | AddFavourite<UnionOfNodes<TNode>>
    | AddFavouriteRequested<UnionOfNodes<TNode>>
    | DeleteFavourite<UnionOfNodes<TNode>>
    | DeleteFavouriteRequested<UnionOfNodes<TNode>>
    | setWithExistingTree<TNode>
    | UpdateNodesLocally<UnionOfNodes<TNode>>
    | search
    | UpdateContext<UnionOfNodes<TNode>>;
