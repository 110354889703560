import React, { ChangeEvent, FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { borderRadius, fontSizes } from "@iventis/styles/src/atomic-rules";

interface TextValueInputProps {
    inputValue: number;
    minValue: number;
    maxValue: number;
    emitValueChange: (value: number) => void;
    className?: string;
    testId?: string;
}

/**
 * Component where the text value can be typed in by the user
 * @param { number } inputValue - the value used initially
 * @param { number } minValue - the minimum value the input can be if a value is chosen which is below this the minimum value is used instead
 * @param { number } maxValue - the maximum value the input can be if a value is chosen which is above this the maximum value is used instead
 * @param { (number) => void } emitValueChange - a function to handle when a new value is inputted
 */
const TextValueInputComponent: FunctionComponent<TextValueInputProps> = ({ inputValue, minValue, maxValue, emitValueChange, className, testId }) => {
    // when the value is changed
    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        // if the text box is empty then use min value
        if (event.target.value === "") {
            emitValueChange(minValue);
            // setValue(minValue);
        } else {
            // if the value is not empty parse the string
            const parsedValue = parseInt(event.target.value, 10);
            if (parsedValue > maxValue) {
                // if value is above the max value use max value
                emitValueChange(maxValue);
            } else if (parsedValue < minValue) {
                // the value is below min value use min value
                emitValueChange(minValue);
            } else {
                // use outputted value
                emitValueChange(parsedValue);
            }
        }
    };

    return (
        <StyledValueContainer>
            <StyledInput className={className}>
                <input data-testid={testId} className="input-box" type="number" value={inputValue} onChange={handleValueChange} />
                <div className="adornment">%</div>
            </StyledInput>
        </StyledValueContainer>
    );
};

export const StyledValueContainer = styled.div`
    flex-basis: 20%;
    width: 100%;
`;

const StyledInput = styled.div<{ theme?: Theme }>`
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) => `1px solid ${props.theme.shades.two}`};
    background-color: ${(props) => props.theme.secondaryColors.blank};
    border-radius: ${borderRadius.standard};
    padding: 10px 3px;
    input:focus {
        outline: none;
    }
    .input-box {
        width: 60%;
        background: transparent;
        border: none;
        text-align: right;
        padding-right: 1px;
        font-size: ${fontSizes.small};
    }
    .adornment {
        width: 40%;
        text-align: left;
        font-size: ${fontSizes.small};
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export default TextValueInputComponent;
