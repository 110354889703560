// This file should include all generic column generator functions for the data table.
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable one-var */
/* eslint-disable sort-vars */
import { compareAsc, differenceInSeconds } from "date-fns";
import { setTimeOnEventDay, timeRangeFrom, timeRangeTo, timeSpanToTimeDifference } from "@iventis/utilities";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { DataFieldListItemPropertyValue } from "@iventis/domain-model/model/dataFieldListItemPropertyValue";
import { ReplaySubject } from "rxjs";
import { ColDef, ICellRendererParams, IDoesFilterPassParams } from "@ag-grid-community/core";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { Content } from "@iventis/translations/content/typed-content";
import { FilterType } from "@iventis/types";
import { basicTranslator } from "@iventis/translations/use-iventis-translate";
import { TextCellRenderer } from "../components/text-cell-renderer";
import { timeSpanValidator } from "../validators/time-span-validator";
import {
    TimeWithPrepositionCellEditor,
    ColumnProps,
    ListItemCellComponent,
    ListItemFilterComponent,
    NumberFilterComponent,
    TextFilterComponent,
    ListItemCellEditorComponent,
    NumberCellEditor,
    TimeCellEditor,
    BooleanCellEditor,
    EditorValidator,
    TimeCellComponent,
    GetTimeCellValue,
    ListItemCellComponentProps,
    ListItem,
    BooleanCell,
    TextCellEditor,
    MultiSelectCellEditorComponent,
    MultiSelectCellComponent,
    IventisRowNode,
    SubheaderListItemCellEditorProps,
    IventisTableData,
    ListItemCellEditorProps,
    TimeCellValue,
    DataTableContext,
    TIME_RANGE_FROM,
    TIME_RANGE_TO,
    TimeOnlyCellEditorParams,
    AsyncTextCellParams,
} from "../index";
import { SubheaderListItemCellEditor } from "../components/subheader-list-item-cell-editor";
import { CheckboxCellRenderer } from "../components/checkbox-cell-renderer";
import { EmptyCellRenderer } from "../components/empty-cell-renderer";
import { RadioButtonCellComponent } from "../components/radio-button-cell";
import { TimeOnlyCellEditor } from "../components/time-only-cell-editor";
import { AsyncTextCell } from "../components/async-text-cell";
import { DateCellRenderer } from "../components/date-cell-renderer";
import { DateCellEditor } from "../components/date-cell-editor";
import { HyperlinkCellRenderer } from "../components/hyperlink-cell-renderer";

export const generateListColumn = <K extends string = string>(
    params: Pick<ListItemCellEditorProps, "componentParams" | "filterOptions">,
    fieldDetails: { field: K; headerName: string },
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: true,
        editable: true,
        suppressAutoSize: true,
        // Pass callbacks for list items into all of these
        cellEditor: ListItemCellEditorComponent,
        cellEditorParams: { componentParams: params.componentParams, filterOptions: params.filterOptions },
        cellEditorPopup: false,
        filter: ListItemFilterComponent,
        filterParams: {
            options: params.componentParams.listItems,
            initialFilter: params.componentParams.initialFilter ?? params.componentParams.listItems,
            apiFilterType,
        },
        cellRenderer: ListItemCellComponent,
        cellRendererParams: { componentParams: params.componentParams },
        comparator: (valueA: ListItem, valueB: ListItem) => (valueA.name ? valueA.name.localeCompare(valueB.name) : 0),
        menuTabs: ["filterMenuTab"],
        // By default we should be able to group all list columns
        enableRowGroup: true,
        ...additionalProps,
    },
});

export const generateSubheaderListColumn = (
    params: Pick<SubheaderListItemCellEditorProps, "componentParams" | "filterOptions">,
    props: OptionalExceptFor<ColDef, "field" | "headerName">,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        cellEditorParams: { componentParams: params.componentParams, filterOptions: params.filterOptions },
        cellRendererParams: { componentParams: params.componentParams },
        cellEditorPopup: false,
        editable: true,
        sortable: true,
        cellEditor: SubheaderListItemCellEditor,
        cellRenderer: ListItemCellComponent,
        filterParams: { apiFilterType },
        ...props,
    },
});

export const generateMultiSelectColumn = <K extends string = string>(
    listItems: OptionalExceptFor<Pick<ListItemCellComponentProps, "componentParams" | "filterOptions">, "componentParams"> | (() => ReplaySubject<ListItem[]>),
    fieldDetails: { field: K; headerName: string },
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: true,
        editable: true,
        suppressAutoSize: true,

        // Pass callbacks for list items into all of these
        cellEditor: MultiSelectCellEditorComponent,
        cellEditorParams: listItems,
        filter: ListItemFilterComponent,
        filterParams: {
            ...listItems,
            doesFilterPass: (params: IDoesFilterPassParams, checkedItems: { id: string; name: string }[]): boolean =>
                checkedItems.some((item) => params.data[fieldDetails.field]?.some((i) => i.id === item.id)),
            apiFilterType,
        },
        cellRenderer: MultiSelectCellComponent,
        cellRendererParams: listItems,
        comparator: (valueA: ListItem, valueB: ListItem) => valueA?.name?.localeCompare(valueB?.name),
        menuTabs: ["filterMenuTab"],
        ...additionalProps,
    },
});

export const generateTextColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: true,
        editable: true,
        suppressAutoSize: true,
        filter: TextFilterComponent,
        filterParams: { apiFilterType },
        cellRenderer: TextCellRenderer,
        cellEditorPopup: false,
        cellEditor: TextCellEditor,
        menuTabs: ["filterMenuTab"],
        enableRowGroup: true,
        ...additionalProps,
    },
});

export const generateAsyncTextColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    getAsyncValue: AsyncTextCellParams["getAsyncValue"],
    additionalProps?: ColDef
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: false,
        editable: false,
        suppressAutoSize: true,
        cellRenderer: AsyncTextCell,
        cellRendererParams: { getAsyncValue },
        ...additionalProps,
    },
});

export const generateDateColumn = <K extends string = string>(fieldDetails: { field: K; headerName: string }, additionalProps?: ColDef): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: false,
        editable: true,
        cellRenderer: DateCellRenderer,
        cellRendererParams: {},
        cellEditor: DateCellEditor,
        ...additionalProps,
    },
});

export const generateHyperlinkColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    onHyerlinkClick: (columnId: string, rowData: unknown) => void,
    additionalProps?: ColDef
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: false,
        editable: false,
        cellRenderer: HyperlinkCellRenderer,
        cellRendererParams: { onClick: onHyerlinkClick },
        cellEditor: null,
        ...additionalProps,
    },
});

export const generateNumberColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => {
    const additionalPropsKeys = additionalProps && Object?.keys(additionalProps);
    const agGridColumnProps: ColumnProps["agGridColumnProps"] = {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: true,
        editable: true,
        cellEditorPopup: false,
        suppressAutoSize: true,
        filter: NumberFilterComponent,
        filterParams: { apiFilterType },
        cellRenderer: additionalPropsKeys?.includes("cellRenderer") ? null : "textCell",
        cellEditor: NumberCellEditor,
        menuTabs: ["filterMenuTab"],
        aggFunc: "sum",
        ...additionalProps,
    };

    return { agGridColumnProps };
};

export const timeValidator: EditorValidator = (node, colId, value) => {
    let doesFail = false;
    switch (colId) {
        case "startAt": {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            doesFail = differenceInSeconds(node.data.endAt?.value, (value as any)?.value) < 0;
            break;
        }
        case "endAt": {
            doesFail = differenceInSeconds((value as any)?.value, node.data.startAt?.value) < 0;
            /* eslint-enable @typescript-eslint/no-explicit-any */
            break;
        }
        default: {
            break;
        }
    }

    return {
        doesFail,
        errorMessage: Content.table.startEndTimeValidationError,
    };
};

export const generateTimeOnlyColumn = (fieldDetails: { field: string; headerName: string }, additionalProps?: ColDef): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        suppressMenu: true,
        sortable: false,
        editable: true,
        cellEditorPopup: false,
        cellRenderer: TextCellRenderer,
        cellEditor: TimeOnlyCellEditor,
        ...additionalProps,
    },
});

export const generateTimeColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    getTimeCellValue: GetTimeCellValue,
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        suppressMenu: true,
        sortable: true,
        comparator: (valueA, valueB) => compareAsc(valueA.value, valueB.value),
        editable: true,
        cellEditorPopup: false,
        cellRenderer: TimeCellComponent,
        cellEditor: TimeCellEditor,
        cellEditorParams: { validator: timeValidator, getTimeCellValue },
        filterParams: { apiFilterType },
        ...additionalProps,
    },
});

export const mapColumnIdsToName = (cols: ColumnProps[]) => {
    const columns = cols.map(({ agGridColumnProps }) => ({ id: agGridColumnProps.field, name: agGridColumnProps.headerName }));
    const columnIdsToNameMapping = {};
    columns.forEach((col) => {
        columnIdsToNameMapping[col.id] = col.name;
    });
    return columnIdsToNameMapping;
};

export const convertDataFieldsToColumns = (
    dataFields: DataField[],
    options?: {
        /* For lazy-loading list items */
        listItemGetter?: (dataFieldId: string) => ReplaySubject<DataFieldListItem[]>;
        valueGetter?: (dataFieldId: string, dataFieldType: DataFieldType) => ColDef["valueGetter"];
        valueFormatter?: (dataFieldId: string) => ColDef["valueFormatter"];
        valueSetter?: ColDef["valueSetter"];
        filterListItems?: (items: DataFieldListItem[], node: IventisRowNode, colId: string) => DataFieldListItem[];
        hiddenDataFields?: DataField[];
        orderedDataFields?: string[];
        cellEditable?: (rowData: unknown, dataFieldId: string) => boolean;
        translate?: DataTableContext["translate"];
        listItemCellEditorComponent?: typeof ListItemCellEditorComponent;
        asyncTextGetter?: (value: string) => Promise<string>;
        onHyperlinkClick?: (columnId: string, rowData: IventisTableData) => void;
    }
): ColumnProps[] => {
    if (options?.orderedDataFields) {
        // if there is an order passed into the function
        const { orderedDataFields } = options;
        dataFields.sort((a, b) => orderedDataFields.indexOf(a.id) - orderedDataFields.indexOf(b.id));
    }

    const translate = typeof options.translate === "function" ? options.translate : basicTranslator;

    return dataFields.reduce((acc, dataField) => {
        const { id, name, type, listValues } = dataField;
        const fieldDetails = {
            field: id,
            headerName: name,
        };
        const additionalProps: ColDef = {
            editable: options.cellEditable ? (params) => options.cellEditable(params.data, id) : true,
            sortable: true,
            menuTabs: ["filterMenuTab"],
            valueGetter: options.valueGetter?.(id, type),
            valueFormatter: options.valueFormatter?.(id),
            valueSetter: options.valueSetter,
            maxWidth: 400,
            initialHide: options?.hiddenDataFields == null ? false : options?.hiddenDataFields?.some((hiddenDf) => hiddenDf.id === id),
        };
        const columnsToAppend: ColumnProps[] = [];
        switch (type) {
            case DataFieldType.List:
                columnsToAppend.push(
                    ...[
                        generateListColumn(
                            {
                                componentParams: {
                                    listItems: options?.listItemGetter ? () => options.listItemGetter(id) : [...listValues],
                                    onDone: (value, node) => {
                                        const listItem = dataField.listValues?.find((lv) => lv.id === value);
                                        // Update the property values that match the list item
                                        dataField.listItemProperties.forEach((property) => {
                                            const propertyValue = listItem.propertyValues.find((p) => p.propertyId === property.id);
                                            node.setDataValue(property.id, propertyValue?.text ?? propertyValue?.number);
                                        });
                                    },
                                },
                            },
                            fieldDetails,
                            { ...additionalProps, cellEditor: options?.listItemCellEditorComponent ?? ListItemCellEditorComponent },
                            "IventisFilterGuidArray"
                        ),
                        ...(generateListItemPropertyColumns(dataField, additionalProps) ?? []),
                    ]
                );
                break;
            case DataFieldType.MultiSelect:
                columnsToAppend.push(
                    generateMultiSelectColumn(
                        { componentParams: { listItems: () => options?.listItemGetter(id) }, filterOptions: options?.filterListItems },
                        fieldDetails,
                        additionalProps,
                        "IventisFilterGuidArray"
                    )
                );
                break;
            case DataFieldType.Number:
                columnsToAppend.push(generateNumberColumn(fieldDetails, additionalProps, "IventisFilterNumber"));
                break;
            case DataFieldType.Text:
                columnsToAppend.push(generateTextColumn(fieldDetails, additionalProps, "IventisFilterString"));
                break;
            case DataFieldType.Tickbox:
                columnsToAppend.push(generateBooleanColumn(fieldDetails, additionalProps, "IventisFilterBoolean"));
                break;
            case DataFieldType.Time:
                columnsToAppend.push(generateTimeOnlyColumn(fieldDetails, additionalProps));
                break;
            case DataFieldType.Image:
                columnsToAppend.push(generateAsyncTextColumn(fieldDetails, options.asyncTextGetter, additionalProps));
                break;
            case DataFieldType.Date:
                columnsToAppend.push(generateDateColumn(fieldDetails, additionalProps));
                break;
            case DataFieldType.RepeatedTimeRanges:
                columnsToAppend.push(generateHyperlinkColumn(fieldDetails, options.onHyperlinkClick, additionalProps));
                break;
            case DataFieldType.TimeRange: {
                // Gets the relevant inner time span value (we know which segment of the time range we need to used by checking the column field)
                const timeRangeValueGetter: ColDef["valueGetter"] = (...props) => {
                    let value = typeof additionalProps.valueGetter === "function" ? additionalProps.valueGetter(...props) : undefined;
                    if (value != null) {
                        const { field } = props[0].colDef;
                        if (field.includes(TIME_RANGE_FROM)) {
                            value = timeRangeFrom(value);
                        }
                        if (field.includes(TIME_RANGE_TO)) {
                            value = timeRangeTo(value);
                        }
                    }
                    return value;
                };
                /** Called when one the time range editors is submitting a value. If the time range didn't previously exist,
                 * we must also set the other range value to ensure we don't send up an undefined timespan (i.e. "07:00:00-undefined") */
                const getOnDoneCallback = (range: typeof TIME_RANGE_FROM | typeof TIME_RANGE_TO): TimeOnlyCellEditorParams["onDone"] => (value, node) => {
                    if (node.data.dataFieldValues?.[id] == null) {
                        const timeRangeToUpdate = {
                            [TIME_RANGE_FROM]: TIME_RANGE_TO,
                            [TIME_RANGE_TO]: TIME_RANGE_FROM,
                        } as const;
                        node.setDataValue(addTimeRangeSuffix(id, timeRangeToUpdate[range]), value);
                    }
                };
                const timeRangeProps: ColDef = {
                    ...additionalProps,
                    valueGetter: timeRangeValueGetter,
                };
                columnsToAppend.push(
                    ...[
                        generateTimeOnlyColumn(
                            { field: addTimeRangeSuffix(id, "time-range-from"), headerName: `${name} (${translate("From")})` },
                            { ...timeRangeProps, cellEditorParams: { ...timeRangeProps.cellEditorParams, onDone: getOnDoneCallback(TIME_RANGE_FROM) } }
                        ),
                        generateTimeOnlyColumn(
                            { field: addTimeRangeSuffix(id, "time-range-to"), headerName: `${name} (${translate("To")})` },
                            { ...timeRangeProps, cellEditorParams: { ...timeRangeProps.cellEditorParams, onDone: getOnDoneCallback(TIME_RANGE_TO) } }
                        ),
                    ]
                );
                break;
            }
            default:
                columnsToAppend.push(generateTextColumn(fieldDetails, additionalProps, "IventisFilterString"));
        }
        return [...acc, ...columnsToAppend];
    }, [] as ColumnProps[]);
};

export const generateBooleanColumn = (fieldDetails: { field: string; headerName: string }, additionalProps?: ColDef, apiFilterType?: FilterType): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        sortable: true,
        editable: false,
        cellRenderer: BooleanCell,
        cellEditor: BooleanCellEditor,
        suppressMenu: true,
        cellEditorPopup: false,
        filterParams: { apiFilterType },
        ...additionalProps,
    },
});

/**
 * Disables filtering on the data table column where this is called.
 * @returns The props required to disable filtering on a column in the data table
 */
export const disableFilteringOnColumn = (): ColDef => ({ filter: null, menuTabs: [] });

export const generateTimeSpanColumn = <K extends string = string>(
    fieldDetails: { field: K; headerName: string },
    getTimeCellValue: GetTimeCellValue,
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        suppressMenu: true,
        sortable: false,
        comparator: (valueA, valueB) => compareAsc(valueA.value, valueB.value),
        editable: true,
        cellRenderer: TextCellRenderer,
        cellEditor: TimeWithPrepositionCellEditor,
        cellEditorParams: { validator: timeSpanValidator },
        valueFormatter: (params) => (params.value != null ? timeSpanToTimeDifference(params.value) : params.value),
        filterParams: { apiFilterType },
        ...additionalProps,
    },
});

export const generateCheckboxColumn = <TData extends IventisTableData>(
    fieldDetails: { field: string; headerName: string },
    /** If returns true, checkbox does not appear for that row */
    uncheckable: (params: ICellRendererParams<TData>) => boolean,
    additionalProps?: ColDef,
    apiFilterType?: FilterType
): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        editable: false,
        suppressMenu: true,
        suppressAutoSize: true,
        cellRenderer: CheckboxCellRenderer,
        cellRendererSelector: (params) => ({ component: uncheckable(params) ? EmptyCellRenderer : CheckboxCellRenderer }),
        filterParams: { apiFilterType },
        ...additionalProps,
    },
});

/** Data field list items can have properties, and sometimes we want those properties displayed as columns. This generates those columns */
export const generateListItemPropertyColumns = (dataField: DataField, additionalProps?: ColDef) =>
    dataField.listItemProperties?.map((property) => {
        const fieldDetails = { field: property.id, headerName: property.name };
        const newAdditionalProps: ColDef = {
            ...additionalProps,
            // For now, we do not support filetering/sorting by the list item properties
            menuTabs: [],
            filter: undefined,
            filterParams: undefined,
            sortable: false,
            // We should never allow the user to edit these, they must edit the list item column instead, these are read only
            editable: false,
        };
        const valueGetter = (propertyValueKey: keyof DataFieldListItemPropertyValue) => ({ node }: { node: IventisRowNode }) => {
            const dataFieldValue = node.data?.dataFieldValues?.[dataField.id];
            if (typeof dataFieldValue !== "object") {
                const listItem = dataField.listValues?.find((listItem) => listItem.id === dataFieldValue);
                const propertyValue = listItem?.propertyValues.find((value) => value.propertyId === property.id);
                if (propertyValue == null) {
                    return null;
                }
                return propertyValue[propertyValueKey];
            }
            // If we reach this point, it means we are likely on an aggregated row,
            // so if the property value is a number, we sum up the values (multiplied by the number of occurrences)
            if (dataFieldValue != null && "listItemAggregations" in dataFieldValue && propertyValueKey === "number") {
                const aggregatedValue = dataField.listValues.reduce(
                    (acc, listItem) =>
                        acc + (listItem.propertyValues.find((pv) => pv.propertyId === property.id)?.number ?? 0) * (dataFieldValue.listItemAggregations[listItem.id] ?? 0),
                    0
                );
                return aggregatedValue;
            }
            return null;
        };
        if (property.type === DataFieldListItemPropertyType.Text) {
            return generateTextColumn(fieldDetails, { ...newAdditionalProps, valueGetter: valueGetter("text") }, "IventisFilterString");
        }
        if (property.type === DataFieldListItemPropertyType.Number) {
            return generateNumberColumn(fieldDetails, { ...newAdditionalProps, valueGetter: valueGetter("number") }, "IventisFilterNumber");
        }
        throw new Error(`List item property type not supported: ${property.type}`);
    });

export const generateRadioButtonColumn = (fieldDetails: { field: string; headerName: string }, additionalProps?: ColDef, apiFilterType?: FilterType): ColumnProps => ({
    agGridColumnProps: {
        field: fieldDetails.field,
        headerName: fieldDetails.headerName,
        editable: false,
        suppressMenu: true,
        suppressAutoSize: true,
        cellRenderer: RadioButtonCellComponent,
        filterParams: { apiFilterType },
        ...additionalProps,
    },
});

/**
 * Genrates a time cell value from the given hours, minutes and the node it's related to
 * @param {string} hours
 * @param {string} mins
 * @param {RowNode} node - row node containing a date field value, where we can get the date of the event
 * @returns {TimeCellValue} - display text and value in Date format
 */
export const getTimeCellValue = <TNode extends IventisRowNode>(hours: string, mins: string, seconds: string, node: TNode, timezone: string): TimeCellValue => {
    let { date } = node.data;
    if (date == null) {
        date = { value: new Date(Date.now()), displayText: "" };
    }
    const time = `${hours}:${mins || "00"}:${seconds || "00"}`;
    return { displayText: time, value: setTimeOnEventDay(date.value, time, timezone) };
};

export const addTimeRangeSuffix = (attributeId: string, suffix: typeof TIME_RANGE_FROM | typeof TIME_RANGE_TO) => `${attributeId}|${suffix}`;

export const removeFieldSuffixes = (field: string) => field.replace("|", "").replace(TIME_RANGE_FROM, "").replace(TIME_RANGE_TO, "");
