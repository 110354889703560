import { AppBarSubmenu, AppBarSubmenuTheme } from "@iventis/components";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { subMenuItems } from "./instances.types";

const InstancesComponent = () => {
    const nav = useNavigate();
    const location = useLocation();
    const selectedItem = location?.pathname?.replace("/customers", "").replace("/", "");
    useEffect(() => {
        if (selectedItem === "") {
            nav(subMenuItems[0].id);
        }
    }, [selectedItem]);
    return (
        <>
            <AppBarSubmenu
                items={subMenuItems}
                selectedItem={selectedItem}
                onItemClick={(item) => nav({ pathname: `/customers/${item.id}` })}
                fullScreenTitle={selectedItem}
                mobileOpen={false}
                theme={AppBarSubmenuTheme.Administrative}
            />
            <Outlet />
        </>
    );
};

export default InstancesComponent;
