/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import {
    ICellRendererParams,
    IFilterParams,
    IHeaderParams,
    IFilter,
    GridOptions,
    GridApi,
    ColumnApi,
    ICellEditor,
    IDoesFilterPassParams,
    ICellEditorParams,
    ColDef,
    NewValueParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from "@ag-grid-community/core";
import { toast } from "@iventis/toasts";
import { Observable, ReplaySubject } from "rxjs";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { FilterType } from "@iventis/types/api.types";

export type IventisGridApi = GridApi;

export type IventisColumnApi = ColumnApi;

export type IventisColDef = ColDef;

export type IventisServerSideDataSource = IServerSideDatasource;

export type IventisServerSideGetRowsParams<TData extends IventisTableData, TContext extends DataTableContext = DataTableContext> = IServerSideGetRowsParams<TData, TContext>;

enum RowModelType {
    Infinite = "infinite",
    ServerSide = "serverSide",
    ClientSide = "clientSide",
}
interface ColumnProps {
    agGridColumnProps: ColDef;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderCellComponentProps extends IHeaderParams<IventisTableData, DataTableContext> {}

interface InsertColumnComponentProps {
    insertColumn: () => void;
}

enum ColumnDataType {
    LIST = "list",
    DATE = "date",
    NUMBER = "number",
    TEXT = "text",
}

interface IventisFilterOverrride extends IFilter {
    getModel: () => IventisFilterModel;
}

type IventisFilterRef = Pick<IventisFilterOverrride, "isFilterActive" | "getModel" | "doesFilterPass">;

interface IventisTableData {
    id: string;
    name?: string;
    canEdit: boolean;
    index?: number;
    new?: boolean;
    type?: { id: string; name: string };
    startAt?: TimeCellValue;
    endAt?: TimeCellValue;
    duration?: number;
    networkStatus?: RowNetworkStatus;
    date?: TimeCellValue;
    /** Often our table nodes may have data field values. Default to any, these can be overridden */
    dataFieldValues?: any;
    rowDrag?: boolean;
    /** When we render a group row, this value will be used to display */
    groupDisplayValue?: string;
    /** Is the group a "none" group? (contains objects which don't have a value for the column being grouped) */
    noneGroup?: boolean;
    /** When grouping is applied, we want a child count */
    count?: number;
}

interface IventisTimeCellData extends IventisTableData {
    startAt: TimeCellValue;
    endAt: TimeCellValue;
}

interface IventisRowNode<T extends IventisTableData = IventisTableData> extends IRowNode<T> {
    data: T;
}

type HasDataTableValueChanged<Data extends IventisTableData = IventisTableData> = <K extends keyof Data, T extends Data[K]>(
    prev: T,
    next: T,
    colId: K,
    node: IventisRowNode<Data>
) => boolean;

interface IventisCellParams {
    required?: boolean;
    validate?: (T) => boolean;
}

interface IventisCellRendererParams<T extends IventisTableData = IventisTableData> extends ICellRendererParams<T, any, DataTableContext>, IventisCellParams {}

interface IventisCellEditorParams<T extends IventisTableData = IventisTableData> extends ICellEditorParams<T, any, DataTableContext>, IventisCellParams {
    required?: boolean;
    validator?: EditorValidator;
}

type TimeCellValue = { displayText: string; value: Date };

interface TimeCellComponentProps extends Omit<IventisCellRendererParams, "value"> {
    value: TimeCellValue;
}

interface AsyncTextCellParams extends IventisCellRendererParams {
    getAsyncValue: (value: string) => Promise<string>;
}

interface HyperlinkCellParams extends IventisCellRendererParams {
    onClick: (columnId: string, rowData: unknown) => void;
}

type AdditionalTimeCellEditorParams = {
    value: TimeCellValue;
    getTimeCellValue: GetTimeCellValue;
};

type TimeCellEditorParams<T extends IventisTableData = IventisTableData> = IventisCellEditorParams<T> & AdditionalTimeCellEditorParams;

type TimeOnlyCellEditorParams<T extends IventisTableData = IventisTableData> = IventisCellEditorParams<T> & { onDone?: OnDoneCellEditorProp<T> };

type GetTimeCellValue = (hours, mins, seconds, node, timezone) => TimeCellValue;

interface TimeCellEditorRef extends ICellEditor {
    getValue: () => TimeCellValue;
}

export type IventisCellEditor = ICellEditor;

type EditorValidator = <
    Row extends IventisRowNode = IventisRowNode,
    ColId extends keyof Row["data"] = keyof Row["data"],
    ValueType extends Row["data"][ColId] = Row["data"][ColId]
>(
    node: Row,
    colId: ColId,
    value: ValueType
) => { doesFail: boolean; errorMessage?: string };

type OnDoneCellEditorProp<T extends IventisTableData> = (value: string, node: IventisRowNode<T>) => void;

interface IventisFilterModel {
    filterType: ColumnDataType;
    apiFilterType?: FilterType;
    fieldName: string;
    operator: IventisFilterOperator;
    values: any;
}

type ListItem = { id: string; name: string };

interface ListItemCellProps {
    componentParams: {
        listItems: ListItem[] | (() => ReplaySubject<ListItem[]>);
        validate?: (node: IventisRowNode) => boolean;
        initialFilter?: ListItem[];
    };
    filterOptions?: (items: ListItem[], node: IventisRowNode, colId: string) => ListItem[];
}

interface SubheaderListItem {
    title: string;
    listItems: ListItem[];
}

interface SubheaderListItemCellProps {
    componentParams: { sections: SubheaderListItem[]; listItems: ListItem[] };
    filterOptions?: (items: ListItem[], node: IventisRowNode, colId: string) => ListItem[];
}

interface SubheaderListItemCellEditorProps<T extends IventisTableData = IventisTableData> extends IventisCellEditorParams<T>, SubheaderListItemCellProps {}

interface ListItemCellComponentProps<T extends IventisTableData = IventisTableData> extends IventisCellRendererParams<T>, ListItemCellProps {}

interface ListItemCellEditorProps<T extends IventisTableData = IventisTableData> extends IventisCellEditorParams<T>, Omit<ListItemCellProps, "componentParams"> {
    componentParams: ListItemCellProps["componentParams"] & { onDone?: OnDoneCellEditorProp<T> };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FilterComponentProps extends IFilterParams {
    context: DataTableContext;
    apiFilterType?: FilterType;
}

interface ListItemFilterComponentProps extends FilterComponentProps {
    options: ListItem[] | (() => Observable<ListItem[]>);
    initialFilter?: ListItem[];
    doesFilterPass?: (params: IDoesFilterPassParams, checkedItems: { id: string; name: string }[]) => boolean;
}

interface DateTimeFilterComponentProps extends FilterComponentProps {}

interface NumberFilterComponentProps extends FilterComponentProps {}

interface TextFilterComponentProps extends FilterComponentProps {}

enum DataTableTranslatedContextNamespaces {
    FilterOperations = "filterOperations",
    ValidtionErrors = "validationErrors",
}

interface DataTableTranslatedContext {
    [DataTableTranslatedContextNamespaces.FilterOperations]?: {
        [IventisFilterOperator.Eq]: string;
        [IventisFilterOperator.Gt]: string;
        [IventisFilterOperator.Gte]: string;
        [IventisFilterOperator.Lt]: string;
        [IventisFilterOperator.Lte]: string;
        [IventisFilterOperator.Ct]: string;
    };
}

interface DataTableContext {
    translate?: (name: string, options?: Record<string, any>, ignoreWarning?: boolean) => string;
    timezone?: string;
    toast: typeof toast;
}

interface DataTableGridOptions<T extends IventisTableData = IventisTableData> extends GridOptions<T> {
    context: DataTableContext;
}

type OperatorsTranslatedTextMapping<T extends string = IventisFilterOperator> = {
    [key in T]: string;
};

export enum RowNetworkStatus {
    PendingCreation = "PendingCreation",
    Saving = "Saving",
    ExistsOnServer = "ExistsOnServer",
    Template = "Template",
}

type DataTableCallbackRefs<TData extends IventisTableData = IventisTableData> = {
    getApi(): GridApi<TData>;
    getColumnApi(): ColumnApi;
    deleteRequest(skipConfirmation?: boolean): void;
    deleteConfirmed(): void;
    deleteCancelled(): void;
    undo(): void;
    redo(): void;
    copy(): void;
    insertRow(data: any, index: number): void;
    insertColumn(data: ColDef<TData>): void;
    refreshIndex(): void;
    restoreSort(): void;
    selectFromOutside(selection: string[]): void;
    startEditingNewRow(): void;
};

type OnCellValueChangeEvent = NewValueParams;

export {
    ColumnProps,
    HeaderCellComponentProps,
    ColumnDataType,
    ListItemFilterComponentProps,
    DateTimeFilterComponentProps,
    ListItemCellComponentProps,
    IventisFilterModel,
    IventisFilterOperator,
    NumberFilterComponentProps,
    TextFilterComponentProps,
    IventisFilterRef,
    DataTableTranslatedContextNamespaces,
    DataTableTranslatedContext,
    DataTableContext,
    DataTableGridOptions,
    OperatorsTranslatedTextMapping,
    DataTableCallbackRefs,
    EditorValidator,
    TimeCellComponentProps,
    TimeCellEditorParams,
    TimeCellValue,
    TimeCellEditorRef,
    IventisCellEditorParams,
    GetTimeCellValue,
    RowModelType as RowModel,
    ListItem,
    ListItemCellEditorProps,
    IventisTableData,
    IventisRowNode,
    IventisCellRendererParams,
    AdditionalTimeCellEditorParams,
    HasDataTableValueChanged,
    SubheaderListItemCellEditorProps,
    SubheaderListItem,
    InsertColumnComponentProps,
    OnCellValueChangeEvent,
    IventisTimeCellData,
    TimeOnlyCellEditorParams,
    AsyncTextCellParams,
    HyperlinkCellParams,
};
