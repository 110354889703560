import { TreeBrowserNode, TreeBrowserState } from "../types/data-types";

const defaultState = {
    mainNodeId: null,
    treeId: null,
    tree: null,
    selectedNodeIds: [],
    expandedNodeIds: [],
    loadingNodeIds: [],
    nodeThumbnails: {},
    pendingRequests: [],
    sort: null,
    isLoadingTree: true,
};

export const createInitialState = <TNode extends TreeBrowserNode>(state: Partial<TreeBrowserState<TNode>>): TreeBrowserState<TNode> => ({
    ...defaultState,
    ...state,
});
