/* eslint-disable no-underscore-dangle */
import { xstateDevTools, CtrlListener } from "@iventis/utilities";
import { ActorRef, interpret, Interpreter, InvokeCallback, Subscription } from "xstate";
import { NodeServices, TreeBrowserNode, TreeBrowserSort, TreeBrowserState, UnionOfNodes } from "../../types/data-types";
import { TreeBrowserStateInterface } from "../tree-browser-state";
import { createTreeBrowserMachine, nodeServicesId } from "./tree-browser.machine";
import { TreeBrowserMachineContext, TreeBrowserMachineEvent, TreeBrowserMachineEventType } from "./tree-browser.machine.types";
import { defaultTreeBrowserSelection } from "../tree-browser-selection";

export class TreeBrowserXState<TNode extends TreeBrowserNode> extends TreeBrowserStateInterface<TNode> {
    private machineSubscription: Subscription;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private stateMachine: Interpreter<TreeBrowserMachineContext<TNode>, any, TreeBrowserMachineEvent<TNode>, any, any>;

    private readonly websockets?: (
        services: NodeServices<TNode>
    ) => (context: TreeBrowserMachineContext<TNode>) => InvokeCallback<TreeBrowserMachineEvent<TNode>, TreeBrowserMachineEvent<TNode>>;

    public ctrlListener: CtrlListener;

    private onKeyUp = (callback: (event: KeyboardEvent) => void) => {
        document.addEventListener("keyup", callback.bind(this), { capture: true });
        return {
            remove: () => document.removeEventListener("keyup", callback.bind(this)),
        };
    };

    private onKeyDown = (callback: (event: KeyboardEvent) => void) => {
        document.addEventListener("keydown", callback.bind(this), { capture: true });
        return {
            remove: () => document.removeEventListener("keydown", callback.bind(this)),
        };
    };

    // Use initialState to pass in expanded nodes and the starting main node id
    constructor(
        initialState: TreeBrowserMachineContext<TNode>,
        nodeServices: NodeServices<TNode>,
        websockets?: (
            services: NodeServices<TNode>
        ) => (context: TreeBrowserMachineContext<TNode>) => InvokeCallback<TreeBrowserMachineEvent<TNode>, TreeBrowserMachineEvent<TNode>>,
        treeBrowserSelection?: TreeBrowserStateInterface<TNode>["treeBrowserSelection"]
    ) {
        super({ ...initialState, isLoadingTree: true }, nodeServices, treeBrowserSelection);
        this.websockets = websockets;
        this.ctrlListener = new CtrlListener(this.onKeyUp.bind(this), this.onKeyDown.bind(this), "tree-browser");
    }

    initialise(
        initialState: TreeBrowserMachineContext<TNode>,
        nodeServices: NodeServices<TNode> = this.nodeServices,
        middleware?: (state: TreeBrowserState<TNode>, event: TreeBrowserMachineEvent<TNode>) => void
    ): void {
        if (this.stateMachine !== undefined) {
            this.stateMachine.stop();
        }
        this.nodeServices = nodeServices;
        this.setState({ ...initialState, isLoadingTree: true, isInitialised: true });
        this.stateMachine = interpret(
            createTreeBrowserMachine(this.nodeServices, initialState, { ...defaultTreeBrowserSelection<TNode>(), ...this.treeBrowserSelection }, this.websockets),
            { devTools: xstateDevTools }
        ).start();

        this.machineSubscription = this.stateMachine.subscribe(({ context, event, matches }) => {
            const newState: TreeBrowserState<TNode> = {
                ...context,
                isLoadingTree: matches("loadingTree") || matches("loadingDisplayTree"),
                isInitialised: true,
                errorLoadingTree: matches("loadingTreeError"),
            };
            this.setState(newState);
            middleware?.(newState, event);
        });
    }

    addNode(node: UnionOfNodes<TNode>) {
        this.getNodeServices().send({ type: TreeBrowserMachineEventType.ADD_NODE_REQUESTED, payload: { node, context: this.currentState } });
    }

    getNode(id: string, node: UnionOfNodes<TNode> = this.state.value.tree) {
        return node.id === id ? node : node.childNodes?.find((childNode) => this.getNode(id, childNode as UnionOfNodes<TNode>)) || undefined;
    }

    addNodesLocally(nodes: UnionOfNodes<TNode>[]) {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.ADD_NODES, payload: nodes });
    }

    deleteNodes(nodes: UnionOfNodes<TNode>[], onDone?: (nodes: UnionOfNodes<TNode>[]) => void) {
        this.getNodeServices().send({ type: TreeBrowserMachineEventType.DELETE_NODES_REQUESTED, payload: { nodes, context: this.currentState, onDone } });
    }

    removeNodesLocally(nodeIds: string[]): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.DELETE_NODES, payload: nodeIds });
    }

    updateNode(node: UnionOfNodes<TNode>, previousNodeParentId?: string, replaceChildren?: boolean) {
        this.getNodeServices().send({
            type: TreeBrowserMachineEventType.UPDATE_NODE_REQUESTED,
            payload: { node, context: this.currentState, previousNodeParentId, replaceChildren },
        });
    }

    updateNodeLocally(node: UnionOfNodes<TNode>, previousNodeParentId?: string, skipExpandedCheck = false, replaceChildren = false): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.UPDATE_NODE, payload: { node, previousNodeParentId, skipExpandedCheck, replaceChildren } });
    }

    updateNodesLocally(nodes: UnionOfNodes<TNode>[]): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.UPDATE_NODES, payload: { nodes } });
    }

    moveNodes(nodes: UnionOfNodes<TNode>[], newParentId: string, onDone?: (nodes: UnionOfNodes<TNode>[]) => void): void {
        this.getNodeServices().send({ type: TreeBrowserMachineEventType.MOVE_NODES_REQUESTED, payload: { nodes, context: this.currentState, newParentId, onDone } });
    }

    toggleExpandNode(node: UnionOfNodes<TNode>, skipGet?: boolean, forceGet?: boolean) {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.TOGGLE_EXPAND_NODE, payload: { node, skipGet, forceGet } });
    }

    collapseNode(node: UnionOfNodes<TNode>): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.COLLAPSE_NODE, payload: node });
    }

    expandNode(node: UnionOfNodes<TNode>, skipGet?: boolean): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.EXPAND_NODE, payload: { node, skipGet } });
    }

    toggleSelectNodes(nodeIds: string[] | UnionOfNodes<TNode>[], from: string, ignoreExistingSelection?: boolean, simulateCtrlDown = false) {
        if (this.state.value.multiSelectRequiresCtrl) {
            if (this.ctrlListener.ctrlDown || simulateCtrlDown) {
                this.stateMachine.send({ type: TreeBrowserMachineEventType.TOGGLE_SELECT_NODES, payload: { nodes: nodeIds, from, ignoreExistingSelection, ctrlHeld: true } });
                return;
            }
        }
        this.stateMachine.send({ type: TreeBrowserMachineEventType.TOGGLE_SELECT_NODES, payload: { nodes: nodeIds, from, ignoreExistingSelection } });
    }

    toggleSelectAll() {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.TOGGLE_SELECT_ALL });
    }

    setMainNode(node: UnionOfNodes<TNode>) {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.SET_MAIN_NODE, payload: node });
    }

    setTree(node: UnionOfNodes<TNode>) {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.SET_TREE, payload: node });
    }

    setSort(sort: TreeBrowserSort<UnionOfNodes<TNode>>) {
        this.getNodeServices().send({
            type: TreeBrowserMachineEventType.SORT_NODES_REQUESTED,
            payload: {
                sort,
                context: this.currentState,
                treeId: this.state.value.treeId,
                expandedNodeIds: this.state.value.expandedNodeIds,
                mainNodeId: this.state.value.mainNodeId,
            },
        });
    }

    getThumbnail(node: UnionOfNodes<TNode>): void {
        this.getNodeServices().send({ type: TreeBrowserMachineEventType.GET_THUMBNAILS_REQUESTED, payload: { node, tree: this.state.value.tree } });
    }

    toggleFavourite(node: UnionOfNodes<TNode>, favourite = true): void {
        if (favourite) {
            this.getNodeServices().send({ type: TreeBrowserMachineEventType.ADD_FAVOURITE_REQUESTED, payload: { node } });
        } else {
            this.getNodeServices().send({ type: TreeBrowserMachineEventType.DELETE_FAVOURITE_REQUESTED, payload: { node } });
        }
    }

    setWithExistingTree(newState: Pick<TreeBrowserState<TNode, UnionOfNodes<TNode>>, "tree" | "mainNodeId">): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.SET_WITH_EXISTING_TREE, payload: newState });
    }

    private getNodeServices(): ActorRef<TreeBrowserMachineEvent<TNode>, unknown> {
        return this.stateMachine.children.get(nodeServicesId);
    }

    search(search: string): void {
        this.stateMachine.send({ type: TreeBrowserMachineEventType.SEARCH, payload: search });
    }

    destroy() {
        this.ctrlListener.destroy();
        this.stateMachine?.stop();
        this.machineSubscription?.unsubscribe();
    }
}
