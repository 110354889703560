import { useThrottledCallback } from "@iventis/utilities";
import React, { FunctionComponent, useEffect, useState } from "react";
import { styled } from "@iventis/styles";
import { ValueSelectorComponentCreator } from "./component.types";
import TextValueInputComponent from "./text-value-input";
import { SliderSelectorComponent, SliderSelectorProps } from "./slider-selector";

export const SliderWithTextInputSelector: FunctionComponent<SliderSelectorProps> = ({ value, changeValue, minValue, maxValue, colour, increment, throttleMs = 200, testId }) => {
    const throttle = useThrottledCallback(changeValue, throttleMs);
    // Emitting the value will be throttled, however the selectors will stay in sync
    const [selectorValue, setSelectorValue] = useState(value);
    useEffect(() => {
        // Stops emitting values when not needed
        if (value !== selectorValue) {
            throttle(selectorValue);
        }
    }, [selectorValue]);
    return (
        <StyledSliderValueContainer>
            <TextValueInputComponent
                testId={`${testId}-text-input`}
                inputValue={Math.round(selectorValue * 100)}
                minValue={minValue * 100}
                maxValue={maxValue * 100}
                emitValueChange={(output) => setSelectorValue(output / 100)}
            />
            <SliderSelectorComponent
                testId={`${testId}-slider`}
                value={selectorValue}
                minValue={minValue}
                maxValue={maxValue}
                increment={increment}
                colour={colour}
                changeValue={setSelectorValue}
            />
        </StyledSliderValueContainer>
    );
};

const StyledSliderValueContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const sliderWithTextSelectorCreator: ValueSelectorComponentCreator<SliderSelectorProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SliderWithTextInputSelector {...genericProps} {...additionalProps} />
);
