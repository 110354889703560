import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import React, { FunctionComponent } from "react";
import { basicTranslator, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { Project } from "@iventis/domain-model/model/project";
import { CustomDialog } from "@iventis/components";
import { ProjectFormComponent } from "./project-form";

export const ProjectModalComponent: FunctionComponent<{
    onClose?: () => void;
    onConfirm?: (
        name: string,
        applications: Record<ApplicationName, boolean>,
        billingProvider: ProjectBillingProvider,
        plan: SubscriptionPlan,
        price: SubscriptionPlanPrice,
        maxUsers: number,
        unlimitedUsers: boolean,
        recommendedLayerTemplateCategories: string[],
        committedUntil?: Date
    ) => void;
    open: boolean;
    subtitle: string;
    translate?: UseIventisTranslate;
    project?: Project;
    instanceName: string;
}> = ({ onClose, onConfirm, open, subtitle, translate = basicTranslator, project, instanceName }) => (
    <CustomDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <ProjectFormComponent onClose={onClose} onConfirm={onConfirm} subtitle={subtitle} translate={translate} project={project} instanceName={instanceName} />
    </CustomDialog>
);
