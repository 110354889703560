import { Theme } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material";

export const InsertRowBelowComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.433 10.182c.313 0 .567.254.567.567v2.684a.567.567 0 0 1-.567.567H4.567A.567.567 0 0 1 4 13.433V10.75c0-.313.254-.567.567-.567h10.866zM0 10.182l2.857 1.909L0 14v-3.818zM15.385 0c.34 0 .615.276.615.615v6.406c0 .34-.276.615-.615.615H.615A.615.615 0 0 1 0 7.021V.615C0 .275.276 0 .615 0h14.77zm-.616 4.455H1.231v1.909h13.538v-1.91zm0-3.182H1.231v1.909h13.538v-1.91z"
                fill={theme.primaryColors.subdued70}
                fillRule="evenodd"
            />
        </svg>
    );
};
