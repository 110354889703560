import debounce from "lodash.debounce";
import { useRef, useState } from "react";

/** Returns a lodash debouncer within a react ref */
export const useDebouncer = <TCallback extends (...props: unknown[]) => unknown>(callback: TCallback, ms: number) => useRef(debounce(callback, ms));

/** Returns a value that only updates after the given timeout (in ms), despite the provided value freely updating */
export const useDebounce = <TValue extends unknown>(
    initialValue: TValue,
    ms: number
): { value: TValue; debouncedValue: TValue; setValue: (value: TValue) => void; isTyping: boolean } => {
    const [value, _setValue] = useState(initialValue);
    const [debouncedValue, setDebouncedValue] = useState(initialValue);
    const debounceRef = useDebouncer(setDebouncedValue, ms);

    const setValue = (val: TValue) => {
        _setValue(val);
        if (debounce) {
            debounceRef.current.cancel();
            debounceRef.current(val);
        }
    };

    return { value, debouncedValue, setValue, isTyping: value !== debouncedValue };
};
