import { CollisionDetection, Modifiers, Over } from "@dnd-kit/core";
import { ReactNode } from "react";

export type DropZonePosition = { top: number; bottom: number; left: number; right: number };

export type DragAndDropContextType = {
    newParentId: string;
    bottomItemId: string;
    idSuffixes: string[];
    setOverElement: (element: HTMLElement) => void;
    setAnchoredToTop: (value: boolean) => void;
    overElement: HTMLElement;
    dropZoneHeight: number;
};

export enum SortableListType {
    NORMAL = "NORMAL",
    VIRTUAL_SCROLLING = "VIRTUAL_SCROLLING",
}

export interface DragAndDropProps<TItem> {
    calculateNewParent: (id: string) => TItem;
    getItems: (id: string) => TItem[];
    onDragStart: (item: TItem[]) => void;
    onDragEnd: (item: TItem[], over: Omit<Over, "id"> & { id: string }) => void;
    onDragOver?: (item: TItem[], over: Over) => void;
    createOverlayComponent?: (items: TItem[]) => ReactNode;
    findBottomItem: (item: TItem) => string;
    collisionDetection?: CollisionDetection;
    calculateDropZoneHeight?: (item: TItem) => number;
    /** Suffixes are used because Dnd requires unique identifiers on droppable elements, but sometimes there are more than one droppable areas for one entity */
    idSuffixes?: string[];
    ghostModifiers?: Modifiers;
    modifiers?: Modifiers;
}

export interface SortableDragAndDropProps<TItem> extends DragAndDropProps<TItem> {
    items: TItem[];
    listType: SortableListType;
}
