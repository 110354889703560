import { ClientRect, CollisionDetection, pointerWithin, rectIntersection } from "@dnd-kit/core";
import { rectSortingStrategy, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { getEventCoordinates, Transform } from "@dnd-kit/utilities";
import { SortableListType } from "./drag-and-drop-types";

export function getSortableListType(listType: SortableListType) {
    switch (listType) {
        case SortableListType.NORMAL:
            return rectSortingStrategy;
        case SortableListType.VIRTUAL_SCROLLING:
            return verticalListSortingStrategy;
        default:
            throw new Error(`List type of ${listType} is not supported`);
    }
}

/** Removes any id suffixes to make sure we have the original id */
export function removeIdSuffixes(id: string, suffixes: string[]) {
    let newId = id;
    suffixes.forEach((suffix) => {
        newId = newId.replace(suffix, "");
    });
    return newId;
}

/** Dnd-Kit modifier to place an element (Drag Overlay) to by the bottom right of a cursor position */
export function anchorToMouseModifier({ transform, draggingNodeRect, activatorEvent }: { transform: Transform; draggingNodeRect: ClientRect; activatorEvent: Event }): Transform {
    if (draggingNodeRect && activatorEvent) {
        const activatorCoordinates = getEventCoordinates(activatorEvent);

        if (!activatorCoordinates) {
            return transform;
        }

        // Create x and y offsets
        const offsetX = activatorCoordinates.x - draggingNodeRect.left; // take the x normalized position we clicked on the element and subtract the left width
        const offsetY = activatorCoordinates.y - draggingNodeRect.top; // take the y normalized position we clicked on the element and subtract the top height

        // Modify our transform with the given offsets
        return {
            ...transform,
            x: transform.x + offsetX + 10, // Offset our transform by 10 pixels on the top left of the overlay
            y: transform.y + offsetY + 10, // Offset our transform by 10 pixels on the top left of the overlay
        };
    }

    return transform;
}

/**
 * A custom collision detection algorithm that will get get different colliders depending on relative vertical position of the pointer
 * @param args - arguments passed by dndkit
 * @returns - an array of colliders that are valid
 */
export const pointerAboveBelow: CollisionDetection = (args) => {
    // Check if our pointer is in the top or bottom portion of our currently over collider
    const currentCollider = pointerWithin(args)[0]; // Returns an array but there should only really be one
    if (currentCollider == null) {
        return [];
    }
    // Get the rect of the container we are over
    const containerRect: ClientRect = currentCollider.data.droppableContainer.rect.current;
    // Calculate the mid point of the rect
    const verticalMid = containerRect.top + containerRect.height / 2;

    if (args.pointerCoordinates.y < verticalMid) {
        // We are above - use the current container as we always insert above
        return [currentCollider];
    }

    // Otherwise we want to use the one below

    // Extra padding around our collision rect
    const collisionPadding = 5;

    // Get all containers in the rect area using closestCenter
    const rectCollisions = rectIntersection({
        ...args,
        collisionRect: {
            ...args.collisionRect,
            // Adjust the center of the rect to be on the pointer position (not where we grabbed it from)
            // Make it a little larger incase we are perfectly ontop of an element
            top: args.pointerCoordinates.y - args.collisionRect.height / 2 + collisionPadding,
            right: args.pointerCoordinates.x + args.collisionRect.width / 2 + collisionPadding,
            bottom: args.pointerCoordinates.y + args.collisionRect.height / 2 + collisionPadding,
            left: args.pointerCoordinates.x - args.collisionRect.width / 2 + collisionPadding,
        },
    });

    // Filter out the one we are currently over
    const collisions = rectCollisions.filter(({ id }) => currentCollider.id !== id);

    // Check if we have any collisions below
    if (collisions.length > 0) {
        return collisions;
    }

    // Incase we have none, just return the collisions that we are over
    return [currentCollider];
};

/**
 * A custom collision detection algorithm that will get get different colliders depending on relative horizontal position of the pointer
 * @param args - arguments passed by dndkit
 * @returns - an array of colliders that are valid
 */
export const pointerLeftRight: CollisionDetection = (args) => {
    // Check if our pointer is in the top or bottom portion of our currently over collider
    const currentCollider = pointerWithin(args)[0]; // Returns an array but there should only really be one
    if (currentCollider == null) {
        return [];
    }
    // Get the rect of the container we are over
    const containerRect: ClientRect = currentCollider.data.droppableContainer.rect.current;
    // Calculate the mid point of the rect
    const horizontalMid = containerRect.left + containerRect.width / 2;

    if (args.pointerCoordinates.x < horizontalMid) {
        // We are to the left - use the current container as we always insert to the left
        return [currentCollider];
    }

    // Otherwise we want to use the one to the right

    // Extra padding around our collision rect
    const collisionPadding = 5;

    // Get all containers in the rect area using closestCenter
    const rectCollisions = rectIntersection({
        ...args,
        collisionRect: {
            ...args.collisionRect,
            // Adjust the center of the rect to be on the pointer position (not where we grabbed it from)
            // Make it a little larger incase we are perfectly ontop of an element
            top: args.pointerCoordinates.y - args.collisionRect.height / 2 + collisionPadding,
            right: args.pointerCoordinates.x + args.collisionRect.width / 2 + collisionPadding,
            bottom: args.pointerCoordinates.y + args.collisionRect.height / 2 + collisionPadding,
            left: args.pointerCoordinates.x - args.collisionRect.width / 2 + collisionPadding,
        },
    });

    // Filter out the one we are currently over
    const collisions = rectCollisions.filter(({ id }) => currentCollider.id !== id);

    // Check if we have any collisions to the right
    if (collisions.length > 0) {
        return collisions;
    }

    // Incase we have none, just return the collisions that we are over
    return [currentCollider];
};

export { closestCenter } from "@dnd-kit/core";
