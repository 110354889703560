import React, { FunctionComponent } from "react";
import { InteractiveElement } from "@iventis/styles/src/components";
import { ICellRendererParams } from "@ag-grid-community/core";
import { CircularProgress, useTheme } from "@mui/material";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowNetworkStatus } from "..";

/**
 * The numbered column pinned to the left
 */
export const IndexCell: FunctionComponent<ICellRendererParams> = ({ node, value }) => {
    const theme = useTheme<Theme>();

    if (node.level > 0) {
        return <StyledBlankCell />;
    }

    const networkState: RowNetworkStatus = node?.data?.networkStatus;

    if (networkState === undefined || networkState === RowNetworkStatus.ExistsOnServer) {
        return (
            <InteractiveElement
                data-cy="data-table-index-cell"
                type="button"
                style={{ width: "100%", height: "100%" }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    node.setSelected(true);
                }}
            >
                {value}
            </InteractiveElement>
        );
    }

    if (networkState === RowNetworkStatus.Template) {
        return (
            <StyledCell>
                <FontAwesomeIcon icon={["far", "plus"]} />
            </StyledCell>
        );
    }

    if (networkState === RowNetworkStatus.PendingCreation) {
        return (
            <StyledCell>
                <FontAwesomeIcon icon={["fas", "triangle-exclamation"]} color={theme.dataTableTheme.validation.invalidCellColour} />
            </StyledCell>
        );
    }

    if (networkState === RowNetworkStatus.Saving) {
        return (
            <StyledCell>
                <CircularProgress data-testid={`data-table-row-${node.data?.name}-loading`} size="1rem" />
            </StyledCell>
        );
    }

    throw new Error("Network state not recognised");
};

const StyledCell = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledBlankCell = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
