import { ChipType, FlatListBrowser, FlatListRow, StyledChip, StyledIconButton } from "@iventis/components";
import { Body1, Header3, defaultSkeletonMixin, formGap, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useState } from "react";

import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { Divider, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefaultSubscriptionPlan } from "@iventis/domain-model/model/defaultSubscriptionPlan";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { RepoContainer } from "./assets-repo-template";
import {
    createSubscriptionPlan,
    getSubscriptionPlans,
    updateSubscriptionPlanAndPricing,
    getDefaultPlan,
    setDefaultPlan,
    SUBSCRIPTION_PLAN_QUERY_KEY,
} from "./subscription-plans-api-helpers";
import { SubscriptionPlanModal } from "./subscription-plan-modal";
import { SubscriptionPlanDefaultModal } from "./subscription-plan-default-modal";
import { SUBSCRIPTION_PRICE_QUERY_KEY } from "./constants";

const DEFAULT_PLAN_QUERY_KEY = "default-subscription-plan";

export const SubscriptionPlansComponent: FunctionComponent = () => {
    const queryClient = useQueryClient();

    const { isLoading, data: plans } = useQuery([SUBSCRIPTION_PLAN_QUERY_KEY], async () => getSubscriptionPlans());
    const [planBeingSaved, setPlanBeingSaved] = useState(null);

    const createMutation = useMutation(async (data: SubscriptionPlan) => createSubscriptionPlan(data), {
        onSuccess: (newPlan) => {
            queryClient.setQueryData<SubscriptionPlan[]>([SUBSCRIPTION_PLAN_QUERY_KEY], (existingData) => [...existingData, newPlan]);
            queryClient.setQueryData<SubscriptionPlanPrice[]>([SUBSCRIPTION_PRICE_QUERY_KEY, newPlan.id], () => newPlan.subscriptionPlanPrices);
        },
    });

    const updateMutation = useMutation(
        async (data: SubscriptionPlan) => {
            const existingPrices = queryClient.getQueryData<SubscriptionPlanPrice[]>([SUBSCRIPTION_PRICE_QUERY_KEY, data.id]);
            return updateSubscriptionPlanAndPricing(data, existingPrices);
        },
        {
            onMutate: (updatedPlan) => {
                setPlanBeingSaved(updatedPlan.id);
                queryClient.setQueryData<SubscriptionPlan[]>([SUBSCRIPTION_PLAN_QUERY_KEY], (existingData) => existingData.map((p) => (p.id === updatedPlan.id ? updatedPlan : p)));
            },
            onSuccess: (newPlan) => {
                queryClient.setQueryData<SubscriptionPlanPrice[]>([SUBSCRIPTION_PRICE_QUERY_KEY, newPlan.id], () => newPlan.subscriptionPlanPrices);
                setPlanBeingSaved(null);
            },
            onError: () => {
                setPlanBeingSaved(null);
            },
        }
    );

    const { data: defaultData } = useQuery([DEFAULT_PLAN_QUERY_KEY], () => getDefaultPlan(), { enabled: plans?.length > 0 });

    const updateDefaultMutation = useMutation(async (data: DefaultSubscriptionPlan) => setDefaultPlan(data), {
        onSuccess: (_, data) => {
            queryClient.setQueryData<DefaultSubscriptionPlan>([DEFAULT_PLAN_QUERY_KEY], () => data);
        },
    });

    const [modal, setmodal] = useState<"creating-plan" | "setting-default-plan" | null>(null);

    return (
        <RepoContainer>
            <div className="header-row" style={{ marginBottom: formGap }}>
                <Header3>Plans</Header3>
                <ChipContainer>
                    <StyledChip
                        label={
                            <>
                                <FontAwesomeIcon icon={["far", "plus"]} /> Add plan
                            </>
                        }
                        chipType={ChipType.PrimaryButton}
                        onClick={() => setmodal("creating-plan")}
                    />
                    <StyledChip
                        disabled={!(plans?.length > 0)}
                        label={
                            <>
                                <FontAwesomeIcon icon={["far", "gear"]} /> Set default
                            </>
                        }
                        chipType={ChipType.PrimaryButton}
                        onClick={() => setmodal("setting-default-plan")}
                    />
                </ChipContainer>
            </div>

            <FlatListBrowser skeleton={<SkeletonRow />} loading={isLoading}>
                <Divider />
                {plans?.map((plan: SubscriptionPlan) => (
                    <SubscriptionPlanListRow key={plan.id} plan={plan} onSave={(newPlan) => updateMutation.mutateAsync(newPlan)} isSaving={plan.id === planBeingSaved} />
                ))}
            </FlatListBrowser>
            {modal === "creating-plan" && (
                <SubscriptionPlanModal
                    open={modal === "creating-plan"}
                    onSave={(newPlan) => {
                        createMutation.mutateAsync(newPlan);
                        setmodal(null);
                    }}
                    onClose={() => setmodal(null)}
                />
            )}
            {modal === "setting-default-plan" && (
                <SubscriptionPlanDefaultModal
                    initialDefaultPlan={defaultData}
                    onClose={() => setmodal(null)}
                    onSave={(defaultPlan) => {
                        updateDefaultMutation.mutateAsync(defaultPlan);
                        setmodal(null);
                    }}
                    plans={plans}
                    open={modal === "setting-default-plan"}
                />
            )}
        </RepoContainer>
    );
};

const SubscriptionPlanListRow: FunctionComponent<{ plan: SubscriptionPlan; onSave: (plan: SubscriptionPlan) => void; isSaving: boolean }> = ({ plan, onSave, isSaving }) => {
    const [modalOpen, setModalOpen] = useState<"edit" | null>(null);

    const theme = useTheme<Theme>();

    return (
        <>
            <FlatListRow>
                <Body1 style={{ fontWeight: 600 }}>{plan.name}</Body1>
                <StyledIconButton disabled={isSaving} onClick={() => setModalOpen("edit")} size="small">
                    <FontAwesomeIcon color={theme.typographyColors.core} icon={["far", "edit"]} />
                </StyledIconButton>
            </FlatListRow>
            <Divider />
            {modalOpen === "edit" && (
                <SubscriptionPlanModal
                    open={modalOpen === "edit"}
                    initialPlan={plan}
                    onSave={(newPlan) => {
                        onSave(newPlan);
                        setModalOpen(null);
                    }}
                    onClose={() => setModalOpen(null)}
                />
            )}
        </>
    );
};

const SkeletonRow = styled.span`
    ${defaultSkeletonMixin}
`;

const ChipContainer = styled.div`
    align-self: flex-end;

    display: flex;
    gap: ${formGap};
`;
