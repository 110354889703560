import React, { useMemo, FunctionComponent, useRef, useState } from "react";
import { useTreeBrowser } from "@iventis/tree-browser/state/tree-browser-hooks";
import { findNode, findAllParentIds } from "@iventis/tree-browser/tree-operations";
import { StyledExpansionContainer } from "@iventis/tree-browser/implementations/library/styles/library-list-node.styles";
import { BaseNode, LibraryOverflowPopover, NODE_HEIGHT_PX } from "@iventis/tree-browser";
import { UserThumbnail, ConfirmationDialogComponent } from "@iventis/components";
import { Body2, iconButtonSize, InteractiveElement, media, styled } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useQuery } from "@tanstack/react-query";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { AdminProjectPersonNode } from "./tree-browser-types";
import { TreeBrowserProjectsContext } from "./project.helpers";
import { getImpersonateUserLink, getUserSpatialLibrary } from "./project-api-functions";
import { ImportMapModal } from "./import-map";

interface PersonNodeProps {
    node: AdminProjectPersonNode;
    setMapInList: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}

type ConfirmationDialogProps = OptionalExceptFor<Parameters<typeof ConfirmationDialogComponent>[0], "title" | "message" | "handleConfirm">;

export const PersonNode: FunctionComponent = ({ node, setMapInList }: PersonNodeProps) => {
    const [{ tree, mainNodeId, expandedNodeIds, loadingNodeIds }, treeBrowserContext] = useTreeBrowser(
        TreeBrowserProjectsContext,
        node.id,
        "tree",
        "mainNodeId",
        "expandedNodeIds",
        "loadingNodeIds"
    );

    const indentLevel = useMemo(() => findAllParentIds(findNode([tree], mainNodeId), node.id).length - 2, [tree, mainNodeId]);
    const expanded = expandedNodeIds.includes(node.id);
    const loading = loadingNodeIds.includes(node.id);

    const isProjectAdmin = () => node.person.projectAdmin;

    const overflowButtonRef = useRef(null);
    const [overFlowOpen, setOverFlowOpen] = useState(false);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<"revoke" | "grant" | "remove" | null>(null);

    const impersonateUser = async () => {
        const projectNode = findNode(tree.childNodes, node.parentId);
        const userLink = await getImpersonateUserLink(tree.id, projectNode.id, node.person.id);
        window.open(userLink, "_blank");
    };

    const [importMap, setImportMap] = useState(false);

    const instanceNodeId = useMemo(() => findNode([tree], node.parentId)?.parentId, [tree, node.parentId]);

    const getConfirmationProps = (): ConfirmationDialogProps => {
        switch (confirmationDialogOpen) {
            case "grant": {
                return {
                    title: "Grant admin rights",
                    message: `Are you sure you want to grant project admin status to ${node.name} for project: ${findNode(tree.childNodes, node.parentId)?.name}?`,
                    handleConfirm: () => {
                        setConfirmationDialogOpen(null);
                        treeBrowserContext.updateNode({ ...node, person: { ...node.person, projectAdmin: true } });
                    },
                };
            }
            case "revoke": {
                return {
                    title: "Revoke admin rights",
                    message: `Are you sure you want to rekove project admin status from ${node.name} for project: ${findNode(tree.childNodes, node.parentId)?.name}?`,
                    handleConfirm: () => {
                        setConfirmationDialogOpen(null);
                        treeBrowserContext.updateNode({ ...node, person: { ...node.person, projectAdmin: false } });
                    },
                };
            }
            case "remove": {
                return {
                    title: "Remove user",
                    message: `Are you sure you want to remove ${node.name} from the project: ${findNode(tree.childNodes, node.parentId)?.name}`,
                    handleConfirm: async () => {
                        setConfirmationDialogOpen(null);
                        treeBrowserContext.deleteNodes([node]);
                    },
                };
            }
            default: {
                return null;
            }
        }
    };

    const { data: spatialLibraries, isLoading: isGettingSpatialLibraries } = useQuery(
        ["user-spatial-library", node.id],
        () => getUserSpatialLibrary(instanceNodeId, node.parentId, node.id),
        { enabled: importMap }
    );

    return (
        <StyledContainer>
            <BaseNode className="person-node" node={node} treeContext={TreeBrowserProjectsContext}>
                {/* Makes sure we have consistent indent level */}
                <StyledExpansionContainer indentLevel={indentLevel}>
                    <InteractiveElement
                        onClick={(e) => {
                            e.stopPropagation();
                            treeBrowserContext.toggleExpandNode(node);
                        }}
                    >
                        <FontAwesomeIcon icon={loading ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="lg" spin={loading} />
                    </InteractiveElement>
                </StyledExpansionContainer>
                <UserThumbnail className="icon" />
                <Body2 className="name">{node.name}</Body2>
                <Body2 className="email">{node.person.email}</Body2>
                <Body2 className="admin">{isProjectAdmin() ? <span>ADMIN</span> : ""}</Body2>
                <InteractiveElement ref={overflowButtonRef} className="ellipsis" onClick={() => setOverFlowOpen((isOpen) => !isOpen)}>
                    <span className="hidden">Click to open options for {node.name}</span>
                    <FontAwesomeIcon icon={["fas", "ellipsis"]} />
                </InteractiveElement>
                <LibraryOverflowPopover
                    overflowButtonRef={overflowButtonRef}
                    overflowOpen={overFlowOpen}
                    setOverflowOpen={setOverFlowOpen}
                    overflowOptions={[
                        isProjectAdmin()
                            ? { label: "Revoke admin", selected: () => setConfirmationDialogOpen("revoke") }
                            : { label: "Grant admin", selected: () => setConfirmationDialogOpen("grant") },
                        { label: "Remove user", selected: () => setConfirmationDialogOpen("remove") },
                        { label: "Impersonate user", selected: impersonateUser },
                        { label: "Import map", selected: () => setImportMap(true) },
                    ]}
                    node={node}
                />
            </BaseNode>
            <ConfirmationDialogComponent
                cancelText="Cancel"
                confirmText="Confirm"
                handleCancel={() => setConfirmationDialogOpen(null)}
                show={typeof confirmationDialogOpen === "string"}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getConfirmationProps()}
            />
            {importMap && !isGettingSpatialLibraries && (
                <ImportMapModal
                    setMapInList={(mapId, mapName, _, instanceName, projectId) => setMapInList(mapId, mapName, node.id, instanceName, projectId)}
                    nodeId={spatialLibraries[0].id}
                    projectId={node.parentId}
                    instanceName={instanceNodeId}
                    onClose={() => setImportMap(false)}
                />
            )}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    .person-node {
        display: grid;
        grid-template-columns: auto auto ${iconButtonSize} 4fr 7fr auto;
        grid-template-rows: ${`${NODE_HEIGHT_PX}px`};
        row-gap: 0;
        .icon {
            width: 30px;
            justify-self: center;
        }
        .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .email {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .admin {
            width: 60px;
            text-align: center;
            display: none;
        }
        .ellipsis {
            height: 100%;
            width: ${iconButtonSize};
        }
        ${media.large} {
            grid-template-columns: auto auto ${iconButtonSize} 4fr 7fr auto auto;
            .admin {
                display: block;
            }
        }
    }
`;
