import { DataField } from "@iventis/domain-model/model/dataField";
import { Content } from "@iventis/translations";
import { Body1, Body2, InteractiveElement, defaultSkeletonMixin, iconButtonSize, inlineTextIconMargin, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { DisabledOverlay, FlatListRow, SimpleTooltip } from "@iventis/components";
import { useQuery } from "@tanstack/react-query";
import { dataFieldTypes } from "./data-fields-types.constants";
import { DataFieldModal } from "./data-field-modal";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";

interface ProjectAttributeRowProps {
    attribute: DataField;
}

/**
 * Displays the name and type of an attribute. Also contains the buttons to delete and modify the attribute. Meant for use in the Project Attribute settings in project settings
 */
export const ProjectAttributeRow: FunctionComponent<ProjectAttributeRowProps> = ({ attribute }) => {
    const translate = useIventisTranslate();

    const [modalOpen, setModalOpen] = useState(false);

    const { dataFieldsService } = useDataFieldServices();

    const { resourceId } = useDataFieldConfig();

    const theme = useTheme<Theme>();

    const icon = useMemo(() => {
        const { icon } = dataFieldTypes.find(({ type }) => type === attribute.type);
        if (icon === undefined) {
            return <></>;
        }
        if (Array.isArray(icon)) {
            return <FontAwesomeIcon icon={icon} style={{ marginRight: "5px", color: theme.typographyColors.subdued }} size="sm" />;
        }
        return <CustomIcon>{icon as ReactNode}</CustomIcon>;
    }, [attribute.type]);

    const attributeType = useMemo(() => dataFieldTypes.find(({ type }) => type === attribute.type).name, [attribute.type]);

    const { data: isDataFieldBeingUsed } = useQuery([`delete-project-attribute-${attribute.id}`], () => dataFieldsService.isDataFieldBeingUsed(attribute.id, resourceId));

    return (
        <FlatListRow>
            <StyledName>{attribute.name}</StyledName>
            <SpaceBetweenContainer>
                <TypeContainer>
                    {icon}
                    <SubduedBody2>{translate(attributeType)}</SubduedBody2>
                </TypeContainer>
                <ButtonContainer>
                    <SimpleTooltip text={translate(Content.common.buttons.edit)} placement="bottom">
                        <StyledButtonIcon
                            data-testid={`edit-attribute-button-${attribute.name}`}
                            onClick={(e) => {
                                setModalOpen(true);
                                e.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon icon={["far", "edit"]} style={{ color: theme.typographyColors.subdued }} size="lg" />
                        </StyledButtonIcon>
                    </SimpleTooltip>

                    <div style={{ position: "relative" }}>
                        <SimpleTooltip data-testid={`delete-attribute-button-${attribute.name}-container`} text={`${translate(Content.common.buttons.delete)}`} placement="bottom">
                            <>
                                {isDataFieldBeingUsed && <DisabledOverlay opacity={0.5} />}
                                <StyledButtonIcon
                                    data-testid={`delete-attribute-button-${attribute.name}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dataFieldsService.deleteDataField(attribute.id, resourceId);
                                    }}
                                >
                                    <FontAwesomeIcon icon={["far", "trash"]} style={{ color: theme.typographyColors.subdued }} size="lg" />
                                </StyledButtonIcon>
                            </>
                        </SimpleTooltip>
                    </div>
                </ButtonContainer>
            </SpaceBetweenContainer>
            <DataFieldModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                close={() => setModalOpen(false)}
                existingDataField={attribute}
                createableProjectDataFields={[]}
                allowDeletion={false}
            />
        </FlatListRow>
    );
};

/**
 * A skeleton loading version of the [ProjectAttributeRow](./project-attribute-row.tsx) component. Aims to mimic the component with skeleton shimmers replacing text content. Used whilst loading Project Attributes.
 */
export const ProjectAttributeRowSkeleton: FunctionComponent = () => (
    <>
        <div style={{ flex: "1 1" }}>
            <div style={{ height: "1.4em", width: "200px" }}>
                <SkeletonPlaceholder />
            </div>
        </div>
        <SpaceBetweenContainer>
            <div style={{ height: "1em", width: "100px" }}>
                <SkeletonPlaceholder />
            </div>
            <ButtonContainer>
                <span style={{ width: "44px", height: "44px" }} />
                <span style={{ width: "44px", height: "44px" }} />
            </ButtonContainer>
        </SpaceBetweenContainer>
    </>
);

const SkeletonPlaceholder = styled.span`
    ${defaultSkeletonMixin}
`;

const StyledButtonIcon = styled(InteractiveElement)`
    width: ${iconButtonSize};
    height: ${iconButtonSize};
`;

const SpaceBetweenContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
`;

const TypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${inlineTextIconMargin};
`;

const StyledName = styled(Body1)`
    font-weight: 600;
    flex: 1 1;
`;

const SubduedBody2 = styled(Body2)`
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
`;

const CustomIcon = styled.div`
    margin-right: 5px;
`;

export default ProjectAttributeRow;
