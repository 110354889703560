import React, { BaseSyntheticEvent, forwardRef, useImperativeHandle, useState } from "react";
import { IDoesFilterPassParams } from "@ag-grid-community/core";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { useEffectIgnoreInitialRender } from "../lib/hooks";
import { StyledInputField } from "../styles/data-table.styles";
import { NumberFilterComponentProps, ColumnDataType, IventisFilterRef, OperatorsTranslatedTextMapping } from "../types/data-table.types";
import { OperatorSelectorComponent } from "./operator-selector";
import { doesFilterPass, getFilterModel } from "../lib/grid.helpers";
import { FilterTemplateComponent } from "./filter-template";

export type TextOperators = Extract<IventisFilterOperator, IventisFilterOperator.Ct | IventisFilterOperator.Eq>;

/**
 * Used as FilterFramework for data table column of type "text"
 */
export const TextFilterComponent = forwardRef<IventisFilterRef, NumberFilterComponentProps>(({ ...props }, ref) => {
    const { context } = props;
    const [operator, setOperator] = useState<TextOperators>(IventisFilterOperator.Ct);
    const [value, setValue] = useState<string>("");

    useImperativeHandle(ref, () => ({
        isFilterActive() {
            return value !== "";
        },

        getModel() {
            return getFilterModel(props.column, ColumnDataType.TEXT, operator, value, props.apiFilterType);
        },
        doesFilterPass(params: IDoesFilterPassParams) {
            return doesFilterPass(value, params.data[props.colDef.field], operator);
        },
    }));

    useEffectIgnoreInitialRender(() => {
        props.filterChangedCallback();
    }, [operator, value]);

    const textOperators: OperatorsTranslatedTextMapping<TextOperators> = {
        [IventisFilterOperator.Eq]: context?.translate(IventisFilterOperator.Eq) || "Equals",
        [IventisFilterOperator.Ct]: context?.translate(IventisFilterOperator.Ct) || "Contains",
    };

    return (
        <FilterTemplateComponent
            title={props.colDef.headerName}
            onClose={(e) => {
                e.stopPropagation();
                props.api.hidePopupMenu();
            }}
        >
            <StyledInputField
                id="outlined-text"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={value}
                onChange={(e: BaseSyntheticEvent) => setValue(e.currentTarget.value)}
            />
            <OperatorSelectorComponent<TextOperators> operator={operator} setOperator={setOperator} operators={textOperators} />
        </FilterTemplateComponent>
    );
});
