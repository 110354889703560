import { Project } from "@iventis/domain-model/model/project";
import { Instance } from "@iventis/domain-model/model/instance";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { Node } from "@iventis/domain-model/model/node";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { findNode, NodeServices } from "@iventis/tree-browser";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { ProjectUser } from "@iventis/domain-model/model/projectUser";
import { AdminTreeNodeType, createPersonNode, PostUserCreateRequest } from "@iventis/people";
import { AxiosResponse } from "axios";
import { toast, ToastType } from "@iventis/toasts";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { CreateProjectRequest } from "@iventis/domain-model/model/createProjectRequest";
import { ProjectWithSubscription } from "@iventis/domain-model/model/projectWithSubscription";
import { SsoConfig } from "@iventis/domain-model/model/ssoConfig";
import { api } from "../api/api";
import {
    appRecordToArray,
    parseMapAndFolderLibraryNodes,
    parseProjectSubscription,
    parseSearchProjectResponse,
    parseSpatialLibraryNode,
    projectResponseToNode,
} from "./project.helpers";
import { AdminFolderTreeNode, AdminProjectNode, AdminProjectNodeTree } from "./tree-browser-types";
import { ProjectSubscriptionResponse } from "./project-types";

const projectUsersBaseRoute = (instanceName: string, projectId: string) => `instances/${instanceName}/projects/${projectId}/users`;

export const getUserSpatialLibrary = async (instanceName: string, projectId: string, userId: string) => {
    const filter = [
        { fieldName: "userId", operator: IventisFilterOperator.Eq, value: userId },
        { fieldName: "libraryType", operator: IventisFilterOperator.In, value: [NodeType.PersonalMappingLibrary] },
    ];
    const userSpatialLibraries = await api.get<Node[]>(`instances/${instanceName}/projects/${projectId}/spatial-libraries?filters=${encodeURI(JSON.stringify(filter))}`);
    return userSpatialLibraries.data;
};

/** Get a shared mapping library for a specific project  */
async function getSharedSpatialLibraryNodes(instanceName: string, projectId: string) {
    const filter = [{ $type: "IventisFilterStringArray", fieldName: "libraryType", operator: IventisFilterOperator.In, value: [NodeType.MappingLibrary] }];
    const response = await api.get<void, { data: Node[] }>(`instances/${instanceName}/projects/${projectId}/spatial-libraries?filters=${encodeURI(JSON.stringify(filter))}`);
    return response.data.map((node) => parseSpatialLibraryNode(node, projectId, instanceName));
}

/** Get a personal mapping library for a given user */
async function getUserSpatialLibraryNodes(instanceName: string, projectId: string, userId: string) {
    const userSpatialLibraries = await getUserSpatialLibrary(instanceName, projectId, userId);
    const mapsAndFoldersRequest = userSpatialLibraries.map((library) => getMapsAndFolders(instanceName, projectId, library.id, userId));
    const mapsAndFoldersResponse = await Promise.all(mapsAndFoldersRequest);
    return mapsAndFoldersResponse.flatMap((nodes) => nodes.map((node) => node));
}

/** Get child nodes for a given node (nodes will be folders and maps) */
async function getMapsAndFolders(instanceName: string, projectId: string, nodeId: string, overrideParentId?: string) {
    const response = await api.get<Node>(`instances/${instanceName}/projects/${projectId}/nodes/${nodeId}`);
    return response.data.childNodes.map((node) => parseMapAndFolderLibraryNodes(node, projectId, instanceName, overrideParentId));
}

export async function getProjectSubscription(instanceName, projectId, subscriptionId): Promise<ProjectSubscription> {
    const response = await api.get<ProjectSubscriptionResponse>(`instances/${instanceName}/projects/${projectId}/subscriptions/${subscriptionId}`);
    return parseProjectSubscription(response.data);
}

export async function updateProjectSubscription(instanceName, projectId, projectSubscription: ProjectSubscription): Promise<ProjectSubscription> {
    await api.put(`instances/${instanceName}/projects/${projectId}/subscriptions/`, projectSubscription);
    return projectSubscription;
}

export async function getInstances(): Promise<Instance[]> {
    const response = await api.get<Instance[]>("instances");
    return response.data;
}

export async function searchProjects(search: string, instanceName: string): Promise<ProjectWithSubscription[]> {
    const response = await api.post<ProjectWithSubscription[]>(`instances/${instanceName}/projects/filter`, [
        {
            fieldName: "search",
            value: search,
            operator: "Eq",
        },
    ]);
    return response.data;
}

export async function getProjects(instanceName: string): Promise<Project[]> {
    const response = await api.get<Project[]>(`instances/${instanceName}/projects`);
    return response.data;
}

export async function createProject(instanceName: string, createProjectRequest: CreateProjectRequest, applications: ApplicationName[]): Promise<Project> {
    const response = await api.post<Project>(`instances/${instanceName}/projects`, { project: createProjectRequest, applications });
    return response.data;
}

export async function updateProject(instanceName: string, project: Project): Promise<Project> {
    const response = await api.put<Project>(`instances/${instanceName}/projects`, { project });
    return response.data;
}

export const postProjectUsers = async (instanceName: string, projectId: string, request: PostUserCreateRequest[]) => {
    const response = await api.post<ProjectUser[], AxiosResponse<ProjectUser[]>, Partial<ProjectUser>[]>(
        projectUsersBaseRoute(instanceName, projectId),
        request.map((r) => ({ ...r.user, projectId }))
    );
    return response.data;
};

/** Returns a function which memoises projects by the given project id and returns the projects' parent id (instance name)  */
export const getInstanceNameMemo = () => {
    const projects = {};
    return (projectId: string, tree: AdminProjectNodeTree): string => {
        if (projectId in projects) {
            const project = projects[projectId];
            return project.parentId;
        }
        const project = findNode(tree.childNodes, projectId);
        projects[projectId] = project;
        return project.parentId;
    };
};

export const instanceServices: NodeServices<AdminProjectNodeTree> = {
    getTree: async (instanceId, __, search) => {
        // The search term must be longer than 2 chars
        if (search !== "" && search != null && search.length > 2) {
            const projects = await searchProjects(search, instanceId);
            return parseSearchProjectResponse(instanceId, projects);
        }
        return parseSearchProjectResponse(instanceId, []);
    },
    addNode: async (node) => {
        if (node.type !== AdminTreeNodeType.Project) {
            throw new Error(`Cannot add nodes other than a Project`);
        }

        // Convert to list of applications as this is what the backend expects
        const appList: ApplicationName[] = appRecordToArray(node.applications);

        const newProject = await createProject(
            node.parentId,
            {
                ...node.project,
                subscription: node?.projectSubscription,
                userEmail: "",
                userPassword: "",
                isUserPasswordSalted: false,
                userFirstName: "",
                userLastName: "",
                tempPassword: undefined,
                marketingOptIn: false,
                recommendedLayerTemplateCategoryNames: node.recommendedLayerTemplateCategories,
            },
            appList
        );
        const newNode = { ...node, id: newProject.id, project: newProject };

        return newNode;
    },
    deleteNodes: async (nodes, tree) => {
        /**
         * ONLY SUPPORTS DELETING USER NODES
         */

        // A function to get the instance name and memoise it, so we don't have to loop through the tree everytime
        const getInstanceName = getInstanceNameMemo();

        // Form an object in the shape of { [instanceName]: { [projectId]: userId[] } }
        const projectToUsersMapping: { [instanceName in string]: { [projectId in string]: string[] } } = nodes.reduce((instances, node) => {
            if (node.type !== AdminTreeNodeType.User) {
                throw new Error(`Not implemented deletion of ${node.type}'s`);
            }
            const instanceName = getInstanceName(node.parentId, tree);
            if (instanceName in instances) {
                const projects = instances[instanceName];
                if (node.parentId in projects) {
                    projects[node.parentId] = [...(projects[node.parentId] ?? []), node.id];
                    return instances;
                }
                // eslint-disable-next-line no-param-reassign
                return { ...instances, [instanceName]: { ...instances[instanceName], [node.parentId]: [node.id] } };
            }
            return { ...instances, [instanceName]: { [node.parentId]: [node.id] } };
        }, {});

        // Separate request for each project within each instance
        await Promise.all(
            Object.entries(projectToUsersMapping).flatMap(([instanceName, projects]) =>
                // Backend only supports deleting one user currently, hence userIds[0] in the query. But this is set up in a way that means we can add multiple deletions easily
                Object.entries(projects).map(([projectId, userIds]) => api.delete(`${projectUsersBaseRoute(instanceName, projectId)}/${userIds[0]}`))
            )
        );

        return nodes.map(({ id }) => id);
    },
    updateNode: async (node, tree) => {
        switch (node.type) {
            case AdminTreeNodeType.User: {
                const { parentId: instanceName } = findNode(tree.childNodes, node.parentId);
                await api.patch<void, AxiosResponse<void>, ProjectUser>(`${projectUsersBaseRoute(instanceName, node.parentId)}/${node.id}`, node.person);
                return node;
            }
            case AdminTreeNodeType.Project: {
                const { parentId: instanceName } = findNode(tree.childNodes, node.parentId);
                const response = await updateProject(instanceName, node.project);
                return projectResponseToNode(response, instanceName);
            }
            default: {
                throw new Error("Not implemented");
            }
        }
    },
    getNode: async (id, context) => {
        const node = findNode(context.tree.childNodes, id);
        if (node == null) {
            throw new Error(`Cannot find the node by id: ${id}`);
        }
        switch (node.type) {
            case AdminTreeNodeType.Project: {
                const instanceName = node.parentId;
                const { data: users } = await api.get<ProjectUser[]>(projectUsersBaseRoute(instanceName, node.id));
                const spatialLibraries = await getSharedSpatialLibraryNodes(instanceName, node.id);
                const children = users.map((user) => createPersonNode<ProjectUser, never>(user, node.id));
                return { ...node, childNodes: [...spatialLibraries, ...children], childCount: children.length } as AdminProjectNode;
            }
            case AdminTreeNodeType.SharedSpatialLibrary: {
                const mapsAndFolders = await getMapsAndFolders(node.instanceName, node.parentId, node.id);
                return { ...node, childNodes: mapsAndFolders, childCount: mapsAndFolders.length };
            }
            case AdminTreeNodeType.Map:
            case AdminTreeNodeType.Folder: {
                const mapsAndFolders = await getMapsAndFolders(node.instanceName, node.projectId, node.id);
                return { ...node, childNodes: mapsAndFolders, childCount: mapsAndFolders.length } as AdminFolderTreeNode;
            }
            case AdminTreeNodeType.User: {
                const projectNode = findNode(context.tree.childNodes, node.parentId);
                const mapsAndFolders = await getUserSpatialLibraryNodes(projectNode.parentId, projectNode.id, node.id);
                return { ...node, childNodes: mapsAndFolders, childCount: mapsAndFolders.length };
            }
            default:
                return node;
        }
    },
    getNodeThumbnails: {},
    // Do not support drag and drop in admin dashboard
    moveNodes: () => Promise.reject(),
};

export const syncAssetsOnProject = async (node: AdminProjectNode<null>) => {
    await api.post<never>(`instances/${node.parentId}/projects/${node.id}/sync`);
    toast.success({ type: ToastType.BASIC, props: { message: `Project '${node.name}' successfully synced`, icon: ["fas", "circle-check"] } });
};

export const getProjectSubscriptions = async (instanceName: string) => {
    const response = await api.get<ProjectSubscriptionResponse[]>(`instances/${instanceName}/projects/subscriptions`);
    return response.data.map((subscription) => parseProjectSubscription(subscription));
};

export const getProjectSsoConfig = async (instanceName: string, projectId: string) => {
    const response = await api.get<SsoConfig>(`instances/${instanceName}/projects/${projectId}/sso-config`);
    return response.data;
};

export const getImpersonateUserLink = async (instanceName: string, projectId: string, userId: string) => {
    const response = await api.post<string>(`instances/${instanceName}/users/${userId}/impersonation-sessions?projectId=${projectId}`);
    return response.data;
};
