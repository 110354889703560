import React, { FunctionComponent, useCallback } from "react";
import { styled, StyledIconButton } from "@iventis/styles";
import { Header5 } from "@iventis/styles/src/components/texts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledFilter } from "../styles/data-table.styles";

export const FilterTemplateComponent: FunctionComponent<{
    children?: React.ReactNode;
    title: string;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ children, title, onClose }) => {
    const preventPopoverClosing = useCallback((e) => e.stopPropagation(), []);
    return (
        <StyledFilter onClick={preventPopoverClosing}>
            <HeaderContainer>
                <PaddingLessHeader5>{title}</PaddingLessHeader5>
                <StyledIconButton onClick={onClose}>
                    <FontAwesomeIcon icon={["fas", "close"]} size="xs" />
                </StyledIconButton>
            </HeaderContainer>
            {children}
        </StyledFilter>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
`;

const PaddingLessHeader5 = styled(Header5)`
    padding: 0;
`;
