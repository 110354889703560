import { inputHeight, styled } from "@iventis/styles";
import { useMaxElementCount } from "@iventis/utilities";
import { Divider } from "@mui/material";
import React, { Fragment, FunctionComponent, ReactNode, useMemo } from "react";

export const FlatListBrowser: FunctionComponent<{ loading: boolean; children: ReactNode; skeleton: ReactNode }> = ({ loading, children, skeleton }) => {
    // Get Skeleton Loading stuff
    const [containerRef, count] = useMaxElementCount<HTMLDivElement>(1, 50, { columns: 1 });

    const skeletons = useMemo(
        () =>
            new Array(count).fill(true).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`Attribute-Skeleton-${i}`}>
                    <FlatListRow style={{ opacity: 1 - i / count }}>{skeleton}</FlatListRow>
                    <Divider style={{ opacity: 1 - i / count }} />
                </Fragment>
            )),
        [count]
    );
    return <ListContainer ref={containerRef}>{loading ? skeletons : children}</ListContainer>;
};

const ListContainer = styled.div`
    flex: 1 1;
    min-height: 0;
    overflow: hidden auto;
`;

export const FlatListRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 3px 20px 3px 30px;
    height: ${inputHeight};
`;
