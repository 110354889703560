import React from "react";
import { styled } from "@iventis/styles";
import { Body2, Body3, Header4 } from "@iventis/styles/src/components/texts";

export const FormWizardTitle: React.FC<{
    header?: React.ReactNode;
    footer?: React.ReactNode;
    title: React.ReactNode;
    customTitle?: boolean;
    customFooter?: boolean;
    customHeader?: boolean;
}> = ({ header, title, footer, customTitle, customFooter, customHeader }) => (
    <StyledTitle twoTitles={header !== undefined && title !== undefined}>
        {header && (customHeader ? header : <StyledBody2>{header}</StyledBody2>)}
        {customTitle ? title : <Header4>{title}</Header4>}
        {footer && (customFooter ? footer : <StyledBody3>{footer}</StyledBody3>)}
    </StyledTitle>
);

const StyledTitle = styled.div<{ twoTitles: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${({ twoTitles }) => (twoTitles ? "16px" : "25px")} 14px 14px 22px;

    svg {
        width: 14px;
        height: 14px;
    }
`;

const StyledBody2 = styled(Body2)`
    color: ${(props) => props.theme.shades.two};
`;

const StyledBody3 = styled(Body3)`
    color: ${(props) => props.theme.shades.two};
`;
