import { AppBarSubmenuItem } from "@iventis/components";

export enum InstanceSubmenuItemType {
    PROJECT = "project",
    PLANSUBSCRIPTIONCOUNTS = "plansubscriptioncounts",
}

export const subMenuItems: AppBarSubmenuItem[] = [
    { id: InstanceSubmenuItemType.PROJECT, name: "Projects", pageIcon: "chart-network", sidePanelIcon: "chart-network" },
    { id: InstanceSubmenuItemType.PLANSUBSCRIPTIONCOUNTS, name: "Plan subscription counts", pageIcon: "clipboard-list-check", sidePanelIcon: "clipboard-list-check" },
];
