import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBoxStyled } from "@iventis/components";
import { StyledIconButton } from "@iventis/styles";
import { NodeComponentProps, useTreeBrowser, getNodeNestedLevel, findNode } from "@iventis/tree-browser";
import { Divider } from "@mui/material";
import React, { FunctionComponent } from "react";
import { RecommendedCategoryNode, CategoryTreeContext } from "./category-types";
import { StyledCategoryRowContainer, StyledCategoryRow } from "./category-repository";

export const RecommendedCategory: FunctionComponent<NodeComponentProps<RecommendedCategoryNode>> = ({ node }) => {
    const [{ expandedNodeIds, loadingNodeIds, tree }, treeBrowserContext] = useTreeBrowser(
        CategoryTreeContext,
        node.id,
        "expandedNodeIds",
        "loadingNodeIds",
        "tree",
        "nodeThumbnails"
    );
    const loading = loadingNodeIds.includes(node.id);
    const expanded = expandedNodeIds.includes(node.id);
    const levelsDeep = getNodeNestedLevel(tree, node);
    const isTopLevel = levelsDeep === 0;

    const handleCheckChange = (value: boolean) => {
        treeBrowserContext.updateNode({ ...node, checked: value });
        // If all children are checked the parent category is expected to be checked as well
        const parentCategory = findNode(tree.childNodes, node.parentId);
        if (parentCategory?.type === "RecommendedCategory" && parentCategory?.childNodes.every((child) => child.checked || child.id === node.id)) {
            treeBrowserContext.updateNode({ ...parentCategory, checked: value });
        }
    };

    return (
        <StyledCategoryRowContainer marginLeft={levelsDeep}>
            <StyledCategoryRow>
                <div className="category-content">
                    <div className="icon-area">
                        {isTopLevel && (
                            <StyledIconButton onClick={() => treeBrowserContext.toggleExpandNode(node)} size="small">
                                <FontAwesomeIcon icon={loading ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="sm" spin={loading} />
                            </StyledIconButton>
                        )}
                    </div>
                    <div className="category-checkbox-container">
                        <span>{node.name}</span>
                        <CheckBoxStyled checked={node.checked} disabled={node.checked} onChange={() => handleCheckChange(!node.checked)} />
                    </div>
                </div>
            </StyledCategoryRow>
            <Divider />
        </StyledCategoryRowContainer>
    );
};
