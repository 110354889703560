import { DataField } from "@iventis/domain-model/model/dataField";
import { Model } from "@iventis/domain-model/model/model";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { FilterFormat } from "@iventis/types";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { createContext, useContext } from "react";
import { IAssetService } from "@iventis/components";
import { Observable } from "rxjs";
import { UnionOfStyles, MapEvents, ModeEvents } from "@iventis/map-engine";

export interface ILayerService {
    postLayer: (layer: MapLayer, previewDataUrl?: string, categories?: string[]) => Promise<void>;
    putLayer: (layer: MapLayer, previewDataUrl?: string, categories?: string[]) => Promise<void>;
    deleteLayer: (layer: MapLayer) => Promise<void>;
}

export interface IStyleService {
    getLayerStyle: (layerId: string, mapId: string, styleType: StyleType) => Promise<UnionOfStyles>;
    getModels: (options?: { filter: FilterFormat[] }) => Promise<Model[]>;
    modelGetter: (ids: string[]) => Promise<Model[]>;
    assetGetter: (ids: string[]) => Promise<{ id: string; url: string }[]>;
    getProjectDataFields: () => Promise<DataField[]>;
    postProjectAttribute: (attribute: DataField) => Promise<DataField>;
    patchProjectAttribute: (attribute: Partial<DataField>) => Promise<DataField>;
    imageCompressor: (id: string, pixelRes: number) => Promise<string>;
    isAssetSdf: (id) => Promise<boolean>;
    bustCacheIds: (ids: string[]) => void;
    refreshAssetEvent?: Observable<ModeEvents | MapEvents>;
}

export interface IEditLayerServices {
    layerService?: ILayerService;
    styleService: IStyleService;
    assetService: IAssetService;
}

export const EditLayerServicesContext = createContext<IEditLayerServices>(undefined);

export const useEditLayerServices = () => useContext(EditLayerServicesContext);
