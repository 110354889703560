import { useDroppable, UseDroppableArguments } from "@dnd-kit/core";
import { MutableRefObject, useContext, useEffect, useMemo, useRef } from "react";
import { DragAndDropContext } from "./drag-and-drop-context";
import { DragAndDropContextType } from "./drag-and-drop-types";

export type UseIventisDroppableOptions = {
    /** Blocks the element from being a drop zone position */
    blockDropZone?: (context: DragAndDropContextType, args: UseDroppableArguments) => boolean;
    suffix?: string;
    overridingDropZoneRef?: MutableRefObject<HTMLElement>;
};

/** Custom useDroppable implementation in line with the iventis Drag and drop context */
export const useIventisDroppable = (args: UseDroppableArguments, options?: UseIventisDroppableOptions): ReturnType<typeof useDroppable> => {
    const dragAndDropContext = useContext(DragAndDropContext);

    // Generate the id with a given suffix if provided
    const id = useMemo(() => {
        if (options?.suffix === undefined) {
            return args.id;
        }
        const suffix = dragAndDropContext.idSuffixes.find((suffix) => suffix === options?.suffix);
        if (suffix === undefined) {
            throw new Error(`Drag and drop context has not provided ${options?.suffix} as an allowed suffix`);
        }
        return `${args.id}${suffix}`;
    }, [args.id, dragAndDropContext.idSuffixes]);

    // Get the dnd droppable data
    const dndDroppable = useDroppable({ ...args, id });

    const dropZoneRef = useRef<HTMLElement>();

    // Create a custom function to set appropriate refs
    const customSetRefs = (element: HTMLElement) => {
        dndDroppable.setNodeRef(element);
        dropZoneRef.current = element;
    };

    useEffect(() => {
        if (
            (dndDroppable.isOver || args.id === dragAndDropContext.newParentId) &&
            (options?.blockDropZone === undefined ? true : options?.blockDropZone?.(dragAndDropContext, args))
        ) {
            // Given we're dropping onto this entity, set the appropriate drop zone
            dragAndDropContext.setOverElement((options?.overridingDropZoneRef ?? dropZoneRef)?.current);
        }
    }, [dndDroppable.isOver, dragAndDropContext.newParentId, options?.overridingDropZoneRef, dropZoneRef?.current]);

    // Return the usual useDroppable return data but with our own custom setNodeRef function
    return { ...dndDroppable, setNodeRef: customSetRefs };
};
