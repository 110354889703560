import { IAssetService } from "@iventis/components";
import React from "react";
import { IStyleService } from "./edit-layer-services";

export interface EditStyleContextInterface {
    setShowAddAttributeTour: (value: boolean) => void;
    canUserEdit: boolean;
    assetService: IAssetService;
    getModels: IStyleService["getModels"];
    getProjectDataFields: IStyleService["getProjectDataFields"];
    onAssetRefresh: IStyleService["refreshAssetEvent"];
}

export const EditStyleContext = React.createContext<EditStyleContextInterface>(null);
