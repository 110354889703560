import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminFolderNode } from "./tree-browser-types";
import { BasicSpatialNode } from "./basic-spatial-node";
import { ImportMapModal } from "./import-map";

export interface FolderNodeProps {
    node: AdminFolderNode;
    setMapInList: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}

export const FolderNodeComponent = ({ node, setMapInList }: FolderNodeProps) => {
    const [showImportDialog, setShowImportDialog] = useState(false);

    return (
        <>
            <BasicSpatialNode
                node={node}
                icon={<FontAwesomeIcon icon={{ prefix: "fas", iconName: "folder" }} className="icon" size="lg" />}
                overflowOptions={[{ label: "Import map", key: "import", selected: () => setShowImportDialog(true) }]}
            />
            {showImportDialog && (
                <ImportMapModal
                    setMapInList={setMapInList}
                    nodeId={node.id}
                    instanceName={node.instanceName}
                    projectId={node.projectId}
                    onClose={() => setShowImportDialog(false)}
                />
            )}
        </>
    );
};
