import React, { ReactElement } from "react";
import { fontSizes, Header3, styled } from "@iventis/styles";

export const AttributeInfoBox = ({ title, infoBlurb, infoExample }: { title: string; infoBlurb: ReactElement | string; infoExample?: ReactElement }) => (
    <StyledWrapper className="attribute-info-box">
        <StyledHeader3>{title}</StyledHeader3>
        <p>{infoBlurb}</p>
        {infoExample && <div className="attribute-info-example">{infoExample}</div>}
    </StyledWrapper>
);

const StyledWrapper = styled.div`
    border-radius: 4px;
    background-color: ${(props) => props.theme.shades.libraryHover};
    color: ${(props) => props.theme.primaryColors.subduedMono};
    font-size: ${fontSizes.xSmall};
    padding: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-style: italic;

    .attribute-info-example table {
        width: 100%;
    }

    .attribute-info-example td,
    .attribute-info-example th {
        text-align: left;
    }
`;

const StyledHeader3 = styled(Header3)`
    font-size: ${fontSizes.summarySmall};
    font-weight: 400;
    font-style: italic;
`;
