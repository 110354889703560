import React, { FunctionComponent } from "react";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";

export const LineEndRoundSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="29" height="18" viewBox="0 0 29 18" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M9 0h20v18H9A9 9 0 0 1 9 0z" fill={theme.shades.three} />
                <path d="M10 6c1.306 0 2.418.835 2.83 2H29v2H12.829A3.001 3.001 0 1 1 10 6z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};

export const LineEndButtSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="33" height="18" viewBox="0 0 33 18" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path fill={theme.shades.three} d="M3.328 0h29v18h-29z" />
                <path d="M3.328 6c1.306 0 2.418.835 2.83 2h26.17v2H6.158a3.001 3.001 0 1 1-2.83-4z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};

export const LineEndSquareSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="30" height="18" viewBox="0 0 30 18" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path fill={theme.shades.three} d="M.675 0h29v18h-29z" />
                <path d="M10.675 6c1.306 0 2.417.835 2.83 2h16.17v2H13.504a3.001 3.001 0 1 1-2.83-4z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};
