import { toast, ToastType } from "@iventis/toasts";
import { Content } from "@iventis/translations";
import { basicTranslator as translate } from "@iventis/translations/use-iventis-translate";
import { AxiosResponse, AxiosError } from "axios";
import { isAuthenticated$ } from "../app";
import { api } from "./api";

const problemWithOurServersErrorToast = () => {
    toast.error({
        type: ToastType.BASIC,
        props: { message: translate(Content.errors.problem_with_our_servers), icon: "triangle-exclamation" },
    });
};

(() => {
    api.interceptors.response.use(
        (next: AxiosResponse) => {
            if (isAuthenticated$.value === false) {
                isAuthenticated$.next(true);
            }
            return Promise.resolve(next);
        },
        ({ response, name }: AxiosError<string>) => {
            if (response.status === 401) {
                if (isAuthenticated$.value === true) {
                    isAuthenticated$.next(false);
                }
            } else if (isAuthenticated$.value === false) {
                isAuthenticated$.next(true);
            }

            switch (response.status) {
                case 400:
                    toast.error({
                        type: ToastType.BASIC,
                        props: { message: translate(Content.errors.problem_with_data_submitted), icon: "triangle-exclamation" },
                    });
                    break;
                case 401:
                    break;
                case 500:
                    toast.error({
                        type: ToastType.BUTTON,
                        props: {
                            message: translate(Content.errors.problem_with_our_servers_code, {
                                code: response.data,
                            }),
                            icon: "triangle-exclamation",
                            buttonLabel: translate(Content.errors.copy_code),
                            onClick: () => {
                                navigator.clipboard.writeText(response.data);
                            },
                        },
                    });
                    break;
                case 503:
                    toast.error({
                        type: ToastType.BASIC,
                        props: { message: translate(Content.errors.connecting_with_our_servers), icon: "triangle-exclamation" },
                    });
                    break;
                default:
                    if (name === "TimeoutError") {
                        toast.error({
                            type: ToastType.BASIC,
                            props: { message: translate(Content.errors.timeout_connecting_servers), icon: "triangle-exclamation" },
                        });
                    } else {
                        // the error code has no be caught, show generic message
                        problemWithOurServersErrorToast();
                    }
            }
        }
    );
})();
