/* eslint-disable react/destructuring-assignment */
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { inputLeftPadding, media, sectionalMargin, formGap, styled } from "@iventis/styles";
import React, { useMemo, useState } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations/content/typed-content";
import { Body1 } from "@iventis/styles/src/components/texts";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { css } from "@emotion/react";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { ValueSelector, ValueSelectorComponentCreator, AutocompleteWithLoading, FormWizardTemplate, CustomDialog } from "@iventis/components";
import { camelToSnakeCase } from "@iventis/utilities";
import { createDataDrivenStyleValue, MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED, useListItemsForFormula } from "./data-driven-styles.helpers";

const allowedDataFieldTypes = [DataFieldType.List];

const listItemCss = css`
    margin-top: ${sectionalMargin};
    padding-left: ${inputLeftPadding};
`;

type CreateListItemFormulaProps<TStyleValue extends StyleValue<unknown>> = {
    dataFields: DataField[];
    value: TStyleValue;
    close: () => void;
    save: (value: TStyleValue) => void;
    valueSelector: ReturnType<ValueSelectorComponentCreator<ValueSelector<unknown>>>;
    styleProperty: string;
};

export function CreateFormulaModal<TStyleValue extends StyleValue<unknown>>({
    open,
    ...props
}: React.PropsWithChildren<{ open: boolean } & CreateListItemFormulaProps<TStyleValue>>) {
    return (
        <CustomDialog open={open} onClose={props.close} data-testid="formula-modal">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <CreateFormulaComponent<TStyleValue> {...props} />
        </CustomDialog>
    );
}

export function CreateFormulaComponent<TStyleValue extends StyleValue<unknown>>({
    dataFields,
    value,
    save,
    close,
    valueSelector,
    styleProperty,
}: React.PropsWithChildren<CreateListItemFormulaProps<TStyleValue>>) {
    const translate = useIventisTranslate();

    const [styleValue, setStyleValue] = useState<TStyleValue>(value);

    const selectedDataField = useMemo(() => dataFields.find((df) => df.id === styleValue.dataFieldId), [styleValue.dataFieldId]);

    const { data: listItems, isLoading: islistItemsLoading } = useListItemsForFormula(selectedDataField, (listItems) => {
        // When the current style value data field matches the selected one and the extraction method is mapped, we can assume this is an existing style value.
        // As this is less than the max list items required for the searching method, we should display all list items that dont have a value with the default.
        if (styleValue?.dataFieldId === selectedDataField.id && styleValue.extractionMethod === StyleValueExtractionMethod.Mapped && styleValue.mappedValues != null) {
            const newMappedValues = {
                ...styleValue.mappedValues,
                ...listItems.filter((l) => !Object.keys(styleValue.mappedValues).includes(l.id)).reduce((cum, l) => ({ ...cum, [l.id]: styleValue.staticValue }), {}),
            };
            setStyleValue({ ...styleValue, mappedValues: newMappedValues });
        } else {
            // Else We can assume the style value is not an existing mappped style and needs a complete new data driven style value with all default values for list items
            const newStyleValue = createDataDrivenStyleValue<TStyleValue, typeof selectedDataField.type>(selectedDataField, styleValue.staticValue, listItems);
            setStyleValue(newStyleValue);
        }
    });

    const setDataField = async (dataField: DataField) => {
        const newStyleValue = createDataDrivenStyleValue<TStyleValue, typeof dataField.type>(dataField, styleValue.staticValue, []);
        setStyleValue(newStyleValue);
    };

    const StaticListItem: DataFieldListItem = {
        name: translate(Content.map2.data_driven.any_others),
        customerIdentifier: "",
        id: "",
        metaData: "",
        order: 0,
        propertyValues: [],
        uniqueId: "",
        dataFieldId: "",
        relationshipValues: [],
    };

    return (
        <FormWizardTemplate
            title={translate(Content.map.data_fields.data_driven_formula.title)}
            stages={[
                {
                    primaryButtonText: translate(Content.common.buttons.confirm),
                    primaryButtonCallback: () => {
                        // Remove any mapped list item values that have the same static value as the default static value. All values default to static so no point storing this data.
                        // Remove any mapped style keys that dont have a list item. Potentially, list items that have been removed may still have a mapped key in the style, so best to remove at this point.
                        const valueToSave = {
                            ...styleValue,
                            mappedValues:
                                styleValue.mappedValues != null
                                    ? Object.entries(styleValue.mappedValues)
                                          .filter(([listItemId, value]) => listItems.some((l) => l.id === listItemId) && value.staticValue !== styleValue.staticValue.staticValue)
                                          .reduce((cum, [listItemId, value]) => ({ ...cum, [listItemId]: value }), {})
                                    : {},
                        };
                        save(valueToSave);
                        close();
                    },
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: close }],
                    isValid: true,
                    submitButtonDataCy: "attribute-based-styling-confirm-button",
                },
            ]}
            currentStage={0}
        >
            <AttributeSelector
                dataFields={dataFields}
                selectedDataField={selectedDataField}
                label={translate(Content.map.data_fields.data_driven_formula.label, {
                    styleProperty: translate(Content.map2.styles[camelToSnakeCase(styleProperty)]).toLocaleLowerCase(),
                })}
                setDataField={setDataField}
            />

            <StyledContent>
                {styleValue.extractionMethod === StyleValueExtractionMethod.Mapped && (
                    <>
                        {listItems != null && !islistItemsLoading && (
                            <>
                                {listItems.length > MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED && (
                                    <AutocompleteWithLoading
                                        placeholder={translate(Content.map.data_fields.data_driven_formula.searchListItem)}
                                        label={translate(Content.map.data_fields.data_driven_formula.addListItem)}
                                        loadingOptions={false}
                                        onChange={(listItem) => {
                                            setStyleValue({
                                                ...styleValue,
                                                mappedValues: { ...styleValue.mappedValues, [listItem.id]: { staticValue: styleValue.staticValue.staticValue } },
                                            });
                                        }}
                                        options={listItems != null ? listItems.filter((l) => !Object.keys(styleValue.mappedValues).includes(l.id)) : []}
                                        value={null}
                                        getOptionLabel={(option) => option.name}
                                        blurOnSelect
                                        className="listItemSearch"
                                    />
                                )}
                                {Object.entries(styleValue.mappedValues)
                                    .filter(([listItemId]) => listItems.some((l) => l.id === listItemId))
                                    .map(([listItemId, value]) => {
                                        const listItem = listItems?.find(({ id }) => listItemId === id);
                                        return (
                                            <ListItem<TStyleValue>
                                                /* We need to unmount and remount this component whenever a different mapped value is changed */
                                                key={styleValue.staticValue + JSON.stringify(Object.entries(styleValue.mappedValues).filter(([key]) => key !== listItemId))}
                                                listItem={listItem}
                                                $css={listItemCss}
                                                valueSelector={valueSelector({
                                                    dataCy: `list-item-${listItem?.name}`,
                                                    value: value.staticValue,
                                                    changeValue: (newValue) =>
                                                        setStyleValue({
                                                            ...styleValue,
                                                            mappedValues: {
                                                                ...styleValue.mappedValues,
                                                                [listItemId]: { ...styleValue.mappedValues[listItemId], staticValue: newValue },
                                                            },
                                                        }),
                                                })}
                                            />
                                        );
                                    })}
                            </>
                        )}
                        <ListItem<TStyleValue>
                            /* We need to unmount and remount this component whenever any mapped value is changed */
                            key={JSON.stringify({ ...styleValue, staticValue: null })}
                            listItem={StaticListItem}
                            $css={listItemCss}
                            valueSelector={valueSelector({
                                value: styleValue.staticValue.staticValue,
                                changeValue: (newValue) => setStyleValue({ ...styleValue, staticValue: { ...styleValue.staticValue, staticValue: newValue } }),
                            })}
                        />
                    </>
                )}
            </StyledContent>
        </FormWizardTemplate>
    );
}

export const AttributeSelector = ({
    label,
    setDataField,
    dataFields,
    selectedDataField,
    className,
}: {
    label: string;
    setDataField: (dataField: DataField) => void;
    dataFields: DataField[];
    selectedDataField: DataField;
    className?: string;
}) => (
    <div data-testid="formula-attribute-selector-container">
        <AutocompleteWithLoading
            label={label}
            loadingOptions={false}
            onChange={setDataField}
            options={dataFields.filter((df) => allowedDataFieldTypes.includes(df.type))}
            value={selectedDataField || null}
            getOptionLabel={(option) => option.name}
            className={className}
        />
    </div>
);

const StyledContent = styled.div`
    ${media.small} {
        // As per designs
        min-height: 280px;
        min-width: 420px;
    }
    .listItemSearch {
        margin-top: ${formGap};
    }
`;

function ListItem<TStyleValue extends StyleValue<unknown>>({
    listItem,
    $css,
    valueSelector,
}: React.PropsWithChildren<{
    listItem: DataFieldListItem;
    $css: ReturnType<typeof css>;
    valueSelector: ReturnType<CreateListItemFormulaProps<TStyleValue>["valueSelector"]>;
}>) {
    return (
        <StyledListItem $css={$css}>
            <Body1>{listItem.name}</Body1>
            {valueSelector}
        </StyledListItem>
    );
}

const StyledListItem = styled.div<{ $css: ReturnType<typeof css> }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ $css }) => $css}
`;
