import { css } from "@emotion/react";
import { styled, main, customMediaQueryMax, iconButtonSize, media } from "@iventis/styles";

import { sharedNodeCss, StyledLibraryNodeProps, getNodeBackgroundColor, NODE_HEIGHT_PX, EMPTY_NODE_HEIGHT_PX } from "./library-shared-node.styles";

const nodeBorder = css`
    border-bottom: ${main.shades.lightBorder} 1px solid;
`;

export const LIST_NODE_HIDE_LATEST_ACTIVITY_BREAKPOINT = 840;

/** When dragging we want to make nodes shorted and slightly transparent */
const draggingNode = (props: StyledLibraryNodeProps) => css`
    width: ${props.draggingNode ? "30%" : "100%"};
    opacity: ${props.draggingNode ? "60%" : "100%"};
`;

export const StyledLibraryListNode = styled.div<StyledLibraryNodeProps>`
    ${(props) => draggingNode(props)};

    display: flex;
    justify-content: stretch;
    height: ${NODE_HEIGHT_PX}px;

    :hover {
        cursor: ${(props) => (props.selectionDisabled ? "default" : "pointer")};
        background-color: ${(props) => ({ theme }) => getNodeBackgroundColor(true, props.selected, props.emptyNode, theme, props.selectionDisabled, false)};
    }

    .bordered-container {
        ${nodeBorder};
        display: flex;
        flex-grow: 1;
        padding-right: 12px;
        min-width: 0;
        justify-content: space-between;
    }
    .content {
        grid-template-columns: 44px 1fr;
        display: grid;

        & > * {
            height: 100%;
        }
        width: 100%;
        ${customMediaQueryMax(LIST_NODE_HIDE_LATEST_ACTIVITY_BREAKPOINT)} {
            justify-items: end;
            grid-template-columns: auto;
            width: 44px;
        }

        ${media.medium} {
            column-gap: 10px;
        }
        ${media.large} {
            width: 60%;
            column-gap: 55px;
        }
        ${media.extraExtraLarge} {
            column-gap: 100px;
        }
    }
    ${sharedNodeCss};
`;

export const StyledExpansionContainer = styled.div<{ indentLevel: number }>`
    flex: 0 0 ${iconButtonSize};
    width: ${iconButtonSize};
    height: 100%;
    margin-left: ${(props) => getIndentPx(props.indentLevel)}px;

    button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: ${({ theme }) => theme.shades.two};
        }
    }
`;

export const NewStyledEmptyNode = styled.div<{ marginLeft: number }>`
    display: flex;
    margin-left: ${iconButtonSize};
    box-sizing: border-box;
    height: ${EMPTY_NODE_HEIGHT_PX}px;
    justify-content: flex-start;
    align-items: center;
    padding-left: ${({ marginLeft }) => marginLeft}px;
    background-color: ${({ theme }) => getNodeBackgroundColor(false, false, true, theme, false)};
`;

export const NewStyledEmptyNodeBordered = styled(NewStyledEmptyNode)`
    ${nodeBorder};
`;

export const getIndentPx = (indentLevel: number) => indentLevel * 20;
