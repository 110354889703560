import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataField } from "@iventis/domain-model/model/dataField";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { borderRadius, iconButtonSize, inlineTextIconMargin, inputLeftPadding } from "@iventis/styles/src/atomic-rules";
import { Body1, Header6 } from "@iventis/styles/src/components/texts";
import { Button } from "@mui/material";
import React, { CSSProperties, FunctionComponent, useMemo } from "react";
import { Theme } from "@emotion/react";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Content } from "@iventis/translations/content/typed-content";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { InteractiveElement, styled } from "@iventis/styles";
import { SimpleTooltip } from "@iventis/components";

export const FormulaSummaryComponent: FunctionComponent<{
    value?: StyleValue<unknown>;
    dataFields: DataField[];
    label?: string;
    PreviewComponent?: FunctionComponent<{ value: unknown }>;
    setFormulaDialogOpen: (open: boolean) => void;
    removeFormula: () => void;
}> = ({ value, dataFields, label, PreviewComponent, setFormulaDialogOpen, removeFormula }) => {
    const translate = useIventisTranslate();
    const dataField = useMemo(() => dataFields.find(({ id }) => id === value?.dataFieldId), [value?.dataFieldId]);
    const wrapperStyle: CSSProperties = { width: "20px", minWidth: "10px", height: "20px" };
    return (
        <StyledFormulaSummary data-testid="formula-summary">
            <div className="formula-section section-one">
                <HorizontalContainer>
                    <Header6>{translate(Content.map2.data_driven.formula_applied)}</Header6>
                    <SimpleTooltip placement="top" text={translate(Content.map4.formula_summary.remove_formula)}>
                        <InteractiveElement style={{ marginLeft: inlineTextIconMargin, width: iconButtonSize, height: iconButtonSize }} onClick={removeFormula}>
                            <FontAwesomeIcon icon={["far", "xmark"]} size="lg" />
                        </InteractiveElement>
                    </SimpleTooltip>
                </HorizontalContainer>
                <FontAwesomeIcon icon={["far", "function"]} />
            </div>
            <div className="formula-section section-two">
                <Body1>
                    <span>{translate(Content.map2.data_driven.by_attributes)}</span>: <span>{label ?? <span>{dataField?.name}</span>}</span>
                </Body1>
                <StyledEditButton data-testid="edit-formula-button" onClick={() => setFormulaDialogOpen(true)}>
                    {translate(Content.schedule.session_card.edit)}
                </StyledEditButton>
            </div>
            {PreviewComponent && (
                <div className="formula-section section-three">
                    {value.extractionMethod === StyleValueExtractionMethod.Mapped &&
                        Object.entries(value.mappedValues)?.map(([key, value]) => (
                            <div key={value.toString() + key} style={wrapperStyle}>
                                <PreviewComponent value={value.staticValue} />
                            </div>
                        ))}
                    <div style={wrapperStyle}>
                        <PreviewComponent value={value.staticValue.staticValue} />
                    </div>
                </div>
            )}
        </StyledFormulaSummary>
    );
};

const StyledEditButton = styled(Button)`
    color: ${(props: { theme: Theme }) => props.theme.primaryColors.focusAccent};
`;

const StyledFormulaSummary = styled.div`
    width: 100%;
    border: solid 2px ${(props: { theme: Theme }) => props.theme.secondaryColors.mediumAccent};
    border-radius: ${borderRadius.input};
    display: flex;
    flex-direction: column;
    .formula-section {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
    }
    .section-one {
        padding: 0 ${inputLeftPadding} 0 ${inputLeftPadding};
        display: flex;
        justify-content: space-between;
        background-color: ${(props: { theme: Theme }) => props.theme.secondaryColors.mediumAccent};
        color: ${(props: { theme: Theme }) => props.theme.typographyColors.blank};
    }
    .section-two {
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 ${inputLeftPadding};
    }
    .section-three {
        padding: 0 0 ${inputLeftPadding} ${inputLeftPadding};
        overflow-x: auto;
        div {
            display: inline-block;
            margin-right: 7px;
        }
    }
`;

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
