import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import React, { FunctionComponent, useCallback, useEffect, useRef, useTransition } from "react";
import { styled, Body2, StyledFieldLabel, formPadding, media, formGap, flexColumn } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { useMutation } from "@tanstack/react-query";
import { DataField } from "@iventis/domain-model/model/dataField";
import { isValidTimeRange, timeRange, timeRangeFrom, timeRangeTo, useFunctionality } from "@iventis/utilities";
import { TimeSelector } from "@iventis/components";
import { TimeRange, TimeSpan } from "@iventis/types";
import { DataFieldListItemTable } from "./data-field-list-item-table";
import { DataFieldListItemTableServiceContext, DataFieldListItemTableService } from "./data-field-list-item-table-services";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";
import { dataFieldTypes } from "./data-fields-types.constants";

/** Renders a data table to manage the attributes list items */
export const AttributeListItemEditor: FunctionComponent<{
    attribute: DataField;
    onInsertColumn?: () => void;
    areListItemsSaving: (areThey: boolean) => void;
    disabled?: boolean;
}> = ({ attribute, onInsertColumn, areListItemsSaving, disabled }) => {
    /*
        Globals
    */
    const { dataFieldListItemsService, dataFieldsService } = useDataFieldServices();
    const { resourceId } = useDataFieldConfig();
    const functionality = useFunctionality();

    /*
        Mutations
    */
    const { isLoading: isCreatingListItem, mutateAsync: postListItem } = useMutation(async (listItem: DataFieldListItem) => {
        await dataFieldListItemsService.postDataFieldListItem(listItem, attribute.id, resourceId);
        return listItem;
    });
    const { isLoading: isUpdatingListItem, mutateAsync: putListItem } = useMutation(async (listItem: DataFieldListItem) => {
        await dataFieldListItemsService.putDataFieldListItem(listItem, attribute.id, resourceId);
        return listItem;
    });
    const { isLoading: isUpdatingListItemOrder, mutateAsync: patchListItemOrder } = useMutation(
        async (listItem: Parameters<DataFieldListItemTableService["updateListItemOrder"]>[1]) => {
            await dataFieldListItemsService.patchDataFieldListItemOrder?.(attribute.id, listItem);
            return listItem;
        }
    );
    const { isLoading: isDeletingListItem, mutateAsync: deleteListItem } = useMutation(async (listItemId: string) => {
        await dataFieldListItemsService.deleteDataFieldListItem?.(listItemId, attribute.id, resourceId);
    });

    /*
        Sync loading states
    */
    useEffect(() => {
        areListItemsSaving(isCreatingListItem || isUpdatingListItem || isDeletingListItem || isUpdatingListItemOrder);
    }, [isCreatingListItem, isUpdatingListItem, isDeletingListItem, isUpdatingListItemOrder]);

    /*
        Data table services
    */
    const updateDefaultValue = useCallback(
        (dataField: Parameters<DataFieldListItemTableService["updateDefaultValue"]>[0]) => dataFieldsService.putDataField({ ...attribute, ...dataField }, resourceId),
        [dataFieldsService.putDataField, resourceId]
    );
    const addListItem = useCallback((dataFieldId: string, listItem: DataFieldListItem) => postListItem(listItem), [postListItem]);
    const updateListItem = useCallback((dataFieldId: string, listItem: DataFieldListItem) => putListItem(listItem), [putListItem]);
    const updateListItemOrder = useCallback((...params: Parameters<DataFieldListItemTableService["updateListItemOrder"]>) => patchListItemOrder(params[1]), [patchListItemOrder]);
    const removeListItem = useCallback((dataFieldId: string, listItemId: string) => deleteListItem(listItemId), [deleteListItem]);
    const getListItems = useCallback((dataFieldId: string) => dataFieldListItemsService.getDataFieldListItems(dataFieldId), [dataFieldListItemsService.getDataFieldListItems]);
    const getListItem = useCallback((dataFieldId: string, listItemId: string) => dataFieldListItemsService.getDataFieldListItem(dataFieldId, listItemId), [
        dataFieldListItemsService.getDataFieldListItem,
    ]);
    const getDataFieldsForResource = useCallback((resourceId: string) => dataFieldsService.getDataFieldsForResource(resourceId), [dataFieldsService.getDataFieldsForResource]);
    const importListItems = useCallback(
        (dataFieldId: string, csv: string, deleteExisting?: boolean) => dataFieldListItemsService.importDataFieldListItems(dataFieldId, csv, resourceId, deleteExisting),
        [dataFieldListItemsService.importDataFieldListItems, resourceId]
    );

    const ref = useRef();
    return (
        <DataFieldListItemTableServiceContext.Provider
            value={{
                updateDefaultValue,
                addListItem,
                updateListItem,
                // Patching the order is optional
                updateListItemOrder: dataFieldListItemsService.patchDataFieldListItemOrder == null ? undefined : updateListItemOrder,
                // Deleting list items is optional
                deleteListItem: dataFieldListItemsService.deleteDataFieldListItem == null ? undefined : removeListItem,
                getListItems,
                getListItem,
                exportListItems: dataFieldListItemsService.exportDataFieldListItems,
                importListItems,
                deleteListItems: undefined,
                getDataFieldsForResource,
                isListItemBeingUsed: dataFieldListItemsService.isListItemBeingUsed,
            }}
        >
            <DataFieldListItemTable
                selectedDataField={attribute}
                isDataChangedRef={ref}
                onNetworkRequestError={() => null}
                onInsertColumn={onInsertColumn}
                canAddColumn={functionality.listItemProperties}
                allowDataDownload={functionality.listItemProperties}
                allowDataUpload={functionality.listItemProperties}
                disabled={disabled}
            />
        </DataFieldListItemTableServiceContext.Provider>
    );
};

/** Editor for an attributes name, What3Words can't be renamed */
export const AttributeNameEditor: FunctionComponent<{ attribute: DataField; updateDataField?: (cb: (df: DataField) => DataField) => void; disabled?: boolean }> = ({
    attribute,
    updateDataField,
    disabled,
}) => {
    const translate = useIventisTranslate();
    return (
        <div className={flexColumn}>
            <StyledFieldLabel>
                {attribute.type === DataFieldType.List ? <span>{translate(Content.map.data_fields.list_name)}</span> : <span>{translate(Content.map.data_fields.name)}</span>}
            </StyledFieldLabel>
            <TextField
                className="input-margin-bottom full-width"
                value={attribute.name || ""}
                onChange={(e) => updateDataField((d) => ({ ...d, name: e.target.value }))}
                inputProps={{ "data-testid": "attribute-name" }}
                disabled={disabled || attribute.type === DataFieldType.What3Words}
            />
        </div>
    );
};

interface AttributeTypeSelectorProps {
    onChange: (value: DataFieldType) => void;
    value: DataFieldType;
}

/**
 * A selector component for choosing a DataFieldType for an attribute. Does not keep its own state, value must be stored externally.
 */
export const AttributeTypeSelector: React.FunctionComponent<AttributeTypeSelectorProps> = ({ value, onChange }) => {
    const translate = useIventisTranslate();
    return (
        <>
            <StyledFieldLabel>{translate(Content.map4.attributes.attribute_type)}</StyledFieldLabel>
            <StyledGridRadioGroup value={value ?? null} onChange={(_, value: DataFieldType) => onChange(value)}>
                {dataFieldTypes
                    .filter(({ type, filterFromCreation }) => type != null && !filterFromCreation)
                    .map(({ name, type }) => (
                        <FormControlLabel
                            control={<Radio data-cy={`attribute-type-option-${type}`} size="small" key={`${type}-${name}-control`} />}
                            label={<Body2 key={`${type}-${name}-label`}>{translate(name)}</Body2>}
                            value={type}
                            key={`${type}-${name}`}
                        />
                    ))}
            </StyledGridRadioGroup>
        </>
    );
};

export const AttributeTimeRangeEditor: FunctionComponent<{
    value: TimeRange | null;
    setValue: (value: TimeRange) => void;
    className?: string;
    testIdPrefix?: string;
    label?: string;
    unifiedInput?: boolean;
}> = ({ value, setValue, className, testIdPrefix, label, unifiedInput }) => {
    const translate = useIventisTranslate();
    const [{ toTime, fromTime }, setTimeRangeState] = React.useState<{ toTime: TimeSpan | null; fromTime: TimeSpan | null }>({
        toTime: timeRangeTo(value) ?? null,
        fromTime: timeRangeFrom(value) ?? null,
    });

    const [, startTransition] = useTransition();

    const updateTimeRange = (newValue: { toTime: TimeSpan | null; fromTime: TimeSpan | null }) => {
        setTimeRangeState(newValue);
        startTransition(() => {
            const newTimeRange = timeRange(newValue.fromTime, newValue.toTime);
            if (value !== newTimeRange && isValidTimeRange(newTimeRange)) {
                setValue(newTimeRange);
            }
        });
    };

    return (
        <StyledWrapper>
            {label && <StyledFieldLabel>{label}</StyledFieldLabel>}
            <StyledTimeRangeContainer className={className} $unifiedInput={unifiedInput}>
                <div>
                    {!unifiedInput && <StyledFieldLabel className={`${className}-label`}>{translate(Content.table.from)}</StyledFieldLabel>}
                    <TimeSelector
                        onChange={(event) => updateTimeRange({ toTime: toTime ?? "00:00:00", fromTime: event.target.value as TimeSpan })}
                        value={fromTime}
                        dataTestId={testIdPrefix ? `${testIdPrefix}-from` : undefined}
                        className="time-range-selector"
                    />
                </div>
                <span aria-hidden="true" style={{ padding: "0 4px" }}>
                    –
                </span>
                <div>
                    {!unifiedInput && <StyledFieldLabel className={`${className}-label`}>{translate(Content.table.to)}</StyledFieldLabel>}
                    <TimeSelector
                        onChange={(event) => updateTimeRange({ toTime: event.target.value as TimeSpan, fromTime: fromTime ?? "00:00:00" })}
                        value={toTime}
                        dataTestId={testIdPrefix ? `${testIdPrefix}-to` : undefined}
                        className="time-range-selector"
                    />
                </div>
            </StyledTimeRangeContainer>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledTimeRangeContainer = styled.div<{ $unifiedInput?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: ${formGap};
    ${(props) => (props.$unifiedInput ? `height:44px; box-sizing: border-box; align-items: center; border: 1px solid ${props.theme.shades.darkBorder}; border-radius: 4px` : "")};
`;

export const StyledGridRadioGroup = styled(RadioGroup)`
    padding: 0 ${formPadding};
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 75%;

    ${media.extraSmall} {
        width: 100%;
    }
`;
