import { AssetType } from "@iventis/domain-model/model/assetType";
import { chipGap, styled } from "@iventis/styles";
import React from "react";
import { useQuery } from "@tanstack/react-query";

import { Hierarchy } from "@iventis/domain-model/model/hierarchy";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { StyledPinkSelectableChip } from "../chips";

export const AssetCategoryChips: React.FC<{
    onCategorySelected: (category: Hierarchy<AssetCategory>) => void;
    selectedCategory: Hierarchy<AssetCategory> | null;
    allCategoriesText: string;
    getTags: (tagType: AssetType) => Promise<Hierarchy<AssetCategory>[]>;
    assetType: AssetType;
    style?: React.CSSProperties;
}> = ({ onCategorySelected, selectedCategory, allCategoriesText, getTags, style, assetType }) => {
    // Categories
    const { data: categories } = useQuery(["tags", assetType], async () => getTags(assetType));

    return (
        <CategoryContainer style={style}>
            <StyledPinkSelectableChip
                key="asset-category-all"
                variant={selectedCategory == null ? "filled" : "outlined"}
                selected={selectedCategory == null}
                label={allCategoriesText}
                onClick={() => onCategorySelected(null)}
            />
            {(categories ?? []).map((category) => {
                const selected = category === selectedCategory;
                return (
                    <StyledPinkSelectableChip
                        key={category.item.id}
                        variant={selected ? "filled" : "outlined"}
                        selected={selected}
                        label={category.item.name}
                        onClick={() => onCategorySelected(category)}
                    />
                );
            })}
        </CategoryContainer>
    );
};

const CategoryContainer = styled.div`
    display: flex;
    gap: ${chipGap};
    flex-wrap: wrap;
`;
