import Resizer from "react-image-file-resizer";

export const imageBlobResizer = async (imageBlob: Blob, pixelRes: number): Promise<string> => {
    const dimensions = await getImageDimensions(imageBlob);
    let minHeight = null;
    let minWidth = null;
    if (dimensions.height < pixelRes && dimensions.width < pixelRes) {
        if (dimensions.height < dimensions.width) {
            minWidth = pixelRes;
        } else {
            minHeight = pixelRes;
        }
    }
    return resizeFile(imageBlob, pixelRes, pixelRes, minWidth, minHeight);
};

const resizeFile: (blob: Blob, maxHeight: number, maxWidth: number, minWidth: number, minHeight: number) => Promise<string> = (
    blob: Blob,
    maxHeight: number,
    maxWidth: number,
    minWidth: number,
    minHeight: number
) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            blob,
            maxWidth,
            maxHeight,
            "PNG",
            100,
            0,
            (uri: string) => {
                resolve(uri);
            },
            "base64",
            minWidth,
            minHeight
        );
    });

/**
 * Given an image file will resize it to the given width and height (Only supports PNG, JPG and JPEG)
 * @param image - image file to be resized
 * @param width - width of the resized image
 * @param height - height of the resized image
 * @returns - resized image file
 */
export function resizeFileImage(image: File, width: number, height: number): Promise<File> {
    const fileType = image?.type?.split("/")?.[1];
    if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        throw new Error(`Unsupported file type ${fileType}, setting to PNG`);
    }

    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            width,
            height,
            fileType,
            100,
            0,
            (file: File) => {
                resolve(file);
            },
            "file",
            width,
            height
        );
    });
}

export const getImageDimensions: (any) => Promise<{ width: number; height: number }> = (blob) =>
    new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({ height: img.height, width: img.width });
        };
        img.src = URL.createObjectURL(blob);
    });

/**
 * Resize an image dimensions given one desired length.
 * The biggest current dimension will be matched to the desired while mainting aspect ratio for the other dimension.
 * @param height - Current height of image
 * @param width - Current width of image
 * @param desiredLength - Desired length of one dimension
 * @returns resized dimension
 * @example width: 25, height: 50, desiredLength: 100 -> 50x100
 */
export const resizeImageDimensionsGivenADesiredLength: (width: number, height: number, desiredLength: number) => { width: number; height: number } = (
    width: number,
    height: number,
    desiredLength: number
) => {
    const scaleFactor = desiredLength / (height > width ? height : width);
    return { width: width * scaleFactor, height: height * scaleFactor };
};

/**
 * Resize an image dimensions given a new height. Aspect ratio will be maintianed
 * @param oldHeight - Current height of image
 * @param oldWidth - Current width of image
 * @param newHeight - Desired length of height
 * @returns resized dimension
 * @example width: 25, height: 50, desiredHeight: 100 -> 50x100
 */
export const resizeImageDimensionGivenHeight: (oldWidth: number, oldHeight: number, newHeight: number) => { width: number; height: number } = (
    oldWidth: number,
    oldHeight: number,
    newHeight: number
) => {
    const scaleFactor = newHeight / oldHeight;
    return { width: oldWidth * scaleFactor, height: oldHeight * scaleFactor };
};

/**
 * Resize an image dimensions given a new width. Aspect ratio will be maintianed
 * @param oldHeight - Current height of image
 * @param oldWidth - Current width of image
 * @param newWidth - Desired length of width
 * @returns resized dimension
 * @example width: 50, height: 25, desiredWidth: 100 -> 100x50
 */
export const resizeImageDimensionGivenWidth: (oldWidth: number, oldHeight: number, newWidth: number) => { width: number; height: number } = (
    oldWidth: number,
    oldHeight: number,
    newWidth: number
) => {
    const scaleFactor = newWidth / oldWidth;
    return { width: oldWidth * scaleFactor, height: oldHeight * scaleFactor };
};

/**
 * Resize an image dimensions given a max width and height. Aspect ratio will be maintained
 *
 * @param oldHeight - Current height of image
 * @param oldWidth - Current width of image
 * @param maxWidth - Desired length of width
 * @param maxHeight - Desired length of height
 */
export function resizeImageGivenMaxWidthAndHeight(oldWidth: number, oldHeight: number, maxWidth: number, maxHeight: number) {
    if (oldWidth > oldHeight) {
        // Width is bigger than height, so we should resize based on width
        const { height, width } = resizeImageDimensionGivenWidth(oldWidth, oldHeight, maxWidth);
        // If the height is still bigger than the max height, we should resize based on height
        if (height > maxHeight) {
            return resizeImageDimensionGivenHeight(width, height, maxHeight);
        }
        return { height, width };
    }
    // Height is bigger than width, so we should resize based on height
    const { height, width } = resizeImageDimensionGivenHeight(oldWidth, oldHeight, maxHeight);
    // If the width is still bigger than the max width, we should resize based on width
    if (width > maxWidth) {
        return resizeImageDimensionGivenWidth(width, height, maxWidth);
    }
    return { height, width };
}
