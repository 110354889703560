import React, { useState } from "react";
import { Body1, Header3, sectionalMargin, styled } from "@iventis/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AutocompleteWithLoading, LoadingComponent } from "@iventis/components";

import { getInstances } from "./project-api-functions";
import { getSubscriptionPlans, getSubscriptionPlansCounts } from "./subscription-plans-api-helpers";

const PlanSubscriptionCounts = () => {
    const [instance, setInstance] = useState<string>(null);

    const { isLoading: isInstancesLoading, data: instances } = useQuery(["instances"], async () => {
        const instances = await getInstances();
        setInstance(instances[0].name);
        return instances;
    });

    const { data: planCounts } = useQuery(
        ["planSubscribedCounts", instance],
        async () => {
            const plans = await getSubscriptionPlans();
            const counts = await getSubscriptionPlansCounts(instance);
            const plansWithCounts: { planName: string; subscribedCount: number }[] = plans.map((p) => ({ planName: p.name, subscribedCount: counts[p.id] }));
            return plansWithCounts;
        },
        { enabled: instance != null }
    );

    return (
        <PlanSubscriptionCountContainer>
            <div className="header">
                <div className="title">
                    <Header3>Plan subscription counts</Header3>
                    <Body1>Number of subscriptions per plan excluding cancelled subscriptions.</Body1>
                </div>
                <AutocompleteWithLoading
                    disableClearable
                    options={instances == null ? [] : instances.map((instance) => instance.name)}
                    value={instance}
                    onChange={(value) => {
                        setInstance(value);
                    }}
                    disabled={false}
                    loadingOptions={isInstancesLoading}
                    getOptionLabel={(option) => option}
                />
            </div>
            <div className="table">
                {planCounts == null ? (
                    <LoadingComponent />
                ) : (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Plan</TableCell>
                                    <TableCell align="right">Subscribed Count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {planCounts.map((row) => (
                                    <TableRow key={row.planName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {row.planName}
                                        </TableCell>
                                        <TableCell align="right">{row.subscribedCount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </PlanSubscriptionCountContainer>
    );
};

export default PlanSubscriptionCounts;

const PlanSubscriptionCountContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        flex-direction: column;
    }
    .header {
        display: flex;
        align-items: center;
        padding: ${sectionalMargin};
        justify-content: space-between;
        box-sizing: border-box;
    }

    .table {
        display: flex;
        overflow-y: auto;
        padding: ${sectionalMargin};
    }
`;
