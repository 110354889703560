import React, { FunctionComponent } from "react";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";

// TODO Replace fills with style variables

export const LineJoinRoundSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M12.003 0h14v16h-6a4 4 0 0 0-4 4v6h-16V12c0-6.627 5.373-12 12-12z" fill={theme.shades.three} />
                <path d="M9.003 6c1.306 0 2.418.835 2.83 2h14.17v2h-14.17a3.008 3.008 0 0 1-1.829 1.829V26h-2V11.83a3.001 3.001 0 0 1 1-5.83z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};

export const LineJoinBevelSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    return (
        <svg width="27" height="26" viewBox="0 0 27 26" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M14.678 0h12v16h-6a4 4 0 0 0-4 4v6h-16V16l14-16z" fill={theme.shades.three} />
                <path d="M9.678 6c1.306 0 2.418.835 2.83 2h14.17v2H12.507a3.008 3.008 0 0 1-1.828 1.829L10.678 26h-2V11.83a3.001 3.001 0 0 1 1-5.83z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};

export const LineJoinMiterSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="27" height="26" viewBox="0 0 27 26" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path fill={theme.shades.three} d="M.328 0h26v16h-10v10h-16z" />
                <path d="M9.328 6c1.306 0 2.418.835 2.83 2h14.17v2h-14.17a3.008 3.008 0 0 1-1.829 1.829V26h-2V11.83a3.001 3.001 0 0 1 1-5.83z" fill={theme.shades.one} />
            </g>
        </svg>
    );
};
