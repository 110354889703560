import { IventisExportedSavedMapView } from "@iventis/domain-model/model/iventisExportedSavedMapView";
import { v4 as uuid } from "uuid";

/** Assigns a new id to each saved map view */
export function replaceSavedMapViewIds(savedMapViews: IventisExportedSavedMapView[], defaultSavedMapViewId?: string) {
    let updatedDefaultSavedMapViewId;
    const updatedSavedMapViews = savedMapViews.map((savedMapView) => {
        const updatedId = uuid();
        if (savedMapView.id === defaultSavedMapViewId) {
            updatedDefaultSavedMapViewId = updatedId;
        }
        return { ...savedMapView, id: updatedId };
    });
    return { updatedSavedMapViews, updatedDefaultSavedMapViewId };
}
