import React, { MutableRefObject } from "react";
import { useIventisDroppable } from "./use-droppable";

export const DropZoneFill = ({
    id,
    className,
    containerElementRef,
    children,
    dataTestId,
}: {
    id: string;
    className?: string;
    containerElementRef: MutableRefObject<HTMLDivElement>;
    children?: React.ReactNode;
    dataTestId?: string;
}) => {
    const { setNodeRef, isOver } = useIventisDroppable(
        { id },
        {
            overridingDropZoneRef: containerElementRef,
            blockDropZone: () => {
                // If this element has children, it has its own on over graphics, don't display the dropzone
                if (children) {
                    return false;
                }
                return true;
            },
        }
    );

    return (
        <div data-testid={dataTestId} className={className} ref={setNodeRef}>
            {isOver && children}
        </div>
    );
};
