import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { borderRadius, inputHeight } from "@iventis/styles/src/atomic-rules";
import { Theme } from "@emotion/react";
import { undefinedValueCheck, useThrottledCallback } from "@iventis/utilities";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";

interface ColourSelectorProps extends ValueSelector<string> {
    throttleMs?: number;
}

export const ColorSelectorComponent: FunctionComponent<ColourSelectorProps> = ({
    value,
    changeValue,
    throttleMs = 100,
    disabled = false,
    testId = "layer-style-colour-selector",
    dataCy,
}) => {
    undefinedValueCheck(value, "Value selector can not accept undefined value.");
    const throttle = useThrottledCallback(changeValue, throttleMs);
    return (
        <>
            <StyledColourPreview colourValue={value}>
                <StyledColourPicker data-testid={testId} data-cy={dataCy} type="color" value={value} onInput={(e) => throttle(e.currentTarget.value)} disabled={disabled} />
            </StyledColourPreview>
        </>
    );
};

const StyledColourPreview = styled.div<{ colourValue: string }>`
    box-sizing: border-box;
    height: ${inputHeight};
    background-color: ${(props) => `${props.colourValue}`};
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.three};
    border-radius: ${borderRadius.standard};
    min-width: 100px;
    position: relative;
`;

const StyledColourPicker = styled.input`
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    // Opacity is zero because we want to use the functionlaity of the input colour picker but not actually show it
    opacity: 0;
`;

export const colourSelectorComponentCreator: ValueSelectorComponentCreator<ColourSelectorProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ColorSelectorComponent {...genericProps} {...additionalProps} />
);

export const ColourSelectorFormulaPreview: FunctionComponent<{ value: string }> = ({ value }) => <div style={{ backgroundColor: value, width: "100%", height: "100%" }} />;
