import { Header3, muiInputFormsCSS, sectionalMargin, Subtitle, styled } from "@iventis/styles";
import React, { FunctionComponent, ReactNode } from "react";

import { formGap } from "@iventis/styles/src/atomic-rules";

export const AssetsRepoTemplate: FunctionComponent<{
    repoTitle: string;
    repoSubtitle: string;
    buttons: ReactNode[];
    browserComponent: ReactNode;
}> = ({ repoTitle, repoSubtitle, buttons, browserComponent }) => (
    <RepoContainer>
        <div className="header-row">
            <Header3>{repoTitle}</Header3>
        </div>
        <div className="subtitle-controls-row">
            <Subtitle>{repoSubtitle}</Subtitle>
            <div className="buttons">{buttons}</div>
        </div>
        <div className="box">{browserComponent}</div>
    </RepoContainer>
);

export const RepoContainer = styled.div`
    padding: ${sectionalMargin};
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    .header-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
    }
    .subtitle-controls-row {
        margin-top: ${sectionalMargin};
        margin-bottom: ${formGap};
        display: flex;
        justify-content: space-between;
        align-items: end;
        flex-shrink: 0;
        .buttons {
            display: flex;
            // Margin between the buttons (no matching/relevant atomic rule to apply)
            gap: 20px;
        }
    }
    .box {
        overflow-y: auto;
        flex-grow: 1;
    }
    ${muiInputFormsCSS}
`;
