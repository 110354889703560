import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { createMappedStyleValue, getStaticStyleValueFromMapped } from "@iventis/map-engine/src/utilities/static-styles";
import { ModelLayerStyle } from "@iventis/map-engine/src/types/models";
import { ModelWithThumbnail } from "./model-edit-style";

/** Gets all the list items currently in use in the width, height and length style properties' mapped values */
export const getListItemsUsedInStylesDimensions = (style: ModelLayerStyle) =>
    Object.keys({ ...style.height.mappedValues, ...style.width.mappedValues, ...style.length.mappedValues });

export type ListItemToDimension = {
    [listItemId: string]: ListItemModel;
};

export type ListItemModel = { thumbnailUrl?: string; modelName?: string; modelId?: string; dimensions?: boolean; height: number; length: number; width: number };

/** Converts a datafield and style values to a map where the datafield id is the key and the model dimensions are the value */
export const convertListItemsToModelListItem = (style: ModelLayerStyle, modelsWithThumbnails: ModelWithThumbnail[], attributeBasedModel: boolean) => {
    const map: ListItemToDimension = {};
    getListItemsUsedInStylesDimensions(style).forEach((listItemId) => {
        const height = style.height?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(style.height);
        const width = style.width?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(style.width);
        const length = style.length?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(style.length);
        // If we model is not attribute based then we only using attribute for dimensions
        if (!attributeBasedModel) {
            map[listItemId] = {
                height,
                width,
                length,
            };
        } else {
            // Get all relating data to the model to show in the UI
            const modelId = style.model?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(style.model);
            const model = modelsWithThumbnails.find((model) => model.id === modelId);
            map[listItemId] = {
                height,
                width,
                length,
                modelId: model.id,
                modelName: model.name,
                thumbnailUrl: model.thumbnailUrl,
                dimensions: !(model?.height == null || model?.height === 0),
            };
        }
    });
    map.static = getStaticModelValues(style, modelsWithThumbnails, attributeBasedModel);
    return map;
};

export const getStaticModelValues = (style: ModelLayerStyle, modelsWithThumbnails: ModelWithThumbnail[], attributeBasedModel: boolean) => {
    const height = getStaticStyleValueFromMapped(style.height);
    const width = getStaticStyleValueFromMapped(style.width);
    const length = getStaticStyleValueFromMapped(style.length);
    // If we model is not attribute based then we only using attribute for dimensions
    if (!attributeBasedModel) {
        return {
            height,
            length,
            width,
        };
    }

    const modelId = getStaticStyleValueFromMapped(style.model);
    const model = modelsWithThumbnails.find((model) => model.id === modelId);
    return {
        height,
        width,
        length,
        modelId: model.id,
        modelName: model.name,
        thumbnailUrl: model.thumbnailUrl,
        dimensions: !(model?.height == null || model?.height === 0),
    };
};

/** Converts a map of list items and dimensions to StyleValues */
export const convertModelListItemsToStyleValues = (
    dataFieldIdToDimensionMap: ListItemToDimension,
    selectedDataFieldId: string,
    attributeBasedModel: boolean
): { height: StyleValue<number>; length: StyleValue<number>; width: StyleValue<number>; model?: StyleValue<string> } => {
    const height: { [listItemId: string]: number } = {};
    const width: { [listItemId: string]: number } = {};
    const length: { [listItemId: string]: number } = {};
    const model: { [listItemId: string]: string } = {};
    Object.entries(dataFieldIdToDimensionMap).forEach(([listItemId, value]) => {
        if (listItemId !== "static") {
            const defaultValues = dataFieldIdToDimensionMap.static;
            const isDifferent = Object.entries(value).some(([key]) => defaultValues[key] !== value[key]);
            // If the value is different to the default value, we can add a mapped value for the list item
            if (isDifferent) {
                height[listItemId] = value.height;
                width[listItemId] = value.width;
                length[listItemId] = value.length;
                model[listItemId] = value?.modelId;
            }
        }
    });
    return {
        height: createMappedStyleValue(dataFieldIdToDimensionMap.static.height, height, selectedDataFieldId),
        length: createMappedStyleValue(dataFieldIdToDimensionMap.static.length, length, selectedDataFieldId),
        width: createMappedStyleValue(dataFieldIdToDimensionMap.static.width, width, selectedDataFieldId),
        model: attributeBasedModel ? createMappedStyleValue(dataFieldIdToDimensionMap.static.modelId, model, selectedDataFieldId) : null,
    };
};
