/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
    createInitialState,
    TreeBrowserComponent,
    useTreeBrowserInitialise,
    TreeBrowserHeaderComponent,
    isAllNodesSelected,
    useTreeBrowser,
    TreeBrowserStateInterface,
    UnionOfNodes,
    TreeBrowserState,
    TreeBrowserXState,
} from "@iventis/tree-browser";
import { Header3 } from "@iventis/styles";
import { useDebounce } from "@iventis/utilities";
import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SearchBarComponent } from "@iventis/search/components/search-bar";
import { AutocompleteWithLoading, LoadingComponent } from "@iventis/components";
import { AdminTreeNodeType } from "@iventis/people";
import { Instance } from "@iventis/domain-model/model/instance";
import { getInstances, instanceServices } from "./project-api-functions";
import { sortingMethods } from "./tree-browser-constants";
import { nodeComponents } from "./tree-browser-nodes";
import { TreeContainer } from "./tree-browser-styles";
import { AdminProjectNodeTree } from "./tree-browser-types";
import { createProjectNode, createRootNode, TreeBrowserProjectsContext } from "./project.helpers";
import { ProjectModalComponent } from "./project-modal";
import { RecommendedAssetType } from "./category-types";

export const InstancesSelector: FunctionComponent<{ instances: Instance[]; instance: string; setInstance: (i: string) => void; isInstancesLoading: boolean }> = ({
    setInstance,
    instance,
    instances,
    isInstancesLoading,
}) => {
    const [, treeBrowserContext] = useTreeBrowser(TreeBrowserProjectsContext);
    return (
        <AutocompleteWithLoading
            disableClearable
            options={instances == null ? [] : instances.map((instance) => instance.name)}
            value={instance}
            onChange={(value) => {
                setInstance(value);
                const rootNode = createRootNode(value, []);
                treeBrowserContext.setTree(rootNode);
            }}
            disabled={false}
            loadingOptions={isInstancesLoading}
            getOptionLabel={(option) => option}
        />
    );
};

export const useProjectSearch = (
    treeBrowserState: TreeBrowserState<AdminProjectNodeTree, UnionOfNodes<AdminProjectNodeTree>>,
    treeBrowserContext: TreeBrowserStateInterface<AdminProjectNodeTree>,
    initialise?: TreeBrowserStateInterface<AdminProjectNodeTree>["initialise"]
) => {
    const [instance, setInstance] = useState<string>(null);
    const { isLoading: isInstancesLoading, data: instances } = useQuery(["instances"], async () => {
        const instances = await getInstances();
        const rootNode = createRootNode(instances[0].name, []);
        // Only really want to initialise the tree once we have our instance
        if (!treeBrowserState.isInitialised) {
            initialise?.(createInitialState({ treeId: rootNode.id, tree: rootNode }));
        }
        treeBrowserContext.setTree(rootNode);
        setInstance(instances[0].name);
        return instances;
    });
    const { value: search, debouncedValue: debouncedSearch, setValue: setSearch } = useDebounce(null, 700);

    useEffect(() => {
        if (debouncedSearch != null && treeBrowserState.isInitialised) {
            treeBrowserContext.search(debouncedSearch);
        }
    }, [debouncedSearch, treeBrowserState.isInitialised]);
    return { search, setSearch, instance, setInstance, instances, isInstancesLoading } as const;
};

const setMapInList = (
    mapInList: { mapId: string; mapName: string; parentId: string; instanceName: string; projectId: string },
    treeBrowserContext: TreeBrowserXState<AdminProjectNodeTree>
) => {
    treeBrowserContext.addNodesLocally([
        {
            parentId: mapInList.parentId,
            instanceName: mapInList.instanceName,
            projectId: mapInList.projectId,
            id: mapInList.mapId,
            name: mapInList.mapName,
            type: AdminTreeNodeType.Map,
            childNodes: [],
        },
    ]);
};

const ProjectsTree = () => {
    const [treeBrowserState, treeBrowserContext, initialise] = useTreeBrowserInitialise(createInitialState<AdminProjectNodeTree>({}), instanceServices, undefined, undefined, {
        delayInitialisation: true,
    });

    const { tree, expandedNodeIds, isLoadingTree, selectedNodeIds } = treeBrowserState;

    const selectAllToggled = useMemo(() => isAllNodesSelected(tree, selectedNodeIds), [selectedNodeIds, tree]);

    const [createProjectOpen, setCreateProjectOpen] = useState(false);

    const { search, setSearch, instance, setInstance, instances, isInstancesLoading } = useProjectSearch(treeBrowserState, treeBrowserContext, initialise);

    if (tree == null && !isLoadingTree) {
        return <p>Something went wrong</p>;
    }

    return (
        <>
            <TreeContainer>
                <TreeBrowserProjectsContext.Provider value={treeBrowserContext}>
                    <div className="header">
                        <Header3>Projects</Header3>
                        <InstancesSelector instances={instances} instance={instance} isInstancesLoading={isInstancesLoading} setInstance={setInstance} />
                    </div>
                    <div className="header">
                        <ProjectSearch search={search ?? ""} onChange={setSearch} loading={instance == null} />
                        <Button onClick={() => setCreateProjectOpen(true)} disabled={instance == null}>
                            Create Project
                        </Button>
                    </div>
                    <TreeBrowserHeaderComponent
                        selectAllValue={selectAllToggled}
                        selectAll={() => treeBrowserContext.toggleSelectAll()}
                        sortMethods={sortingMethods}
                        selectedSortMethod={sortingMethods[0]}
                        onSortMethodChange={() => {}}
                        disabledSort={isLoadingTree}
                        disabledSelection={isLoadingTree}
                    />
                    <div className="browser">
                        {isLoadingTree ? (
                            <LoadingComponent />
                        ) : (
                            <TreeBrowserComponent<AdminProjectNodeTree>
                                node={tree}
                                openNodes={expandedNodeIds}
                                components={nodeComponents}
                                displayRootNode={false}
                                setMapInList={(mapId, mapName, parentId, instanceName, projectId) =>
                                    setMapInList({ mapId, mapName, parentId, instanceName, projectId }, treeBrowserContext)
                                }
                            />
                        )}
                    </div>
                </TreeBrowserProjectsContext.Provider>
            </TreeContainer>
            <ProjectModalComponent
                open={createProjectOpen}
                subtitle={instance}
                onClose={() => setCreateProjectOpen(false)}
                onConfirm={(name, applications, billing, plan, price, maxUsers, unlimitedUsers, recommendedLayerTemplateCategories, committedUntil) => {
                    setCreateProjectOpen(false);

                    const projNode = createProjectNode(
                        instance,
                        name,
                        applications,
                        {
                            billingProvider: billing,
                            subscriptionPlanPriceId: price.id,
                            subscriptionPlanId: plan.id,
                            status: undefined,
                            projectId: undefined,
                            id: undefined,
                            maximumUsers: unlimitedUsers ? null : maxUsers,
                            committedUntil,
                            cancelAtCommittedUntil: false,
                        },
                        {
                            [RecommendedAssetType.RecommendedLayerTemplate]: recommendedLayerTemplateCategories,
                        }
                    );
                    treeBrowserContext.addNode(projNode);
                }}
                instanceName={instance}
            />
        </>
    );
};

export const ProjectSearch: FunctionComponent<{ search: string; onChange: (s: string) => void; loading: boolean; disabled?: boolean }> = ({
    search,
    onChange,
    loading,
    disabled,
}) => <SearchBarComponent placeHolderText="Search by name or email" value={search ?? ""} onValueChange={onChange} loading={loading} disabled={disabled} />;

export default ProjectsTree;
