import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChipType, LoadingComponent, StyledChip } from "@iventis/components";
import {
    convertSitemapsToSitemapNodes,
    ISitemapContext,
    SitemapContext,
    SitemapFormRef,
    SitemapFormWizard,
    SitemapModal,
    sitemapNodeComponents,
    SitemapRootNode,
    SitemapTreeBrowserContext,
} from "@iventis/sitemaps";
import { Header3, sectionalMargin, Subtitle, styled } from "@iventis/styles";
import { createInitialState, TreeBrowserComponent, useTreeBrowserInitialise } from "@iventis/tree-browser";
import { useConstant } from "@iventis/utilities";
import { Button } from "@mui/material";
import React, { useState, FunctionComponent, useRef } from "react";

import { RepoContainer } from "./assets-repo-template";
import { useProjectSelector } from "./project-selector";
import { createRootIdFromProjectIdAndInstanceName, createSitemapContext, sitemapServices } from "./sitemap-api-functions";

export const SitemapRepository = () => {
    const { embeddedSelector, instanceName, isProjectSelected, projectId, projectName, switchProjectCallback } = useProjectSelector();

    return (
        <RepoContainer>
            <div className="header-row">
                <StyledHeader>
                    <span>Sitemaps</span> {isProjectSelected && <span>({projectName})</span>}
                </StyledHeader>
                {isProjectSelected && (
                    <Button onClick={switchProjectCallback} variant="text">
                        Switch project
                    </Button>
                )}
            </div>
            <StyledContainer>
                {!isProjectSelected && embeddedSelector}
                {isProjectSelected && <SitemapTree projectId={projectId} instanceName={instanceName} />}
            </StyledContainer>
        </RepoContainer>
    );
};

const SitemapTree: FunctionComponent<{ projectId: string; instanceName: string }> = ({ projectId, instanceName }) => {
    const treeId = useConstant(() => createRootIdFromProjectIdAndInstanceName(projectId, instanceName));
    const [{ tree, expandedNodeIds, isLoadingTree }, sitemapsTreeBrowserContext] = useTreeBrowserInitialise(
        createInitialState<SitemapRootNode>({
            mainNodeId: treeId,
            expandAllNodesOnLoad: true,
            treeId,
        }),
        sitemapServices,
        null
    );
    const sitemapContextValue = useConstant<ISitemapContext>(() => createSitemapContext(instanceName, projectId));

    const formRef = useRef<SitemapFormRef>();

    const [modal, setModal] = useState<"create-sitemap" | null>(null);
    return (
        <>
            <SitemapContext.Provider value={sitemapContextValue}>
                <div className="subtitle-controls-row">
                    <Subtitle>Sitemap repository</Subtitle>
                    <StyledChip
                        label={
                            <>
                                <FontAwesomeIcon icon={["far", "plus"]} /> Add sitemap
                            </>
                        }
                        chipType={ChipType.PrimaryButton}
                        onClick={() => setModal("create-sitemap")}
                    />
                </div>
                <StyledTreeContainer>
                    {isLoadingTree ? (
                        <LoadingComponent />
                    ) : (
                        <SitemapTreeBrowserContext.Provider value={sitemapsTreeBrowserContext}>
                            <TreeBrowserComponent<SitemapRootNode> node={tree} openNodes={expandedNodeIds} components={sitemapNodeComponents} />
                        </SitemapTreeBrowserContext.Provider>
                    )}
                </StyledTreeContainer>
                <SitemapModal open={modal === "create-sitemap"} onClose={() => (formRef.current.isUploadingTiles() ? null : setModal(null))}>
                    <SitemapFormWizard
                        ref={formRef}
                        saveAndClose={(s) => {
                            sitemapsTreeBrowserContext.updateNodeLocally(convertSitemapsToSitemapNodes([s], treeId)[0], undefined, true, true);
                            setModal(null);
                        }}
                        save={(s) => sitemapsTreeBrowserContext.addNodesLocally(convertSitemapsToSitemapNodes([s], treeId))}
                        close={() => (formRef.current.isUploadingTiles() ? null : setModal(null))}
                    />
                </SitemapModal>
            </SitemapContext.Provider>
        </>
    );
};

const StyledHeader = styled(Header3)`
    align-self: start;
`;

const StyledContainer = styled.div`
    margin-top: ${sectionalMargin};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const StyledTreeContainer = styled.div`
    overflow-y: auto;
    flex: 1 1 0;
`;
