import React, { FunctionComponent } from "react";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";

export const LineStyleSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" id="header-icon">
            <rect transform="rotate(-45 3.672 12.121)" x="2" y="9" width="16" height="3" rx="1.5" fill={theme.shades.one} fillRule="evenodd" />
        </svg>
    );
};

export const LineJoinSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg" id="header-icon">
            <path
                d="M6.525 1.575a3.5 3.5 0 0 1 5.773 3.65l5.187 5.19-2.121 2.12-5.188-5.187c-.759.27-1.592.27-2.351 0l-5.189 5.188-2.121-2.122 5.188-5.188a3.5 3.5 0 0 1 .822-3.65z"
                fill={theme.shades.one}
                fillRule="evenodd"
            />
        </svg>
    );
};

export const LineEndSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" id="header-icon">
            <path d="M9.414 1.136a3.5 3.5 0 1 1 1.3 5.773L7.41 10.214a3.5 3.5 0 1 1-2.122-2.122l3.305-3.305a3.5 3.5 0 0 1 .822-3.651z" fill={theme.shades.one} fillRule="evenodd" />
        </svg>
    );
};

export const ArrowHeaderSVGComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return (
        <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg" id="header-icon">
            <g fill="none" fillRule="evenodd">
                <path stroke={theme.shades.one} strokeWidth="3" strokeLinecap="round" d="m3.99 15.51 7.07-7.07" />
                <path fill={theme.shades.one} d="m16.01 3.49-3.535 9.192-5.657-5.657z" />
            </g>
        </svg>
    );
};
