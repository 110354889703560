import { createMachine } from "xstate";

export const layerThumbnailGeneratorMachine = createMachine({
    id: "layerThumbnailGenerator",
    initial: "loadingMap",
    states: {
        loadingMap: {
            on: {
                MAP_LOADED: "takingSnapshot",
            },
        },
        takingSnapshot: {
            invoke: {
                src: "takeSnapshot",
                id: "takeSnapshot",
                onDone: "updatingThumbnail",
                onError: "failed",
            },
        },
        updatingThumbnail: {
            invoke: {
                src: "updateThumbnail",
                id: "updateThumbnail",
                onDone: "finished",
                onError: "failed",
            },
        },
        failed: {
            on: {
                RETRY: "loadingMap",
            },
        },
        finished: {
            type: "final",
            entry: "onDone",
        },
    },
    schema: {
        events: {} as { type: "MAP_LOADED" } | { type: "RETRY" },
        services: {} as { takeSnapshot: { data: string } },
    },
    // eslint-disable-next-line no-undef
    tsTypes: {} as import("./layer-thumbnail-generator-machine.typegen").Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
});
