/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { FormLabel, StyledFieldLabel, formGap, muiInputFormsCSS, styled } from "@iventis/styles";
import React, { FunctionComponent, useMemo, useState } from "react";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { TextField, Autocomplete } from "@mui/material";
import { CustomDialog, FormWizardTemplate } from "@iventis/components";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { DefaultSubscriptionPlan } from "@iventis/domain-model/model/defaultSubscriptionPlan";
import { useQuery } from "@tanstack/react-query";
import { SUBSCRIPTION_PRICE_QUERY_KEY } from "./constants";
import { getPricesForSubscriptionPlan } from "./subscription-plans-api-helpers";

export const SubscriptionPlanDefaultModal: FunctionComponent<{
    open: boolean;
    plans: SubscriptionPlan[];
    initialDefaultPlan?: DefaultSubscriptionPlan;
    onSave: (defaultPlan: DefaultSubscriptionPlan) => void;
    onClose: () => void;
}> = ({ open, plans, initialDefaultPlan = { subscriptionPlanId: plans[0].id, subcriptionPlanPriceId: plans[0].subscriptionPlanPrices[0]?.id }, onSave, onClose }) => {
    const [defaultPlan, setDefaultPlan] = useState(initialDefaultPlan);
    const setPlan = (plan: SubscriptionPlan) => setDefaultPlan({ subcriptionPlanPriceId: null, subscriptionPlanId: plan?.id });
    const setPrice = (price: SubscriptionPlanPrice) => setDefaultPlan((d) => ({ ...d, subcriptionPlanPriceId: price?.id }));

    const plan = useMemo(() => {
        const plan = plans.find((p) => p.id === defaultPlan.subscriptionPlanId);
        return plan;
    }, [defaultPlan, plans]);

    const { data: prices, isLoading: loadingPrices } = useQuery([SUBSCRIPTION_PRICE_QUERY_KEY, plan?.id], () => getPricesForSubscriptionPlan(plan?.id), {
        enabled: plan?.id != null,
    });

    const price = useMemo(() => prices?.find((p) => p.id === defaultPlan?.subcriptionPlanPriceId), [prices, defaultPlan.subcriptionPlanPriceId]);

    const isValid =
        defaultPlan.subscriptionPlanId != null &&
        defaultPlan.subcriptionPlanPriceId != null &&
        (defaultPlan.subscriptionPlanId !== initialDefaultPlan.subscriptionPlanId || defaultPlan.subcriptionPlanPriceId !== initialDefaultPlan.subcriptionPlanPriceId);

    return (
        <CustomDialog fullWidth open={open} onClose={onClose}>
            <FormWizardTemplate
                title="Set default plan"
                currentStage={0}
                stages={[
                    {
                        isValid,
                        primaryButtonCallback: () => {
                            onSave(defaultPlan);
                        },
                        primaryButtonText: "Save",
                        secondaryButtons: [{ buttonText: "Cancel", onButtonPressed: onClose }],
                    },
                ]}
            >
                <StyledFormContainer>
                    <div>
                        <StyledFieldLabel>
                            <FormLabel>Choose subscription plan</FormLabel>
                        </StyledFieldLabel>
                        <Autocomplete
                            multiple={false}
                            options={plans}
                            value={plan ?? null}
                            getOptionLabel={(option: SubscriptionPlan) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(_, value: SubscriptionPlan) => {
                                setPlan(value);
                            }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div>
                        <StyledFieldLabel>
                            <FormLabel>Choose price for subscription plan</FormLabel>
                        </StyledFieldLabel>
                        <Autocomplete
                            loading={loadingPrices}
                            multiple={false}
                            options={prices ?? []}
                            value={price ?? null}
                            getOptionLabel={(option: SubscriptionPlanPrice) => `${option.currencyCode} - ${option.price} - ${option.frequency} - ${option.billingUnit}`}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(_, value: SubscriptionPlanPrice) => {
                                setPrice(value);
                            }}
                            style={{ width: "100%" }}
                        />
                    </div>
                </StyledFormContainer>
            </FormWizardTemplate>
        </CustomDialog>
    );
};

const StyledFormContainer = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    flex-direction: column;
    gap: ${formGap};
`;
