import React from "react";
import { styled, fontSizes } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Checkbox } from "@mui/material";

import { useTreeBrowser } from "../../../state/tree-browser-hooks";
import { TreeBrowserContext } from "../../../state/tree-browser-state";
import { TreeBrowserNode } from "../../../types/data-types";
import { getNodeBackgroundColor, NewStyledCheckBoxContainer as CheckBoxContainer, NODE_HEIGHT_PX } from "../../library/styles/library-shared-node.styles";

interface BaseNodeProps {
    node: TreeBrowserNode;
    treeContext: typeof TreeBrowserContext;
    children: React.ReactNode;
    className?: string;
}

export const BaseNode = ({ node, treeContext, children, className }: BaseNodeProps) => {
    const [{ selectedNodeIds }, treeBrowserContext] = useTreeBrowser(treeContext, node.id, "tree", "selectedNodeIds", "mainNodeId");

    const selected = selectedNodeIds.includes(node.id);

    return (
        <StyledNode className={className} selected={selected}>
            <CheckBoxContainer>
                <Checkbox size="small" checked={selected} onChange={() => treeBrowserContext.toggleSelectNodes([node], undefined)} color="default" />
            </CheckBoxContainer>
            {children}
        </StyledNode>
    );
};

const StyledNode = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    gap: 20px;
    height: ${NODE_HEIGHT_PX}px;
    font-size: ${fontSizes.small};

    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.four};
    background-color: ${(props) => getNodeBackgroundColor(false, props.selected, false, props.theme)};

    :hover {
        background-color: ${(props) => getNodeBackgroundColor(true, props.selected, false, props.theme)};
    }
    .icon {
        color: ${({ theme }: { theme: Theme }) => theme.shades.two};
    }
`;
