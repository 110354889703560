import React from "react";
import { CustomDialog } from "@iventis/components";
import { ImportMapForm } from "./import-map-form";

export const ImportMapModal = ({
    nodeId,
    projectId,
    instanceName,
    onClose,
    setMapInList,
}: {
    nodeId: string;
    projectId: string;
    instanceName: string;
    onClose: () => void;
    setMapInList: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}) => (
    <CustomDialog open fullWidth onClose={onClose} maxWidth="md">
        <ImportMapForm setMapInList={setMapInList} onClose={onClose} nodeId={nodeId} instanceName={instanceName} projectId={projectId} />
    </CustomDialog>
);
