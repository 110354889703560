import { Theme, css } from "@emotion/react";
import { Popover, PopoverProps } from "@mui/material";
import { styled } from "@iventis/styles";

export interface StyledPopoverProps extends PopoverProps {
    space: number;
    $withIcons?: boolean;
}
export const StyledPopover = styled(Popover)<StyledPopoverProps>`
    .MuiPopover-paper {
        display: grid;
        grid-auto-rows: ${({ space }) => `${space}px`};
        padding: 0.1rem;
    }
    .title {
        text-align: center;
        align-self: center;
        padding: 0 0.5rem;
    }
    .button {
        width: 100%;
        padding: 0 0.75rem;
        min-width: 200px;
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        ${(props) =>
            props.$withIcons
                ? css`
                      justify-content: start;
                      gap: 0.75rem;
                  `
                : ""}
        #tick {
            position: absolute;
            left: 0.75rem;
        }
        :hover {
            background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.warm};
        }
    }
`;
