import { TextButton } from "@iventis/styles";
import React, { FunctionComponent, useState, useMemo } from "react";
import { UploadModelData, LoadingComponent, AssetBrowserComponent, UploadModelForm, UploadAssetModal, ConfirmationDialogComponent } from "@iventis/components";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAssetSignatureCache } from "@iventis/utilities";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { convertModelToUploadModelData, ModelAsset, uploadFileModalDimensions } from "@iventis/api-helpers";
import { getAllModelAssetIds } from "@iventis/layer-styles";
import { AssetsRepoTemplate } from "./assets-repo-template";
import { ASSET_CARD_SIZE } from "./constants";
import { createModelWithAssets, deleteAsset, deleteModel, getAssetsbyIds, getModel, getModels, updateModelWithAssets } from "./assets-api-helpers";
import { getCategories } from "./category-services";

type Action =
    | { type: "upload"; target?: null }
    | { type: "edit"; target: ModelAsset }
    | { type: "delete"; target: ModelAsset; deleting: boolean }
    | { type: null; target?: null; deleting: null };

export const ModelsRepositoryComponent: FunctionComponent = () => {
    const [action, setAction] = useState<Action>({ type: null });

    const [selection, setSelection] = useState<string[]>([]);

    const queryClient = useQueryClient();

    const filter = useMemo(() => [], []);

    const { status, data: assets, error, refetch } = useQuery(
        ["models", filter],
        async () => {
            const models = await getModels({ filter });
            const modelThumbnailIds = models.map((m) => m.thumbnailAssetId);
            const thumbnails = await getAssetsbyIds(modelThumbnailIds);
            return models.map((m) => {
                const modelAsset: ModelAsset = { ...m, thumbnail: thumbnails.find((t) => t.id === m.thumbnailAssetId) };
                return modelAsset;
            });
        },
        { refetchOnWindowFocus: false }
    );
    /** Selected asset (undefined if more than one asset is selected) */
    const selectedModelSingle = useMemo(() => (selection?.length === 1 ? assets?.find((a) => selection?.[0] === a.thumbnailAssetId) : undefined), [selection, assets]);

    const { data: categories } = useQuery(["categories", AssetType.Model], async () => getCategories(AssetType.Model));

    const modelCategories = useMemo(() => categories?.map((c) => c.name) ?? [], [categories]);

    const mutationPost = useMutation(async (data: UploadModelData) => createModelWithAssets(data), {
        onSuccess: (data) => {
            queryClient.setQueryData<ModelAsset[]>(["models", filter], (oldData) => [data, ...oldData]);
        },
    });

    const mutationPut = useMutation(async (data: UploadModelData) => updateModelWithAssets(data, action.target), {
        onSuccess: (updatedModel) => {
            queryClient.setQueryData<ModelAsset[]>(["models", filter], (existingData) =>
                existingData.reduce((cum, existingModel) => [...cum, updatedModel.id === existingModel.id ? updatedModel : existingModel], [])
            );
        },
    });

    const imageGetter = useAssetSignatureCache();

    const getExistingModelData = async (id: string) => {
        const model = await getModel(id);
        return convertModelToUploadModelData({ ...model, thumbnail: action.target.thumbnail }, imageGetter);
    };

    const handleDeleteModel = async () => {
        setAction({ ...action, deleting: true });
        const modelAssetIds = getAllModelAssetIds(action.target);
        const deleteAssetRequests = modelAssetIds.map((id) => deleteAsset(id));
        await Promise.all([deleteModel(action.target.id), ...deleteAssetRequests]);
        await refetch();
        setAction({ type: null });
    };

    return (
        <>
            <AssetsRepoTemplate
                repoTitle="3D models"
                repoSubtitle="3D models repository"
                buttons={[
                    <TextButton key="upload" type="button" onClick={() => setAction({ type: "upload" })}>
                        Upload new 3D model
                    </TextButton>,
                    <TextButton key="edit" type="button" onClick={() => setAction({ type: "edit", target: selectedModelSingle })} disabled={selectedModelSingle == null}>
                        Edit 3D model
                    </TextButton>,
                    <TextButton
                        key="delete"
                        type="button"
                        onClick={() => setAction({ type: "delete", target: selectedModelSingle, deleting: false })}
                        disabled={selectedModelSingle == null}
                    >
                        Delete 3d model
                    </TextButton>,
                ]}
                browserComponent={
                    status === "loading" ? (
                        <LoadingComponent />
                    ) : status === "error" ? (
                        <>
                            <p>Error!</p>
                            <p>{JSON.stringify(error, null, 4)}</p>
                        </>
                    ) : (
                        <AssetBrowserComponent
                            imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
                            assets={assets.filter((m) => m.thumbnail != null).map((m) => m.thumbnail)}
                            fetchNextPage={() => null}
                            isFetchingNextPage={false}
                            cardHeight={ASSET_CARD_SIZE}
                            cardMinWidth={ASSET_CARD_SIZE}
                            cardWidth="100%"
                            onSelectionChange={setSelection}
                            onEditClicked={(id) => setAction({ type: "edit", target: assets.find((a) => a.thumbnailAssetId === id) })}
                        />
                    )
                }
            />
            <UploadAssetModal open={action.type === "upload"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadModelForm close={() => setAction({ type: null })} uploadRemotely={(data) => mutationPost.mutateAsync(data)} categories={modelCategories} />
            </UploadAssetModal>
            <UploadAssetModal open={action.type === "edit"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadModelForm
                    close={() => setAction({ type: null })}
                    uploadRemotely={(data) => mutationPut.mutateAsync(data)}
                    categories={modelCategories}
                    existingId={action.target?.id}
                    getExistingModel={getExistingModelData}
                />
            </UploadAssetModal>
            {action.type === "delete" && (
                <ConfirmationDialogComponent
                    handleConfirm={handleDeleteModel}
                    handleCancel={() => setAction({ type: null })}
                    title={`Delete ${action?.target?.name} model`}
                    message="Are you sure you want to delete this model?"
                    confirmText="Confirm"
                    cancelText="Cancel"
                    isSubmitting={action.deleting}
                    show
                />
            )}
        </>
    );
};
