import { FormWizardEditableTitle } from "@iventis/components/src/form-wizard-editable-title";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { ExtractRefFromComponent } from "@iventis/types/useful.types";
import { MutableRefObject } from "react";
import { assign, createMachine } from "xstate";
import { TemplateLayerWithPreviewUrl } from "./layer-template-selector";

export enum LayerEditStages {
    TemplateSelection = 0,
    NameInput = 1,
    TypeInput = 2,
    StyleEdit = 3,
}

export type EditLayerMachineEvents =
    | { type: "NEXT" }
    | { type: "EDIT_LAYER"; layer: Partial<MapLayer> }
    | { type: "SELECT_TEMPLATE"; templateLayer: TemplateLayerWithPreviewUrl }
    | { type: "CONFIRM" }
    | { type: "BACK" }
    | { type: "FROM_SCRATCH" };

export const editLayerMachine = createMachine(
    {
        id: "layerEditor",
        context: {
            stage: undefined,
            existing: undefined,
            layerWithModifications: undefined,
            selectedTemplateLayer: undefined,
            templateEditor: undefined,
            editLayerTitleRef: undefined,
        },
        initial: "chekingIfLayerExists",
        on: {
            EDIT_LAYER: { actions: "editLayer" },
        },
        states: {
            chekingIfLayerExists: {
                description: "Checks if we are editing or creating",
                always: [
                    {
                        target: "editingExistingStyle",
                        cond: "existing",
                    },
                    {
                        target: "checkingIfCreatingTemplate",
                    },
                ],
            },
            checkingIfCreatingTemplate: {
                description: "Checks if we are creating a template or a layer",
                always: [
                    {
                        target: "editingName",
                        cond: "isTemplateEditor",
                    },
                    {
                        target: "choosingTemplate",
                    },
                ],
            },
            // NAME INPUT
            editingName: {
                description: "Name editor - choosing a name for a new layer or template",
                entry: "enterNameInput",
                on: {
                    NEXT: {
                        target: "editingStyleType",
                    },
                    BACK: [{ cond: "isTemplateEditor", target: "finished" }, { target: "choosingTemplate" }],
                },
            },
            // TYPE INPUT
            editingStyleType: {
                description: "Style type editor - choosing a style type for a new layer or template",
                entry: "enterTypeInput",
                on: {
                    BACK: [{ cond: "isTemplateEditor", target: "finished" }, { target: "choosingTemplate" }],
                    NEXT: "editingExistingStyle",
                },
            },
            // TEMPLATE SELECTOR
            choosingTemplate: {
                description: "Template selector - selecting a template for a new layer",
                entry: ["enterTemplateSelection", "resetLayer"],
                initial: "idle",
                on: { BACK: "finished" },
                states: {
                    idle: {
                        on: {
                            SELECT_TEMPLATE: { actions: "selectTemplate" },
                            NEXT: "customiseTemplate",
                            FROM_SCRATCH: {
                                actions: "removeTemplateSelection",
                                target: "#layerEditor.editingName",
                            },
                            CONFIRM: "addTemplateToMap",
                        },
                    },
                    customiseTemplate: {
                        invoke: {
                            src: "createFromTemplate",
                            onDone: { actions: "updateLayerFromTemplate", target: "#layerEditor.editingStyleFromTemplate" },
                        },
                    },
                    addTemplateToMap: {
                        invoke: {
                            src: "createFromTemplate",
                            onDone: { actions: "updateLayerFromTemplate", target: "#layerEditor.finished" },
                        },
                    },
                },
            },
            // EDITING STYLE
            editingStyleFromTemplate: {
                description: "Layer editor - creating a new layer based off a template",
                entry: "enterStyle",
                on: {
                    BACK: "choosingTemplate",
                },
            },
            editingExistingStyle: {
                description: "Layer editor - editing an existing layer",
                entry: "enterStyle",
                on: {
                    BACK: [{ cond: "existing", target: "finished" }, { target: "editingStyleType" }],
                },
            },
            // FORM CLOSED
            finished: {
                entry: "close",
                type: "final",
            },
        },
        schema: {
            context: {} as {
                stage: LayerEditStages;
                existing: boolean;
                layerWithModifications: MapLayer;
                selectedTemplateLayer: TemplateLayerWithPreviewUrl;
                templateEditor: boolean;
                editLayerTitleRef: MutableRefObject<ExtractRefFromComponent<typeof FormWizardEditableTitle>>;
            },
            events: {} as EditLayerMachineEvents,
            services: {} as { addTemplateToMap: { data: void }; createFromTemplate: { data: MapLayer }; getCategories: { data: string[] } },
        },
        // eslint-disable-next-line no-undef
        tsTypes: {} as import("./edit-layer-machine.typegen").Typegen0,
        predictableActionArguments: true,
        preserveActionOrder: true,
    },
    {
        guards: {
            existing: (context) => context.existing,
            isTemplateEditor: (context) => context.templateEditor,
        },
        actions: {
            enterTemplateSelection: assign({ stage: () => LayerEditStages.TemplateSelection }),
            enterNameInput: assign({ stage: () => LayerEditStages.NameInput }),
            enterTypeInput: assign({ stage: () => LayerEditStages.TypeInput }),
            enterStyle: assign({ stage: () => LayerEditStages.StyleEdit }),
            updateLayerFromTemplate: assign({
                layerWithModifications: ({ editLayerTitleRef }, event) => {
                    editLayerTitleRef.current.updateNameExternally(event.data.name);
                    return event.data;
                },
            }),
            removeTemplateSelection: assign({ selectedTemplateLayer: () => undefined, layerWithModifications: {} as MapLayer }),
            selectTemplate: assign({ selectedTemplateLayer: (_, event) => event.templateLayer }),
            resetLayer: assign({ layerWithModifications: () => ({}) }),
            editLayer: assign({
                layerWithModifications: (context, event) => ({
                    ...context.layerWithModifications,
                    ...event.layer,
                    iconStyle: { ...context.layerWithModifications.iconStyle, ...event.layer.iconStyle },
                    pointStyle: { ...context.layerWithModifications.pointStyle, ...event.layer.pointStyle },
                    areaStyle: { ...context.layerWithModifications.areaStyle, ...event.layer.areaStyle },
                    lineModelStyle: { ...context.layerWithModifications.lineModelStyle, ...event.layer.lineModelStyle },
                    modelStyle: { ...context.layerWithModifications.modelStyle, ...event.layer.modelStyle },
                    lineStyle: { ...context.layerWithModifications.lineStyle, ...event.layer.lineStyle },
                }),
            }),
        },
    }
);
