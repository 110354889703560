/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState, useCallback, useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";
import { Body2, styled } from "@iventis/styles";

import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { IDoesFilterPassParams } from "@ag-grid-community/core";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { ListItemFilterComponentProps, ColumnDataType, IventisFilterRef, ListItem } from "../types/data-table.types";
import { useLazyLoadOptions } from "../lib/hooks";
import { FilterTemplateComponent } from "./filter-template";
import { getFilterModel } from "../lib/grid.helpers";

/**
 * Used as FilterFramework for data table column of type "list-item"
 */
export const ListItemFilterComponent = forwardRef<IventisFilterRef, ListItemFilterComponentProps>(({ options: propOptions, initialFilter, doesFilterPass, ...props }, ref) => {
    const translate = useIventisTranslate();
    const [loading, options] = useLazyLoadOptions<ListItem>(propOptions);
    const optionsHaveBeenSet = useRef(typeof propOptions !== "function");

    const [itemsChecked, setItemsChecked] = useState<ListItem[]>(options);

    useEffect(() => {
        setItemsChecked(initialFilter);
    }, [initialFilter]);

    useEffect(() => {
        props.column.setFilterActive(itemsChecked.length !== options.length);
    }, [options.length, itemsChecked.length]);

    useEffect(() => {
        if (!optionsHaveBeenSet.current && !loading) {
            // If we haven't set the options yet and we are no longer loading, we can set the itemsChecked to the loaded options
            setItemsChecked(options);
            optionsHaveBeenSet.current = true;
        }
    }, [loading]);

    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return itemsChecked.length !== options.length;
            },

            getModel() {
                return getFilterModel(props.column, ColumnDataType.LIST, IventisFilterOperator.In, itemsChecked, props.apiFilterType);
            },

            doesFilterPass(params: IDoesFilterPassParams) {
                return doesFilterPass == null ? itemsChecked.some((item) => item.id === params.data[props.colDef.field]?.id) : doesFilterPass(params, itemsChecked);
            },
        };
    });

    useEffect(() => {
        props.filterChangedCallback();
    }, [itemsChecked]);

    const checkBoxChanged = useCallback(
        (e, id, name) => {
            if (e.target.checked) {
                if (!itemsChecked.some((item) => item.id === id)) setItemsChecked([...itemsChecked, { id, name }]);
            } else {
                setItemsChecked(itemsChecked.filter((item) => item.id !== id));
            }
        },
        [itemsChecked]
    );

    return (
        <FilterTemplateComponent
            title={props.colDef.headerName}
            onClose={(e) => {
                e.stopPropagation();
                props.api.hidePopupMenu();
            }}
        >
            <RowContainer>
                <Body2>{translate(Content.table.dataTable.filter.select_all)}</Body2>
                <Checkbox
                    key="all"
                    data-testid="select-all-button"
                    checked={itemsChecked.length === options.length}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setItemsChecked(options);
                        } else {
                            setItemsChecked([]);
                        }
                    }}
                />
            </RowContainer>
            {options.map(({ id, name }) => {
                return (
                    <RowContainer key={id}>
                        <Body2>{name}</Body2>
                        <Checkbox key={id} checked={itemsChecked.some((val) => val.id === id)} onChange={(e) => checkBoxChanged(e, id, name)} />
                    </RowContainer>
                );
            })}
        </FilterTemplateComponent>
    );
});

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
`;
