import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSizes, styled } from "@iventis/styles";
import { MenuItem, Select } from "@mui/material";
import React from "react";

export const SortSelectionComponent: React.FC<{
    sortMethods: SortMethod[];
    selectedSortMethod: SortMethod;
    onSortMethodChange: (sortName: string) => void;
    disabled?: boolean;
    className?: string;
    disableUnderline?: boolean;
}> = ({ sortMethods, selectedSortMethod, onSortMethodChange, disabled, disableUnderline, className }) => (
    <StyledSelect
        className={`${className}`}
        variant="standard"
        value={selectedSortMethod.id}
        onChange={(event) => onSortMethodChange(event.target.value as string)}
        IconComponent={() => <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />}
        disabled={disabled}
        disableUnderline={disableUnderline}
    >
        {sortMethods.map((method) => (
            <MenuItem value={method.id} key={method.id}>
                {method.displayName}
            </MenuItem>
        ))}
    </StyledSelect>
);

const StyledSelect = styled(Select)`
    /* Increase specificity to override muiInputFormCSS since this input should not abide by those rules */
    .MuiSelect-select.MuiInputBase-input {
        font-size: ${fontSizes.small};
        height: fit-content;
        padding-right: 24px;
    }
`;

export interface SortMethod {
    displayName: string;
    id: string;
}
