import React, { useCallback, useRef, useState } from "react";
import { Body1, Body2, Header4, SolidButton, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper } from "@mui/material";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { SUPPORT_URL } from "@iventis/utilities";
import { Content } from "@iventis/translations";

export type DragDropFileUploadType = {
    onFileUpload: (file: File) => void;
    supportedExtensions: string[];
    loading?: boolean;
    /** Max file size in Mb */
    maxFileSize?: number;
};

export const DragDropFileUpload: React.FC<DragDropFileUploadType> = ({ onFileUpload, supportedExtensions, maxFileSize, loading }) => {
    const translate = useIventisTranslate();
    const [dragOver, setDragOver] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);

    const handleDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            setDragOver(false);
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                onFileUpload(event.dataTransfer.files[0]);
            }
        },
        [onFileUpload]
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files[0]) {
                onFileUpload(event.target.files[0]);
            }
        },
        [onFileUpload]
    );

    const handleButtonClick = () => {
        // If the same file is selected again then ensure onChange is triggered
        fileInput.current.value = "";
        fileInput?.current?.click();
    };

    const formattedSupportedExtensions = supportedExtensions.join(", ");

    return (
        <StyledPaper variant="outlined" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} $dragOver={dragOver}>
            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <StyledFileIcon icon={["fal", "file"]} />
                <StyledHeader4>
                    <StyledInfoIcon icon={["fas", "file-arrow-down"]} /> {translate(Content.map6.fileUpload.drop_file)}
                </StyledHeader4>
                <Body1>
                    {translate(Content.map6.fileUpload.supported_extensions)}: <strong>{formattedSupportedExtensions}</strong>
                </Body1>
                {maxFileSize != null && <Body2>{translate(Content.map6.fileUpload.max_file_size, { maxFileSize })}</Body2>}
                <Body2>
                    {translate(Content.map6.fileUpload.contact_support)}:{" "}
                    <StyledA target="_blank" rel="noreferrer" href={SUPPORT_URL}>
                        {SUPPORT_URL}
                    </StyledA>
                </Body2>
                <label htmlFor="file-upload-input">
                    <input
                        data-testid="upload-file"
                        ref={fileInput}
                        accept={formattedSupportedExtensions}
                        style={{ display: "none" }}
                        id="file-upload-input"
                        multiple
                        type="file"
                        onChange={handleChange}
                    />
                    <StyledButton aria-label="Upload file" data-testid="choose-file-button" type="button" disabled={loading} onClick={handleButtonClick}>
                        {loading ? <FontAwesomeIcon icon={["fas", "circle-notch"]} spin /> : <FontAwesomeIcon icon={["far", "download"]} />}
                        <span>{translate(Content.map6.fileUpload.choose_file)}</span>
                    </StyledButton>
                </label>
            </Box>
        </StyledPaper>
    );
};

const StyledPaper = styled(Paper)<{ theme?: Theme; $dragOver: boolean }>`
    border: 3px dashed ${({ theme, $dragOver }) => ($dragOver ? theme.primaryColors.subduedMono : theme.typographyColors.subdued)};
    padding: 50px 100px;
    margin: 24px;
    margin-top: 0;
    text-align: center;
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.lessSubdued};
    background: ${({ theme, $dragOver }) => ($dragOver ? theme.shades.libraryHover : theme.shades.five)};
`;

const StyledA = styled.a`
    color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    &:hover {
        text-decoration: underline;
    }
`;

const StyledFileIcon = styled(FontAwesomeIcon)`
    &.svg-inline--fa {
        color: ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
        height: 80px;
        width: 60px;
        margin-bottom: 36px;
    }
`;

const StyledInfoIcon = styled(FontAwesomeIcon)`
    &.svg-inline--fa {
        margin-right: 10px;
        height: 24px;
        width: 18px;
    }
`;

const StyledHeader4 = styled(Header4)`
    font-weight: 500;
    line-height: 24px;
    display: flex;
`;

const StyledButton = styled(SolidButton)`
    display: flex;
    gap: 5px;
`;
