/* eslint-disable no-param-reassign */
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";

type AnyLayerStyle = AreaStyle | LineStyle | PointStyle | IconStyle | ModelStyle | LineModelStyle;

/** Replace the previous datafield and list item ids with the new ones in the layer style */
export function replaceDatafieldAndListItemIdsInStyle<TLayer extends { styleType: StyleType }>(
    layer: TLayer,
    replacementDataFieldIds: Map<string, string>,
    replacementListItemIds: Map<string, string>,
    getLayerStyle: (layer: TLayer) => AnyLayerStyle
): TLayer {
    const style = getLayerStyle(layer);

    // Go through all the style values and replace datafield and list item ids
    Object.values(style).forEach((value: StyleValue<unknown>) => {
        if (value?.dataFieldId != null) {
            // Replace the datafield id with the new id
            value.dataFieldId = replacementDataFieldIds.get(value.dataFieldId);
        }

        if (value?.mappedValues != null) {
            const newMappedValues: Record<string, ZoomableValue<unknown>> = {};
            // For each mapped value replace it with the new list item id
            Object.entries(value.mappedValues).forEach(([key, value]) => {
                const newKey = replacementListItemIds.get(key);
                if (newKey != null) {
                    newMappedValues[newKey] = value;
                }
            });
            value.mappedValues = newMappedValues;
        }
    });
    return layer;
}
