/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState, useRef } from "react";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { FeatureCollection, LineString, Polygon, Point } from "geojson";
import { SelectItemsComponent, UploadFileWithText } from "@iventis/components";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { Content } from "@iventis/translations";
import { DragDropFileUpload } from "@iventis/components/src/drag-drop-file-upload";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { Body2, styled } from "@iventis/styles";
import { convertGPXToGeoJSON } from "@iventis/utilities";
import { incrementMonitoringCount, MetricName, MetricTagName } from "@iventis/observability-and-monitoring";
import { GPXOption } from "@iventis/types";
import { validateGPXFile, validateLayerGeojsonInput, validateKMLFile } from "./layer-geojson-validation";
import { getFeaturesProperties, getListItemsForLayer, getMappableAttributes, showValidationErrorToast } from "./layer-geojson-file-upload-helpers";
import { ValidationError, ValidationResponse } from "./layer-geojson-validation-types";
import { LayerGeoJsonAttributeMappingMappingComponent, LayerGeoJsonAttributeMappingRef } from "./layer-geojson-attribute-mapping";
import { ImportGeoJsonContext } from "./layer-geojson-context";
import { createLayerObjectsFromGeoJson } from "./layer-geojson-create-objects";
import { KmlFolder } from "./kml-to-geojson";

const MAX_GEOJSON_UPLOAD_FILE_SIZE_MB = 10;

export const ImportGeojsonFileUploadComponent = ({ mapLayer }: { mapLayer: MapLayer }) => {
    const translate = useIventisTranslate();
    const [importedFeatures, setImportedFeatures] = useState<{ features: FeatureCollection<Polygon | LineString | Point>; file: File }>();
    const [gpxOptions, setGpxOptions] = useState<{ options: GPXOption[]; value: GPXOption }>({ options: ["tracks", "routes"], value: "tracks" });
    const [kmlOptions, setKMLOptions] = useState<{ folders: KmlFolder[]; selectedFolderId: KmlFolder["folder_id"] }>({ folders: [], selectedFolderId: "" });
    const [storedAllFeatures, setStoredAllFeatures] = useState<{ features: FeatureCollection<Polygon | LineString | Point> }>();

    const { projectAttributes, getListItem, postMapObjects } = useContext(ImportGeoJsonContext);

    const attributeMappingRef = useRef<LayerGeoJsonAttributeMappingRef>();

    /** Called when a new file is selected */
    const handleFileUpload = async (file: File) => {
        let validationResult: ValidationResponse;

        if (file.name.endsWith(".kml") || file.name.endsWith(".kmz")) {
            const { validationResult: result, folders } = await validateKMLFile(file, mapLayer.styleType);
            setKMLOptions({ folders: [...folders], selectedFolderId: kmlOptions.selectedFolderId });
            validationResult = result;
        } else if (file.name.endsWith(".gpx")) {
            const result = await validateGPXFile(file, gpxOptions.value);
            setGpxOptions({ options: result.options, value: result.selectedOption });
            validationResult = result.validationResult;
        } else {
            validationResult = await validateLayerGeojsonInput(file, mapLayer.styleType);
        }

        if (validationResult.error === ValidationError.None) {
            if (importedFeatures?.features != null) {
                attributeMappingRef.current.setProperties(getFeaturesProperties(validationResult.features));
            }
            setImportedFeatures({ features: validationResult.features, file });
            setStoredAllFeatures({ features: validationResult.features });
        } else {
            showValidationErrorToast(validationResult, translate, mapLayer);
        }
    };

    const handleGpxTypeSwitch = (type: GPXOption) => {
        setGpxOptions(({ options }) => ({ options, value: type }));
        convertGPXToGeoJSON(importedFeatures.file, type).then((result) => {
            setImportedFeatures((importedFeatures) => ({ ...importedFeatures, features: result.featureCollection }));
        });
    };

    const handleKMLFolderSwitch = (selectedFolderId: KmlFolder["folder_id"]) => {
        const filteredFeatures = storedAllFeatures.features.features.filter((feature) => feature.properties?.folder_id === selectedFolderId);
        setImportedFeatures((importedFeatures) => ({ ...importedFeatures, features: { features: filteredFeatures, type: importedFeatures.features.type } }));
        setKMLOptions({ ...kmlOptions, selectedFolderId });
    };

    const handleConfirm = async (mappedAttributes: { [attributeId: string]: string }, overwriteExistingObjects: boolean) => {
        // Get all list items from attributes so they can be mapped
        const mappedListItems = await getListItemsForLayer(
            mapLayer.dataFields.filter((attribute) => attribute.type === DataFieldType.List),
            getListItem
        );
        createLayerObjectsFromGeoJson(mapLayer, mappedListItems, importedFeatures.features, mappedAttributes, projectAttributes, (objects) =>
            postMapObjects(objects, overwriteExistingObjects)
        );

        incrementMonitoringCount(MetricName.MAP_OBJECTS_IMPORTED, {
            includeUserTags: true,
            tags: {
                [MetricTagName.LAYER_ID]: mapLayer.id,
                [MetricTagName.LAYER_NAME]: mapLayer.name,
                // Cant rely on the file type - often comes back empty so assuming type based on named extension
                [MetricTagName.IMPORT_FORMAT]: importedFeatures.file.name.split(".").pop(),
            },
        });
        setImportedFeatures(undefined);
    };

    return (
        <>
            {importedFeatures?.features == null ? (
                <DragDropFileUpload
                    supportedExtensions={[".geojson", ".json", ".gpx", ".kml", ".kmz"]}
                    onFileUpload={handleFileUpload}
                    maxFileSize={MAX_GEOJSON_UPLOAD_FILE_SIZE_MB}
                />
            ) : (
                <LayerGeoJsonAttributeMappingMappingComponent
                    mapLayerAttributes={getMappableAttributes(mapLayer.dataFields)}
                    properties={getFeaturesProperties(importedFeatures.features)}
                    onConfirm={handleConfirm}
                    ref={attributeMappingRef}
                >
                    <div className="upload-container">
                        <UploadFileWithText
                            uploadFile={handleFileUpload}
                            uploadButtonText={translate(Content.common.buttons.upload)}
                            loading={false}
                            fileName={importedFeatures.file?.name}
                            persistFileSelectInput
                            topText={translate(Content.map7.importGeoJson.mappedAttributeDescription)}
                            fileExtensions={["geojson", "json", "gpx", "kml", "kmz"]}
                            fileSizeRestriction={MAX_GEOJSON_UPLOAD_FILE_SIZE_MB}
                        />

                        {/* If the file uploaded is a .kml file and it includes folders, we must allow the user to choose which folder they would like to import map objects from */}
                        {(importedFeatures?.file?.name?.endsWith(".kml") || importedFeatures?.file?.name?.endsWith(".kmz")) && kmlOptions.folders?.length > 1 && (
                            <StyledKMLFolderSelector>
                                <Body2>{translate(Content.map7.importKML.folderSelectLabel)}:</Body2>
                                <SelectItemsComponent
                                    placeholder={translate(Content.map7.importKML.allFolders)}
                                    options={[
                                        { name: "All", component: () => <em>{translate(Content.map7.importKML.allFolders)}</em>, id: "" },
                                        ...kmlOptions.folders.map((folder) => ({
                                            name: folder.name,
                                            id: folder.folder_id,
                                            isPlaceholder: storedAllFeatures?.features?.features?.some((feature) => feature.properties?.folder_id === folder.folder_id) === false,
                                        })),
                                    ]}
                                    value={kmlOptions.folders.filter((folder) => folder.folder_id === kmlOptions.selectedFolderId)[0]?.folder_id}
                                    changeValue={(value) => handleKMLFolderSwitch(value)}
                                />
                            </StyledKMLFolderSelector>
                        )}
                        {/* If the file uploaded is a .gpx file and it includes tracks and routes, we must allow the user to choose which type they would like to import */}
                        {importedFeatures?.file?.name?.endsWith(".gpx") && gpxOptions.options?.length > 1 && (
                            <RadioGroup value={gpxOptions.value} onChange={(_, value: GPXOption) => handleGpxTypeSwitch(value)} sx={{ flexDirection: "row" }}>
                                {[
                                    { type: "tracks", label: translate(Content.map7.importGpx.import_track) },
                                    { type: "routes", label: translate(Content.map7.importGpx.import_route) },
                                ].map(({ label, type }) => (
                                    <FormControlLabel
                                        control={<Radio data-cy={`import-gpx-option-${type}`} size="small" key={`${type}-control`} />}
                                        label={<Body2 key={`${type}-label`}>{label}</Body2>}
                                        value={type}
                                        key={type}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    </div>
                </LayerGeoJsonAttributeMappingMappingComponent>
            )}
        </>
    );
};

const StyledKMLFolderSelector = styled.div`
    margin-bottom: 10px;
`;
