import { CheckBoxStyled, DateSelectorComponent, TimeSelector } from "@iventis/components";
import { StyledFieldLabel, formFieldVerticalGap, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import React, { useState, useMemo } from "react";
import { Theme } from "@emotion/react";
import { TextAreaSolid } from "@iventis/styles/src/components/text-field-solid";
import { TextField } from "@mui/material";
import { DataFieldValueV2 } from "@iventis/domain-model/model/dataFieldValueV2";
import { isValidTimeRange, parseRepeatedTimeRangeDataFieldValueToNumber, parseRepeatedTimeRangeDataFieldValueToString, timeRange } from "@iventis/utilities";
import { RepeatedTimeRangeValueComponent } from "@iventis/repeated-time-ranges";
import { MapObjectRepeatedTimeRangeValue } from "@iventis/types";
import { getAttributeDefaultValue, createDataFieldDefaultValue } from "./attribute-default-value-helpers";
import { AttributeTimeRangeEditor } from "./attribute-editor-components";

/** Can set an attribute default value, does not handle list items */
export const AttributeDefaultValueComponent = ({ attribute, setDefaultValue }: { attribute: DataField; setDefaultValue: (defaultValue: DataField["defaultValue"]) => void }) => {
    const translate = useIventisTranslate();

    // Newly added project data fields will be missing a default value, so check if it's null here
    const defaultValue = useMemo(() => (attribute?.defaultValue ? getAttributeDefaultValue(attribute) : null), [attribute.defaultValue]);

    const [defaultCheckbox, setDefaultCheckbox] = useState(defaultValue != null);

    const tickbox = attribute.type === DataFieldType.Tickbox;

    // What3Words does not have a default value
    if (attribute.type === DataFieldType.What3Words) {
        return null;
    }

    return (
        <>
            <TickboxContainer>
                <CheckBoxStyled
                    // StyleAdditions for alignment
                    styleAdditions={{ marginLeft: "-8px", marginTop: "-2px" }}
                    checked={defaultCheckbox}
                    onChange={() => {
                        setDefaultValue(createDataFieldDefaultValue(attribute, !defaultCheckbox));
                        setDefaultCheckbox(!defaultCheckbox);
                    }}
                    disabled={false}
                    testId="default-value-checkbox"
                />
                {tickbox ? (
                    <TickboxText>
                        <span>{translate(Content.map6.defaultAttribute.tickbox.label)}</span> (
                        {defaultCheckbox ? <span>{translate(Content.map6.defaultAttribute.tickbox.on)}</span> : <span>{translate(Content.map6.defaultAttribute.tickbox.off)}</span>}
                        )
                    </TickboxText>
                ) : (
                    <TickboxText>{translate(Content.map6.defaultAttribute.add)}</TickboxText>
                )}
            </TickboxContainer>

            {!tickbox && defaultCheckbox && (
                <DefaultValueContainer>
                    <StyledHr />
                    {attribute.type !== DataFieldType.Image && (
                        <StyledFieldLabel>
                            {translate(Content.map6.defaultAttribute.default_field_type, { dataFieldType: translate(Content.map.data_fields.types[attribute.type]).toLowerCase() })}
                        </StyledFieldLabel>
                    )}
                    <AttributeInputComponent setValue={setDefaultValue} attribute={attribute} testId="default-value-input" />
                </DefaultValueContainer>
            )}
        </>
    );
};

/** Shows the correct input for each attribute type */
const AttributeInputComponent = ({ setValue, attribute, testId }: { setValue: (value: DataFieldValueV2) => void; attribute: DataField; testId?: string }) => {
    if (attribute.type === DataFieldType.Date) {
        return (
            <DateSelectorComponent value={attribute?.defaultValue?.valueDate} valueChanged={(value) => setValue(createDataFieldDefaultValue(attribute, value))} testId={testId} />
        );
    }

    if (attribute.type === DataFieldType.Number) {
        return (
            <TextField
                type="number"
                value={getAttributeDefaultValue(attribute) ?? ""}
                onChange={(event) => {
                    setValue(createDataFieldDefaultValue(attribute, event.target.value != null && event.target.value !== "" ? Number(event.target.value) : undefined));
                }}
                data-testid={testId}
            />
        );
    }

    if (attribute.type === DataFieldType.Time) {
        return (
            <TimeSelector
                name={attribute.name}
                onChange={(event) => setValue(createDataFieldDefaultValue(attribute, event.target.value))}
                value={getAttributeDefaultValue(attribute) ?? null}
                dataTestId={testId}
            />
        );
    }

    if (attribute.type === DataFieldType.TimeRange) {
        return (
            <AttributeTimeRangeEditor
                value={timeRange(attribute.defaultValue.valueTimeRangeFrom, attribute.defaultValue.valueTimeRangeTo)}
                setValue={(timerange) => {
                    if (isValidTimeRange(timerange)) {
                        setValue(createDataFieldDefaultValue(attribute, timerange));
                    }
                }}
                testIdPrefix={attribute.name}
            />
        );
    }

    if (attribute.type === DataFieldType.RepeatedTimeRanges) {
        const handleSetValue = (value: MapObjectRepeatedTimeRangeValue[]) => {
            setValue({ dataFieldId: attribute.id, valueText: null, valueRepeatedTimeRanges: parseRepeatedTimeRangeDataFieldValueToString(value) });
        };

        return (
            <RepeatedTimeRangeValueContainer>
                <RepeatedTimeRangeValueComponent
                    initialValue={parseRepeatedTimeRangeDataFieldValueToNumber(attribute.defaultValue.valueRepeatedTimeRanges ?? [])}
                    attributeName={attribute.name}
                    setValue={handleSetValue}
                    setValidation={() => true}
                />
            </RepeatedTimeRangeValueContainer>
        );
    }

    return (
        <TextAreaSolid
            value={getAttributeDefaultValue(attribute) ?? ""}
            minRows={1}
            maxRows={4}
            onChange={(event) => setValue(createDataFieldDefaultValue(attribute, event.target.value))}
            data-testid={testId}
        />
    );
};

const TickboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const TickboxText = styled(StyledFieldLabel)`
    margin-bottom: 0px;
`;

const StyledHr = styled.hr`
    border: none;
    height: 1px;
    background-color: ${(props: { theme: Theme }) => props.theme.shades.lightBorder};
    margin: ${formFieldVerticalGap} 0px;
`;

const DefaultValueContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const RepeatedTimeRangeValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
