import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import { styled, media } from "@iventis/styles";
import { useTheme } from "@mui/material";

import { Theme } from "@emotion/react";
import { Header3 } from "@iventis/styles/src/components/texts";
import { borderRadius, fontSizes } from "@iventis/styles/src/atomic-rules";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { LoadingComponent } from "./loading";

export interface AppBarSubmenuItem<TType extends string = string> {
    id: TType;
    name: string;
    sidePanelIcon: IconProp;
    pageIcon: IconProp;
    upgradeNeeded?: boolean;
}

interface AppBarSubmenuProps<TItems extends AppBarSubmenuItem = AppBarSubmenuItem> {
    items: TItems[];
    fullScreenTitle: string;
    selectedItem: string;
    onItemClick: (item: TItems) => void;
    mobileOpen: boolean;
    theme?: AppBarSubmenuTheme;
    translationObject?: Record<string, string>;
}

export enum AppBarSubmenuTheme {
    Primary,
    Administrative,
}

export function AppBarSubmenu<TItems extends AppBarSubmenuItem = AppBarSubmenuItem>({
    items,
    selectedItem,
    onItemClick,
    mobileOpen,
    fullScreenTitle,
    theme,
    translationObject,
}: PropsWithChildren<AppBarSubmenuProps<TItems>>) {
    const itemsLoaded = Boolean(items);
    const iventisTheme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const buttons =
        items == null
            ? []
            : items.map((item) => (
                  <ItemButton
                      data-cy={`app-submenu-${item.name.replace(" ", "-")}`}
                      appBarTheme={theme}
                      key={item.id}
                      value={item.id}
                      type="button"
                      selected={selectedItem === item.id}
                      onClick={() => onItemClick(item)}
                  >
                      <FontAwesomeIcon icon={item.sidePanelIcon} className="icon" />
                      <div className="text">{translationObject ? <span>{translate(translationObject[item.name])}</span> : <span>{item.name}</span>}</div>
                      {item.upgradeNeeded && <FontAwesomeIcon icon={["far", "circle-up"]} color={iventisTheme.secondaryColors.blank} className="icon" />}
                  </ItemButton>
              ));
    return (
        <StyledAppBarSubmenuContainer appBarTheme={theme} mobileOpen={mobileOpen}>
            {mobileOpen && <Header3>{fullScreenTitle}</Header3>}
            <AppBarSubmenuOptions>{itemsLoaded ? buttons : <LoadingComponent />}</AppBarSubmenuOptions>
        </StyledAppBarSubmenuContainer>
    );
}

export const StyledAppBarContainer = styled.div`
    height: 100%;
    display: flex;
    width: 100%;
    overflow: hidden;

    ${media.appAlongBase} {
        display: block;
    }
`;

const StyledAppBarSubmenuContainer = styled.div<{ mobileOpen: boolean; appBarTheme: AppBarSubmenuTheme }>`
    padding-top: 10px;
    background-color: ${(props) => (props.appBarTheme === AppBarSubmenuTheme.Administrative ? props.theme.secondaryColors.lighterAccent : props.theme.primaryColors.subdued70)};
    flex: 0 1;
    box-sizing: border-box;
    h3 {
        display: none;
        color: ${({ theme, appBarTheme }: { theme: Theme; appBarTheme: AppBarSubmenuTheme }) =>
            appBarTheme === AppBarSubmenuTheme.Administrative ? theme.typographyColors.core : theme.secondaryColors.blank};
        width: "90%";

        ${media.appAlongBase} {
            display: block;
        }
    }

    ${media.appAlongBase} {
        ${(props) =>
            props.mobileOpen
                ? `display: grid;
        grid-template-rows: 7% 93%;
        grid-row: 1;
        width: 100%;
        height: 100%;
        padding: 45px 16px 5px 16px;
        align-content: top;
        align-items: top;`
                : `display: none;`}
    }
`;

const AppBarSubmenuOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.appAlongBase} {
        justify-content: left;
        align-items: flex-start;
    }
`;

const ItemButton = styled.button<{ selected: boolean; appBarTheme: AppBarSubmenuTheme }>`
    margin-top: 10px;
    width: 90%;
    min-height: 40px;
    background-color: ${(props) =>
        props.selected
            ? props.appBarTheme === AppBarSubmenuTheme.Administrative
                ? props.theme.tertiaryColors.headerBackground
                : props.theme.secondaryColors.darkAccent
            : "Transparent"};
    background-blend-mode: luminosity;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: ${borderRadius.standard};
    color: ${(props) => (props.appBarTheme === AppBarSubmenuTheme.Administrative ? props.theme.typographyColors.lessSubdued : props.theme.typographyColors.blank)};
    display: flex;

    padding: 10px 67px 10px 18px;

    &:hover {
        background-color: ${({ selected, theme, appBarTheme }: { selected: boolean; theme: Theme; appBarTheme: AppBarSubmenuTheme }) =>
            appBarTheme === AppBarSubmenuTheme.Administrative
                ? selected
                    ? theme.tertiaryColors.headerBackground
                    : theme.secondaryColors.grey
                : selected
                ? theme.secondaryColors.darkAccent
                : theme.secondaryColors.lightAccent};
    }

    .icon {
        height: 14px;
        align-self: center;
        flex: 0 1 16%;
        aspect-ratio: 1/1;
    }

    .text {
        align-self: flex-start;
        font-weight: ${(props) => (props.selected ? 600 : "normal")};
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        font-size: ${fontSizes.medium};
        margin-left: 15px;
        white-space: nowrap;
        min-width: 175px;
        text-align: left;
    }

    ${media.appAlongBase} {
        justify-content: left;
        width: 90%;
        white-space: nowrap;
        justify-items: left;
        border-radius: 0;
        padding-left: 10px;
        margin-top: 0;

        width: 100%;

        .text {
            font-weight: normal;
        }
    }
`;
