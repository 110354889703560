/* eslint-disable react-hooks/rules-of-hooks */
import { Content } from "@iventis/translations/content/typed-content";
import { useActor, useMachine } from "@xstate/react";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { ActorRef } from "xstate";
import { basicTranslator, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { FormWizardTemplate, FormWizardTitle } from "@iventis/components";
import { Project } from "@iventis/domain-model/model/project";
import { Toasts } from "@iventis/toasts";
import { xstateDevTools } from "@iventis/utilities";
import { v4 as uuid } from "uuid";
import { AddNewPeopleEvents, addNewPeopleMachine, AddNewPeopleMachineEvents } from "./add-new-people.machine";
import { AddPeopleToTeamSelector } from "./team-selector";
import { GetTeams, PostNewUserAndGroups } from "./types";

type AddNewPeopleProps = {
    project: Project;
    translate?: UseIventisTranslate;
    specifyProjectAdminStatus?: boolean;
    specifyUserSso?: boolean;
} & (
    | {
          addNewPeopleActor: ActorRef<AddNewPeopleEvents>;
          postNewUserAndGroups?: null;
          getTeams?: null;
          toast?: null;
          onClose?: null;
      }
    | { postNewUserAndGroups: ReturnType<PostNewUserAndGroups>; getTeams: GetTeams; toast: Toasts; onClose: () => void; addNewPeopleActor?: null }
);

export const AddNewPeopleComponent = forwardRef<{ cancel(): void }, AddNewPeopleProps>(
    (
        { addNewPeopleActor, project, getTeams, postNewUserAndGroups, onClose, toast, translate = basicTranslator, specifyProjectAdminStatus = false, specifyUserSso = false },
        ref
    ) => {
        const machine = useRef<ReturnType<typeof addNewPeopleMachine>>(null);
        if (addNewPeopleActor == null && machine.current == null) {
            machine.current = addNewPeopleMachine({ postNewUserAndGroups, getTeams, toast, translate, isChild: false });
        }
        // If an actor is passed to this component, this means the parent component has a parent machine to communicate with the add new use rmachine
        // If an actor isn't passed to this componnet, we assume communication is done via props (postNewUsers, getTeams etc)
        const [state, send] =
            addNewPeopleActor == null
                ? useMachine(machine.current, { context: { newUserGroup: [{ id: uuid(), emails: [], valid: false, teams: [] }] }, devTools: xstateDevTools })
                : useActor(addNewPeopleActor);
        const { newUserGroup, teams } = state.context;

        const cancel = () => {
            send(AddNewPeopleMachineEvents.CANCEL);
            onClose?.();
        };

        useEffect(() => {
            if (state.matches("finished")) {
                onClose?.();
            }
        }, [state.matches("finished")]);

        useImperativeHandle(ref, () => ({
            cancel,
        }));

        return (
            <FormWizardTemplate
                title={<FormWizardTitle title={translate(Content.people.add_people.invite_people_to_the_project)} header={project?.name} />}
                currentStage={0}
                stages={[
                    {
                        isValid: state.context.newUserGroup.every(({ valid }) => valid),
                        primaryButtonCallback: () => send({ type: AddNewPeopleMachineEvents.SAVE }),
                        primaryButtonText: translate(Content.common.buttons.confirm),
                        secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: cancel }],
                    },
                ]}
                isSubmitting={state.matches("savingAddingNewPeople")}
                classNameContent="form-wizard-content"
            >
                <AddPeopleToTeamSelector
                    userGroups={newUserGroup}
                    send={send}
                    teams={teams}
                    specifyProjectAdminStatus={specifyProjectAdminStatus}
                    specifyUserSso={specifyUserSso}
                />
            </FormWizardTemplate>
        );
    }
);
