import React, { FunctionComponent, useState } from "react";
import { AssetInputComponent, FormWizardTemplate, FormWizardTitle, LoadingComponent } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { DragDropFileUpload } from "@iventis/components/src/drag-drop-file-upload";
import { fileToString } from "@iventis/utilities";
import { ImportIventisMap } from "@iventis/map-export-import-mapper/src/map-json-types";
import { ToastType, toast } from "@iventis/toasts";
import { MissingAsset, createMapJsonImport, mapImportLayerToMapLayer } from "@iventis/map-export-import-mapper";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { styled } from "@iventis/styles";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { Asset } from "@iventis/domain-model/model/asset";
import { useQuery } from "@tanstack/react-query";
import { AssetMapper } from "./asset-mapper";
import { getAllUniqueAssetsAsMissing, getDataFieldListItem, getProjectDataFields, importMapRequests, postLayerThumbnail } from "./import-map-helpers";
import { assetService } from "./layer-template.helpers";
import { getAssetsInProject, getModelsInProject } from "./assets-api-helpers";
import { ImportThumbnailGenerator } from "./import-thumbnail-generator";

export const ImportMapForm: FunctionComponent<{
    onClose: () => void;
    nodeId: string;
    instanceName: string;
    projectId: string;
    setMapInList: (mapId: string, mapName: string, parentId: string, instanceName: string, projectId: string) => void;
}> = ({ onClose, nodeId, instanceName, projectId, setMapInList }) => {
    const translate = useIventisTranslate();

    const [stage, setStage] = useState<number>(0);

    const [importedMap, setImportedMap] = useState<ImportIventisMap>(null);

    const [assetsToReplace, setAssetsToReplace] = useState<MissingAsset[]>([]);

    const [mapBackgroundId, setMapBackgroundId] = useState<string>(null);

    const [mapJsonImport, setMapJsonImport] = useState<ImportIventisMap>(null);

    const { data: models } = useQuery(["import-models", projectId], () => getModelsInProject({ instanceName, projectId }));

    const handleFileUpload = async (file: File) => {
        const jsonString = await fileToString(file);
        const mapJson = JSON.parse(jsonString);
        setImportedMap(mapJson);
        setAssetsToReplace(await getAllUniqueAssetsAsMissing(mapJson, instanceName, projectId));
        setStage(1);
    };

    const handleSelectedAsset = () => {
        // Reverse the mapping so that we are using the id to be in the style (which is the lod file not the thumbnail id)
        setAssetsToReplace(assetsToReplace.map((asset) => ({ ...asset, modelId: models?.find((m) => m.thumbnailAssetId === asset.newId)?.id ?? asset.newId })));
        setStage(2);
    };

    const handleSelectedMapBackground = async () => {
        const projectDataFields = await getProjectDataFields(instanceName, projectId);
        const mapJsonImport = await createMapJsonImport(importedMap, assetsToReplace, projectDataFields, mapBackgroundId, {
            getListItems: (dataFieldId) => getDataFieldListItem(instanceName, projectId, dataFieldId),
        });
        setMapJsonImport(mapJsonImport);
        setStage(3);
    };

    const handleImportMap = async () => {
        try {
            await importMapRequests(mapJsonImport, nodeId, instanceName, projectId);
            const { id: mapId, name: mapName } = mapJsonImport;
            setMapInList(mapId, mapName, nodeId, instanceName, projectId);
            toast.success({ type: ToastType.BASIC, props: { message: "Map was imported successfully" } });
            onClose();
        } catch (err) {
            toast.error({ type: ToastType.BASIC, props: { message: "There was a problem with importing the map" } });
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    const handleBack = () => {
        setStage(stage - 1);
    };

    const { data: modelAssets, isLoading: modelLoading } = useQuery(["models", projectId], () =>
        getAssetsInProject({
            filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.Model }],
            instanceName,
            projectId,
        })
    );

    const { data: iconAssets, isLoading: iconLoading } = useQuery(["icons", projectId], () =>
        getAssetsInProject({
            filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.MapIcon }],
            instanceName,
            projectId,
        })
    );

    const { data: mapBackgrounds, isLoading: backgroundsLoading } = useQuery(["map-backgrounds", projectId], () =>
        getAssetsInProject({
            filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.MapBackground }],
            instanceName,
            projectId,
        })
    );

    const importAssetService = {
        ...assetService,
        async getAsset(assetId) {
            return Promise.resolve([...iconAssets, ...modelAssets, ...mapBackgrounds].find((a) => a.id === assetId));
        },
        getAssetsByType: async (pageNumber: number, assetType: AssetType) => {
            const assets = await getAssetsInProject({
                filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: assetType }],
                instanceName,
                projectId,
            });
            // Construct a single page and filter out the model lod files
            const pagedAsset: PagedResult<Asset[]> = { pageNumber, pageSize: assets.length, lastPage: true, results: assets.filter((a) => a.name !== "model lod file") };
            return pagedAsset;
        },
        getAssetsById: async (ids: string[]) => {
            const modelAssets = await getAssetsInProject({
                filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.Model }],
                instanceName,
                projectId,
            });

            const iconAssets = await getAssetsInProject({
                filter: [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.MapIcon }],
                instanceName,
                projectId,
            });

            return [...modelAssets, ...iconAssets].filter((a) => ids.includes(a.id));
        },
    };

    return (
        <>
            <FormWizardTemplate
                currentStage={stage}
                title={<FormWizardTitle title="Import map data" />}
                stages={[
                    {
                        Component: <DragDropFileUpload supportedExtensions={[".json"]} onFileUpload={handleFileUpload} />,
                        overrideFormButtons: true,
                    },
                    {
                        primaryButtonText: translate(Content.common.buttons.next),
                        primaryButtonCallback: () => handleSelectedAsset(),
                        secondaryButtons: [{ buttonText: "Cancel", onButtonPressed: onClose }],
                        isValid: assetsToReplace?.every((x) => x.newId != null),
                        Component: (
                            <ImportMapFormContainer>
                                {modelLoading || iconLoading || backgroundsLoading ? (
                                    <LoadingComponent />
                                ) : (
                                    <AssetMapper
                                        missingAssets={assetsToReplace}
                                        onSelectAsset={(assetId, missing) => {
                                            setAssetsToReplace((latestAssets) =>
                                                latestAssets.map((asset) =>
                                                    asset.originalId === missing.originalId
                                                        ? { ...asset, newId: assetId, modelId: models?.find((m) => m.thumbnailAssetId === assetId)?.id }
                                                        : asset
                                                )
                                            );
                                        }}
                                        assetService={importAssetService}
                                    />
                                )}
                            </ImportMapFormContainer>
                        ),
                    },
                    {
                        primaryButtonText: translate(Content.common.buttons.save),
                        primaryButtonCallback: () => handleSelectedMapBackground(),
                        secondaryButtons: [{ buttonText: "Back", onButtonPressed: handleBack }],
                        isValid: mapBackgroundId != null,
                        Component: (
                            <ImportMapFormContainer>
                                <AssetInputComponent
                                    assetType={AssetType.MapBackground}
                                    selectorDescription="Choose Map background"
                                    imageUrlGetter={(asset) => asset.thumbnailUrl}
                                    assetService={importAssetService}
                                    value={mapBackgroundId}
                                    changeValue={(asset) => setMapBackgroundId(asset)}
                                />
                            </ImportMapFormContainer>
                        ),
                    },
                    {
                        overrideFormButtons: true,
                        Component: (
                            <ImportThumbnailGenerator
                                onDone={(thumbnails) => {
                                    mapJsonImport.layers.forEach((layer) => {
                                        postLayerThumbnail(layer.id, thumbnails[layer.id].replace("data:image/png;base64,", ""), instanceName, projectId);
                                    });
                                    handleImportMap();
                                }}
                                layers={mapJsonImport?.layers.map((l) => mapImportLayerToMapLayer(l))}
                                models={models}
                                importAssetService={importAssetService}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};

const ImportMapFormContainer = styled.div`
    min-height: 250px;
`;
