import { Body2, Header5, InteractiveElement, inlineTextIconMargin, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio } from "@mui/material";
import React, { PropsWithChildren, useMemo, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListenerToolTip, InfoTooltip } from "./tooltips";

interface ExpandableRadioOptionProps {
    title: string;
    selected: boolean;
    subtitle?: string;
    value: string;
    infoPopup?: {
        title: string;
        text: React.ReactNode;
        placement: "top" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start";
    };
    className?: string;
    disabled?: boolean;
    dataCy?: string;
}

export const ExpandableRadioOption: React.FunctionComponent<PropsWithChildren<ExpandableRadioOptionProps>> = ({
    title,
    subtitle,
    children,
    selected,
    value,
    infoPopup,
    className,
    disabled,
    dataCy,
}) => {
    const [openHelp, setOpenHelp] = useState(false);
    const summaryRef = useRef<HTMLDivElement>(null);

    const accordionSummaryHeight = useMemo(() => summaryRef?.current?.getBoundingClientRect().height, [summaryRef?.current]);

    return (
        <Container
            expanded={selected}
            className={className}
            disableGutters
            square
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            // Make the min height equal to the height of the accoordion summary, we always want it to show
            style={{ minHeight: accordionSummaryHeight > 0 ? `${accordionSummaryHeight}px` : "0px" }}
        >
            <OptionContainer ref={summaryRef}>
                <TitleContainer>
                    <FormControlLabel
                        control={<Radio data-cy={dataCy} size="medium" />}
                        label={<Header5>{title}</Header5>}
                        value={value}
                        style={{ marginRight: "0" }}
                        disabled={disabled}
                    />
                    <ClickAwayListenerToolTip onClickAway={() => setOpenHelp(false)}>
                        {infoPopup && (
                            <InfoTooltip title={infoPopup.title} text={infoPopup.text} open={openHelp} onClose={() => setOpenHelp(false)} placement={infoPopup.placement}>
                                <InteractiveElement onClick={() => setOpenHelp(!openHelp)}>
                                    <FontAwesomeIcon icon={["far", "circle-info"]} />
                                </InteractiveElement>
                            </InfoTooltip>
                        )}
                    </ClickAwayListenerToolTip>
                </TitleContainer>
                <StyledBody2 disabled={disabled}>{subtitle}</StyledBody2>
            </OptionContainer>
            <AccordionDetails>{children}</AccordionDetails>
        </Container>
    );
};

const Container = styled(Accordion)`
    display: flex;
    flex-direction: column;
    .MuiAccordionSummary-root {
        flex: 0 0 fit-content;
    }

    .MuiCollapse-root {
        overflow: hidden auto;
        margin: 4px 0;
    }

    &:before {
        display: none;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${inlineTextIconMargin};
    align-items: center;
`;

const OptionContainer = styled(AccordionSummary)`
    .MuiAccordionSummary-content {
        display: block;

        :hover {
            cursor: default;
        }
    }
`;

const StyledBody2 = styled(Body2)<{ disabled?: boolean }>`
    color: ${({ disabled, theme }: { disabled: boolean; theme: Theme }) => (disabled ? theme.shades.three : theme.typographyColors.subdued)};
`;

export default ExpandableRadioOption;
