import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { InteractiveElement, borderRadius, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useFunctionality } from "@iventis/utilities";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Status } from "@iventis/domain-model/model/status";
import React, { useMemo, useState } from "react";

import { ConfirmationDialogComponent } from "../confirmation-dialog";

export const AssetActionsComponent = ({
    selectedAsset,
    hideActions,
    canEditAsset,
    deleteAsset,
    editAsset,
    uploadAsset,
}: {
    selectedAsset: Asset;
    hideActions: boolean;
    canEditAsset: (asset: Asset) => boolean;
    deleteAsset: (assetId: string) => Promise<void>;
    editAsset: (assetId: string) => void;
    uploadAsset: () => void;
}) => {
    const translate = useIventisTranslate();

    const functionality = useFunctionality();

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleDeleteConfirm = async () => {
        setSaving(true);
        await deleteAsset(selectedAsset.id);
        setSaving(false);
        setDeleteConfirmationModal(false);
    };

    const showButtons = (selectedAsset?.type === AssetType.MapIcon || selectedAsset?.type === AssetType.Model) && functionality.iconAndModelUploads;

    const canEditSelectedAsset = useMemo(() => selectedAsset != null && canEditAsset(selectedAsset) && selectedAsset.status === Status.Active, [
        selectedAsset?.id,
        selectedAsset?.status,
    ]);

    return (
        <>
            <AssetActionsContainer>
                <div className="asset-name-container">
                    {translate(Content.common.selection.selected)}: <span className="asset-name">{selectedAsset?.name}</span>
                </div>
                {showButtons && !hideActions && (
                    <div className="asset-buttons-container" data-testid="asset-actions-container">
                        <AssetButton disabled={false} onClick={uploadAsset} data-testid="add-asset-button">
                            <FontAwesomeIcon size="lg" icon={["far", "plus"]} />
                            <span className="hidden">{`${translate(Content.map7.assets.uploadAsset)}`}</span>
                        </AssetButton>
                        <AssetButton disabled={!canEditSelectedAsset} onClick={() => editAsset(selectedAsset.id)} data-testid="edit-asset-button">
                            <FontAwesomeIcon size="lg" icon={["fas", "pen-to-square"]} />
                            <span className="hidden">{`${translate(Content.map7.assets.edit)}`}</span>
                        </AssetButton>
                        <AssetButton disabled={!canEditSelectedAsset} onClick={() => setDeleteConfirmationModal(true)} data-testid="delete-asset-button">
                            <FontAwesomeIcon size="lg" icon={["far", "trash"]} />
                            <span className="hidden">{`${translate(Content.map7.assets.delete)}`}</span>
                        </AssetButton>
                    </div>
                )}
            </AssetActionsContainer>
            {deleteConfirmationModal && selectedAsset?.type === AssetType.Model && (
                <ConfirmationDialogComponent
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={() => setDeleteConfirmationModal(false)}
                    title={translate(Content.map7.assets.deleteTitleModel)}
                    message={translate(Content.map7.assets.deleteDescriptionModel)}
                    confirmText={translate(Content.common.buttons.confirm)}
                    cancelText={translate(Content.common.buttons.cancel)}
                    isSubmitting={saving}
                    dataCy="confirm-asset-delete"
                />
            )}
            {deleteConfirmationModal && selectedAsset?.type === AssetType.MapIcon && (
                <ConfirmationDialogComponent
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={() => setDeleteConfirmationModal(false)}
                    title={translate(Content.map7.assets.deleteTitleIcon)}
                    message={translate(Content.map7.assets.deleteDescriptionIcon)}
                    confirmText={translate(Content.common.buttons.confirm)}
                    cancelText={translate(Content.common.buttons.cancel)}
                    isSubmitting={saving}
                    dataCy="confirm-asset-delete"
                />
            )}
        </>
    );
};
const AssetActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0px;
    align-items: center;

    .asset-buttons-container {
        display: flex;
        gap: 5px;
    }

    .asset-name-container {
        font-weight: 600;
    }

    .asset-name {
        color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    }
`;

const AssetButton = styled(InteractiveElement)<{ disabled: boolean }>`
    padding: 10px;
    border-radius: ${borderRadius.circle};
    :hover {
        cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
        background-color: ${({ theme, disabled }: { theme: Theme; disabled: boolean }) => (disabled ? theme.typographyColors.blank : theme.shades.four)};
    }
`;
