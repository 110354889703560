/* eslint-disable no-underscore-dangle */
import { Body1, InteractiveElement, styled } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { replaceItemInArray } from "@iventis/utilities";
import { Button } from "@mui/material";
import React, { MutableRefObject, useImperativeHandle, useState } from "react";
import { MapObjectRepeatedTimeRangeValue } from "@iventis/types";
import { Content, translateRichText } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfoTooltip, AttributeInfoBox } from "@iventis/components";
import { createNewDateRange, isRepeatedTimeRangeValid, parseDataFieldValueToDateRange, parseDataFieldValuesToRepeatedTimeRange } from "./repeated-time-range-helpers";
import { DateRangeAttributeValue } from "./repeated-time-range-types";
import { DateRangeAttribute } from "./attribute-repeated-date-range";

interface RepeatedTimeRangeValueProps {
    /** Initial values to populate the state, after component mounted the component handles the value state due to validation */
    initialValue: MapObjectRepeatedTimeRangeValue[];
    attributeName: string;
    /** Set an external validation value */
    setValidation: (valid: boolean) => void;
    /** If defined will be called whenever the values are valid and have changed */
    setValue?: (value: MapObjectRepeatedTimeRangeValue[]) => void;
}

export interface RepeatedTimeRangeValueRef {
    getValues: () => MapObjectRepeatedTimeRangeValue[];
}

const MAX_TIME_RANGES = 15;

const ExampleTable = () => {
    const t = useIventisTranslate();
    return (
        <table>
            <thead>
                <tr>
                    <th>{t(Content.map8.dates.dateRangeAttribute.name)}</th>
                    <th>{t(Content.map8.dates.dateRangeAttribute.dates)}</th>
                    <th>{t(Content.map8.dates.dateRangeAttribute.time)}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t(Content.map8.dates.attributeInfoBox.johnsMorningShift)}</td>
                    <td>01/04/2024 - 14/04/2024</td>
                    <td>9:45 - 17:45</td>
                </tr>
            </tbody>
        </table>
    );
};

const _RepeatedTimeRangeValueComponent = (
    { attributeName, initialValue, setValidation, setValue }: RepeatedTimeRangeValueProps,
    ref: MutableRefObject<RepeatedTimeRangeValueRef | undefined>
) => {
    const translate = useIventisTranslate();
    const [values, _setValues] = useState<DateRangeAttributeValue[]>(parseDataFieldValueToDateRange(initialValue));
    const [canAddRange, setCanAddRange] = useState(values.length < MAX_TIME_RANGES);
    const [openHelp, setOpenHelp] = useState<boolean>(false);

    const setValues = (values: DateRangeAttributeValue[]) => {
        _setValues(values);
        const isValid = isRepeatedTimeRangeValid(values);

        if (isValid && setValue) {
            // If valid and set value is defined emit the value
            setValue(parseDataFieldValuesToRepeatedTimeRange(values));
        }
        // Always allow user to add range if there is 0 ranges
        setCanAddRange(values.length === 0 || (values.length < MAX_TIME_RANGES && isValid));
        setValidation(isValid);
    };

    const handleValueChange = (value: DateRangeAttributeValue) => {
        const updatedValues = replaceItemInArray(values, value);
        setValues(updatedValues);
    };

    const handleDeleteTimeRange = (id: string) => {
        const filteredValues = values.filter((value) => value.id !== id);
        setValues(filteredValues);
    };

    const handleOnAddTimeRange = () => {
        setValues([...values, createNewDateRange()]);
    };

    useImperativeHandle(
        ref,
        () => ({
            getValues: () => parseDataFieldValuesToRepeatedTimeRange(values),
        }),
        [values]
    );

    return (
        <>
            <HeaderContainer>
                <StyledHeader style={{ position: "relative", display: "flex", gap: "8px" }}>
                    <Body1 className="attribute-name">{attributeName}</Body1>
                    <InfoTooltip
                        title={translate(Content.map8.dates.tooltip.title)}
                        text={<StyledSpan>{translate(Content.map8.dates.tooltip.blurb)}</StyledSpan>}
                        open={openHelp}
                        onClose={() => setOpenHelp(false)}
                        placement="right-end"
                    >
                        <InteractiveElement onClick={() => setOpenHelp(!openHelp)}>
                            <FontAwesomeIcon icon={["far", "circle-info"]} size="lg" />
                        </InteractiveElement>
                    </InfoTooltip>
                </StyledHeader>
                <Button className="add-range-button" variant="contained" onClick={handleOnAddTimeRange} disabled={!canAddRange} data-testid="add-repeated-time-range-button">
                    {translate(Content.map8.dates.addRange)}
                </Button>
            </HeaderContainer>
            <StyledAttributeInfoBox
                title={translate(Content.map8.dates.attributeInfoBox.title)}
                infoBlurb={translateRichText(Content.map8.dates.attributeInfoBox.infoBlurb)}
                infoExample={<ExampleTable />}
            />
            {values.map((value) => (
                <DateRangeAttribute key={value.id} handleDelete={() => handleDeleteTimeRange(value.id)} handleChange={handleValueChange} value={value} />
            ))}
        </>
    );
};

const StyledSpan = styled.span`
    font-weight: normal;
`;

const StyledHeader = styled.div`
    display: flex;
    position: relative;
    gap: 8px;
`;

const StyledAttributeInfoBox = styled(AttributeInfoBox)`
    max-width: 700px;
    align-self: center;
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-range-button {
        width: 150px;
    }

    .attribute-name {
        font-weight: 500;
    }
`;

export const RepeatedTimeRangeValueComponent = React.forwardRef(_RepeatedTimeRangeValueComponent);
