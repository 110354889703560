/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { useDateFieldState } from "react-stately";
import { useDateField, useDateSegment, useLocale } from "react-aria";
import { AriaDateFieldProps } from "@react-types/datepicker";
import { createCalendar } from "@internationalized/date";
import { styled } from "@iventis/styles";
import { DateValue } from "./date-types";

export type DateFieldProps = AriaDateFieldProps<DateValue> & { visuallyHideLabel?: boolean; className?: string; testId?: string };

export function DateField(props: DateFieldProps) {
    const { label, visuallyHideLabel, className } = props;
    const { locale } = useLocale();
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = useRef();
    const { fieldProps } = useDateField(props, state, ref);

    return (
        <StyledDateField {...fieldProps} ref={ref} className={className}>
            <StyledLabel $visuallyHideLabel={visuallyHideLabel ?? false}>{label}</StyledLabel>
            <div className="date-segment__wrapper">
                {state.segments.map((segment, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <DateSegment key={i} testId={`repeated-time-range-date-input-${props.testId}-${i}`} segment={segment} state={state} />
                ))}
            </div>
        </StyledDateField>
    );
}

function DateSegment({ segment, state, testId }) {
    const ref = useRef();
    const { segmentProps } = useDateSegment(segment, state, ref);

    return (
        <StyledDateSegment
            {...segmentProps}
            ref={ref}
            style={{
                ...segmentProps.style,
                minWidth: segment.maxValue != null && `${String(segment.maxValue).length}ch`,
            }}
            data-testid={testId}
        >
            {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
            <StyledSpan aria-hidden="true" $isPlaceholder={segment.isPlaceholder}>
                {segment.placeholder}
            </StyledSpan>
            {segment.isPlaceholder ? "" : segment.text}
        </StyledDateSegment>
    );
}

const StyledDateField = styled.div`
    display: flex;
    min-width: 14ch;
    color: ${(props) => props.theme.primaryColors.subduedMono};

    .date-segment__wrapper {
        display: flex;
    }
`;

const StyledDateSegment = styled.div`
    display: block;
    padding: 0 2px;
    box-sizing: border-box;
    font-variant-numeric: tabular-nums;
    text-align: right;
    outline: none;
    :focus-within {
        color: ${(props) => props.theme.typographyColors.blank};
        background-color: ${(props) => props.theme.primaryColors.focus};
    }
`;

const StyledSpan = styled.span<{ $isPlaceholder: boolean }>`
    display: block;
    width: 100%;
    text-align: center;
    pointer-events: none;
    visibility: ${(props) => (props.$isPlaceholder ? "visible" : "hidden")};
    height: ${(props) => (props.$isPlaceholder ? "" : "0")};
`;

const StyledLabel = styled.label<{ $visuallyHideLabel: boolean }>`
    display: block;
    font-size: 0.75rem;
    color: ${(props) => props.theme.primaryColors.subduedMono};
    ${(props) => props.$visuallyHideLabel && "display: none"}
`;
