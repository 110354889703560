/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { useDelayedObservableValue, useConstant, useObservableSelectedValue } from "@iventis/utilities";
import { InvokeCallback } from "xstate";
import { TreeBrowserStateInterface } from "./tree-browser-state";
import { NodeServices, TreeBrowserNode, TreeBrowserState } from "../types/data-types";
import { TreeBrowserXState } from "./xstate/tree-browser-xstate";
import { TreeBrowserMachineContext, TreeBrowserMachineEvent } from "./xstate/tree-browser.machine.types";
import { createInitialState } from "./tree-browser-initial-state";

export const useTreeBrowser = <TNode extends TreeBrowserNode, TKeys extends (keyof TreeBrowserState<TNode>)[]>(
    TreeBrowserContext: React.Context<TreeBrowserStateInterface<TNode>>,
    id?: string | null,
    ...keys: TKeys
) => {
    const treeBrowserContext = useContext(TreeBrowserContext);
    // If the loading nodes, selected nodes or expanded nodes does not include the given id, return true and the useObservableSelectedValue hook will block the re-render
    const blocker = <T>(prev: T, next: T, key: keyof TreeBrowserState<TNode>) =>
        ["loadingNodeIds", "selectedNodeIds", "expandedNodeIds"].includes(key) && Array.isArray(next[key]) && !(id == null || prev[key].includes(id) || next[key].includes(id));
    const state = useObservableSelectedValue(treeBrowserContext.state, treeBrowserContext.currentState, blocker, ...keys);
    return [state, treeBrowserContext] as const;
};

export type TreeBrowserHookOptions<TNode extends TreeBrowserNode> = Partial<{
    delayInitialisation: boolean;
    middleware: (state: TreeBrowserState<TNode>, event: TreeBrowserMachineEvent<TNode>) => void;
}>;

export const useTreeBrowserInitialise = <
    TNode extends TreeBrowserNode,
    TOptions extends TreeBrowserHookOptions<TNode> = TreeBrowserHookOptions<TNode>,
    TState extends Partial<TreeBrowserState<TNode>> = Partial<TreeBrowserState<TNode>>
>(
    initialState: TState,
    services: NodeServices<TNode>,
    websockets?: (services: NodeServices<TNode>) => (context: TreeBrowserMachineContext<TNode>) => InvokeCallback<TreeBrowserMachineEvent<TNode>, TreeBrowserMachineEvent<TNode>>,
    treeBrowserSelection?: TreeBrowserStateInterface<TNode>["treeBrowserSelection"],
    options?: TOptions
): TOptions extends { delayInitialisation: true }
    ? [TreeBrowserState<TNode>, TreeBrowserXState<TNode>, TreeBrowserStateInterface<TNode>["initialise"]]
    : TState extends { treeId: string }
    ? [TreeBrowserState<TNode>, TreeBrowserXState<TNode>]
    : never => {
    const treeBrowserContext = useConstant(() => new TreeBrowserXState(createInitialState(initialState), services, websockets, treeBrowserSelection));

    const { value: treeBrowserState, initialise } = useDelayedObservableValue(treeBrowserContext?.currentState);

    const instantiate = (
        state: Partial<TreeBrowserState<TNode>>,
        nodeServices: NodeServices<TNode> = services,
        middleware?: (state: TreeBrowserState<TNode>, event: TreeBrowserMachineEvent<TNode>) => void
    ) => {
        treeBrowserContext.initialise(createInitialState(state), nodeServices, middleware);
        initialise(treeBrowserContext.state);
    };

    useEffect(() => {
        if (!options?.delayInitialisation) {
            treeBrowserContext.initialise(createInitialState(initialState), services, options?.middleware);
            initialise(treeBrowserContext.state);
        }
        // If the user switches tab or window, we want to force the ctrl key to be up
        const onBlur = () => treeBrowserContext?.ctrlListener?.forceCtrlUp();
        window.addEventListener("blur", onBlur);
        return () => {
            treeBrowserContext.destroy();
            window.removeEventListener("blur", onBlur);
        };
    }, []);
    const state = treeBrowserState ?? createInitialState(initialState);

    // Unfortunately typescript is not clever enough to work out this terniary expression, so we cast it to any knowing that the return tuple is typed anyway
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return options?.delayInitialisation ? [state, treeBrowserContext, instantiate] : ([state, treeBrowserContext] as any);
};
