import { DisabledOverlay, InfoIcon, ValueSelector, ValueSelectorComponentCreator, CustomRadioSelector } from "@iventis/components";
import { DataField } from "@iventis/domain-model/model/dataField";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { fontSizes, inputHeight, narrowPadding } from "@iventis/styles/src/atomic-rules";
import { InteractiveElement, styled } from "@iventis/styles";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { Body2, Header5 } from "@iventis/styles/src/components/texts";
import { Content } from "@iventis/translations/content/typed-content";
import { Checkbox, useTheme } from "@mui/material";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import React, { FunctionComponent, ReactNode, useContext, useMemo, useState } from "react";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { CheckedCheckBox } from "@iventis/styles/src/components/checkbox-elements";
import { Theme } from "@emotion/react";
import { UnionOfStyleProperties, DerivedStyleValue, DerivedStyleValueType } from "@iventis/map-engine";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { defaultMappedZoomValues } from "@iventis/map-engine/src/utilities/default-style-values";
import { CreateFormulaModal } from "./create-formula";
import { ChangeStyleValue } from "./edit-style.helpers";
import { ToggleableStyleItemId } from "./style.types";
import { FormulaSummaryComponent } from "./edit-style-formula-summary";
import ZoomOptionsModal from "./edit-style-item-zoom-modal";
import { EditStyleContext } from "./edit-style-context";
import { LayerTourContext } from "./layer-tour";

export const dataDrivenButtonClassName = "data-driven-button";

type DefaultEditStyleItemProps<
    TStyleProperty extends UnionOfStyleProperties,
    TShowDataDriven extends boolean,
    TStyleValue = TStyleProperty extends "styleType" ? StyleValue<StyleType> : DerivedStyleValue<TStyleProperty>
> = {
    styleProperty: TStyleProperty;
    /** title of the edit style item to be shown in the template */
    title?: string | ReactNode;
    /** whether the title should use semiBold (default value false) */
    bold?: boolean;
    /** id for when the checkbox is clicked (optional) */
    toggleId?: ToggleableStyleItemId;
    /** to show the checkbox so the style item can be toggled to be hidden (default value false) */
    showToggle?: boolean;
    /** a function to handle when the checkbox is clicked (optional) */
    emitToggleChange?: (name: ToggleableStyleItemId, value: boolean) => void;
    icon?: ReactNode;
    showDataDriven?: TShowDataDriven;
    ignoreTitle?: boolean;
    infoTitle?: string;
    infoContent?: string | ReactNode;
    component?: ReturnType<ValueSelectorComponentCreator<ValueSelector<unknown>>>;
    dataFields?: DataField[];
    changeStyleValue?: ChangeStyleValue;
    value?: TStyleValue;
    /** If true will provide the option to change value by zoom level, defaults to true on all number fields */
    isZoomableValue?: boolean;
    disabled?: boolean;
    /** If true and is a zoomable value, will show the hide tickbox for eaach zoom level in the zoom level adjustment editor. Defaults to false */
    showHiddenOptions?: boolean;
    /** Override function for when Set using attributes button is pressed */
    onShowFormula?: () => void;
};

type EditStyleItemProps<
    TStyleProperty extends UnionOfStyleProperties,
    TShowDataDriven extends boolean,
    TStyleValueType = TStyleProperty extends "styleType" ? StyleType : DerivedStyleValueType<TStyleProperty>
> = TShowDataDriven extends true
    ? TStyleProperty extends "styleType"
        ? never // We can't do data driven styling on "styleType", so we must never allow it
        : // if data driven styling is applied, the props must include datafields and pa preview component
          DefaultEditStyleItemProps<TStyleProperty, TShowDataDriven> & { dataFields: DataField[]; PreviewComponent?: FunctionComponent<{ value: TStyleValueType }> }
    : DefaultEditStyleItemProps<TStyleProperty, TShowDataDriven> & { dataFields?: DataField[]; PreviewComponent?: FunctionComponent<{ value: TStyleValueType }> };

type Modals = "Zoom" | "Formula" | null;

/** A containter for a style item */
export function EditStyleItemComponent<TStyleProperty extends UnionOfStyleProperties, TShowDataDriven extends boolean>({
    styleProperty,
    title,
    bold = false,
    showToggle = false,
    toggleId,
    emitToggleChange,
    icon,
    showDataDriven = false,
    ignoreTitle = false,
    infoTitle,
    infoContent,
    component,
    dataFields,
    changeStyleValue,
    value,
    PreviewComponent,
    isZoomableValue = typeof value.staticValue.staticValue === "number" && !showDataDriven,
    disabled = false,
    showHiddenOptions = false,
    onShowFormula,
}: React.PropsWithChildren<EditStyleItemProps<TStyleProperty, TShowDataDriven>>) {
    const [show, setShow] = useState(true);

    const [currentModal, setCurrentModal] = useState<Modals>(null);

    const { setShowAddAttributeTour, canUserEdit } = useContext(EditStyleContext);

    const { ref: layerTourRef } = useContext(LayerTourContext);

    if (showDataDriven && isZoomableValue) {
        // eslint-disable-next-line no-param-reassign
        isZoomableValue = false;

        // eslint-disable-next-line no-console
        console.warn(`Data driven and zoomable values are not yet supported! Defaulting to data driven on ${styleProperty}`);
    }

    const translate = useIventisTranslate();

    const theme = useTheme<Theme>();

    // when the checkbox is clicked
    const handleToggleChange = () => {
        const newShowValue = !show;
        emitToggleChange(toggleId, newShowValue);
        setShow(newShowValue);
    };

    const changeValue = (value: StyleValue<unknown>) => changeStyleValue(styleProperty, value);

    const valueSelector = useMemo(
        () =>
            value.extractionMethod === StyleValueExtractionMethod.Static
                ? component({
                      value: value.staticValue.staticValue,
                      changeValue: (newValue: unknown) => changeValue({ ...value, staticValue: { ...value.staticValue, staticValue: newValue } }),
                  })
                : null,
        [value, changeValue]
    );
    const sufficientDataFieldsForDataDriven = dataFields && dataFields.length > 0 && dataFields.some((df) => df.type === DataFieldType.List);

    const [selectedUnit, setSelectedUnit] = useState(value.staticValue?.extractionMethod === ZoomableValueExtractionMethod.Static ? "fixed" : "zoom");

    const handleUnitChange = (unit: string) => {
        setSelectedUnit(unit);
        if (isZoomableValue) {
            if (unit === "zoom") {
                if (value.staticValue.mappedZoomValues == null) {
                    // We want to fill our mappedValues with default values based on the static value given
                    changeValue({
                        ...value,
                        staticValue: {
                            ...value.staticValue,
                            mappedZoomValues: defaultMappedZoomValues(value.staticValue.staticValue),
                            extractionMethod: ZoomableValueExtractionMethod.Continuous,
                        },
                    });
                } else {
                    changeValue({
                        ...value,
                        staticValue: {
                            ...value.staticValue,
                            extractionMethod: ZoomableValueExtractionMethod.Continuous,
                        },
                    });
                }
            } else {
                changeValue({
                    ...value,
                    staticValue: { ...value.staticValue, extractionMethod: ZoomableValueExtractionMethod.Static },
                });
            }
        }
    };

    return (
        <>
            <StyledEditStyleItemContainer formulaApplied={value.extractionMethod !== StyleValueExtractionMethod.Static} data-testid="edit-style-container">
                {(!canUserEdit || disabled) && <DisabledOverlay />}
                {!ignoreTitle && (
                    <StyledEditStyleTitleContainer>
                        {showToggle && (
                            <Checkbox
                                style={{ padding: "0px", paddingRight: "5px" }}
                                checked={show}
                                onChange={handleToggleChange}
                                checkedIcon={
                                    <CheckedCheckBox
                                        size={13}
                                        tickColour={theme.typographyColors.core}
                                        borderStyle={`1px solid ${theme.typographyColors.core}`}
                                        backgroundColour={theme.secondaryColors.blank}
                                    />
                                }
                            />
                        )}
                        {icon != null && icon}
                        {title != null && ((!bold && <div id="title">{title}</div>) || (bold && <Header5 id="title">{title}</Header5>))}
                        {infoTitle && infoContent && <InfoIcon title={infoTitle} content={infoContent} />}
                    </StyledEditStyleTitleContainer>
                )}
                {isZoomableValue && (
                    <div>
                        <CustomRadioSelector
                            options={[
                                { id: "fixed", name: translate(Content.map3.zoomableValues.fixed) },
                                { id: "zoom", name: translate(Content.map3.zoomableValues.by_zoom_level) },
                            ]}
                            onValueChange={(_, value) => handleUnitChange(value)}
                            row
                            defaultValueId={selectedUnit}
                            testId="edit-style-unit-selector"
                        />
                    </div>
                )}
                <IventisErrorBoundaryWithToast size="overlay">
                    <StyledInputRow>
                        {show && (
                            <>
                                {value.extractionMethod === StyleValueExtractionMethod.Static && selectedUnit === "fixed" && (
                                    <StyledChildContainer data-testid="edit-style-base-selector">{valueSelector}</StyledChildContainer>
                                )}
                                {value.extractionMethod === StyleValueExtractionMethod.Static && selectedUnit === "zoom" && (
                                    <StyledChildContainer>
                                        <StyledInteractiveElement onClick={() => setCurrentModal("Zoom")}>
                                            {translate(Content.map3.zoomableValues.edit_zoom_levels)}
                                        </StyledInteractiveElement>
                                    </StyledChildContainer>
                                )}
                                {value.extractionMethod === StyleValueExtractionMethod.Mapped && dataFields && (
                                    <FormulaSummaryComponent
                                        value={value}
                                        dataFields={dataFields}
                                        setFormulaDialogOpen={(open: boolean) => (open ? setCurrentModal("Formula") : setCurrentModal(null))}
                                        removeFormula={() => {
                                            changeValue({ ...value, extractionMethod: StyleValueExtractionMethod.Static, mappedValues: {}, dataFieldId: undefined });
                                        }}
                                        PreviewComponent={PreviewComponent}
                                        data-testid="edit-style-formula-layout"
                                    />
                                )}
                            </>
                        )}
                    </StyledInputRow>
                    {showDataDriven && value.extractionMethod === StyleValueExtractionMethod.Static && (
                        <div style={{ display: "flex", justifyContent: "right" }} className={`${dataDrivenButtonClassName}-container`}>
                            <StyledSetAttributeButton
                                onClick={() => {
                                    if (layerTourRef?.current?.isRunning()) {
                                        layerTourRef.current.minimiseTour();
                                    }
                                    if (sufficientDataFieldsForDataDriven) {
                                        if (onShowFormula) {
                                            onShowFormula();
                                        } else {
                                            setCurrentModal("Formula");
                                        }
                                    } else if (setShowAddAttributeTour) {
                                        // Provide joyride to add attributes
                                        setShowAddAttributeTour(true);
                                    } else {
                                        throw Error("setShowAddAttributeTour is not set on attribute driven style");
                                    }
                                }}
                                data-testid={`edit-style-formula-button-${title}`}
                                className={dataDrivenButtonClassName}
                            >
                                <Body2>{translate(Content.map4.attributes.dataDriven.label)}</Body2>
                            </StyledSetAttributeButton>
                        </div>
                    )}
                </IventisErrorBoundaryWithToast>
            </StyledEditStyleItemContainer>
            {showDataDriven && (
                <CreateFormulaModal
                    dataFields={dataFields}
                    save={changeValue}
                    value={value}
                    open={currentModal === "Formula"}
                    close={() => {
                        setCurrentModal(null);
                        if (layerTourRef?.current?.isRunning()) {
                            layerTourRef.current.maximiseTour();
                        }
                    }}
                    valueSelector={component}
                    styleProperty={styleProperty}
                />
            )}
            {currentModal === "Zoom" && (
                <ZoomOptionsModal
                    component={component}
                    changeValue={(newValue) => changeValue({ ...value, staticValue: newValue })}
                    value={value.staticValue}
                    open={currentModal === "Zoom"}
                    onClose={() => {
                        setCurrentModal(null);
                    }}
                    showHiddenOptions={showHiddenOptions}
                />
            )}
        </>
    );
}

const StyledInteractiveElement = styled(InteractiveElement)`
    text-decoration: underline;
    font-size: ${fontSizes.small};
`;

const StyledInputRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledChildContainer = styled.div`
    height: 100%;
    flex-grow: 1;
`;

export const StyledEditStyleItemContainer = styled.div<{ formulaApplied?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
    margin-right: auto;
    #data-driven-button {
        min-width: ${inputHeight};
    }
    position: relative;
`;

export const StyledEditStyleTitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 20px;
    margin-bottom: 10px;
    font-size: ${fontSizes.small};
`;

const StyledSetAttributeButton = styled(InteractiveElement)`
    text-decoration: underline;
    height: ${inputHeight};
    padding: ${narrowPadding};
`;
