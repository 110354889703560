import { DataField } from "@iventis/domain-model/model/dataField";
import { UnionOfStyles } from "@iventis/map-engine";
import { ChangeStyleValue } from "./edit-style.helpers";

export interface ToggleableStyleItems {
    id: ToggleableStyleItemId;
    name: string;
    show: boolean;
}

export enum ToggleableStyleItemId {
    BLUR = "blur",
    OFFSET = "offset",
}

export type EditStyleProps<TStyle extends UnionOfStyles> = {
    layerStyle: TStyle;
    changeStyleValue: ChangeStyleValue<TStyle>;
    dataFields: DataField[];
};
