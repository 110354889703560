import { LoadingComponent } from "@iventis/components";
import { AdminTreeNodeType } from "@iventis/people";
import { Body2, StyledFieldLabel, StyledInteractiveArea, borderRadius, formButton, zIndexes, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useTreeBrowserInitialise, createInitialState, TreeBrowserComponent, NODE_HEIGHT_PX } from "@iventis/tree-browser";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { SearchBarComponent, SimpleSearchResults } from "@iventis/search";
import { useQuery } from "@tanstack/react-query";
import { Project } from "@iventis/domain-model/model/project";
import { instanceServices, searchProjects } from "./project-api-functions";
import { ProjectNodeProps } from "./project-node";
import { TreeBrowserProjectsContext } from "./project.helpers";
import { useProjectSearch, InstancesSelector, ProjectSearch } from "./projects-tree";
import { AdminProjectNodeTree } from "./tree-browser-types";

/** Used inside resource pages to select an instance and a project (which writes to the url search params) */
export const useProjectSelector = () => {
    const location = useLocation();
    const nav = useNavigate();

    const { projectId, instanceName, isProjectSelected, projectName } = useMemo(() => {
        const projectId = new URLSearchParams(location.search).get("projectId");
        const projectName = new URLSearchParams(location.search).get("projectName");
        const instanceName = new URLSearchParams(location.search).get("instanceName");
        return { projectId, instanceName, isProjectSelected: projectId != null, projectName };
    }, [location.search]);

    return {
        embeddedSelector: <ProjectSelector />,
        switchProjectCallback: () => nav({ search: new URLSearchParams().toString() }),
        projectId,
        instanceName,
        isProjectSelected,
        projectName,
    } as const;
};

const ProjectSelector = () => {
    const [treeBrowserState, projectsTreeBrowserContext] = useTreeBrowserInitialise(createInitialState<AdminProjectNodeTree>({}), instanceServices, undefined, undefined, {
        delayInitialisation: false,
    });
    const { tree: projectsTree, isLoadingTree: isLoadingProjectsTree } = treeBrowserState;
    const { instance, setInstance, instances, isInstancesLoading, search, setSearch } = useProjectSearch(treeBrowserState, projectsTreeBrowserContext);
    const nav = useNavigate();
    return (
        <>
            <TreeBrowserProjectsContext.Provider value={projectsTreeBrowserContext}>
                <StyledSelectorContainer>
                    <div>
                        <StyledFieldLabel>Search for project</StyledFieldLabel>
                        <ProjectSearch search={search ?? ""} onChange={setSearch} loading={isInstancesLoading} disabled={instance == null} />
                    </div>
                    <InstancesSelector
                        instances={instances}
                        instance={instance}
                        isInstancesLoading={isInstancesLoading}
                        setInstance={(i) => {
                            setInstance(i);
                            nav({ search: new URLSearchParams({ instanceName: i }).toString() });
                        }}
                    />
                </StyledSelectorContainer>

                {isLoadingProjectsTree ? (
                    <LoadingComponent />
                ) : (
                    <TreeBrowserComponent<AdminProjectNodeTree> node={projectsTree} openNodes={[instance]} components={projectSelectorComponents} displayRootNode={false} />
                )}
            </TreeBrowserProjectsContext.Provider>
        </>
    );
};

export const ProjectSelectorNode: FunctionComponent<ProjectNodeProps> = ({ node }) => {
    const nav = useNavigate();
    return (
        <StyledBasicprojectNode>
            <Body2>{node.name}</Body2>
            <StyledInteractiveArea onClick={() => nav({ search: new URLSearchParams({ projectId: node.id, projectName: node.name, instanceName: node.parentId }).toString() })}>
                <span className="hidden">Click to select project</span>
            </StyledInteractiveArea>
        </StyledBasicprojectNode>
    );
};

export const projectSelectorComponents = {
    [AdminTreeNodeType.Root]: undefined,
    [AdminTreeNodeType.Project]: ProjectSelectorNode,
    [AdminTreeNodeType.User]: undefined,
};

const StyledSelectorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    .MuiAutocomplete-inputRoot,
    .MuiAutocomplete-input {
        min-width: 150px;
    }
`;

const StyledBasicprojectNode = styled.div`
    width: 100%;
    height: ${NODE_HEIGHT_PX}px;
    position: relative;
    border-bottom: 0.5px solid ${(props: { theme: Theme }) => props.theme.otherColors.separatorLight};
    display: flex;
    align-items: center;
    padding-left: 10px;
    :hover {
        background-color: ${(props: { theme: Theme }) => props.theme.otherColors.hover};
    }
`;

interface SimpleProjectSelectorProps {
    instance: string;
    onProjectSelected: (project: Project | null) => void;
}

/** Project selector which displays it's results below the search box */
export const SimpleProjectSelectorComponent: FunctionComponent<SimpleProjectSelectorProps> = ({ instance, onProjectSelected }) => {
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [projectSearch, setProjectSearch] = useState<string>("");
    const { isLoading: projectSearchLoading, data: projects } = useQuery(["projects", projectSearch], async () => searchProjects(projectSearch, instance), {
        enabled: projectSearch?.length > 1,
    });

    const handleProjectSelected = (project: Project) => {
        onProjectSelected(project);
        setSelectedProject(project);
    };

    return (
        <>
            <SearchBarComponent
                className="project-search"
                value={selectedProject?.name ?? projectSearch}
                onValueChange={(value) => {
                    setProjectSearch(value);
                    setSelectedProject(null);
                }}
                placeHolderText="Project search"
                loading={projectSearchLoading && projectSearch?.length > 1}
                disabled={instance == null}
                debounceMs={500}
            />
            {projects?.length > 0 && selectedProject == null && (
                <ProjectResults>
                    <SimpleSearchResults results={projects} noResultsMessage="No projects" searching={projectSearchLoading} onClick={handleProjectSelected} />
                </ProjectResults>
            )}
        </>
    );
};

const ProjectResults = styled.div`
    z-index: ${zIndexes.searchResults};
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.secondaryColors.blank};
    border: 1px solid ${({ theme }) => theme.shades.four};
    padding: 10px;
    border-radius: ${borderRadius.standard};

    button {
        height: ${formButton.height};
    }

    button:hover {
        background-color: ${({ theme }) => theme.otherColors.hover};
    }
`;
