import { Checkbox, useTheme } from "@mui/material";
import React, { FunctionComponent, useContext } from "react";
import { styled } from "@iventis/styles";
import { UncheckedCheckBox } from "@iventis/styles/src/components/checkbox-elements";
import { Theme } from "@emotion/react";
import { DisabledOverlay } from "@iventis/components";
import { EditStyleContext } from "./edit-style-context";

interface CollapsedStyleItem {
    id?: string;
    name: string;
    emitCheckboxChange: (name: string) => void;
}

/**
 * @param { string } id - id of the style item
 * @param { string } name - name of the style item which will be shown in the template
 * @param { (string) => void } emitCheckboxChange - a function to handle when the checkbox is clicked
 * NOTE: the check box will always not be checked, the checked style item is a seperate component
 * This is done because of the layout of the style items
 */
const CollapsedStyleItemComponent: FunctionComponent<CollapsedStyleItem> = ({ id, name, emitCheckboxChange }) => {
    const theme = useTheme<Theme>();
    // when checkbox is clicked
    const handleCheckboxChange = () => {
        emitCheckboxChange(id);
    };

    const { canUserEdit } = useContext(EditStyleContext);

    return (
        <StyledCollapsedStyleItemContainer>
            {!canUserEdit && <DisabledOverlay />}
            <Checkbox
                disabled={!canUserEdit}
                style={{ paddingLeft: "0px" }}
                checked={false}
                onChange={handleCheckboxChange}
                icon={<UncheckedCheckBox size={13} borderStyle={`1px solid ${theme.typographyColors.core}`} backgroundColour={theme.secondaryColors.blank} />}
            />
            <span>{name}</span>
        </StyledCollapsedStyleItemContainer>
    );
};

export default CollapsedStyleItemComponent;

const StyledCollapsedStyleItemContainer = styled.div`
    display: flex;
    margin-right: 15px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;
