import axios from "axios";

// Switch to true if you wish to use your local databases instead of the deployed api
const useLocalApi = window.location.hostname === "localhost" && false;
const devMode = window.location.hostname.toLowerCase() === "localhost";

const baseURL = () => {
    if (!devMode) {
        return `https://api.${window.location.hostname}`;
    }
    if (useLocalApi) {
        return `http://localhost:5190/`;
    }
    return "https://api.admin-dashboard-dev.iventis.com";
};

const initialiseAxios = () => axios.create({ baseURL: baseURL(), withCredentials: true });

export const api = initialiseAxios();
