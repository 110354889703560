/* eslint-disable no-console */
import { MAX_SIZE_MODEL, MIN_SIZE_MODEL } from "./model-sizing-component";

export interface ValidationReducer {
    showError: boolean;
    valid: boolean;
    touchedWidth: boolean;
    touchedHeight: boolean;
    touchedLength: boolean;
    widthValid: boolean;
    heightValid: boolean;
    lengthValid: boolean;
}

export type ValidationReducerAction = {
    value: number;
    type: "width" | "height" | "length";
};

export function createValidationReducerInitialValues(values: { height: number; length: number; width: number }): ValidationReducer {
    const widthValid = checkDimensionValid(values.width);
    const heightValid = checkDimensionValid(values.height);
    const lengthValid = checkDimensionValid(values.length);
    return {
        valid: widthValid && heightValid && lengthValid,
        showError: false,
        widthValid,
        heightValid,
        lengthValid,
        touchedWidth: false,
        touchedHeight: false,
        touchedLength: false,
    };
}

export const dimensionValidation = (values: { height: number; length: number; width: number }): boolean =>
    Object.values(values).every((dimension) => checkDimensionValid(dimension));

const checkDimensionValid = (value: number) => value >= MIN_SIZE_MODEL && value <= MAX_SIZE_MODEL;

/** Checks if the error should be shown when a value is changed. Error is shown when a value has touched and is invalid */
export function validationReducer(state: ValidationReducer, action: ValidationReducerAction): ValidationReducer {
    const widthTouchedInvalid = state.touchedWidth && !state.widthValid;
    const heightTouchedInvalid = state.touchedHeight && !state.heightValid;
    const lengthTouchedInvalid = state.touchedLength && !state.lengthValid;

    const isValueValid = checkDimensionValid(action.value);

    switch (action.type) {
        case "height":
            return {
                ...state,
                touchedHeight: true,
                heightValid: isValueValid,
                valid: isValueValid && state.widthValid && state.lengthValid,
                showError: !isValueValid || widthTouchedInvalid || lengthTouchedInvalid,
            };
        case "width":
            return {
                ...state,
                touchedWidth: true,
                widthValid: isValueValid,
                valid: isValueValid && state.heightValid && state.lengthValid,
                showError: !isValueValid || heightTouchedInvalid || lengthTouchedInvalid,
            };
        case "length":
            return {
                ...state,
                touchedLength: true,
                lengthValid: isValueValid,
                valid: isValueValid && state.widthValid && state.heightValid,
                showError: !isValueValid || heightTouchedInvalid || widthTouchedInvalid,
            };
        default:
            console.error(`Action ${action.type} not handled`);
            return state;
    }
}
