import { Active, Over } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const useDragAndDropSortable = (itemId: string, isDragAllowed: boolean) => {
    const { attributes, listeners, setNodeRef, isOver, isDragging, transition, transform, active, over } = useSortable({ id: itemId });

    const setRefs = (element: HTMLElement) => {
        if (isDragAllowed) {
            setNodeRef(element);
        }
    };

    const transitionStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return { setRefs, attributes, listeners, isOver, isDragging, transitionStyle, placement: getPlacement(active, over) } as const;
};

const getPlacement = (active: Active, over: Over) => {
    const activeIndex = active?.data?.current?.sortable?.index;
    const overIndex = over?.data?.current?.sortable?.index;
    if (activeIndex == null || overIndex == null || active.id === over.id) {
        return "none";
    }
    return activeIndex > overIndex ? "before" : "after";
};
