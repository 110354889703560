import { AutocompleteWithLoading, DisabledOverlay } from "@iventis/components";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { EMPTY_GUID } from "@iventis/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { DerivedStyleValue, UnionOfStyleProperties } from "@iventis/map-engine";
import { createStaticStyleValue, isStaticStyleDefaultValue } from "@iventis/map-engine/src/utilities/static-styles";
import { StyledEditStyleItemContainer, StyledEditStyleTitleContainer } from "./edit-style-item";
import { ChangeStyleValue } from "./edit-style.helpers";
import { createDataDrivenStyleValue } from "./data-driven-styles.helpers";

const allowedDataFieldTypes = [DataFieldType.List, DataFieldType.Text, DataFieldType.Number, DataFieldType.Time, DataFieldType.TimeRange, DataFieldType.What3Words];

type EditDataDrivenStyleItemProps<
    TStyleProperty extends UnionOfStyleProperties,
    TStyleValue = TStyleProperty extends "styleType" ? StyleValue<StyleType> : DerivedStyleValue<TStyleProperty>
> = {
    styleProperty: TStyleProperty;
    dataFields: DataField[];
    changeStyleValue?: ChangeStyleValue;
    value: TStyleValue;
    defaultValue: TStyleValue;
    disabled?: boolean;
};

/**
 * Component for setting or removing a data-driven style value for textContent.
 */
export function EditDataDrivenTextContent<TStyleValue extends StyleValue<unknown>>({
    dataFields,
    value,
    styleProperty,
    changeStyleValue,
    disabled = false,
    defaultValue,
}: EditDataDrivenStyleItemProps<UnionOfStyleProperties, TStyleValue>) {
    const translate = useIventisTranslate();

    const [styleValue, setStyleValue] = useState<TStyleValue>(value);

    useEffect(() => {
        if (isStaticStyleDefaultValue(styleValue, EMPTY_GUID)) {
            changeValue(defaultValue);
        }
    }, [styleValue]);

    const dataFieldToListItemMapping = useMemo(() => dataFields?.reduce((cum, { id, listValues }) => ({ ...cum, [id]: listValues }), {}), [dataFields]);

    const selectedDataField = useMemo(() => dataFields?.find((df) => df.id === styleValue?.dataFieldId), [styleValue?.dataFieldId, dataFields]);

    const setDataField = async (dataField: DataField) => {
        // If null set back to default value
        if (dataField == null) {
            changeValue(createStaticStyleValue(defaultValue));
        }

        switch (dataField.type) {
            case DataFieldType.List:
                {
                    const newStyleValue = createDataDrivenStyleValue<TStyleValue, typeof dataField.type>(
                        // With the absence of strict mode check, typescript can't infer that this is a list item, so we explicitly set it
                        { ...dataField, type: DataFieldType.List },
                        styleValue.staticValue,
                        dataFieldToListItemMapping[dataField?.id]
                    );

                    const newMappedValues: Record<string, ZoomableValue<unknown>> = {} as Record<string, ZoomableValue<unknown>>;

                    Object.keys(newStyleValue.mappedValues).forEach((x) => {
                        newMappedValues[x] = { ...newStyleValue.mappedValues[x], staticValue: x };
                    });

                    newStyleValue.mappedValues = newMappedValues;
                    changeValue(newStyleValue);
                }
                break;
            case DataFieldType.Time:
            case DataFieldType.TimeRange:
            case DataFieldType.Text:
            case DataFieldType.Number:
            case DataFieldType.What3Words:
                {
                    const newStyleValue = createDataDrivenStyleValue<TStyleValue, typeof dataField.type>({ ...dataField, type: dataField.type }, styleValue.staticValue, null);
                    changeValue(newStyleValue);
                }
                break;
            default:
                throw Error("DataFieldType not supported");
        }
    };

    const changeValue = (value: StyleValue<unknown>) => {
        setStyleValue(value as TStyleValue);
        changeStyleValue(styleProperty, value);
    };

    // Memo to check if the number of list items has updated, and if so, update the selected df
    useEffect(() => {
        if (
            styleValue &&
            selectedDataField &&
            selectedDataField.type === DataFieldType.List &&
            Object.keys(styleValue.mappedValues).length !== selectedDataField.listValues.length
        ) {
            setDataField(selectedDataField);
        }
    }, [selectedDataField]);

    return (
        <StyledEditStyleItemContainer>
            {disabled && <DisabledOverlay />}
            <AutocompleteWithLoading
                label={<StyledEditStyleTitleContainer id="title">{translate(Content.map2.styles.text_content)}</StyledEditStyleTitleContainer>}
                loadingOptions={false}
                onChange={setDataField}
                options={[...dataFields?.filter((df) => allowedDataFieldTypes.includes(df.type))]}
                value={selectedDataField}
                getOptionLabel={(option) => option.name}
                disabled={disabled}
                disableClearable
            />
        </StyledEditStyleItemContainer>
    );
}
