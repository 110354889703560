import React, { useEffect, useState } from "react";
import { createRoot, Root } from "react-dom/client";
import { flushSync } from "react-dom";

/** Given some string to jsx mapping, this hook returns the inner html string for each entry */
export const useRenderJsxToString = (jsx: Record<string, React.ReactElement>, tagName: string) => {
    const [state, setState] = useState<{ loadingIcons: boolean; icons: Record<string, string> }>({ loadingIcons: true, icons: {} });
    const elements = Object.keys(jsx).reduce<Record<string, HTMLElement>>((acc, name) => ({ ...acc, [name]: document.createElement(tagName) }), {});
    const roots = Object.entries(elements).reduce<Record<string, Root>>((acc, [name, element]) => ({ ...acc, [name]: createRoot(element) }), {});
    useEffect(() => {
        setTimeout(() => {
            flushSync(() => {
                Object.entries(roots).forEach(([name, root]) => {
                    root.render(jsx[name]);
                });
            });
            setState({
                loadingIcons: false,
                icons: Object.entries(elements).reduce<Record<string, string>>((acc, [name, element]) => ({ ...acc, [name]: element.innerHTML }), {}),
            });
        }, 0);
    }, []);
    return state;
};
